import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import { Text } from "../../layout/components/text";

const TextArea = (props) => (
  <div className={props.classes.container} style={props.style}>
    <span className={props.classes.label}>{props.title}</span>
    <textarea
      rows={props?.rows || "6"}
      cols="50"
      disabled={props.disabled || false}
      className={props.classes.field}
      style={props.textAreaStyle}
      onChange={(e) => (props.onChange ? props.onChange(e) : false)}
      name={props.name}
      value={props.value}
      placeholder={props.placeholder}
    />
    {props.footerNote && (
      <Text detail style={{ textAlign: "right" }}>
        {props.footerNote}
      </Text>
    )}
  </div>
);

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    gap: 4,
  },
  field: {
    fontSize: 14,
    padding: 16,
    border: `1px solid #eee`,
    backgroundColor: "#f7f7f7",
    borderRadius: 12,
    lineHeight: 1.618,
    fontFamily: "Ubuntu",
    fontSize: 14,
    color: "#444",
  },
  label: {
    fontSize: 14,
    fontWeight: "500",
    color: "#444",
  },
});

export default withStyles(styles)(TextArea);
