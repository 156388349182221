import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import Loading from "../utils/Loading";
import Card from "../cads";

import { formatter, uid } from "../../functions";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import AlertDialog from "../utils/AlertDialog";
import { Colors } from "../../styles/Colors";
import { Text } from "../layout/components/text";

class EditUser extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        name: "",
        email: "",
        active: false,
        email_credentials: false,
        admin: true,
        permission: "",
        mobileNumber: "",
        cpf: "",
        rg: "",
        dateBirth: "",
      },
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      showAlert: false,
      showErrorNewUser: false,
      showDeleteUserAlert: false,
      loading: true,
      showAlertNewUser: false,
      permissions: ["Administrador", "SEO", "Financeiro", "Arquiteto"],
      blockedId: "",
      partners: [],
      payment_profiles: [],
      showAlertPointsDelete: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onResetPasswordPress = this.onResetPasswordPress.bind(this);
    this.onDeleteUserPress = this.onDeleteUserPress.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount = async () => {
    const { _id } = this.state.data;
    if (!_id) return this.setState({ loading: false });

    const token = localStorage.getItem("token");
    const _this = this;

    axios
      .get(`${process.env.REACT_APP_API_URL}/user/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        _this.setState({ data: response.data });
        _this.getPaymentProfiles();
        _this.checkIfClientIsBlocked();
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/partners/all`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => _this.setState({ partners: response.data }))
      .finally(() => _this.setState({ loading: false }));
  };

  formatExpiration = (date) => {
    if (!date) return "-";

    const [_, month, year] = date.split("T")[0].split("-").reverse();
    return `${month}/${year.slice(-2)}`;
  };

  onChange = (e) => {
    const { data } = this.state;
    let { name, value } = e.target;

    if (name === "mobileNumber") {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    if (name === "cpf") {
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
    }

    this.setState({
      data: {
        ...data,
        [name]: value,
      },
      success: false,
    });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  checkFields = () => {
    const { name, email, mobileNumber, cpf, dateBirth } = this.state.data;

    if (!name || !email || !mobileNumber || !cpf || !dateBirth) {
      alert(
        "Os seguintes campos não podem estar vazios: Nome, E-mail, Celular, CPF, Data de aniversário."
      );
      return false;
    }

    return true;
  };

  onSavePress = async () => {
    const isFieldsChecked = this.checkFields();

    if (!isFieldsChecked) return;

    this.setState({ saving: true });

    const { data } = this.state;
    if (!data._id) return this.newUser();

    const token = localStorage.getItem("token");

    axios
      .patch(`${process.env.REACT_APP_API_URL}/user/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then(() => {
        this.setState({
          saving: false,
          success: true,
        });
        this.props.history.goBack();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newUser = async () => {
    const { data } = this.state;
    data._id = undefined;

    this.setState({
      saving: true,
    });

    const token = localStorage.getItem("token");
    const _this = this;

    axios
      .post(`${process.env.REACT_APP_API_URL}/user`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          showAlertNewUser: true,
          data: response.data.data,
        });

        _this.props.history.push(`${response.data.data._id}`);
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          showErrorNewUser: true,
          error: error.response.data.message,
        });
      });
  };

  resetPassword = async () => {
    const { data } = this.state;
    const token = localStorage.getItem("token");

    axios
      .patch(`${process.env.REACT_APP_API_URL}/login/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then(() => this.setState({ saving: false, success: true }))
      .catch((error) =>
        this.setState({ saving: false, success: false, error: error.response })
      );
  };

  deleteUser = async () => {
    const { data } = this.state;
    const token = localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}/user/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then(() => {
        this.setState({
          saving: false,
          success: true,
        });
        this.props.history.push("/app/users");
      })
      .catch((error) =>
        this.setState({ saving: false, success: false, error: error.response })
      );
  };

  onCancelPress = () => this.props.history.goBack();

  handleClick = (event) =>
    this.setState({ anchorEl: event.currentTarget, open: true });

  handleClose = () =>
    this.setState({ anchorEl: null, open: false, showAlert: false });

  onResetPasswordPress = () =>
    this.setState({ anchorEl: null, open: false, showAlert: true });

  onDeleteUserPress = () =>
    this.setState({ anchorEl: null, open: false, showDeleteUserAlert: true });

  checkIfClientIsBlocked = async () => {
    this.setState({ ...this.state, loading: true });

    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}/blockClient`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        let checkIfUserIsBlocked = data.filter(
          (user) => user.id_user == this.state.data._id
        );
        this.setState({
          ...this.state,
          ...(checkIfUserIsBlocked.length > 0 && {
            blockedId: checkIfUserIsBlocked[0]._id,
          }),
        });
      })
      .catch((error) => {
        this.setState({ ...this.state });
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  blockClient = async () => {
    const token = localStorage.getItem("token");
    const data = {
      name: this.state.data.name,
      id_user: this.state.data._id,
      cpf: this.state.data.cpf,
    };

    this.setState({ ...this.state, loading: true });
    axios
      .post(`${process.env.REACT_APP_API_URL}/blockClient`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then(() => {
        this.setState({ ...this.state, loading: false });
        this.props.history.go(0);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ ...this.state, loading: false });
      });
  };

  unblockClient = async () => {
    const { blockedId: userId } = this.state;
    const token = localStorage.getItem("token");

    this.setState({ ...this.state, loading: true });

    axios
      .delete(`${process.env.REACT_APP_API_URL}/blockClient/${userId}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then(() => {
        this.setState({ ...this.state });
        this.props.history.go(0);
      })
      .catch((error) => {
        console.log(error);
        this.setState({ ...this.state });
      })
      .finally(() => this.setState({ loading: false }));
  };

  getPaymentProfiles = () => {
    const { data } = this.state;
    let id_vindi = data.idVindi;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/vindi/paymentprofile/` + id_vindi,
        {}
      )
      .then((response) => {
        const dados = response;

        this.setState({
          payment_profiles: dados.data.data.payment_profiles,
        });
      });
  };

  formateShortDate(date) {
    const parts = date.split("/");

    const shortDay = parseInt(parts[0]);
    const shortMonth = parseInt(parts[1]);
    const shortYear = parseInt(parts[2]);
    return new Date(shortYear, shortMonth - 1, shortDay);
  }

  formateDefaultDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
  }

  formatDateWithT(date) {
    const parts = date.split("T")[0].split("-");
    const year = parseInt(parts[0]);
    const month = parseInt(parts[1]);
    const day = parseInt(parts[2]);
    return new Date(year, month - 1, day);
  }

  formatDate(dateString) {
    if (!dateString) return;

    let date = new Date(dateString);
    date.setHours(date.getHours() + 3);

    if (dateString.includes("/")) date = this.formateShortDate(dateString);

    if (dateString.includes("T") && dateString.includes("Z"))
      date = this.formatDateWithT(dateString);

    return this.formateDefaultDate(date);
  }

  render() {
    const {
      permissions,
      showAlert,
      showDeleteUserAlert,
      loading,
      showAlertNewUser,
      showErrorNewUser,
      error,
      blockedId,
      partners,
      payment_profiles,
      showAlertPointsDelete,
    } = this.state;
    const { data } = this.state;
    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title={data.name || "Novo usuário"}
          subtitle={blockedId ? `Cliente bloqueado` : ``}
          buttons={[
            {
              text: "Salvar",
              onClick: this.onSavePress,
            },
            ...(blockedId
              ? [
                  {
                    text: "Desbloquear",
                    destructive: true,
                    onClick: this.unblockClient,
                  },
                ]
              : [
                  {
                    text: "Bloquear",
                    destructive: true,
                    onClick: this.blockClient,
                  },
                ]),
          ]}
          dotsMenuItems={[
            { text: "Resetar senha", action: this.onResetPasswordPress },
            { text: "Excluir usuário", action: this.onDeleteUserPress },
          ]}
        />
        <Box
          style={{
            display: "flex",
            gap: 28,
            width: "100%",
          }}
        >
          <ContentContainer label="Dados" gap={0} fullWidth>
            <FormControl margin="normal" required fullWidth>
              <TextField
                id="name"
                name="name"
                label="Nome"
                variant="outlined"
                value={data.name}
                onChange={this.onChange}
                InputLabelProps={{
                  shrink: true,
                  style: { marginRight: 30, marginLeft: 4 },
                }}
                InputProps={{ style: { borderRadius: 40, paddingLeft: 10 } }}
                inputProps={{
                  style: {
                    fontSize: 14,
                    borderRadius: 40,
                    paddingLeft: 10,
                    WebkitBoxShadow: `0 0 0 1000px #fff inset`,
                  },
                }}
                style={{ borderRadius: 40 }}
                fullWidth
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextField
                id="email"
                name="email"
                label="E-mail"
                variant="outlined"
                value={data.email}
                onChange={this.onChange}
                InputLabelProps={{
                  shrink: true,
                  style: { marginRight: 30, marginLeft: 4 },
                }}
                InputProps={{ style: { borderRadius: 40, paddingLeft: 10 } }}
                inputProps={{
                  style: {
                    fontSize: 14,
                    borderRadius: 40,
                    paddingLeft: 10,
                    WebkitBoxShadow: `0 0 0 1000px #fff inset`,
                  },
                }}
                style={{ borderRadius: 40 }}
                fullWidth
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <TextField
                id="mobileNumber"
                name="mobileNumber"
                label="Celular"
                variant="outlined"
                value={data.mobileNumber}
                onChange={this.onChange}
                InputLabelProps={{
                  shrink: true,
                  style: { marginRight: 30, marginLeft: 4 },
                }}
                InputProps={{ style: { borderRadius: 40, paddingLeft: 10 } }}
                inputProps={{
                  style: {
                    fontSize: 14,
                    borderRadius: 40,
                    paddingLeft: 10,
                    WebkitBoxShadow: `0 0 0 1000px #fff inset`,
                  },
                  maxLength: 15,
                }}
                style={{ borderRadius: 40 }}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextField
                id="cpf"
                name="cpf"
                label="CPF"
                variant="outlined"
                value={data.cpf}
                onChange={this.onChange}
                InputLabelProps={{
                  shrink: true,
                  style: { marginRight: 30, marginLeft: 4 },
                }}
                InputProps={{ style: { borderRadius: 40, paddingLeft: 10 } }}
                inputProps={{
                  style: {
                    fontSize: 14,
                    borderRadius: 40,
                    paddingLeft: 10,
                    WebkitBoxShadow: `0 0 0 1000px #fff inset`,
                  },
                  maxLength: 14,
                }}
                style={{ borderRadius: 40 }}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <TextField
                id="rg"
                name="rg"
                label="RG"
                variant="outlined"
                value={data.rg}
                onChange={this.onChange}
                InputLabelProps={{
                  shrink: true,
                  style: { marginRight: 30, marginLeft: 4 },
                }}
                InputProps={{ style: { borderRadius: 40, paddingLeft: 10 } }}
                inputProps={{
                  style: {
                    fontSize: 14,
                    borderRadius: 40,
                    paddingLeft: 10,
                    WebkitBoxShadow: `0 0 0 1000px #fff inset`,
                  },
                }}
                style={{ borderRadius: 40 }}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextField
                id="dateBirth"
                name="dateBirth"
                label="Data de Nascimento"
                type="date"
                variant="outlined"
                value={this.formatDate(data?.dateBirth) || ""}
                onChange={this.onChange}
                InputLabelProps={{
                  shrink: true,
                  style: { marginRight: 30, marginLeft: 4 },
                }}
                InputProps={{ style: { borderRadius: 40, paddingLeft: 10 } }}
                inputProps={{
                  style: {
                    fontSize: 14,
                    borderRadius: 40,
                    paddingLeft: 10,
                    WebkitBoxShadow: `0 0 0 1000px #fff inset`,
                  },
                }}
                style={{ borderRadius: 40 }}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth variant="outlined">
              <InputLabel id="permission">Permissão</InputLabel>
              <Select
                key="permission"
                name="permission"
                value={data.permission}
                onChange={this.onChange}
                InputLabelProps={{
                  shrink: true,
                  style: { marginRight: 30, marginLeft: 4 },
                }}
                InputProps={{ style: { borderRadius: 40, paddingLeft: 10 } }}
                inputProps={{
                  style: {
                    fontSize: 14,
                    borderRadius: 40,
                    paddingLeft: 0,
                    WebkitBoxShadow: `0 0 0 1000px #fff inset`,
                  },
                }}
                style={{ borderRadius: 40, fontSize: 14, overflow: "hidden" }}
                label="Permissão **"
                labelId="permission"
                id="permission"
                fullWidth
              >
                {permissions.map((permission) => (
                  <MenuItem
                    key={uid()}
                    value={permission}
                    style={{ fontSize: 14 }}
                  >
                    {permission}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <TextField
                id="sapSellerId"
                name="sapSellerId"
                label="ID SAP do Vendedor"
                variant="outlined"
                value={data.sapSellerId}
                onChange={this.onChange}
                InputLabelProps={{
                  shrink: true,
                  style: { marginRight: 30, marginLeft: 4 },
                }}
                InputProps={{ style: { borderRadius: 40, paddingLeft: 10 } }}
                inputProps={{
                  style: {
                    fontSize: 14,
                    borderRadius: 40,
                    paddingLeft: 10,
                    WebkitBoxShadow: `0 0 0 1000px #fff inset`,
                  },
                }}
                style={{ borderRadius: 40 }}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth variant="outlined" style={{ marginTop: 20 }}>
              <InputLabel id="id_partner_label">Parceria</InputLabel>
              <Select
                name="id_partner"
                key="id_partner_label"
                value={data.id_partner}
                onChange={this.onChange}
                inputProps={{ style: { fontSize: 14 } }}
                style={{ fontSize: 14 }}
                label="Parceria"
                labelId="id_partner_label"
                id="id_partner"
                fullWidth
                defaultValue={""}
              >
                {partners.map((partner) => (
                  <MenuItem value={partner._id} style={{ fontSize: 14 }}>
                    {partner.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 10,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.active}
                    onChange={this.onCheckChange("active")}
                    value="active"
                    color="secondary"
                  />
                }
                label="Ativo"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.admin}
                    onChange={this.onCheckChange("admin")}
                    value="true"
                    color="secondary"
                  />
                }
                label="Administrador"
              />
            </Box>

            {!data._id && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.email_credentials}
                      onChange={this.onCheckChange("email_credentials")}
                      value="active"
                      color="primary"
                    />
                  }
                  label="Enviar credenciais de acesso por e-mail?"
                />
              </FormGroup>
            )}
          </ContentContainer>
          {payment_profiles?.length > 0 && (
            <ContentContainer
              label={`Cartões de crédito (${payment_profiles.length})`}
            >
              <Box sx={{ overflowX: "auto", paddingRight: 8, maxHeight: 780 }}>
                {payment_profiles.map((profile) => (
                  <Box
                    key={`${profile.holder_name}_${profile.card_number_last_four}`}
                    style={{
                      marginBottom: 12,
                      position: "relative",
                      overflow: "hidden",
                    }}
                    onClick={() => {}}
                  >
                    <Box style={{ ...styles.cardContainer }}>
                      <Box style={{ ...styles.cardContainerEffect }} />
                      <Box
                        style={{
                          width: "100%",
                          flex: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          flexDirection: "row",
                          zIndex: 2,
                        }}
                      >
                        <Box
                          style={{
                            width: 50,
                            height: 36,
                            backgroundColor: "#d7d7d7",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                            borderRadius: 3,
                            overflow: "hidden",
                          }}
                        >
                          <Box
                            style={{
                              width: 1,
                              height: "100%",
                              backgroundColor: "#d7d7d7",
                            }}
                          />
                          <Box
                            style={{
                              width: "100%",
                              height: 1,
                              backgroundColor: "#bbb",
                              position: "absolute",
                              top: 18,
                              left: 0,
                            }}
                          />
                          <Box
                            style={{
                              width: 8,
                              height: 10,
                              backgroundColor: "#d7d7d7",
                              position: "absolute",
                              top: 12,
                              left: 21,
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "#bbb",
                              borderRadius: 2,
                            }}
                          />
                          <Box
                            style={{
                              width: 46,
                              height: 22,
                              position: "absolute",
                              top: 7,
                              left: -27,
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "#bbb",
                              borderRadius: 4,
                            }}
                          />
                          <Box
                            style={{
                              width: 46,
                              height: 22,
                              position: "absolute",
                              top: 7,
                              right: -27,
                              borderWidth: 1,
                              borderStyle: "solid",
                              borderColor: "#bbb",
                              borderRadius: 4,
                            }}
                          />
                        </Box>

                        <Text
                          style={{
                            fontWeight: 500,
                            fontSize: 18,
                            color: "#333",
                          }}
                        >
                          {profile.payment_company.name}
                        </Text>
                      </Box>
                      <Box sx={{ zIndex: 1, marginTop: 16 }}>
                        <Box style={{ alignSelf: "center", marginBottom: 1 }}>
                          <Text
                            style={{
                              fontSize: 16,
                              letterSpacing: 4,
                              textAlign: "center",
                              color: "#333",
                            }}
                          >
                            **** **** **** {profile.card_number_last_four}
                          </Text>
                        </Box>

                        <Box
                          style={{
                            marginTop: 10,
                            alignSelf: "flex-start",
                            width: 238,
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Box style={{ display: "flex", flex: 1 }}>
                            <Text
                              style={{
                                fontSize: 14,
                                color: "#333",
                              }}
                            >
                              {profile.holder_name}
                            </Text>
                          </Box>
                          <Box style={{ display: "flex" }}>
                            <Text
                              style={{
                                fontSize: 14,
                                marginRight: 2,
                                color: "#333",
                              }}
                            >
                              {this.formatExpiration(profile.card_expiration)}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Text style={{ fontSize: 13 }}>
                        Cadastrado em{" "}
                        {formatter.date(profile.created_at, "short")}
                      </Text>
                    </Box>
                  </Box>
                ))}
              </Box>
            </ContentContainer>
          )}
        </Box>
        {showAlert && (
          <AlertDialog
            msg={`Deseja gerar uma nova senha e enviar para o e-mail ${data.email}`}
            title="Reenviar Senha"
            open={showAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showAlert: false,
                  });
                },
              },
              {
                label: "Reenviar",
                onPress: () => {
                  this.setState({
                    showAlert: false,
                  });

                  this.resetPassword();
                },
              },
            ]}
          />
        )}

        {/* Aparentemente nao e usado */}
        {showAlertPointsDelete && (
          <AlertDialog
            msg={`Deseja remover estes pontos ? `}
            title="Tuim Points"
            open={showAlertPointsDelete}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showAlertPointsDelete: false,
                  });
                },
              },
              {
                label: "Remover",
                onPress: () => {
                  this.setState({
                    showAlertPointsDelete: false,
                  });

                  this.removePoints();
                },
              },
            ]}
          />
        )}

        {showAlertNewUser && (
          <AlertDialog
            msg={`Novo usuário cadastrado com sucesso, um e-mail com a senha foi enviado para ${data.email}.`}
            title="Novo Usuário"
            open={showAlertNewUser}
            buttons={[
              {
                label: "Fechar",
                onPress: () => {
                  this.setState({
                    showAlertNewUser: false,
                  });
                },
              },
            ]}
          />
        )}

        {showErrorNewUser && (
          <AlertDialog
            msg={error}
            title="Falha ao Cadastrar Novo Usuário"
            open={showErrorNewUser}
            buttons={[
              {
                label: "Fechar",
                onPress: () => {
                  this.setState({
                    showErrorNewUser: false,
                  });
                },
              },
            ]}
          />
        )}

        {showDeleteUserAlert && (
          <AlertDialog
            msg={`Deseja relmente excluir o usuário ${data.name}? Esta ação é irreversível`}
            title="Excluir Usuário"
            open={showDeleteUserAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showDeleteUserAlert: false,
                  });
                },
              },
              {
                label: "Excluir",
                onPress: () => {
                  this.setState({
                    showDeleteUserAlert: false,
                  });

                  this.deleteUser();
                },
                color: "primary",
              },
            ]}
          />
        )}
      </>
    );
  }
}

const styles = {
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 10,
    backgroundColor: "#eee",
    padding: 25,
    marginBottom: 8,
    position: "relative",
    overflow: "hidden",
  },
  cardContainerEffect: {
    position: "absolute",
    top: -120,
    left: -210,
    backgroundColor: "#e7e7e7",
    width: 400,
    height: 320,
    borderRadius: 200,
    transform: "rotate(-35deg)",
    zIndex: 0,
  },
};

export default EditUser;
