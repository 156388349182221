import { Box, Button } from "@material-ui/core";
import axios from "axios";
import React, { useRef, useState } from "react";
import { formatter } from "../../functions";
import { useFetch } from "../../hooks/useFetch";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Text } from "../layout/components/text";

const REFERENCES = [
  { text: "Nome do cliente", value: "**NOME**" },
  { text: "CPF", value: "**CPF**" },
  { text: "RG", value: "**RG**" },
  { text: "Endereço", value: "**ENDERECO**" },
  { text: "Período", value: "**PERIODO**" },
  { text: "Data de Entrega", value: "**DATA_DE_ENTREGA**" },
  { text: "Produtos", value: "**PRODUTOS**" },
  { text: "Valor Total", value: "**VALOR_TOTAL**" },
  { text: "Taxa de Entrega", value: "**TAXA_DE_ENTREGA**" },
  {
    text: "Taxa de Mov. Adicional",
    value: "**TAXA_DE_MOVIMENTACAO_ADICIONAL**",
  },
  { text: "Data", value: "**DATA**" },
];

const user = JSON.parse(localStorage.getItem("tuimAdmin.user") || "{}");
const headers = { headers: { Authorization: "bearer " + user?.token } };

const ContractTemplate = () => {
  const ref = useRef(null);
  const { data } = useFetch({ url: "/v2/contract-template" });
  const [editMode, setEditMode] = useState(false);

  const contractLines = data?.template?.split(/\\n/g) || [];

  const lastUpdate = data?.updatedAt
    ? `Última atualização em ${formatter.date(data.updatedAt, "short")} por ${
        data.updatedBy.name
      }.`
    : "";

  const createContract = async () => {
    const newContractLines = ref.current.value.replace(/\\n/g, "\n");
    const body = { template: newContractLines };

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/v2/contract-template`,
        body,
        headers
      )
      .then(({ status }) => {
        if (status === 200) window.location.reload();
      })
      .catch((error) => alert("Tivemos um problema ao atualizar o contrato."));
  };

  return (
    <>
      <ContentHeader
        title="Contrato de Locação"
        subtitle={lastUpdate}
        buttons={[
          ...(editMode
            ? [{ text: "Salvar", secondary: true, onClick: createContract }]
            : [
                {
                  text: "Editar",
                  tertiary: true,
                  onClick: () => setEditMode(true),
                },
              ]),
        ]}
      />
      <ContentContainer>
        {editMode ? (
          <>
            <Box style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
              {REFERENCES.map(({ text, value }) => (
                <ReferenceTag
                  key={value}
                  text={text}
                  action={() => {
                    const start = ref.current.selectionStart;
                    const end = start;
                    ref.current.setRangeText(value, start, end);
                    ref.current.focus();
                  }}
                />
              ))}
            </Box>
            <textarea
              ref={ref}
              defaultValue={data?.template?.replace(/\\n/g, "\n\n") || ""}
              rows={24}
              style={styles.textArea}
            />
          </>
        ) : (
          <>
            {contractLines.map((line) => (
              <Text key={line} style={{ whiteSpace: "break-spaces" }}>
                {line}
              </Text>
            ))}
          </>
        )}
      </ContentContainer>
    </>
  );
};

const ReferenceTag = ({ text, action }) => (
  <Button style={styles.tagRoot} onClick={action}>
    <Text style={{ fontSize: 12 }}>{text}</Text>
  </Button>
);

const styles = {
  textArea: {
    textAlign: "justify",
    resize: "none",
    padding: 20,
    borderRadius: 8,
    fontFamily: "Roboto",
    fontSize: 14,
    color: "#444",
  },
  tagRoot: {
    backgroundColor: "#eee",
    borderRadius: 20,
    padding: `6px 14px`,
  },
};

export default ContractTemplate;
