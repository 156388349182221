import React, { Component } from "react";
import {
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Dropzone from "../ui/components/upload/Dropzone";
import Loading from "../utils/Loading";
import { Alert } from "../ui";
import readXlsxFile from "read-excel-file";
import axios from "axios";
import Color from "../../functions/Color";
import FormControl from "@material-ui/core/FormControl";

class ImportProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      alert: {
        open: false,
        title: false,
        msg: "",
      },
      saveProduct: false,
      products: [],
      discounts: [],
      data: [],
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: false });
    this.getDiscount();
  };
  getDiscount = async () => {
    const _this = this;
    const token = await localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}/listDiscount`, {
        // axios.get('http://127.0.0.1:3000/kit', {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          discounts: data,
        });
        // console.log('data discounts ', data)
      });
  };
  changeDiscount = (e) => {
    const { discounts } = this.state;
    let index = e.target.value;
    if (index === "") {
      this.setState({
        createDiscount: "on",
        data: {
          ...this.state.data,
          discounts: [],
        },
      });
    } else {
      let descontos = discounts[index].discounts;
      let descontoNome = discounts[index].name;
      this.setState({
        createDiscount: "",
        data: {
          ...this.state.data,
          discounts: descontos,
          discountName: descontoNome,
        },
      });
    }
  };

  getColors = async (colors) => {
    let result = colors.split("/");
    let array = [];
    await Promise.all(
      result.map((item, index) => {
        //Transform the first character on sencond or later words
        const colorString = item
          .split(" ")
          .map((word, index) => {
            return index > 0
              ? word.charAt(0).toUpperCase() + word.substring(1)
              : word;
          })
          .join("");

        if (Color[colorString]) {
          array.push({
            color: Color[colorString],
          });
        }
      })
    );

    return array;
  };

  onDrop = async (files) => {
    this.setState({ loading: true });
    let products = [];

    await readXlsxFile(files[0]).then(async (rows) => {
      await Promise.all(
        rows.map(async (item, index) => {
          if (index !== 0) {
            const productModel = {
              iv: item[0], //id SAP
              stock: item[1], // Stock
              // price: item[1], // Price
              // order: item[2], // order
              // visible: item[3] == 0 ? true : false, // Visible
              // active: item[4] == 0 ? true : false, // Active
            };

            if (productModel) {
              products.push(productModel);
            }
          }
        })
      );
    });
    this.setState({ products: products });
    this.setState({ loading: false });
  };
  verifyListProduct = async () => {
    const { products = [] } = this.state;
    this.setState({ loading: true, saveProduct: true });
    const token = await localStorage.getItem("token");

    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/checkProductsIntegration`,
        {
          products,
        },
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        this.setState({
          alert: {
            open: true,
            title: false,
            msg: "Produtos verificados com sucesso.",
          },
          loading: false,
          data: response.data,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({
          alert: {
            open: true,
            title: false,
            msg: "Ocorreu um erro ao verificar os produtos.",
          },
          loading: false,
        });
      });
  };

  onSave = async () => {
    const { products = [], data } = this.state;
    data.products = products;
    console.log(data);
    this.setState({ loading: true });
    const token = await localStorage.getItem("token");
    // if(typeof data.discounts == 'undefined'){
    //     alert("Escolhar a tabela de preço")
    //     this.setState({ loading: false });
    //     return false
    // }

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/updateProductsFromExcelExistents`,
        {products: data.products},
        { headers: { Authorization: "bearer " + token } }
      )
      .then((result) => {
        this.setState({
          alert: {
            open: true,
            title: false,
            msg: "Produtos atualizados com sucesso.",
          },
          loading: false,
        });
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({
          alert: {
            open: true,
            title: false,
            msg: "Ocorreu um erro ao atualizar os produtos.",
          },
          loading: false,
        });
      });
  };

  onVerifyProducts = async () => {};
  render() {
    const { loading, alert, products, saveProduct, data, discounts } =
      this.state;
    const { classes } = this.props;
    const coluns = [
      { id: "id_sap", disablePadding: false, label: "ID SAP" },
      { id: "stock", disablePadding: false, label: "Estoque SP" },
      // { id: "price", disablePadding: false, label: "Preço" },
      // {
      //     id: "visible",
      //     disablePadding: false,
      //     label: "Exibir",
      //     align: "center",
      // },
      // {
      //     id: "active",
      //     disablePadding: false,
      //     label: "Ativo",
      //     align: "center",
      // },
    ];

    return (
      <Paper className={classes.paper}>
        <Loading loading={loading} />
        {alert.open && (
          <Alert open={alert.open} title={alert.title} msg={alert.msg} />
        )}
        <Typography component="h1" variant="h5" style={{ paddingBottom: 50 }}>
          Atualização de Produtos
        </Typography>
        <div style={{ maxWidth: 150, padding: "20px 0px", marginTop: "-60px" }}>
          <FormControl
            style={{
              margin: 1,
              minWidth: 120,
            }}
          >
            <InputLabel id="demo-simple-select-label">
              {typeof data.discountName == "undefined"
                ? "Descontos"
                : data.discountName}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={data.discountName}
              onChange={this.changeDiscount}
            >
              <MenuItem value="">Criar um valor </MenuItem>
              {discounts.map((d, index) => (
                <MenuItem value={index}>{d.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Typography variant="body" style={{ paddingBottom: 5 }}>
          Planilha de Produtos
        </Typography>
        <Dropzone
          accept=".xlsx"
          onDrop={this.onDrop}
          txt="Arraste aqui a planilha de produtos ou clique e selecione a planilha."
        />

        <div style={{ maxHeight: 300, height: 300, overflowY: "scroll" }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {coluns.map(
                  (row) => (
                    <TableCell
                      key={row.id}
                      align={row.align}
                      padding={row.disablePadding ? "none" : "default"}
                    >
                      {row.label}
                    </TableCell>
                  ),
                  this
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product, index) => {
                return (
                  <TableRow
                    // key={row._id}
                    hover
                    className={classes.row}
                  >
                    <TableCell component="th" scope="row">
                      {product.iv}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {product.stock}
                    </TableCell>
                    {/* <TableCell  component="th" scope="row">
                                            {product.price}
                                        </TableCell>                                      
                                        <TableCell  component="th" scope="row" align="center">
                                            {product.visible ? <CheckCircle /> : "-"}
                                        </TableCell>
                                        <TableCell  component="th" scope="row" align="center">
                                            {product.active ? (
                                                <CheckCircle style={{ color: "green" }} />
                                            ) : (
                                                "-"
                                            )}
                                        </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {saveProduct == true ? (
          <>
            <hr style={{ width: "100%", height: 1, marginTop: 35 }} />
            <Typography
              variant="body"
              style={{ paddingBottom: 5, marginTop: 23, fontWeight: "600" }}
            >
              Produtos que não estão com o integra Tuim no SAP
            </Typography>
            <Table style={{ maxHeight: 100, width: 572, overFlowY: "auto" }}>
              <TableHead>
                <TableRow>
                  <TableCell>ID SAP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((iv, index) => {
                  return (
                    <TableRow>
                      <TableCell>{iv}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </>
        ) : (
          <></>
        )}

        <div>
          <Button
            variant="outlined"
            style={{ marginTop: 25, marginBottom: 25 }}
            color="primary"
            onClick={() => this.onSave()}
          >
            Salvar Produtos
          </Button>

          {/*   <Button
                        variant="contained"
                        style={{
                            marginTop: 25,
                            marginBottom: 25,
                            marginLeft: 25,
                            background: "#fff",
                        }}
                        onClick={() => this.verifyListProduct()}
                    >
                        Analisar Produtos
                    </Button> */}
        </div>
      </Paper>
    );
  }
}

const styles = (theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  table: {
    maxHeight: 300,
    overFlowY: "auto",
  },
});

export default withStyles(styles)(ImportProducts);
