import {
  Box,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextEditor from "../TextEditor";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import Dropzone from "../ui/components/upload/Dropzone";
import Loading from "../utils/Loading";

const token = localStorage.getItem("token");

export default function EditArticle(props) {
  const { history } = props;
  const { pathname } = props.location;
  const _id = pathname.split("/")[3];

  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({
    title: "",
    description: "",
    active: false,
    id_category: "",
    urlImg: "",
    active: true,
    htmlContent: "",
  });
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response1 = await axios.get(
          `${process.env.REACT_APP_API_URL}/articles/category`,
          {
            headers: { Authorization: "bearer " + token },
          }
        );
        setCategories(response1.data);

        if (_id) {
          const response2 = await axios.get(
            `${process.env.REACT_APP_API_URL}/articleID/${_id}`,
            {
              headers: { Authorization: "bearer " + token },
            }
          );
          setArticle(response2.data[0]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setArticle({ ...article, [name]: value });
  };

  const updateThumbail = (urlImg) => setArticle({ ...article, urlImg });

  const onDropFiles = async (files, callBack) => {
    return await files
      .map((file) => ({
        file,
        id: 0,
        name: file.name,
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
      }))
      .map(async (file) => {
        await processUpload(file, callBack).then((response) => {
          return response;
        });
      });
  };

  const processUpload = async (uploadedFile, callBack) => {
    const data = new FormData();
    data.append("file", uploadedFile.file, uploadedFile.name);

    await axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        return callBack ? callBack(data.url) : data.url;
      })
      .catch((error) => console.log("error", error));
  };

  const onSave = async () => {
    if (_id) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/updateArticle/${_id}`,
          article,
          {
            headers: { Authorization: "bearer " + token },
          }
        )
        .then(() => {
          alert("Artigo atualizado com sucesso!");
          history.goBack();
        })
        .catch((error) => {
          console.log(error);
          alert("Tivemos um problema ao salvar o artigo");
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/addArticle`, article, {
          headers: { Authorization: "bearer " + token },
        })
        .then((response) => {
          alert("Artigo criado com sucesso!");
          history.push(`${response.data._id}`);
        })
        .catch((error) => {
          alert(
            error?.response?.data?.error ||
              "Tivemos um problema ao salvar o artigo"
          );
        });
    }
  };

  const deleteArticle = async () => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/deleteArticle/${_id}`,
        article,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          alert("Artigo foi excluido!");
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleActiveChange = (event) => {
    setArticle({ ...article, active: event.target.checked });
  };

  const handleCategoryChange = (event) => {
    const newCategoryTitle = event.target.value;
    const newCategory = categories.find(
      (category) => category.title === newCategoryTitle
    );

    setArticle({ ...article, id_category: [newCategory] });
  };

  return (
    <>
      <Loading loading={loading} />
      <ContentHeader
        title={article?.title || "Novo artigo"}
        buttons={[
          { text: "Salvar", secondary: true, onClick: onSave },
          { text: "Excluir", destructive: true, onClick: deleteArticle },
        ]}
      />
      <ContentContainer>
        <TextField
          size="medium"
          variant="outlined"
          required
          name="title"
          label="Título"
          fullWidth
          autoComplete="title"
          value={article?.title || ""}
          onChange={handleChange}
          style={{ marginTop: 5 }}
        />
        <TextField
          multiline
          size="medium"
          variant="outlined"
          required
          name="description"
          label="Descrição"
          fullWidth
          autoComplete="description"
          value={article?.description || ""}
          onChange={handleChange}
          style={{ marginBottom: 20 }}
        />

        <Box
          style={{
            display: "flex",
            gap: 16,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              flex: 1,
            }}
          >
            <Select
              style={{ backgroundColor: "#FAFAFA", marginBottom: 10 }}
              variant="outlined"
              value={article?.id_category[0]?.title || ""}
              onChange={handleCategoryChange}
              name="id_category"
              fullWidth
            >
              {categories.map((category) => (
                <MenuItem value={category.title}>{category.title}</MenuItem>
              ))}
            </Select>
            <Dropzone
              txt="Arraste aqui a Thumbnail do artigo ou clique para selecionar."
              onDrop={(files) => onDropFiles(files, updateThumbail)}
            />
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={article?.active}
                    onChange={handleActiveChange}
                  />
                }
                label={article?.active ? "Ativo" : "Inativo"}
              />
            </FormGroup>
          </Box>

          <Box
            style={{
              marginBottom: 15,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 8,
              width: 300,
            }}
          >
            {article.urlImg ? (
              <>
                <Box style={{ fontWeight: 500, marginBottom: 2 }}>
                  Thumbnail
                </Box>
                <img
                  src={article.urlImg}
                  style={{ width: "100%" }}
                  alt="main-image"
                />
              </>
            ) : (
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#f0f0f0",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 8,
                }}
              >
                <span style={{ color: "#999" }}>Thumbnail</span>
              </Box>
            )}
          </Box>
        </Box>

        {!_id ? (
          <TextEditor
            doc={article}
            setDoc={setArticle}
            uploadImg={async (files) => await onDropFiles(files)}
            style={{ color: "#999" }}
          />
        ) : (
          <>
            {article?.htmlContent && (
              <TextEditor
                doc={article}
                setDoc={setArticle}
                uploadImg={async (files) => await onDropFiles(files)}
                style={{ color: "#999" }}
              />
            )}
          </>
        )}
      </ContentContainer>
    </>
  );
}
