import React, { Component } from 'react';
import axios from 'axios'
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, TextField, Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Loading from '../utils/Loading';
import AlertDialog from '../utils/AlertDialog'

class EditPasswordDialog extends Component {

   constructor(props) {
      super(props)

      this.state = {
         data: {
            old_password: '',
            new_password: '',
            check_password: '',
         },
         error: false,
         error_msg: '',
         loading: false,
         success: false,
         showDialog: true
      };

      this.onChange = this.onChange.bind(this);
   }

   onChange = (e) => {

      const { data } = this.state

      this.setState({
         data: {
            ...data,
            [e.target.name]: e.target.value
         }
      })
   }

   handleClose = () => {
      this.props.onClose()
      this.setState({ open: false });
   };

   onChangePassword = async () => {

      await this.checkValues()

      const { error } = this.state

      const { old_password, new_password, check_password } = this.state.data

      if (error) {
         return
      }

      this.setState({
         saving: true,
         loading: true
      })

      const token = await localStorage.getItem('token');

      axios.post(`${process.env.REACT_APP_API_URL}/editPassword/${localStorage._id}`, { new_password, old_password, confirmation_password: check_password }, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {

            const { success, message } = response.data

            // console.log('success, message', success, message)

            if (!success) {
               this.setState({
                  error_msg: message,
                  error: true,
                  success,
                  loading: false
               })
               return
            }

            // console.log('message', message)
            this.setState({
               saving: false,
               success: true,
               loading: false,
               message
            })



            // this.props.history.goBack()
            // this.handleClose()

         })
         .catch(error => {
            // this.setState({
            // 	saving: false,
            // 	success: false,
            // 	error_msg: error.message
            // })

            console.log('error', error)
         })
   }

   checkValues = () => {
      const { old_password, new_password, check_password } = this.state.data

      if (old_password.length < 1) {
         this.setState({
            error: true,
            error_msg: 'Favor inserir a senha anterior'
         })

         return
      }

      if (new_password.length < 8) {
         this.setState({
            error: true,
            error_msg: 'A nova senha deve conter no mínimo 8 caracteres'
         })

         return
      }

      if (check_password != new_password) {
         this.setState({
            error: true,
            error_msg: 'As senhas não conferem'
         })

         return
      }

      this.setState({
         error: false,
         error_msg: ''
      })
   }

   render() {

      const { open, classes } = this.props
      const { error, loading, success, message } = this.state
      const { old_password, new_password, check_password } = this.state.data

      return (
         <div>

            <Loading loading={loading} />

            <Dialog
               open={open}
               aria-labelledby="form-dialog-title"
            >
               <DialogTitle id="form-dialog-title">w3Lib Admin</DialogTitle>
               <DialogContent>
                  <DialogContentText>
                     Sua nova senha deve conter no mínimo 8 caracteres.
                  </DialogContentText>
                  <TextField
                     autoFocus
                     // onBlur={this.checkValues}
                     required
                     margin="dense"
                     id="password"
                     label="Senha Atual"
                     type="password"
                     name='old_password'
                     fullWidth
                     value={old_password}
                     onChange={this.onChange}
                  />
                  <TextField
                     required
                     // onBlur={this.checkValues}
                     margin="dense"
                     id="new_password"
                     label="Nova Senha"
                     type="password"
                     name='new_password'
                     fullWidth
                     value={new_password}
                     onChange={this.onChange}
                  />
                  <TextField
                     margin="dense"
                     // onBlur={this.checkValues}
                     required
                     id="check_password"
                     label="Repita a Nova Senha"
                     type="password"
                     name='check_password'
                     fullWidth
                     value={check_password}
                     onChange={this.onChange}
                  />
                  {error && <DialogContentText className={classes.errorMsg}>
                     {this.state.error_msg}
                  </DialogContentText>}
               </DialogContent>
               <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                     Cancelar
                  </Button>
                  <Button onClick={this.onChangePassword} color="primary">
                     Alterar Senha
                  </Button>
               </DialogActions>
            </Dialog>


            {success && <AlertDialog
               msg={message}
               title="Alteração de Senha"
               open={success}
               buttons={[
                  {
                     label: 'Fechar',
                     onPress: () => {

                        this.setState({
                           success: false,

                        })

                        this.handleClose()
                     }
                  }
               ]}
            />}
         </div>
      );
   }
}

const styles = theme => ({
   errorMsg: {
      fontSize: 12,
      color: theme.palette.error.main,
      marginTop: theme.spacing.unit * 3
   }
});

export default (withStyles(styles)(EditPasswordDialog))