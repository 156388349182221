import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  withTheme,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";
import { uid } from "../../../../functions";
import { TuimButton } from "../../Button";
import { Text } from "../text";

const ContentHeader = (props) => {
  const {
    title = "",
    subtitle,
    buttons = [],
    link = null,
    style,
    dotsMenuItems = [],
    blockedClient = false,
  } = props;

  const [dotsMenu, setDotsMenu] = useState({
    anchorEl: null,
    openMenuOptions: false,
  });
  const Title = () => (
    <Text title style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
      {title}
    </Text>
  );

  const handleClose = (event = null) => {
    setDotsMenu({
      anchorEl: event?.currentTarget || null,
      openMenuOptions: !dotsMenu.openMenuOptions,
    });
  };

  return (
    <div style={{ ...{ display: "flex", flexDirection: "row" }, ...style }}>
      <div
        xs={6}
        style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {link ? (
            <Link href={link} target="_blank">
              <Title />
            </Link>
          ) : (
            <Title />
          )}
          {dotsMenuItems.length > 0 && (
            <Box
              style={{
                display: "flex",
                gap: 8,
              }}
            >
              <IconButton
                aria-label="More"
                // aria-owns={true}
                aria-haspopup="true"
                onClick={handleClose}
                style={{ width: 40, height: 40, alignSelf: "center" }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={dotsMenu.anchorEl}
                open={dotsMenu.openMenuOptions}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 200,
                    width: 200,
                  },
                }}
              >
                {dotsMenuItems.map(({ action, text }) => (
                  <MenuItem
                    key={uid()}
                    onClick={() => {
                      action();
                      handleClose();
                    }}
                  >
                    {text}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}
        </Box>
        {subtitle && typeof subtitle === "string" ? (
          <Text
            style={
              blockedClient
                ? {
                    color: "#444444",
                    backgroundColor: "#f6c8b8",
                    border: "1px",
                    borderStyle: "solid",
                    borderColor: "#CE856A",
                    padding: "7px 20px",
                    borderRadius: "12px",
                    width: "fit-content",
                    fontWeight: "500",
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }
                : { color: "#999" }
            }
          >
            {subtitle}
          </Text>
        ) : (
          subtitle
        )}
      </div>
      <div
        xs={6}
        style={{
          textAlign: "right",
          display: "flex",
          alignItems: "center",
          gap: 8,
        }}
      >
        {buttons.map((button) => (
          <TuimButton key={uid()} {...button} />
        ))}
      </div>
    </div>
  );
};

export default withTheme(ContentHeader);
