import { Box } from "@material-ui/core";
import React from "react";
import { BsCartDash, BsCartPlus } from "react-icons/bs";
import { FiUser } from "react-icons/fi";
import { IoLocationOutline, IoTimeOutline } from "react-icons/io5";
import { LuCircleDollarSign } from "react-icons/lu";
import { MdAddCircleOutline, MdOutlineDiscount, MdOutlineRemoveCircleOutline, MdOutlineShoppingCartCheckout } from "react-icons/md";
import { PiTruckDuotone } from "react-icons/pi";
import { formatter } from "../../functions";
import { Colors } from "../../styles/Colors";
import { Text } from "../layout/components/text";

export const CartHistory = ({ event, lastChild }) => {
  const Icon = eventTypes[event.type] || null;

  return (
    <Box style={styles.root}>
      {Icon &&
        <Box style={styles.iconContainer}>
          <Icon.component size={22} color={Icon.color || '#bbb'} style={{ minWidth: 22, minHeight: 22 }} />
          {!lastChild && <DashedLine />}
        </Box>
      }
      <Box style={{ marginBottom: 20 }}>
        <Text strong>{event.event}</Text>
        <Text detail>{formatter.date(event.createdAt, 'short')}</Text>
      </Box>
    </Box>
  )
}

const DashedLine = () => (
  <Box style={{
    width: 2,
    height: '100%',
    borderRight: `1px dashed #ccc`,
    margin: `8px 0px`,
  }} />
);

const styles = {
  root: {
    display: 'flex',
    gap: 16,
  },
  iconContainer: {
    padding: `0px 4px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
}

const eventTypes = {
  'period': { component: IoTimeOutline },
  'location': { component: IoLocationOutline },
  'coupon': { component: MdOutlineDiscount },
  'login': { component: FiUser },
  'checkout-page': { component: MdOutlineShoppingCartCheckout },
  'sale-made': { component: LuCircleDollarSign, color: Colors.lightGreen },
  'add-product': { component: BsCartPlus, color: Colors.lightGreen },
  'remove-product': { component: BsCartDash, color: '#d67660' },
  'increment-product-qtd': { component: MdAddCircleOutline, color: Colors.lightGreen },
  'decrement-product-qtd': { component: MdOutlineRemoveCircleOutline, color: '#d67660' },
  'deliveryDate': { component: PiTruckDuotone },
}