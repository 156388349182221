import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Grid } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Numeral from "numeral";
import "numeral/locales/pt-br";

import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Loading from '../utils/Loading';

Numeral.locale('pt-br');

const CustomTable = (data) => {
   return (
      <tr>
         <td>{data.id_subscription}</td>

         <td>{data.price}</td>
      </tr>
   )
}
class Relatorio extends Component {

   constructor(props) {
      super(props)

      const { classes } = props;

      this.state = {
         classes,
         data: [],
         searchTerm: '',
         page: 0,
         rowsPerPage: 12,
         order: 'asc',
         orderBy: 'calories',
         status_filter: '',
         data_inicio_filter: '',
         data_final_filter: '',
         loading: true
      }

      this.onSearchChange = this.onSearchChange.bind(this);
   }

   componentDidMount = async () => {

      const _this = this

      const token = localStorage.getItem('token');

      axios.get(`${process.env.REACT_APP_API_URL}/products/admin`, {
         params: {
            limit: 5000,
         },
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data = {} } = response.data
            _this.setState({
               data,
               loading: false
            })
         })
   }

   onSearchChange = (e) => {

      this.setState({
         searchTerm: e.target.value
      })
   }
   handleChange = (event) => {
      this.setState({ status_filter: event.target.value })
   }
   handleChangePage = (event, page) => {
      this.setState({ page });
   };

   createSortHandler = property => event => {
      this.handleRequestSort(event, property);
   };

   handleRequestSort = (event, property) => {
      const orderBy = property;
      let order = 'desc';

      if (this.state.orderBy === property && this.state.order === 'desc') {
         order = 'asc';
      }

      this.setState({ order, orderBy });
   };

   handleChangeRowsPerPage = event => {
      this.setState({ page: 0, rowsPerPage: event.target.value });
   };

   filtrarContracts = async () => {
      const { status_filter, data_inicio_filter, data_final_filter } = this.state

      let data_filter = {}

      if (status_filter != '') {
         data_filter.status = status_filter
      }

      if (data_inicio_filter != '') {

         data_filter.data_inicio = data_inicio_filter
      }

      if (data_final_filter != '') {

         data_filter.data_final = data_final_filter
      }

      const _this = this

      const token = localStorage.getItem('token');


      axios.post(`${process.env.REACT_APP_API_URL}/products/admin`, data_filter, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response.data
            _this.setState({
               data
            })
         })
   }
   render() {

      const { classes, data, loading } = this.state

      const coluns = [
         { id: 'id', numeric: true, disablePadding: false, label: 'Pedido' },
         { id: 'Nome', numeric: false, disablePadding: false, label: 'Nome' },
      ];

      const discountPeriods = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 24, 30];
      const availability = [
         'São Paulo',
         'Rio de Janeiro',
         'Paraná',
         'Distrito Federal',
         'Bahia',
         'Minas Gerais'
      ]
      const features = [
         'Pequenos Ninhos',
         'Grandes Ninhos',
         'Adequado para Crianças',
         'Modular',
         'Multiuso'
      ]
      const stylesArray = [
         'Moderno',
         'Contemporâneo',
         'Industrial',
         'Rústico',
         'Versátil',
         'Clássico',
         'Minimalista',
      ]
      const ambients = [
         'Sala de Estar',
         'Sala de Jantar',
         'Quarto Casal',
         'Quarto Solteiro',
         'Quarto Infantil',
         'Home Office',
         'Outdoor',
         'Cozinha e Lavanderia'
      ]

      return (
         <div>
            <Loading loading={loading} />
            <style jsx global>
               {`
                        .btn {
                            color: rgba(0, 0, 0, 0.87);
                            padding: 6px 16px;
                            font-size: 0.875rem;
                            min-width: 64px;
                            box-sizing: border-box;
                            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            line-height: 1.75;
                            font-weight: 500;
                            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                            border-radius: 4px;
                            border: none;
                            text-transform: uppercase;
                            color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
    background-color: #e0e0e0;
color: rgba(0, 0, 0, 0.87);
background-color: #00c830;
                          }
                        `}
            </style>
            <Grid container>
               <Grid item xs >
                  <Typography
                     component="h1"
                     variant="h6"
                     color="inherit"
                     noWrap
                     className={classes.title}

                  >{`Relatório de Produtos (${data.length})`}
                  </Typography>
               </Grid>

            </Grid>


            <Paper className={classes.root}>
               <div style={{ maxWidth: 1200, height: 500, overflow: 'scroll', marginLeft: 20, marginTop: 50 }}>
                  <table id="table_contracts">
                     <thead style={{ position: 'sticky', top: 0, backgroundColor: '#fff' }}>
                        <tr>
                           <th>Id</th>
                           <th>Nome</th>
                           <th>ID SAP</th>
                           <th>Descrição</th>
                           <th>Descrição Técnica</th>
                           <th>Categoria</th>
                           <th>Preço Base</th>
                           <th>Estoque</th>
                           <th>Profundidade</th>
                           <th>Largura</th>
                           <th>Altura</th>
                           <th>Status</th>
                           <th>Imagem Principal</th>
                           <th>Imagem Secundária</th>
                           <th>URL SEO</th>
                           <th>Cor</th>
                           {features.map(feature => <th>{feature}</th>)}
                           {availability.map(location => <th>{location}</th>)}
                           {stylesArray.map(style => <th>{style}</th>)}
                           {ambients.map(ambient => <th>{ambient}</th>)}
                           <th>Ordem</th>
                           <th>Descontos</th>
                           <th>Acima de 2 meses</th>
                           <th>Acima de 3 meses</th>
                           <th>Acima de 4 meses</th>
                           <th>Acima de 5 meses</th>
                           <th>Acima de 6 meses</th>
                           <th>Acima de 7 meses</th>
                           <th>Acima de 8 meses</th>
                           <th>Acima de 9 meses</th>
                           <th>Acima de 10 meses</th>
                           <th>Acima de 11 meses</th>
                           <th>Acima de 12 meses</th>
                           <th>Acima de 24 meses</th>
                           <th>Acima de 30 meses</th>
                           <th>Exibir no Site</th>
                        </tr>
                     </thead>
                     <tbody>
                        {
                           data.map((item, index) => {

                              return (
                                 <tr key={`productsReport_${item._id}`} style={{ borderBottom: '1px solid black', marginBottom: 5, backgroundColor: index % 2 == 0 ? '#fff' : '#f5f5f5' }}>
                                    <td>{item?._id}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.id_sap}</td>
                                    <td>{item?.description}</td>
                                    <td>{item?.technicalDescription}</td>
                                    <td>{item?.category}</td>
                                    <td>{item?.price}</td>
                                    <td>{item?.estoque}</td>
                                    <td>{item?.width?.toFixed(0)}</td>
                                    <td>{item?.length?.toFixed(0)}</td>
                                    <td>{item?.height?.toFixed(0)}</td>
                                    <td>{item?.active ? "Ativo" : "Desativado"}</td>
                                    <td>{item?.urlImg ? "Contém" : "Não Contém"}</td>
                                    <td>{item?.secondaryImage ? "Contém" : "Não Contém"}</td>
                                    <td>{item?.urlSeo}</td>
                                    <td>{item?.colors?.map(color => `${color.color} `)}</td>
                                    {features.map(feature =>
                                       <td>{item?.features?.filter(productFeature => productFeature.title == feature).length > 0 && 'Sim'}</td>
                                    )}
                                    {availability.map(location =>
                                       <td>{item?.units?.filter(productLocation => productLocation.title == location).length > 0 && 'Sim'}</td>
                                    )}
                                    {stylesArray.map(style =>
                                       <td>{item?.styles?.filter(productStyle => productStyle.title == style).length > 0 && 'Sim'}</td>
                                    )}
                                    {ambients.map(ambient =>
                                       <td>{item?.ambients?.filter(productAmbient => productAmbient.title == ambient).length > 0 && 'Sim'}</td>
                                    )}
                                    <td>{item?.order}</td>
                                    <td>{item?.discounts?.length || '0'} descontos cadastrados</td>
                                    {discountPeriods.map(period =>
                                       <td>
                                          {item?.discounts?.filter(discount => discount.parametro == period).map(discount => `${discount.percentual}%`)}
                                       </td>
                                    )}
                                    <td>{item?.visible ? 'Sim' : 'Não'}</td>
                                 </tr>
                              )
                           })
                        }
                     </tbody>
                  </table>
               </div>


               <div style={{ paddingTop: 50, paddingBottom: 50, marginLeft: 20 }}>

                  <ReactHTMLTableToExcel
                     className="btn btn-info"
                     table="table_contracts"
                     filename="ProdutosExcel"
                     sheet="Sheet"
                     buttonText="Exportar para Excel" />
               </div>
            </Paper >
         </div >



      );
   }
}


function stableSort(array, cmp) {
   const stabilizedThis = array.map((el, index) => [el, index]);
   stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
   });
   return stabilizedThis.map(el => el[0]);
}


Relatorio.propTypes = {
   classes: PropTypes.object.isRequired,
};

const styles = theme => ({
   root: {
      width: '100%',
      marginTop: theme.spacing.unit * 2,
      overflowX: 'auto',
   },
   table: {
      minWidth: 700,
   },
   row: {
      cursor: 'pointer'
   },
   fab: {
      margin: 0,
   },
   search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade('#ececec', 0.35),
      '&:hover': {
         backgroundColor: fade('#ececec', 0.80),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
         width: 'auto',
      },
   },
   searchIcon: {
      marginLeft: theme.spacing.unit * 1,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   inputRoot: {
      color: 'inherit',
      width: '100%',
   },
   inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 5,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
         width: 200,
      },
   }
});

export default withStyles(styles)(Relatorio);
