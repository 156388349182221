import axios from "axios";

const isCouponApplicable = ({
  period,
  couponApiResponse,
  checkoutTotalPrice,
  priceWithoutDiscount,
}) => {
  const cartValue = priceWithoutDiscount ?? priceWithoutDiscount;

  const hasMinimumPeriod = period >= couponApiResponse.data?.minMonths;
  if (!hasMinimumPeriod) {
    alert(
      `Cupom requer um período mínimo de ${couponApiResponse.data?.minMonths} meses`
    );
    return false;
  }

  const hasMaximumPeriod = period <= couponApiResponse.data?.maxMonths;
  if (!hasMaximumPeriod) {
    alert(
      `Cupom requer um período máximo de ${couponApiResponse.data?.maxMonths} meses`
    );
    return false;
  }

  const maxPrice = couponApiResponse.data?.max_price;
  if (maxPrice) {
    const hasMaxiValuePerMonth = cartValue >= couponApiResponse.data?.max_price;
    if (hasMaxiValuePerMonth) {
      alert(
        `Cupom requer um valor máximo de R$${couponApiResponse.data?.max_price} de mensalidade`
      );
      return false;
    }
  }

  const minPrice = couponApiResponse.data?.min_price;
  if (minPrice) {
    const hasMinValuePerMonth = cartValue <= couponApiResponse.data?.min_price;
    if (hasMinValuePerMonth) {
      alert(
        `Cupom requer um valor mínimo de R$${couponApiResponse.data?.min_price} de mensalidade`
      );
      return false;
    }
  }

  const hasDiscountPercentage =
    typeof couponApiResponse.data?.discount === "number";
  const hasDiscountAbsoluteValue =
    typeof couponApiResponse.data?.discount_price === "number";

  if (hasDiscountPercentage) {
    return true;
  }

  if (!hasDiscountPercentage && hasDiscountAbsoluteValue) {
    const discountPrice = couponApiResponse.data?.discount_price;
    if (discountPrice) {
      const valueToReachMinimum = discountPrice - checkoutTotalPrice;

      if (checkoutTotalPrice < discountPrice) {
        alert(
          `Adicione mais ${valueToReachMinimum.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })} ao seu pedido e garanta o desconto.`
        );

        return false;
      }

      return true;
    }
  }
};

const calculateDiscount = ({
  couponApiResponse,
  checkoutPriceWithoutDiscount,
  freightPriceWithoutDiscount,
}) => {
  if (!couponApiResponse) return;
  const type = couponApiResponse.data?.type;
  const discount = couponApiResponse.data?.discount;
  const discountPrice = couponApiResponse.data?.discount_price;
  const deliveryPrice = couponApiResponse.data?.delivery_price;

  const hasDiscountPercentage = typeof discount === "number";
  const hasDiscountAbsoluteValue = typeof discountPrice === "number";
  const hasDeliveryPrice = typeof deliveryPrice === "number";

  const typeIsDelivery = type === "delivery";
  const typeIsCartDelivery = type === "cart-delivery";

  const discountType = {
    freightDiscount: null,
    cartDiscount: null,
  };

  if (typeIsDelivery) {
    if (!hasDiscountPercentage && hasDiscountAbsoluteValue && discountPrice) {
      discountType.freightDiscount = discountPrice;
      return discountType;
    }

    if (hasDiscountPercentage) {
      discountType.freightDiscount =
        (discount / 100) * freightPriceWithoutDiscount -
        freightPriceWithoutDiscount;

      return discountType;
    }

    return;
  }

  if (!hasDiscountPercentage && hasDiscountAbsoluteValue && discountPrice) {
    discountType.cartDiscount = checkoutPriceWithoutDiscount - discountPrice;
    return discountType;
  }

  if (typeIsCartDelivery) {
    if (hasDeliveryPrice) {
      discountType.freightDiscount =
        (deliveryPrice / 100) * freightPriceWithoutDiscount -
        freightPriceWithoutDiscount;

      discountType.cartDiscount =
        checkoutPriceWithoutDiscount -
        (discount / 100) * checkoutPriceWithoutDiscount;

      return discountType;
    }
    return;
  }

  if (hasDiscountPercentage) {
    discountType.cartDiscount =
      checkoutPriceWithoutDiscount -
      (discount / 100) * checkoutPriceWithoutDiscount;

    return discountType;
  }
};

const requestCouponApi = async (coupon) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/verifyValidCoupon?code=${coupon}`,
      { headers: { Authorization: "bearer " + token } }
    );

    return response;
  } catch (error) {
    console.log(error);
  }
};

const requestCouponHandler = async (coupon) => {
  const formatCoupon = coupon.name.replaceAll("#", "%23");
  const response = await requestCouponApi(formatCoupon);

  if (!response) return false;

  return response.data;
};

export const couponHandler = async ({
  period,
  checkoutPriceWithoutDiscount,
  freightPriceWithoutDiscount,
  coupon,
}) => {
  if (!Boolean(coupon.name)) return;

  const couponApiResponse = await requestCouponHandler(coupon);

  if (!couponApiResponse) return;

  const isApplicable = isCouponApplicable({
    period,
    couponApiResponse,
    checkoutTotalPrice: checkoutPriceWithoutDiscount,
  });

  if (!isApplicable) return;

  return {
    calculateDiscount: calculateDiscount({
      couponApiResponse,
      checkoutPriceWithoutDiscount,
      freightPriceWithoutDiscount,
    }),
    couponId: couponApiResponse.data._id,
  };
};
