import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow, Paper, Table, Typography, Grid, Fab } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import Loading from '../utils/Loading';

class ListPress extends Component {

    constructor(props) {
        super(props)

        const { classes } = props;

        this.state = {
            classes,
            data: [],
            loading: true
        }
    }

    componentDidMount = async () => {

        const _this = this

        const token = await localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_URL}/press`, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {

                const { data } = response
                _this.setState({
                    data
                })

                _this.setState({
                    loading: false
                })
            })
    }

    render() {

        const { classes, data, loading } = this.state

        return (
            <div>
                <Loading loading={loading} />

                <Grid container>
                    <Grid item xs >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}

                        >Na Mídia
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Fab color="primary" aria-label="Add" className={classes.fab} size="small">
                            <AddIcon onClick={() => this.props.history.push(`/app/press/edit/`)} />
                        </Fab>
                    </Grid>
                </Grid>

                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>

                                <TableCell>Título</TableCell>
                                <TableCell align="center">Categoria</TableCell>
                                <TableCell align="center">Data de Cadastro</TableCell>
                                <TableCell align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(row => (
                                <TableRow
                                    key={row._id}
                                    hover
                                    onClick={() => this.props.history.push(`/app/press/edit/${row._id}`)}
                                    className={classes.row}
                                >

                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>


                                    <TableCell align="center">{row.category ? row.category.title : '-'}</TableCell>
                                    <TableCell align="center">{row.createdAt}</TableCell>
                                    <TableCell align="center">{row.active ? <CheckCircle /> : '-'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>

        );
    }
}

ListPress.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        cursor: 'pointer'
    },
    fab: {
        margin: 0,
    }
});

export default withStyles(styles)(ListPress);
