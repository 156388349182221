import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { uid } from "../../functions";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer";
import Loading from "../utils/Loading";

const ADDITIONAL_BILL_INITIAL_DATA = {
  installments: 1,
  amount: "",
  due_at: "",
  paymentProfile: "",
  product_id: "",
};

const validateDates = (dates) =>
  dates.some((date) => !/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/.test(date));

export const AdditionalBill = (props) => {
  const token = localStorage.getItem("token");
  const { contract, idVindi, handleClose } = props;
  const reducer = (prev, next) => ({ ...prev, ...next });

  const [additionalBillData, setAdditionalBillData] = useReducer(
    reducer,
    ADDITIONAL_BILL_INITIAL_DATA
  );
  const [scheduleCharge, setSchedulescheduleCharge] = useState(false);
  const [vindiProducts, setVindiProducts] = useState([]);
  const [paymentProfiles, setPaymentProfiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target }) => {
    const dateFields = ["billing_at", "due_at"];
    const checkboxFields = ["scheduleCharge"];

    const { name, value } = target;
    let formatedValue = value;

    if (dateFields.includes(name)) formatedValue = formatDate(value);
    if (checkboxFields.includes(name)) formatedValue = target.checked;

    if (name === "amount") formatedValue = formatedValue.replace(/[a-z]/g, "");

    setAdditionalBillData({ [name]: formatedValue });
  };

  const formatDate = (date) => {
    return date
      .replace(/[^\d/\\]/g, "")
      .slice(0, 10)
      .replace(/^(\d{2})\/?/, "$1")
      .replace(/^(\d{2})\/?(\d{1,2})\/?/, "$1/$2")
      .replace(/^(\d{2})\/?(\d{2})\/?(\d{1,4})$/, "$1/$2/$3");
  };

  const validateFields = () => {
    const missingFields = !Object.values(additionalBillData).every(Boolean);
    if (missingFields) return true;

    const dates = [
      additionalBillData.due_at,
      ...(scheduleCharge ? [additionalBillData.billing_at] : []),
    ];
    const invalidDates = validateDates(dates);
    return invalidDates;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const hasInvalidFields = validateFields();

      if (hasInvalidFields)
        return alert(
          "Dados inválidos\n\nPor favor, verifique se todos os campos estão preenchidos corretamente."
        );

      const body = additionalBillData;
      await Axios.post(
        `${process.env.REACT_APP_API_URL}/v2/admin/vindi/additionalbill`,
        body,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
        .then((response) => {
          handleClose();
          alert("Tudo certo!\n\nFatura lançada com sucesso.");
          window.location.reload();
        })
        .catch((error) => {
          alert("Desculpe\n\nTivemos um problema ao lançar a fatura.");
        });
    } catch (error) {
      alert("Desculpe\n\nTivemos um problema.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (idVindi && contract.id) {
      setAdditionalBillData({ customer_id: idVindi, code: contract.id });
      loadVindiData();
    }
  }, []);

  const loadVindiData = () => {
    setLoading(true);
    Axios.get(
      `${process.env.REACT_APP_API_URL}/v2/admin/vindi/additionalbilldata?customerId=${idVindi}`,
      {
        headers: { Authorization: "bearer " + token },
      }
    )
      .then((response) => {
        setVindiProducts(response.data.products);
        setPaymentProfiles(response.data.paymentProfiles);
      })
      .catch((error) => {
        alert(
          "Houve um problema ao carregar dados de produtos e perfis de pagamento."
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Loading loading={loading} />
      <Box style={styles.root} onClick={handleClose}>
        <ContentContainer
          label="Nova Fatura"
          style={{ overflow: "auto", maxHeight: "90%" }}
          onClick={(e) => e.stopPropagation()}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 14,
              minWidth: 360,
            }}
          >
            <TextField
              variant="outlined"
              id="amount"
              label={`Valor`}
              name="amount"
              value={additionalBillData.amount}
              onChange={handleChange}
              InputLabelProps={styles.InputLabelProps}
              InputProps={styles.InputProps}
              inputProps={styles.inputProps}
              style={{ borderRadius: 40 }}
            />
            <TextField
              variant="outlined"
              id="due_at"
              label={`Vencimento`}
              name="due_at"
              value={additionalBillData.due_at}
              onChange={handleChange}
              InputLabelProps={styles.InputLabelProps}
              InputProps={styles.InputProps}
              inputProps={styles.inputProps}
              style={{ borderRadius: 40 }}
            />
            {scheduleCharge && (
              <TextField
                variant="outlined"
                id="billing_at"
                label={`Data da Cobrança`}
                name="billing_at"
                autoFocus
                value={additionalBillData.billing_at}
                onChange={handleChange}
                InputLabelProps={styles.InputLabelProps}
                InputProps={styles.InputProps}
                inputProps={styles.inputProps}
                style={{ borderRadius: 40 }}
              />
            )}
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={scheduleCharge} />}
                name="scheduleCharge"
                label="Agendar cobrança"
                onChange={({ target }) =>
                  setSchedulescheduleCharge(target.checked)
                }
              />
            </FormGroup>
            <FormControl fullWidth>
              <InputLabel id="paymentProfile_label">
                Cartão de Crédito
              </InputLabel>
              <Select
                labelId="paymentProfile_label"
                id="paymentProfile"
                name="paymentProfile"
                value={additionalBillData.paymentProfile}
                label="Cartão de Crédito"
                onChange={handleChange}
              >
                {paymentProfiles.map(
                  ({ id, holder_name, card_number_last_four }) => (
                    <MenuItem
                      key={uid()}
                      value={id}
                    >{`${holder_name}, final ${card_number_last_four}`}</MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="product_id_label">Produto</InputLabel>
              <Select
                labelId="product_id_label"
                id="product_id"
                name="product_id"
                value={additionalBillData.product_id}
                label="Produto"
                onChange={handleChange}
              >
                {vindiProducts.map(({ id, name }) => (
                  <MenuItem key={uid()} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="installments_label">Parcelamento</InputLabel>
              <Select
                labelId="installments_label"
                id="installments"
                name="installments"
                value={additionalBillData.installments}
                label="Parcelamento"
                onChange={handleChange}
                defaultValue={1}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                  <MenuItem key={uid()} value={item}>
                    {item === 1 ? `À Vista` : `${item}x`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              marginTop: 12,
            }}
          >
            <TuimButton text={"Lançar Fatura"} onClick={handleSubmit} />
            <TuimButton tertiary text={"Cancelar"} onClick={handleClose} />
          </Box>
        </ContentContainer>
      </Box>
    </>
  );
};

const styles = {
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000044",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  main: {
    backgroundColor: "#fff",
    borderRadius: 14,
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  InputProps: {
    style: {
      borderRadius: 40,
      paddingLeft: 10,
    },
  },
  inputProps: {
    style: {
      fontSize: 15,
      borderRadius: 40,
      paddingLeft: 10,
      WebkitBoxShadow: `0 0 0 1000px #fff inset`,
    },
  },
  InputLabelProps: {
    style: {
      marginRight: 30,
      marginLeft: 4,
    },
  },
};
