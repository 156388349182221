import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { BsFillCheckCircleFill } from "react-icons/bs";
import { formatter } from '../../functions';
import { Colors } from '../../styles/Colors';
import { ContentContainer } from '../layout/components/contentContainer';
import ContentHeader from '../layout/components/contentHeader';
import Loading from '../utils/Loading';

class ListUsers extends Component {
  constructor(props) {
    super(props)

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      loading: true
    }
  }

  componentDidMount = async () => {
    const _this = this
    const token = localStorage.getItem('token');

    axios.get(`${process.env.REACT_APP_API_URL}/user`, {
      headers: { 'Authorization': "bearer " + token }
    }).then(response => {
      const { data } = response
      _this.setState({ data, loading: false })
    })
  }

  render() {
    const { classes, data, loading } = this.state

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title='Usuários'
          buttons={[
            { text: 'Novo', onClick: () => this.props.history.push(`/app/user/`) }]}
        />
        <ContentContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Data de Cadastro</TableCell>
                <TableCell align="center">Ativo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <TableRow
                  key={row._id}
                  margin='dense'
                  hover
                  onClick={() => this.props.history.push(`/app/user/${row._id}`)}
                  className={classes.row}
                  style={{ color: row.active ? '#444' : '#bbb' }}
                >
                  <TableCell component="th" scope="row" style={{ color: 'inherit' }}>{row.name}</TableCell>
                  <TableCell style={{ color: 'inherit' }}>{row.email}</TableCell>
                  <TableCell style={{ color: 'inherit' }}>{formatter.date(row.createdAt, 'short')}</TableCell>
                  <TableCell align="center" style={{ color: 'inherit' }}><BsFillCheckCircleFill size={20} color={row.active ? Colors.lightGreen : '#ccc'} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ContentContainer>
      </>
    );
  }
}

ListUsers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: 'pointer'
  },
  fab: {
    margin: 0,
  }
});

export default withStyles(styles)(ListUsers);
