import React, { Component } from 'react'
import axios from 'axios'
import {
   Paper, Typography, Button, FormControl, InputLabel, Input, CircularProgress, Checkbox,
   IconButton, Menu, MenuItem, FormControlLabel,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';
import green from '@material-ui/core/colors/green';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import Confirm from '../utils/Confirm'
import AlertDialog from '../utils/AlertDialog'
import Moment from 'react-moment';
import Calender from './calender.styled'
class EditCalendar extends Component {

   constructor(props) {
      super(props)

      const { classes } = props;
      const { pathname } = props.location
      const arrayPath = pathname.split('/')
      const _id = arrayPath[arrayPath.length - 1];

      this.state = {
         classes,
         data: {
            _id,
            name: '',
            description: '',
            daysOfWeek: [],
            disabledDates: [],

         },
         dateDisabed: '',
         domingo: false,
         segunda: false,
         terca: false,
         quarta: false,
         quinta: false,
         sexta: false,
         sabado: false,
         saving: false,
         success: false,
         error: null,
         anchorEl: null,
         open: false,
         isConfirmOpen: false,
         showAlert: false,
         showDeleteUnitAlert: false
      }

      this.onChange = this.onChange.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.onDeleteUnitPress = this.onDeleteUnitPress.bind(this);
      this.deleteUnit = this.deleteUnit.bind(this);
      this.onCheckChange = this.onCheckChange.bind(this);
   }

   componentDidMount = () => {
      const { _id } = this.state.data
      if (_id != '') {
         this.getCalendar()

      }

   }
   week = () => {
      const { daysOfWeek } = this.state.data
      daysOfWeek.map(w => {
         switch (w) {
            case '0':
               return (this.setState({ domingo: true }))
            case '1':
               return (this.setState({ segunda: true }))
            case '2':
               return (this.setState({ terca: true }))
            case '3':
               return (this.setState({ quarta: true }))
            case '4':
               return (this.setState({ quinta: true }))
            case '5':
               return (this.setState({ sexta: true }))
            case '6':
               return (this.setState({ sabado: true }))
            default:

         }
      })
   }
   getCalendar = async () => {
      const token = await localStorage.getItem('token');
      const { data } = this.state
      axios.get(`${process.env.REACT_APP_API_URL}/deliveryCalender/${data._id}`, data, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            this.setState({
               saving: false,
               success: true,
               data: response.data
            })
            this.week()



         })
         .catch(error => {
            this.setState({
               saving: false,
               success: false,
               error: error.response
            })
         })

   }


   onChange = (e) => {

      this.setState({
         data: {
            ...this.state.data,
            [e.target.name]: e.target.value

         }

      })
   }
   onSavePress = async () => {

      const { data } = this.state

      console.log('save data', data)

      if (!data._id) {
         this.newCalendar()
         return;
      }

      this.setState({
         saving: true
      })

      const token = await localStorage.getItem('token');

      axios.patch(`${process.env.REACT_APP_API_URL}/deliveryCalender/${data._id}`, data, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            this.setState({
               saving: false,
               success: true
            })


            this.props.history.goBack()
         })
         .catch(error => {
            this.setState({
               saving: false,
               success: false,
               error: error.response
            })
         })
   }

   newCalendar = async () => {

      const { data } = this.state

      data._id = undefined

      this.setState({
         saving: true
      })

      const token = await localStorage.getItem('token');
      const _this = this

      axios.post(`${process.env.REACT_APP_API_URL}/deliveryCalender`, data, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            this.setState({
               saving: false,
               success: true,
               data: response.data.data
            })

            this.props.history.goBack()

            _this.props.history.push(`${response.data.data._id}`)
         })
         .catch(error => {
            this.setState({
               saving: false,
               success: false,
               error: error.response
            })
         })
   }

   deleteUnit = async () => {

      const { data } = this.state
      const token = await localStorage.getItem('token');

      axios.delete(`${process.env.REACT_APP_API_URL}/deliveryCalender/${data._id}`, {
         headers: { 'Authorization': "bearer " + token }
      }).then(response => {
         this.setState({
            saving: false,
            success: true
         })
         this.props.history.goBack()
         this.props.history.push('/app/calendar')
      }).catch(error => {
         this.setState({
            saving: false,
            success: false,
            error: error.response
         })
      })
   }

   onCancelPress = () => {
      this.props.history.goBack()
   }

   handleClick = event => {
      this.setState({ anchorEl: event.currentTarget, open: true });
   };

   handleClose = () => {
      this.setState({ anchorEl: null, open: false, showAlert: false });
   };

   onDeleteUnitPress = () => {
      this.setState({ anchorEl: null, open: false, showDeleteUnitAlert: true });
   };

   onCheckChange = (e) => {
      console.log(e.target.name)
      const { data } = this.state
      let { daysOfWeek } = data
      let value = e.target.value
      daysOfWeek.push(value)
      this.setState({
         data: {
            ...this.state.data,
            daysOfWeek
         },
         [e.target.name]: true,
         success: false
      });


   }
   removeCheck = (e) => {
      const { daysOfWeek } = this.state.data
      let value = e.target.value
      let index = daysOfWeek.indexOf(value)
      if (index !== -1) {
         daysOfWeek.splice(index, 1)
         this.setState({
            data: {
               ...this.state.data,
               daysOfWeek

            },
            [e.target.name]: false

         })
      }
   }
   onChangeRadio = (e) => {
      console.log(e.target.name, e.target.value)
      let condicao = '';
      if (e.target.value == 'true') {
         condicao = false
      } else {
         condicao = true
      }
      this.setState({
         data: {
            ...this.state.data,
            [e.target.name]: condicao
         }
      })

   }

   changeData = (e) => {
      this.setState({
         disabed: e.target.value
      })

   }
   onAddData = () => {
      const { disabed } = this.state
      const { data } = this.state
      let { disabledDates = [] } = data
      disabledDates.push(disabed)
      this.setState({
         data: {
            ...this.state.data,
            disabledDates
         },
         disabed: ''
      })





   }
   render() {

      const { classes, saving, success, anchorEl, open, showDeleteUnitAlert, data, disabed, segunda, terca, quarta, quinta, sexta, sabado, domingo } = this.state



      const buttonClassname = classNames({
         [classes.buttonSuccess]: success,
      });

      return (
         <Calender>
            <Paper className={classes.paper}>
               <Typography component="h1" variant="h5">
                  Calendário
                  <IconButton
                     aria-label="More"
                     aria-owns={true}
                     aria-haspopup="true"
                     onClick={this.handleClick}
                  >
                     <MoreVertIcon />
                  </IconButton>
                  <Menu
                     id="long-menu"
                     anchorEl={anchorEl}
                     open={open}
                     onClose={this.handleClose}
                     PaperProps={{
                        style: {
                           maxHeight: 200,
                           width: 200,
                        },
                     }}
                  >
                     <MenuItem key={0} onClick={this.onDeleteUnitPress}>
                        Excluir Calendário
                     </MenuItem>
                  </Menu>

               </Typography>
               <form className={classes.form}>
                  <FormControl margin="normal" required fullWidth>
                     <InputLabel htmlFor="email" >Nome Calendário</InputLabel>
                     <Input id="name" name="name" autoFocus value={data.name}
                        onChange={this.onChange}
                     />
                  </FormControl>
                  <br />
                  <FormControl margin="normal" required fullWidth>
                     <InputLabel htmlFor="email" >Disponiviel a partir de </InputLabel>
                     <Input type='number' id="startBefore " name="startBefore" autoFocus value={data.startBefore}
                        onChange={this.onChange}
                     />
                  </FormControl>
                  <br />
                  <InputLabel htmlFor="email" >Status</InputLabel>
                  <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 5 }}>
                     <FormControlLabel

                        control={
                           <Checkbox
                              name='status'
                              style={{ textAlign: 'center' }}
                              checked={data.status == true ? true : ''}
                              onChange={this.onChangeRadio}
                              value={data.status}
                              color="primary"
                           />
                        }
                        label="Ativo" />


                  </FormControl>
                  <br />
                  <InputLabel htmlFor="email" >Calendário Principal</InputLabel>
                  <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 5 }}>
                     <FormControlLabel

                        control={
                           <Checkbox
                              name='main'
                              style={{ textAlign: 'center' }}
                              checked={data.main == true ? true : ''}
                              onChange={this.onChangeRadio}
                              value={data.main}
                              color="primary"
                           />
                        }
                        label="Ativo"
                     />
                  </FormControl>
                  <br />
                  <InputLabel htmlFor="email" >Data desabilitadas do calendário</InputLabel>
                  <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 5 }}>
                     <Input type='date' id="disabledDates " name="disabledDates " autoFocus value={disabed}
                        onChange={this.changeData}

                     />
                     <Button
                        style={{
                           width: 120,
                           fontSize: 11,
                           marginLeft: 13
                        }}
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={saving}
                        onClick={this.onAddData}
                     >
                        Adicionar data
                        {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                     </Button>

                  </FormControl>

                  {data._id != '' ? <div>

                     {typeof data.disabledDates == 'undefined' ? ''
                        :
                        data.disabledDates.map((item) => (

                           <p><Moment format="DD/MM/YYYY">
                              {item}
                           </Moment></p>

                        ))
                     }



                  </div>

                     : <div>

                        {typeof data.disabledDates == 'undefined' ? ''
                           :
                           data.disabledDates.map((item) => (
                              <p><Moment format="DD/MM/YYYY">
                                 {item}
                              </Moment></p>

                           ))
                        }



                     </div>}

                  <br />
                  <InputLabel htmlFor="email" >- Dias da Semana que devem estar desabilitados</InputLabel>
                  <FormControl style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 5 }}>
                     <FormControlLabel

                        control={
                           <Checkbox
                              name='segunda'
                              style={{ textAlign: 'center' }}
                              checked={segunda}
                              onChange={segunda == true ? this.removeCheck : this.onCheckChange}
                              value="1"
                              color="primary"
                           />
                        }
                        label="Segunda-Feira"
                     />
                     <FormControlLabel
                        control={
                           <Checkbox
                              name='terca'
                              style={{ textAlign: 'center' }}
                              checked={terca}
                              onChange={terca == true ? this.removeCheck : this.onCheckChange}
                              value="2"
                              color="primary"
                           />
                        }
                        label="Terça-Feira"
                     />
                     <FormControlLabel
                        control={
                           <Checkbox
                              name='quarta'
                              style={{ textAlign: 'center' }}
                              checked={quarta}
                              onChange={quarta == true ? this.removeCheck : this.onCheckChange}
                              value="3"
                              color="primary"
                           />
                        }
                        label="Quarta-Feira"
                     />
                     <FormControlLabel
                        control={
                           <Checkbox
                              name='quinta'
                              style={{ textAlign: 'center' }}
                              checked={quinta}
                              onChange={quinta == true ? this.removeCheck : this.onCheckChange}
                              value="4"
                              color="primary"
                           />
                        }
                        label="Quinta-Feira"
                     />
                     <FormControlLabel
                        control={
                           <Checkbox
                              name='sexta'
                              checked={sexta}
                              onChange={sexta == true ? this.removeCheck : this.onCheckChange}
                              value="5"
                              color="primary"
                           />
                        }
                        label="Sexta-Feira"
                     />
                     <FormControlLabel
                        control={
                           <Checkbox
                              name='sabado'
                              style={{ textAlign: 'center' }}
                              checked={sabado}
                              onChange={sabado == true ? this.removeCheck : this.onCheckChange}
                              value="6"
                              color="primary"
                           />
                        }
                        label="Sábado"
                     />
                     <FormControlLabel
                        control={
                           <Checkbox
                              name='domingo'
                              style={{ textAlign: 'center' }}
                              checked={domingo}
                              onChange={domingo == true ? this.removeCheck : this.onCheckChange}
                              value="0"
                              color="primary"
                           />
                        }
                        label="Domingo"
                     />
                  </FormControl>

                  <div className={classes.wrapper}>
                     <Button
                        variant="contained"
                        color="primary"
                        className={buttonClassname}
                        disabled={saving}
                        onClick={this.onSavePress}
                     >
                        <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                        Salvar
                        {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                     </Button>
                     <Button variant="contained" color="default" className={classes.button}
                        onClick={this.onCancelPress}
                     >Cancelar
                     </Button>
                     {this.state.error && <Typography>{this.state.error}</Typography>}


                     <Button variant="contained"
                        style={{ backgroundColor: '#f44336', color: '#FFF', left: 667 }}
                        className={classes.button}
                        onClick={() => this.setState({ isConfirmOpen: true })}>
                        Excluir
                     </Button>
                     {this.state.error && <Typography>{this.state.error}</Typography>}

                     <Confirm open={this.state.isConfirmOpen} title='Exclusão de Filial' text='Deseja realmente excluir essa filial?'
                        onDeletePress={this.deleteUnit} onClose={() => this.setState({ isConfirmOpen: false })}
                     />

                  </div>


               </form>

               {
                  showDeleteUnitAlert && <AlertDialog
                     msg={`Deseja relmente excluir esta filial ${data.title}? Obs.: Esta ação è irreversível`}
                     title="Excluir Filial"
                     open={showDeleteUnitAlert}
                     buttons={[
                        {
                           label: 'Cancelar',
                           onPress: () => {
                              this.setState({
                                 showDeleteUnitAlert: false
                              })
                           },

                        },
                        {
                           label: 'Excluir',
                           onPress: () => {
                              this.setState({
                                 showDeleteUnitAlert: false
                              })

                              this.deleteUnit()
                           },
                           color: 'primary'
                        }
                     ]} />
               }

            </Paper >
         </Calender>
      )
   }
}

const styles = theme => ({
   main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
         width: 400,
         marginLeft: 'auto',
         marginRight: 'auto',
      },
   },

   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
   },
   avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      maxWidth: '450px',
      marginTop: theme.spacing.unit,
   },
   button: {
      marginLeft: theme.spacing.unit
   },
   leftIcon: {
      marginRight: theme.spacing.unit,
   },
   success: {
      backgroundColor: green[600],
   },
   buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   wrapper: {
      marginTop: theme.spacing.unit * 3,
      position: 'relative',
   },
   buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
         backgroundColor: green[700],
      },
   },
   titleName: {
      flex: 1
   },
   titleBar: {
      flexDirection: 'row'
   },
   chips: {
      display: 'flex',
      flexWrap: 'wrap',
   },
   chip: {
      margin: theme.spacing.unit / 4,
   },
});

export default (withStyles(styles)(EditCalendar))