import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import classNames from "classnames";
import { uniqueId } from "lodash";
import React, { Component } from "react";
import { formatter } from "../../functions";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import Dropzone from "../ui/components/upload/Dropzone";
import { TextArea, TextField } from "../ui/index";
import AlertDialog from "../utils/AlertDialog";
import Loading from "../utils/Loading";

class EditPartner extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        name: "",
        urlSeo: "",
        title: "",
        description: "",
        description: "",
        descriptionBuildingPage: "",
        email: "",
        active: false,
        partner_ref: {},
        type: true,
        minMonths: "",
        maxMonths: "",
        urlImg: "",
        descriptionPage: "",
        code: "",
        accessLink: true,
        showNavbar: false,
      },
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      showAlert: false,
      showErrorNewUser: false,
      showDeleteUserAlert: false,
      loading: true,
      showAlertNewUser: false,
      permissions: [{ permission: "Administrador" }, { permission: "SEO" }],
    };

    this.onChange = this.onChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onResetPasswordPress = this.onResetPasswordPress.bind(this);
    this.onDeleteUserPress = this.onDeleteUserPress.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount = async () => {
    const { _id } = this.state.data;

    if (!_id) {
      this.setState({ loading: false });
      return;
    }

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .get(`${process.env.REACT_APP_API_URL}/partnerId/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          data: data,
        });

        _this.setState({
          loading: false,
        });
      });
  };

  onChange = (e) => {
    const { data } = this.state;
    let { urlSeo } = data;
    if (e.target.name == "name") {
      urlSeo = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      urlSeo = urlSeo.replace("?", "");
      urlSeo = urlSeo.replace(" - ", " ");
      urlSeo = urlSeo.toLowerCase().trim().split(" ").join("-");
    }

    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value,
        urlSeo,
      },
      success: false,
    });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  sendCupom = async () => {
    const { data } = this.state;

    if (!data._id) {
      this.newUser();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/broker/sendCupom/${data._id}`,
        data,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };
  onSavePress = async () => {
    const { data } = this.state;

    const {
      name,
      title,
      code,
      minMonths,
      maxMonths,
      descriptionPage,
      descriptionBuildingPage,
    } = data;

    if (
      !Boolean(name) ||
      !Boolean(title) ||
      !Boolean(code) ||
      !Boolean(minMonths) ||
      !Boolean(maxMonths) ||
      !Boolean(descriptionPage) ||
      !Boolean(descriptionBuildingPage)
    ) {
      alert("Você precisa preencher todos os campos obrigatórios");
      return;
    }

    if (!data._id) {
      this.newUser();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .patch(`${process.env.REACT_APP_API_URL}/partner/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.goBack();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newUser = async () => {
    const { data } = this.state;

    data._id = undefined;

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .post(`${process.env.REACT_APP_API_URL}/partner`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        // console.log('response', response);
        this.setState({
          saving: false,
          success: true,
          showAlertNewUser: true,
          data: response.data.data,
        });

        // this.props.history.goBack()

        _this.props.history.push(`${response.data.data._id}`);
      })
      .catch((error) => {
        console.log("responserror", error.response.data.message);

        this.setState({
          saving: false,
          success: false,
          showErrorNewUser: true,
          error: error.response.data.message,
        });
      });
  };

  resetPassword = async () => {
    const { data } = this.state;

    const token = await localStorage.getItem("token");

    axios
      .patch(`${process.env.API_URL}login/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  deleteUser = async () => {
    const { data } = this.state;
    const token = await localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}/partner/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        window.location.href = "/app/partners";
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
        this.props.history.push("/app/brokers");
      });
  };

  onCancelPress = () => {
    this.props.history.goBack();
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, showAlert: false });
  };

  onResetPasswordPress = () => {
    this.setState({ anchorEl: null, open: false, showAlert: true });
  };

  onDeleteUserPress = () => {
    this.setState({ anchorEl: null, open: false, showDeleteUserAlert: true });
  };

  processUpload = async (uploadedFile, mainImg = true) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    const token = await localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.setState({
          data: {
            ...this.state.data,
            urlImg: data.url,
          },
        });
      })
      .catch((error) => console.log("error", error));
  };

  onDrop = (files) => {
    const uploadedFiles = files.map((file, index) => ({
      file,
      id: uniqueId(),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(this.processUpload, true);
  };
  render() {
    const {
      classes,
      saving,
      permissions,
      success,
      anchorEl,
      open,
      showAlert,
      showDeleteUserAlert,
      loading,
      showAlertNewUser,
      showErrorNewUser,
      error,
    } = this.state;
    const { data } = this.state;

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title={data?.name || "Novo parceiro"}
          subtitle={
            data?.createdAt
              ? `Cadastrado em ${formatter.date(
                  data?.createdAt,
                  "long",
                  false
                )}`
              : ""
          }
          dotsMenuItems={
            data?._id
              ? [{ text: "Excluir parceiro", action: this.onDeleteUserPress }]
              : []
          }
          buttons={[{ text: "Salvar", onClick: this.onSavePress }]}
        />
        <ContentContainer>
          <form>
            <FormControl margin="normal" required fullWidth>
              <TextField
                id="name"
                name="name"
                title="Nome *"
                autoFocus
                value={data.name}
                onChange={this.onChange}
              />
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <TextField
                title="URL Seo"
                id="urlSeo"
                name="urlSeo"
                autoFocus
                value={data.urlSeo}
                disabled
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <TextField
                id="title"
                name="title"
                title="Título *"
                autoFocus
                value={data.title}
                onChange={this.onChange}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <TextField
                id="code"
                name="code"
                title="Código Para Liberação dos Produtos *"
                autoFocus
                value={data.code}
                onChange={this.onChange}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <TextField
                id="minMonths"
                name="minMonths"
                title="Mínimo de meses *"
                type="number"
                autoFocus
                value={data.minMonths}
                onChange={this.onChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextField
                id="maxMonths"
                name="maxMonths"
                title="Máximo de meses *"
                type="number"
                autoFocus
                value={data.maxMonths}
                onChange={this.onChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextArea
                multiline
                rows={5}
                value={data.description}
                onChange={this.onChange}
                name="description"
                title="Descrição *"
                id="description"
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextArea
                value={data.descriptionPage}
                onChange={this.onChange}
                name="descriptionPage"
                id="descriptionPage"
                title="Descrição Página Interna *"
              ></TextArea>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <TextArea
                value={data.descriptionBuildingPage}
                onChange={this.onChange}
                name="descriptionBuildingPage"
                id="descriptionBuildingPage"
                title="Descrição Página dos Imóveis *"
              ></TextArea>
            </FormControl>

            <div className="form-group">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    marginBottom: 10,
                    marginTop: 16,
                    flex: 1,
                    fontWeight: "500",
                  }}
                >
                  Imagem principal
                </div>
                <i
                  className="fa fa-times-circle"
                  style={{ color: "gray", fontSize: 16, cursor: "pointer" }}
                  onClick={() => this.onDelete(0)}
                ></i>
              </div>
              <Dropzone onDrop={this.onDrop} />
              {data.urlImg ? (
                <img src={data.urlImg} style={{ maxWidth: 400, height: 300 }} />
              ) : (
                ""
              )}
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  checked={data.active}
                  onChange={this.onCheckChange("active")}
                  value="active"
                  color="secondary"
                />
              }
              label="Ativo"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.accessLink}
                  onChange={this.onCheckChange("accessLink")}
                  value="active"
                  color="secondary"
                />
              }
              label="Permitir acesso ao Link"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.showNavbar}
                  onChange={this.onCheckChange("showNavbar")}
                  value="showNavbar"
                  color="secondary"
                />
              }
              label="Exibir na barra de navegação do site"
            />
          </form>

          {showAlert && (
            <AlertDialog
              msg={`Deseja gerar uma nova senha e enviar para o e-mail ${data.email}`}
              title="Reenviar Senha"
              open={showAlert}
              buttons={[
                {
                  label: "Cancelar",
                  onPress: () => {
                    this.setState({
                      showAlert: false,
                    });
                  },
                },
                {
                  label: "Reenviar",
                  onPress: () => {
                    this.setState({
                      showAlert: false,
                    });

                    this.resetPassword();
                  },
                },
              ]}
            />
          )}

          {showAlertNewUser && (
            <AlertDialog
              msg={`Novo usuário cadastrado com sucesso, um e-mail com a senha foi enviado para ${data.email}.`}
              title="Novo Usuário"
              open={showAlertNewUser}
              buttons={[
                {
                  label: "Fechar",
                  onPress: () => {
                    this.setState({
                      showAlertNewUser: false,
                    });
                  },
                },
              ]}
            />
          )}

          {showErrorNewUser && (
            <AlertDialog
              msg={error}
              title="Falha ao Cadastrar Novo Usuário"
              open={showErrorNewUser}
              buttons={[
                {
                  label: "Fechar",
                  onPress: () => {
                    this.setState({
                      showErrorNewUser: false,
                    });
                  },
                },
              ]}
            />
          )}

          {showDeleteUserAlert && (
            <AlertDialog
              msg={`Esta ação é irreversível! Deseja relmente excluir o parceiro ${data.name}?`}
              title="Excluir Parceiro"
              open={showDeleteUserAlert}
              buttons={[
                {
                  label: "Cancelar",
                  onPress: () => {
                    this.setState({
                      showDeleteUserAlert: false,
                    });
                  },
                },
                {
                  label: "Excluir",
                  onPress: () => {
                    this.setState({
                      showDeleteUserAlert: false,
                    });

                    this.deleteUser();
                  },
                  color: "primary",
                },
              ]}
            />
          )}
        </ContentContainer>
      </>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
});

export default withStyles(styles)(EditPartner);
