const defaultState = {
    userData: {
        nome: ''
    }
}

const userReducer = (state = defaultState, action) => {

    switch (action.type) {

        case 'SET_USER_DATA':
            // console.log('action.type', action)
            return { ...state, userData: action.payload }
        default:
            return state
    }
}

export default userReducer