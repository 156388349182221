import React from "react";
import { Box } from "@material-ui/core";
import { formatter } from "../../../functions";
import { ReportCard } from "../ReportCard";

export function ReportContainerData({ statistics }) {
   if (statistics) return (
      <Box sx={{
         display: 'grid',
         gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
         gap: '20px'
      }}>

         {/* assinaturas */}
         <ReportCard.Root>
            <ReportCard.Header
               value={statistics.contracts.approved}
               caption='Assinaturas'
            />
            <ReportCard.Divider />
            <ReportCard.Table
               data={[
                  { key: 'Residencial', value: `${statistics.contracts.residential.sum} (${statistics.contracts.residential.percentage}%)` },
                  { key: 'Home Office', value: `${statistics.contracts.homeOffice.sum} (${statistics.contracts.homeOffice.percentage}%)` },
                  { key: 'Eletro', value: `${statistics.contracts.eletro.sum} (${statistics.contracts.eletro.percentage}%)` },
               ]}
            />
         </ReportCard.Root>

         {/* fechamento */}
         <ReportCard.Root>
            <ReportCard.Header
               value={formatter.currency(statistics.income.estimated.all)}
               caption='Fechamento'
            />
            <ReportCard.Divider />
            <ReportCard.Table
               data={[
                  { key: 'Residencial', value: `${formatter.currency(statistics.income.estimated.residential.sum)} (${statistics.income.estimated.residential.percentage}%)` },
                  { key: 'Home Office', value: `${formatter.currency(statistics.income.estimated.homeOffice.sum)} (${statistics.income.estimated.homeOffice.percentage}%)` },
                  { key: 'Eletro', value: `${formatter.currency(statistics.income.estimated.eletro.sum)} (${statistics.income.estimated.eletro.percentage}%)` },
                  
               ]}
            />
         </ReportCard.Root>

         {/* faturados */}
         <ReportCard.Root>
            <ReportCard.Header
               value={formatter.currency(statistics.income.all)}
               caption='Faturados'
            />
            <ReportCard.Divider />
            <ReportCard.Table
               data={[
                  { key: 'Residencial', value: `${formatter.currency(statistics.income.residential.sum)} (${statistics.income.residential.percentage}%)` },
                  { key: 'Home Office', value: `${formatter.currency(statistics.income.homeOffice.sum)} (${statistics.income.homeOffice.percentage}%)` },
                  { key: 'Eletro', value: `${formatter.currency(statistics.income.eletro.sum)} (${statistics.income.eletro.percentage}%)` },
               ]}
            />
         </ReportCard.Root>

         {/* average */}
         <ReportCard.Root>
            <ReportCard.Header
               value={formatter.currency(statistics.average.ticket)}
               caption='Ticket Médio'
            />
            <ReportCard.Divider />
            <ReportCard.Table
               data={[
                  { key: 'Período médio', value: `${statistics.average.period} meses` },
                  { key: 'Top Ticket', value: formatter.currency(statistics.average.topTicket) },
               ]}
            />
         </ReportCard.Root>

         {/* discounts */}
         <ReportCard.Root>
            <ReportCard.Header
               value={formatter.currency(statistics.discount.sum)}
               caption='em descontos'
            />
            <ReportCard.Divider />
            <ReportCard.Table
               data={[
                  { key: 'Porcentagem', value: `${statistics.discount.percentage}%` },
               ]}
            />
         </ReportCard.Root>
      </Box>
   )

   return <></>;
} 