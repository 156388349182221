import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import { uniqueId } from "lodash";
import React from "react";
import { formatter, lightOrDark, pluralize } from "../../functions";
import { Colors } from "../../styles/Colors";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Text } from "../layout/components/text";
import { Alert, ComboBox, Selector, Stock, TextArea, TextField } from "../ui";
import { AddButton, RemoveButton } from "../ui/components/buttons";
import Dropzone from "../ui/components/upload/Dropzone";
import ImageList from "../ui/components/upload/ImageList";
import Confirm from "../utils/Confirm";
import Loading from "../utils/Loading";

const DottedLine = ({ leftData, rightData }) => {
  if (!leftData || !rightData) return <></>;

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: 4,
      }}
    >
      <Text detail style={{ whiteSpace: "nowrap" }}>
        {leftData}
      </Text>
      <Box
        style={{
          borderBottom: `1px dotted #ccc`,
          width: "100%",
          height: "84%",
        }}
      />
      <Text detail>{rightData}</Text>
    </Box>
  );
};

class EditProduct extends React.Component {
  constructor(props) {
    super(props);

    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      descriptionRef: React.createRef(null),
      priceAndActionRef: React.createRef(null),
      stockRef: React.createRef(null),
      loading: true,
      data: {
        _id,
        name: "",
        description: "",
        technicalDescription: "",
        price: 0,
        category: "",
        width: "",
        length: "",
        height: "",
        discounts: [],
        colors: [],
        features: [],
        units: [],
        styles: [],
        images: [],
        ambients: [],
        id_john: "",
        estoque: 0,
        order: 0,
        active: false,
        toPartner: false,
        driveThru: false,
        id_sap: "",
        codLoopBox: "",
        loopBox: false,
        enableStockAlert: false,
        discount: 0,
        updateStock: true,
        featured: false,
        visible: false,
        owner: "6092b6d08383184b98844d26",
        manual: undefined,
        stock: [],
      },

      categories: [],
      features: [],
      ambients: [],
      units: [],
      styles: [],
      selectorData: [],
      alert: {
        open: false,
        title: false,
        msg: "",
      },
      isDiscountFormOpen: false,
      isSelectorOpen: false,
      createDiscount: "",
      saving: false,
      isConfirmOpen: false,
      page_selected: 0,
      StockModalOpen: false,
      units_data: [],
      enableStockAlert: false,
    };

    this.onCheckChange = this.onCheckChange.bind(this);
    this.token = localStorage.getItem("token");
    this.headers = {
      headers: { Authorization: "bearer " + this.token },
    };
  }

  componentDidMount = async () => {
    this.getProduct();
    this.getCategories();
    this.getStyles();
    this.getFeatures();
    this.getUnits();
    this.getAmbients();
    this.getDiscount();
  };

  getDiscount = async () => {
    const _this = this;
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}/listDiscount`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          discounts: data,
        });
      });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    if (!data?.price || data?.price === "0" || !data.urlImg) {
      alert(
        "Para exibir o produto no site ele deve possuir uma imagem principal e um preço."
      );
      return;
    }

    this.setState({
      ...(name === "enableStockAlert"
        ? {
            [name]: event.target.checked,
            data: {
              ...data,
              stockAlert: null,
            },
          }
        : {
            data: {
              ...data,
              [name]: event.target.checked,
            },
            success: false,
          }),
    });
  };

  onSave = async () => {
    const { data } = this.state;

    if (!data?.price || data?.price === "0" || !data.urlImg) {
      return alert(
        "Para exibir o produto no site ele deve possuir uma imagem principal e um preço."
      );
    }

    if (!data._id) {
      return this.newProduct();
    }

    this.setState({
      saving: true,
      loading: true,
    });

    data.id_sap = data.id_john;
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/product/${data._id}`,
        data,
        this.headers
      )
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          loading: false,
        });

        this.showAlert({ msg: response.data.message });
        window.location.reload();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
          loading: false,
        });
      });
  };

  newProduct = async () => {
    const { data } = this.state;

    this.setState({
      saving: true,
      loading: true,
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/addProduct`, data, this.headers)
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          loading: false,
        });
        this.showAlert({ msg: response.data.message });
        this.props.history.push(`/app/product/${response?.data?.data?._id}`);
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
          loading: false,
        });
      });
  };

  arredondar(nr) {
    return Math.round(nr, -1).toFixed(2);
  }

  getProduct = async () => {
    const { _id } = this.state.data;

    if (!_id) return this.setState({ loading: false });

    axios
      .get(`${process.env.REACT_APP_API_URL}/product/${_id}`, this.headers)
      .then((response) => {
        const { data } = response;

        if (data.length && data.height && data.width) {
          data.length = data.length.toFixed(1);
          if (data.length.split(".")[1] == 0) {
            data.length = data.length.split(".")[0];
          }
          data.height = data.height.toFixed(1);
          if (data.height.split(".")[1] == 0) {
            data.height = data.height.split(".")[0];
          }

          data.width = data.width.toFixed(1);
          if (data.width.split(".")[1] == 0) {
            data.width = data.width.split(".")[0];
          }
        }
        if (typeof data.ambients == "undefined" || data.ambients == null) {
          data.ambients = [];
        }
        if (typeof data.styles == "undefined" || data.styles == null) {
          data.styles = [];
        }
        if (
          typeof data.units == "undefined" ||
          data.units == null ||
          data.units.length == 0
        ) {
          data.units = [];
        }
        if (
          typeof data.features == "undefined" ||
          data.features == null ||
          data.features.length == 0
        ) {
          data.features = [];
        }

        this.setState({
          data: { ...this.state.data, ...data },
          loading: false,
          enableStockAlert: typeof data.stockAlert === "number",
        });
      });
  };

  getCategories = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/v2/admin/categories`, this.headers)
      .then((response) => {
        this.setState({ categories: response.data.map((item) => item.title) });
      });
  };

  getFeatures = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/feature/`, this.headers)
      .then((response) => {
        const { data } = response;

        this.setState({
          features: data.map((item) => item.title),
        });
      });
  };

  getUnits = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/unit/`, this.headers)
      .then((response) => {
        const { data } = response;

        this.setState({
          units: data.map((item) => item.title),
          units_data: data,
        });
      });
  };

  getStyles = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/style/`, this.headers)
      .then((response) => {
        const { data } = response;

        this.setState({
          styles: data.map((item) => item.title),
        });
      });
  };

  getAmbients = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/ambient/`, this.headers)
      .then((response) => {
        const { data } = response;

        this.setState({
          ambients: data.map((item) => item.title),
        });
      });
  };

  showAlert = (alertData) => {
    const { msg = "" } = alertData;
    alert(msg);
  };

  onChangeStock = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    const { stock = {} } = data;
    let index = name;
    stock[index].stock = value;
    this.setState({ ...data, stock });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;

    this.setState({ data: { ...data, [name]: value } });
  };

  updateCategory = async ({ removeFrom, addTo }) => {
    await axios.patch(
      `${process.env.REACT_APP_API_URL}/v2/admin/product-categories/${addTo}?removeFrom=${removeFrom}&productId=${this.state.data._id}`,
      {},
      this.headers
    );
  };

  handleChangeCategory = ({ target }) => {
    const { value } = target;
    this.setState({ data: { ...this.state.data, category: value } });
    this.updateCategory({ removeFrom: this.state.data.category, addTo: value });
  };

  handleRemoveProductFromAmbient = async (ambient) => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/v2/admin/product-ambients/${ambient}?productId=${this.state.data._id}`,
      this.headers
    );
  };

  handlePushProductIntoAmbient = async (ambient) => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/v2/admin/product-ambients/${ambient}?productId=${this.state.data._id}`,
      {},
      this.headers
    );
  };

  onChangeCost = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        cost: {
          ...data.cost,
          [name]: Number(value),
        },
      },
    });
  };

  onDiscountAdd = (data) => {
    let { discounts } = this.state.data;
    const { percentual, parametro } = data;

    discounts.push({ percentual, parametro });

    this.setState({
      data: {
        ...this.state.data,
        discounts,
      },
      isDiscountFormOpen: false,
    });
  };

  onStockAdd = async (data) => {
    let { units_data = [] } = this.state;
    let { stock } = this.state.data;
    const { stock: qtd, unit } = data;
    let unit_item = await units_data.filter((item) => item._id == unit)[0];

    const newStock = [
      ...stock.filter((item) => item.id_unit !== unit_item.id_unit),
      {
        stock: Number(qtd),
        id_unit: unit,
        unit_id: unit_item.unit_id,
        urlSeo: unit_item.urlSeo,
      },
    ];

    this.setState({
      data: {
        ...this.state.data,
        stock: newStock,
      },
      StockModalOpen: false,
    });
  };

  removeDiscount = (index) => {
    let { discounts } = this.state.data;

    discounts.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        discounts,
      },
    });
  };

  onAddColor = (e) => {
    let { colors } = this.state.data;

    colors.push({ color: "#FFF" });
    this.setState({
      data: {
        ...this.state.data,
        colors,
      },
    });
  };

  onRemoveColor = (index) => {
    let { colors } = this.state.data;

    colors.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        colors,
      },
    });
  };

  onChangeColor = (e, index) => {
    let { colors } = this.state.data;

    colors[index].color = e.target.value;

    this.setState({
      data: {
        ...this.state.data,
        colors,
      },
    });
  };

  onAddFeature = (item) => {
    let { features } = this.state.data;

    features.push({ title: item.label });

    this.setState({
      data: {
        ...this.state.data,
        features,
      },
    });
  };

  removeFeature = (index) => {
    let { features } = this.state.data;

    features.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        features,
      },
    });
  };

  onAddUnit = async (item) => {
    let { units_data } = this.state;
    let { units } = this.state.data;

    const unit_item = await units_data.filter(
      (unit) => unit._id === item.value
    )[0];

    units.push(unit_item);

    this.setState({
      data: {
        ...this.state.data,
        units,
      },
    });
  };

  removeUnit = (index) => {
    let { units } = this.state.data;

    units.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        units,
      },
    });
  };

  onAddAmbient = (item) => {
    let { ambients = [] } = this.state.data;
    ambients.push({ title: item.label });
    this.setState({
      data: {
        ...this.state.data,
        ambients,
      },
    });
    this.handlePushProductIntoAmbient(item.label);
  };

  removeAmbient = (index) => {
    let { ambients } = this.state.data;
    this.handleRemoveProductFromAmbient(ambients[index].title);

    ambients.splice(index, 1);
    this.setState({
      data: {
        ...this.state.data,
        ambients,
      },
    });
  };

  onAddStyle = (item) => {
    let { styles } = this.state.data;

    styles.push({ title: item.label });

    this.setState({
      data: {
        ...this.state.data,
        styles,
      },
    });
  };

  removeStyle = (index) => {
    let { styles } = this.state.data;

    styles.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        styles,
      },
    });
  };

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  onDropFiles = async (files) => {
    const uploadedFiles = files.map((file, index) => ({
      file,
      id: this.getRandomInt(1, 999),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(this.processUpload);
  };

  processUpload = async (uploadedFile) => {
    this.setState({
      data: {
        ...this.state.data,
        images: [...this.state.data.images, uploadedFile],
      },
    });

    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    const token = await localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.updateFile(uploadedFile.id, data);
      })
      .catch((error) => console.log("error", error));
  };

  updateFile = (id, data) => {
    this.setState({
      data: {
        ...this.state.data,
        images: this.state.data.images.map((image) => {
          if (image.id == id) {
            return { ...image, ...data, uploaded: true };
          }

          return image;
        }),
      },
    });
  };

  onDeleteImage = (index) => {
    let { images } = this.state.data;
    images.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        images,
      },
    });
  };

  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  onDeletePress = async () => {
    const { _id } = this.state.data;
    const token = localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}/product/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.props.history.push(`/app/products/`);
      });
  };

  onDropPdf = (files) => {
    const uploadedFiles = files.map((file, index) => ({
      file,
      id: uniqueId(),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(this.processUploadPdf, true);
  };

  getUnitBySeo = (urlSeo) => {
    const { units_data } = this.state;
    const unit = units_data.filter((item) => item.urlSeo === urlSeo)[0];
    return unit;
  };

  processUploadPdf = async (uploadedFile, mainImg = true) => {
    const data = new FormData();
    data.append("file", uploadedFile.file, uploadedFile.name);

    const token = await localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.setState({
          data: {
            ...this.state.data,
            manual: data.url,
          },
        });
      })
      .catch((error) => console.log("error", error));
  };

  changeDiscount = (e) => {
    const { discounts } = this.state;
    let index = e.target.value;

    let descontos = discounts[index].discounts;
    let descontoNome = discounts[index].name;
    this.setState({
      createDiscount: "",
      data: {
        ...this.state.data,
        discounts: descontos,
        discountName: descontoNome,
      },
    });
  };

  render() {
    const {
      loading,
      data,
      enableStockAlert,
      alert,
      categories,
      isSelectorOpen,
      features,
      ambients,
      selectorData,
      selectorCallback,
      styles,
      StockModalOpen,
      units_data,
      discounts = [],
      descriptionRef,
      priceAndActionRef,
      stockRef,
    } = this.state;

    const imageContainerHeight = descriptionRef?.current?.clientHeight || 0;
    const stockContainerHeight = stockRef?.current?.clientHeight || 300;
    const discountContainerHeight =
      priceAndActionRef?.current?.clientHeight || 0;

    const discountAmount = Math.ceil(data?.price * (data.discount / 100));
    const finalPrice = Math.floor(data?.price - discountAmount);

    return (
      <>
        <Loading loading={loading} />
        {alert.open && (
          <Alert open={true} title={alert.title} msg={alert.msg} />
        )}
        <Confirm
          open={this.state.isConfirmOpen}
          title="Exclusão de Produto"
          text="Deseja realmente excluir este produto?"
          onDeletePress={this.onDeletePress}
          onClose={() => this.setState({ isConfirmOpen: false })}
        />
        <ContentHeader
          title={data?.name || "Novo Produto"}
          subtitle={
            data?.lastUpdatedBy?.name
              ? `Atualizado por ${data.lastUpdatedBy.name} em ${formatter.date(
                  data?.updatedAt,
                  "short"
                )}`
              : ``
          }
          buttons={[
            { text: "Salvar", onClick: this.onSave },
            {
              text: "Excluir",
              destructive: true,
              onClick: () => this.setState({ isConfirmOpen: true }),
            },
          ]}
        />
        <Box style={{ display: "flex", gap: 24, flex: 1 }}>
          <Box ref={descriptionRef} style={{ display: "flex", flex: 1 }}>
            <ContentContainer label="Nome e descrição" fullWidth>
              <TextField
                value={data?.name}
                onChange={this.onChange}
                name="name"
                placeholder="Digite o nome do produto"
                title="Nome do Produto"
              />
              <TextArea
                value={data?.description}
                name="description"
                title="Descrição"
                onChange={this.onChange}
              />
              <TextArea
                value={data?.technicalDescription}
                name="technicalDescription"
                title="Descrição Técnica"
                onChange={this.onChange}
              />
            </ContentContainer>
          </Box>
          <ContentContainer
            label="Imagens"
            style={{ height: imageContainerHeight }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                maxWidth: 300,
                flexDirection: "column",
                margin: 15,
              }}
            >
              {data?.urlImg && (
                <div style={{ marginBottom: 15 }}>
                  <Text detail>Imagem principal</Text>
                  <img
                    src={data.urlImg}
                    style={{ width: "100%", borderRadius: 8 }}
                    alt="main-image"
                  />
                </div>
              )}
              {data?.secondaryImage && (
                <div style={{ marginBottom: 15 }}>
                  <Text detail>Imagem secundária</Text>
                  <img
                    src={data.secondaryImage}
                    style={{ width: "100%", borderRadius: 8 }}
                    alt="secondary-image"
                  />
                </div>
              )}
              <Dropzone
                txt="Arraste aqui as imagens do produto ou clique para selecionar os arquivos. Imagem Padrão dos produtos (800px x 610px) ou proporcional."
                onDrop={this.onDropFiles}
              />
              <ImageList
                data={data.images}
                onDeleteImage={this.onDeleteImage}
                setImageDefault={(urlImg) => {
                  this.setState({ data: { ...data, urlImg } });
                }}
                setSecondaryImage={(secondaryImage) =>
                  this.setState({ data: { ...data, secondaryImage } })
                }
              />
            </div>
          </ContentContainer>
        </Box>

        <Box style={{ display: "flex", gap: 24 }}>
          <Box
            style={{ display: "flex", flexDirection: "column", gap: 24 }}
            ref={priceAndActionRef}
          >
            <ContentContainer label="Preço" style={{ width: 400 }}>
              <Text
                style={{
                  textAlign: "center",
                  color: Colors.darkGreenAlt,
                  fontSize: 24,
                  fontWeight: "700",
                  marginTop: 20,
                }}
              >
                {formatter.currency(finalPrice)}
              </Text>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <DottedLine
                  leftData="Valor original"
                  rightData={formatter.currency(data?.price)}
                />
                <DottedLine
                  leftData="Desconto"
                  rightData={formatter.currency(discountAmount)}
                />
              </Box>

              <TextField
                value={data?.price}
                onChange={this.onChange}
                name="price"
                title="Valor mensal"
                style={{ flex: "unset" }}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <TextField
                  value={data?.discount}
                  onChange={this.onChange}
                  name="discount"
                  title="Porcentagem de desconto"
                  style={{ flex: "unset" }}
                />
                <Text detail>
                  Ao definir uma porcentagem, o usuário no site verá este
                  produto com preço promocional.
                </Text>
              </Box>
              <Text detail>
                Todos os valores são arredondados para baixo. Portanto, um
                produto que, com desconto, custaria R$ 82,01 ou R$ 82,99, por
                exemplo, terá seu valor arredondado para R$ 82,00.
              </Text>
            </ContentContainer>
            <ContentContainer label="Ações">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.toPartner}
                      onChange={this.onCheckChange("toPartner")}
                      color="secondary"
                    />
                  }
                  label={<Text>Para Parceria</Text>}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.driveThru}
                      onChange={this.onCheckChange("driveThru")}
                      color="secondary"
                    />
                  }
                  label={<Text>Para Drive Thru</Text>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.updateStock}
                      onChange={this.onCheckChange("updateStock")}
                      color="secondary"
                    />
                  }
                  label={<Text>Aceitar Atualizações de Estoque</Text>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.featured}
                      onChange={this.onCheckChange("featured")}
                      color="secondary"
                    />
                  }
                  label={<Text>Destacar Produto</Text>}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enableStockAlert}
                      onChange={this.onCheckChange("enableStockAlert")}
                      color="secondary"
                    />
                  }
                  label={<Text>Ativar alerta de estoque</Text>}
                />
                {enableStockAlert ? (
                  <Box>
                    <TextField
                      value={data?.stockAlert}
                      onChange={this.onChange}
                      name="stockAlert"
                      style={{ maxWidth: 220 }}
                      title="Número limite"
                    />
                  </Box>
                ) : (
                  <></>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.visible}
                      onChange={this.onCheckChange("visible")}
                      color="secondary"
                    />
                  }
                  label={<Text>Exibir no site</Text>}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data?.active}
                      onChange={this.onCheckChange("active")}
                      color="secondary"
                    />
                  }
                  label={<Text>Ativo</Text>}
                />
              </div>
            </ContentContainer>
          </Box>

          <ContentContainer
            label="Descontos"
            fullWidth
            style={{ height: discountContainerHeight }}
          >
            <Table size="medium">
              <TableHead>
                <TableRow>
                  {["Período", "Porcentagem", "Preço final"].map((row) => (
                    <TableCell key={row} align="center">
                      {row}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.discounts.map(
                  ({ parametro: month, percentual: percentage }) => {
                    const discountApplicable = month > 6;
                    const price = discountApplicable
                      ? Math.floor(
                          Number(finalPrice) - (percentage * finalPrice) / 100
                        )
                      : Math.floor(
                          Number(finalPrice) + (percentage * finalPrice) / 100
                        );

                    return (
                      <TableRow key={`${month}_${percentage}`}>
                        <TableCell component="th" scope="row" align="center">
                          <Text>
                            {month} {pluralize("mês", month)}
                          </Text>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Text>
                            {`${discountApplicable ? "-" : "+"}
                        ${percentage}%`}
                          </Text>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Text>{formatter.currency(price)}</Text>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
            <Box
              style={{
                display: "flex",
                alignItems: "flex-end",
                flex: 1,
              }}
            >
              <FormControl style={{ minWidth: 120, flex: 1 }}>
                <InputLabel id="demo-simple-select-label">
                  {typeof data?.discountName == "undefined"
                    ? "Tabela de descontos"
                    : data.discountName}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data.discountName}
                  onChange={this.changeDiscount}
                >
                  {discounts.map((discountTable, index) => (
                    <MenuItem key={discountTable.name} value={index}>
                      {discountTable.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </ContentContainer>
        </Box>
        <Box style={{ display: "flex", gap: 24, flex: 1 }} ref={stockRef}>
          <ContentContainer label="Estoque" fullWidth>
            <Box
              // ref={stockRef}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 24,
                flex: 1,
              }}
            >
              <TextField
                value={data?.estoque}
                onChange={this.onChange}
                name="estoque"
                title="São Paulo"
                fullWidth
              />
              {data?.stock.map((stock, indexStock) => {
                let unit = this.getUnitBySeo(stock.urlSeo);
                if (unit) {
                  return (
                    <>
                      {unit.title !== "São Paulo" && (
                        <TextField
                          key={indexStock + stock.title}
                          value={stock.stock}
                          onChange={this.onChangeStock}
                          id={stock.id_unit}
                          name={indexStock}
                          title={`${unit.title}`}
                        />
                      )}
                    </>
                  );
                }
              })}
              <div
                onClick={() => this.setState({ StockModalOpen: true })}
                style={{
                  padding: 7,
                  borderWidth: 0.5,
                  borderStyle: "dashed",
                  cursor: "pointer",
                  borderRadius: 5,
                  marginBottom: 15,
                  fontSize: 12,
                }}
              >
                + Estoque
              </div>
              {StockModalOpen && (
                <Stock
                  units={units_data.filter((stock) => stock.unit_id != 8)}
                  onClose={() => this.setState({ StockModalOpen: false })}
                  onStockAdd={this.onStockAdd}
                />
              )}
            </Box>
          </ContentContainer>
          <ContentContainer
            label="Características"
            fullWidth
            style={{ maxHeight: stockContainerHeight }}
          >
            {/* <ComboBox
              title="Categoria"
              onChange={this.onChange}
              name="category"
              value={data?.category}
              data={categories}
              categories={categories}
              style={{ marginBottom: 15 }}
            /> */}

            <FormControl style={{ minWidth: 120, flex: 1 }}>
              <InputLabel id="category-select-label">Categoria</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={data.category}
                name="category"
                onChange={this.handleChangeCategory}
              >
                {categories.map((item) => {
                  return (
                    <MenuItem key={`category_${item}`} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <TextField
                value={data?.width}
                onChange={this.onChange}
                name="width"
                placeholder="Largura"
                title="Largura (cm)"
                style={{ maxWidth: 120 }}
              />
              <div style={{ marginBottom: 15, padding: 10 }}>X</div>
              <TextField
                value={data?.height}
                onChange={this.onChange}
                name="height"
                placeholder="Altura"
                title="Altura (cm)"
                style={{ maxWidth: 120 }}
              />
              <div style={{ marginBottom: 15, padding: 10 }}>X</div>
              <TextField
                value={data?.length}
                onChange={this.onChange}
                name="length"
                placeholder="Profundidade"
                title="Profundidade (cm)"
                style={{ maxWidth: 120 }}
              />
            </div>
            <TextField
              value={data?.id_sap}
              onChange={this.onChange}
              name="id_sap"
              placeholder="ID John Richard"
              title="ID John Richard"
              style={{ maxWidth: 120 }}
            />
            <div>
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "500",
                  marginBottom: 5,
                }}
              >
                Cor
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {data?.colors.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 20,
                      marginBottom: 20,
                    }}
                  >
                    <input
                      type="text"
                      key={index}
                      style={{
                        height: 30,
                        width: 70,
                        fontSize: 14,
                        fontWeight: "600",
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: "gray",
                        backgroundColor: item.color,
                        color:
                          lightOrDark(item.color) == "light" ? "#000" : "#FFF",
                      }}
                      value={item.color}
                      onChange={(e) => this.onChangeColor(e, index)}
                      name="color"
                    />
                    <RemoveButton
                      onClick={() => this.onRemoveColor(index)}
                      style={{ marginLeft: -5, marginTop: -5 }}
                    />
                  </div>
                ))}
                <AddButton text="+ Cor" onClick={this.onAddColor} />
              </div>
            </div>

            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text strong>Características</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                {data?.features != null
                  ? data?.features.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginRight: 15,
                        }}
                      >
                        <div
                          style={{
                            borderColor: "gray",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: 5,
                            padding: 7,
                            fontSize: 12,
                          }}
                        >
                          {item.title}
                        </div>
                        <RemoveButton
                          onClick={() => this.removeFeature(index)}
                        />
                      </div>
                    ))
                  : ""}
                <AddButton
                  text="+ Característica"
                  onClick={() =>
                    this.setState({
                      isSelectorOpen: true,
                      selectorData: features,
                      selectorCallback: (item) => this.onAddFeature(item),
                    })
                  }
                />
              </div>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text strong>Disponibilidade</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                {data?.units != null &&
                  data?.units.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 15,
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => this.removeUnit(index)} />
                    </div>
                  ))}
                <AddButton
                  text="+ Filial"
                  onClick={() =>
                    this.setState({
                      isSelectorOpen: true,
                      selectorData: units_data,
                      selectorCallback: (item) => this.onAddUnit(item),
                    })
                  }
                />
              </div>
            </Box>
            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text strong>Estilos</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                {data?.styles != null &&
                  data?.styles.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 15,
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => this.removeStyle(index)} />
                    </div>
                  ))}
                <AddButton
                  text="+ Estilo"
                  onClick={() =>
                    this.setState({
                      isSelectorOpen: true,
                      selectorData: styles,
                      selectorCallback: (item) => this.onAddStyle(item),
                    })
                  }
                />
              </div>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text strong>Ambientes</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                {data?.ambients != null &&
                  data?.ambients.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 15,
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => this.removeAmbient(index)} />
                    </div>
                  ))}
                <AddButton
                  text="+ Ambiente"
                  onClick={() =>
                    this.setState({
                      isSelectorOpen: true,
                      selectorData: ambients,
                      selectorCallback: (item) => this.onAddAmbient(item),
                    })
                  }
                />
              </div>
            </Box>
            {isSelectorOpen && (
              <Selector
                open={isSelectorOpen}
                onSelect={(item) => {
                  this.setState({ isSelectorOpen: false });
                  selectorCallback(item);
                }}
                title="Selecione"
                data={selectorData}
                unit={units_data == selectorData ? true : false}
                onClose={() => this.setState({ isSelectorOpen: false })}
              />
            )}
          </ContentContainer>
        </Box>
      </>
    );
  }
}

const styles = (theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  dropZone: {
    // backgroundColor: 'red',
    display: "flex",
    flex: 1,
    padding: 15,
    justifyContent: "center",
    alignItems: "center",
    margin: 15,
    borderRadius: 10,
    flexDirection: "column",
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: "gray",
    maxHeight: 150,
    cursor: "pointer",
  },
});

export default withStyles(styles)(EditProduct);
