import React, { Component } from "react";
import axios from "axios";
import {
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Input,
  CircularProgress,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import SaveIcon from "@material-ui/icons/Save";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import classNames from "classnames";
import green from "@material-ui/core/colors/green";
import Loading from "../utils/Loading";
import AlertDialog from "../utils/AlertDialog";

class EditWarn extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        name: "",
        email: "",
        product: [
          {
            name: "",
          },
        ],
        createdAt: "",
        status: "",
        type: "",
      },
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      showAlert: false,
      showDeleteCouponAlert: false,
      loading: true,
    };

    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onDeleteCouponPress = this.onDeleteCouponPress.bind(this);
    this.deleteCoupon = this.deleteCoupon.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
  }

  componentDidMount = async () => {
    const { _id } = this.state.data;

    if (!_id) {
      this.setState({ loading: false });
      return;
    }

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .get(`${process.env.REACT_APP_API_URL}/getWarn/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        console.log("dados", data);
        _this.setState({
          data: data,
        });

        this.setState({ loading: false });
      });
  };

  onChange = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value,
      },
      success: false,
    });
  };

  onSavePress = async () => {
    const { data } = this.state;

    if (!data._id) {
      this.newCoupon();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");

    axios
      .patch(`${process.env.REACT_APP_API_URL}/warnMe/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.goBack();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newCoupon = async () => {
    const { data } = this.state;

    data._id = undefined;

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .post(`${process.env.REACT_APP_API_URL}/coupon`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          data: response.data.data,
        });

        this.props.history.goBack();

        _this.props.history.push(`${response.data.data._id}`);
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  deleteCoupon = async () => {
    const { data } = this.state;
    const token = await localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}/warnMe/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.props.history.push("/app/warns");
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  onCancelPress = () => {
    this.props.history.goBack();
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, showAlert: false });
  };

  onDeleteCouponPress = () => {
    this.setState({ anchorEl: null, open: false, showDeleteCouponAlert: true });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  render() {
    const {
      classes,
      saving,
      success,
      anchorEl,
      open,
      showDeleteCouponAlert,
      loading,
    } = this.state;
    const { data } = this.state;
    console.log("data", data);

    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    return (
      <Paper className={classes.paper}>
        <Loading loading={loading} />

        <Typography component="h1" variant="h5">
          {data.title}
          <IconButton
            aria-label="More"
            aria-owns={true}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
            PaperProps={{
              style: {
                maxHeight: 200,
                width: 200,
              },
            }}
          >
            <MenuItem key={0} onClick={this.onDeleteCouponPress}>
              Excluir Aviso
            </MenuItem>
          </Menu>
        </Typography>
        <form className={classes.form}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="name">Nome</InputLabel>

            <Input
              id="name"
              name="name"
              autoFocus
              value={data.name}
              onChange={this.onChange}
            />
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">email</InputLabel>

            <Input
              id="email"
              name="email"
              autoFocus
              value={data.email}
              onChange={this.onChange}
            />
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="product">Produto</InputLabel>

            <Input
              id="product"
              name="product"
              autoFocus
              value={
                typeof data.product !== "undefined" ? data.product[0].name : ""
              }
              onChange={this.onChange}
            />
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="type">Tipo de Aviso</InputLabel>

            <Input
              id="type"
              name="type"
              autoFocus
              value={data.type}
              onChange={this.onChange}
            />
          </FormControl>

          {typeof data.quantity != "undefined" ? (
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="status">Qtd Solicitada</InputLabel>

              <Input
                id="status"
                name="status"
                autoFocus
                value={data.quantity}
                onChange={this.onChange}
              />
            </FormControl>
          ) : (
            ""
          )}

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="status">Status</InputLabel>

            <Input
              id="status"
              name="status"
              autoFocus
              value={data.status}
              onChange={this.onChange}
            />
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={data.active}
                onChange={this.onCheckChange("active")}
                value="active"
                color="primary"
              />
            }
            label="Ativo"
          />
          <div className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              className={buttonClassname}
              disabled={saving}
              onClick={this.onSavePress}
            >
              <SaveIcon
                className={classNames(classes.leftIcon, classes.iconSmall)}
              />
              Salvar
              {saving && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              onClick={this.onCancelPress}
            >
              Cancelar
            </Button>
            {this.state.error && <Typography>{this.state.error}</Typography>}
          </div>
        </form>

        {showDeleteCouponAlert && (
          <AlertDialog
            msg={`Deseja relmente excluir o cupom ${data.title}? Obs.: Esta ação è irreversível`}
            title="Excluir Cupom"
            open={showDeleteCouponAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showDeleteCouponAlert: false,
                  });
                },
              },
              {
                label: "Excluir",
                onPress: () => {
                  this.setState({
                    showDeleteCouponAlert: false,
                  });

                  this.deleteCoupon();
                },
                color: "primary",
              },
            ]}
          />
        )}
      </Paper>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    maxWidth: "450px",
    marginTop: theme.spacing.unit,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
});

export default withStyles(styles)(EditWarn);
