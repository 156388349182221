import styled from 'styled-components';


const Container = styled.div`
width: 100%;

.MuiTypography-body1-60{
    width: 89px;
}


`
export default Container