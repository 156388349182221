import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { setUserData } from "../store/actions";

class AuthRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: false,
      loading: true,
      userData: null,
      token: null,
    };
  }

  componentDidMount = async () => {
    // const _this = this;
    const { token } = JSON.parse(
      localStorage.getItem("tuimAdmin.user") || "{}"
    );
    const headers = { headers: { Authorization: "bearer " + token } };

    await axios
      .get(`${process.env.REACT_APP_API_URL}/login`, headers)
      .then(async (response) => {
        const { data } = response;
        this.setState({
          logged: true,
          loading: false,
          userData: data.userData,
        });
      })
      .catch((error) => {
        this.setState({ logged: false, loading: false });
        localStorage.removeItem("tuimAdmin.user");
      });
  };

  render() {
    const { logged, loading, userData } = this.state;
    const Component = this.props.component;

    if (loading)
      return (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f1f1f1",
            }}
          >
            <h4 style={{ color: "#444" }}>Carregando dados...</h4>
          </div>
        </div>
      );

    if (logged)
      return (
        <>
          <Route
            component={(props) => <Component {...props} userData={userData} />}
            userData={userData}
          />
        </>
      );

    return <Redirect to="/" />;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (userData) => dispatch(setUserData(userData)),
  };
};

const mapStateToProps = (state) => ({
  user: state,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthRoute);
