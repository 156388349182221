import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import Routes from "./routes/index";
import Loading from "./components/utils/Loading";
import { setConfig } from "./config";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "./config/theme";
import ChatProvider from "./contexts/chatContext";

const App = () => {
  setConfig();

  return (
    <ChatProvider>
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Loading />
          <Routes />
        </MuiThemeProvider>
      </Provider>
    </ChatProvider>
  );
};

export default App;
