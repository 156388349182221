import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SignIn from "../components/login/SignIn";
import LoggedIn from "../components/layout/LoggedIn";
import AuthRoute from "./AuthRoute";
import { connect } from "react-redux";

class Routes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <AuthRoute path="/app" component={LoggedIn} />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state,
});

export default connect(mapStateToProps, null)(Routes);
