import React, { useState } from "react";
import { ReportContainer } from "..";
import { formatter } from "../../../../functions";
import { ReportCard } from "../../ReportCard";
import { statusOrderByCategory } from "./Helpers";

export const ByCategory = ({ data }) => {
  const tabs = data?.data ? Object.keys(data.data) : [];
  const statistics = data?.statistics;

  const [selectedTab, setSelectedTab] = useState('Total')

  return (
    <ReportContainer.Root>
      <>
        <ReportContainer.Tab tabs={['Total', ...tabs]} onClick={setSelectedTab} selectedTab={selectedTab} />

        {selectedTab === 'Total' ?
          <ReportCard.Group title={'Total'}>
            <ReportCard.Root>
              <ReportCard.Header value={formatter.number(statistics.contracts.approved)} caption='Assinaturas' />
              <ReportCard.Divider />
              <ReportCard.Table
                data={Object.keys(data.data).map(item => ({
                  key: item, value: formatter.number(data.data[item].statistics.contracts.approved)
                }))}
              />
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(statistics.income.estimated.all)} caption='Fechamento' />
              <ReportCard.Divider />
              <ReportCard.Table
                data={Object.keys(data.data).map(item => ({
                  key: item, value: formatter.currency(data.data[item].statistics.income.estimated.all)
                }))}
              />
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(statistics.income.all)} caption='Faturamento' />
              <ReportCard.Divider />
              <ReportCard.Table
                data={Object.keys(data.data).map(item => ({
                  key: item, value: formatter.currency(data.data[item].statistics.income.all)
                }))}
              />
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(statistics.average.ticket)} caption='Ticket Médio' />
              <ReportCard.Divider />
              <ReportCard.Table
                data={Object.keys(data.data).map(item => ({
                  key: item, value: formatter.currency(data.data[item].statistics.average.ticket)
                }))}
              />
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(statistics.discount.sum)} caption='em descontos' />
              <ReportCard.Divider />
              <ReportCard.Table
                data={Object.keys(data.data).map(item => ({
                  key: item, value: formatter.currency(data.data[item].statistics.discount.sum)
                }))}
              />
            </ReportCard.Root>

          </ReportCard.Group>
          :
          <>
            <ReportCard.Group title='Aprovados'>
              <ReportCard.Root>
                <ReportCard.Header value={formatter.number(data.data[selectedTab].statistics.contracts.approved)} caption='Assinaturas' />
                <ReportCard.Divider />
                <ReportCard.Table
                  data={[
                    { key: 'Residencial', value: `${formatter.number(data.data[selectedTab].statistics.contracts.residential.sum)} (${formatter.number(data.data[selectedTab].statistics.contracts.residential.percentage)}%)` },
                    { key: 'Home Office', value: `${formatter.number(data.data[selectedTab].statistics.contracts.homeOffice.sum)} (${formatter.number(data.data[selectedTab].statistics.contracts.homeOffice.percentage)}%)` },
                    { key: 'Eletro', value: `${formatter.number(data.data[selectedTab].statistics.contracts.eletro.sum)} (${formatter.number(data.data[selectedTab].statistics.contracts.eletro.percentage)}%)` }
                  ]}
                />
              </ReportCard.Root>

              <ReportCard.Root>
                <ReportCard.Header value={formatter.currency(data.data[selectedTab].statistics.income.estimated.all)} caption='Fechamento' />
                <ReportCard.Divider />
                <ReportCard.Table
                  data={[
                    { key: 'Residencial', value: `${formatter.currency(data.data[selectedTab].statistics.income.estimated.residential.sum)} (${formatter.number(data.data[selectedTab].statistics.income.estimated.residential.percentage)}%)` },
                    { key: 'Home Office', value: `${formatter.currency(data.data[selectedTab].statistics.income.estimated.homeOffice.sum)} (${formatter.number(data.data[selectedTab].statistics.income.estimated.homeOffice.percentage)}%)` },
                    { key: 'Eletro', value: `${formatter.currency(data.data[selectedTab].statistics.income.estimated.eletro.sum)} (${formatter.number(data.data[selectedTab].statistics.income.estimated.eletro.percentage)}%)` },
                  ]}
                />
              </ReportCard.Root>

              <ReportCard.Root>
                <ReportCard.Header value={formatter.currency(data.data[selectedTab].statistics.income.all)} caption='Faturamento' />
                <ReportCard.Divider />
                <ReportCard.Table
                  data={[
                    { key: 'Residencial', value: `${formatter.currency(data.data[selectedTab].statistics.income.residential.sum)} (${formatter.number(data.data[selectedTab].statistics.income.residential.percentage)}%)` },
                    { key: 'Home Office', value: `${formatter.currency(data.data[selectedTab].statistics.income.homeOffice.sum)} (${formatter.number(data.data[selectedTab].statistics.income.homeOffice.percentage)}%)` },
                    { key: 'Eletro', value: `${formatter.currency(data.data[selectedTab].statistics.income.eletro.sum)} (${formatter.number(data.data[selectedTab].statistics.income.eletro.percentage)}%)` },
                  ]}
                />
              </ReportCard.Root>

              <ReportCard.Root>
                <ReportCard.Header value={formatter.currency(data.data[selectedTab].statistics.average.ticket)} caption='Ticket Médio' />
                <ReportCard.Divider />
                <ReportCard.Table
                  data={[
                    { key: 'Período médio', value: formatter.number(data.data[selectedTab].statistics.average.period) },
                    { key: 'Top Ticket', value: formatter.currency(data.data[selectedTab].statistics.average.topTicket) }
                  ]}
                />
              </ReportCard.Root>

              <ReportCard.Root>
                <ReportCard.Header value={formatter.currency(data.data[selectedTab].statistics.discount.sum)} caption='em descontos' />
                <ReportCard.Divider />
                <ReportCard.Table
                  data={[
                    { key: 'Porcentagem', value: `${formatter.number(data.data[selectedTab].statistics.discount.percentage)}%` },
                  ]}
                />
              </ReportCard.Root>
            </ReportCard.Group>

            {statusOrderByCategory.map(({ status, display, colors }, index) => {
              const itemStatistics = data.data[selectedTab].contracts?.[status];
              return (
                itemStatistics ?
                  <ReportCard.Group title={display} key={`${index}_${display}`}>
                    <ReportCard.Root background={colors.background} border={colors.border}>
                      <ReportCard.Header value={formatter.number(itemStatistics.statistics.contracts.all)} caption='Assinaturas' />
                      <ReportCard.Divider />
                      <ReportCard.Table
                        data={[
                          { key: 'Residencial', value: `${formatter.number(itemStatistics.statistics.contracts.residential.sum)} (${formatter.number(itemStatistics.statistics.contracts.residential.percentage)}%)` },
                          { key: 'Home Office', value: `${formatter.number(itemStatistics.statistics.contracts.homeOffice.sum)} (${formatter.number(itemStatistics.statistics.contracts.homeOffice.percentage)}%)` },
                          { key: 'Eletro', value: `${formatter.number(itemStatistics.statistics.contracts.eletro.sum)} (${formatter.number(itemStatistics.statistics.contracts.eletro.percentage)}%)` }
                        ]}
                      />
                    </ReportCard.Root>

                    <ReportCard.Root background={colors.background} border={colors.border}>
                      <ReportCard.Header value={formatter.currency(itemStatistics.statistics.income.estimated.all)} caption='Fechamento' />
                      <ReportCard.Divider />
                      <ReportCard.Table
                        data={[
                          { key: 'Residencial', value: `${formatter.currency(itemStatistics.statistics.income.estimated.residential.sum)} (${formatter.number(itemStatistics.statistics.income.estimated.residential.percentage)}%)` },
                          { key: 'Home Office', value: `${formatter.currency(itemStatistics.statistics.income.estimated.homeOffice.sum)} (${formatter.number(itemStatistics.statistics.income.estimated.homeOffice.percentage)}%)` },
                          { key: 'Eletro', value: `${formatter.currency(itemStatistics.statistics.income.estimated.eletro.sum)} (${formatter.number(itemStatistics.statistics.income.estimated.eletro.percentage)}%)` },
                        ]}
                      />
                    </ReportCard.Root>

                    <ReportCard.Root background={colors.background} border={colors.border}>
                      <ReportCard.Header value={formatter.currency(itemStatistics.statistics.income.all)} caption='Faturamento' />
                      <ReportCard.Divider />
                      <ReportCard.Table
                        data={[
                          { key: 'Residencial', value: `${formatter.currency(itemStatistics.statistics.income.residential.sum)} (${formatter.number(itemStatistics.statistics.income.residential.percentage)}%)` },
                          { key: 'Home Office', value: `${formatter.currency(itemStatistics.statistics.income.homeOffice.sum)} (${formatter.number(itemStatistics.statistics.income.homeOffice.percentage)}%)` },
                          { key: 'Eletro', value: `${formatter.currency(itemStatistics.statistics.income.eletro.sum)} (${formatter.number(itemStatistics.statistics.income.eletro.percentage)}%)` },
                        ]}
                      />
                    </ReportCard.Root>

                    <ReportCard.Root background={colors.background} border={colors.border}>
                      <ReportCard.Header value={formatter.currency(itemStatistics.statistics.average.ticket)} caption='Ticket Médio' />
                      <ReportCard.Divider />
                      <ReportCard.Table
                        data={[
                          { key: 'Período médio', value: formatter.number(itemStatistics.statistics.average.period) },
                          { key: 'Top Ticket', value: formatter.currency(itemStatistics.statistics.average.topTicket) }
                        ]}
                      />
                    </ReportCard.Root>

                    <ReportCard.Root background={colors.background} border={colors.border}>
                      <ReportCard.Header value={formatter.currency(itemStatistics.statistics.discount.sum)} caption='em descontos' />
                      <ReportCard.Divider />
                      <ReportCard.Table
                        data={[
                          { key: 'Porcentagem', value: `${formatter.number(itemStatistics.statistics.discount.percentage)}%` },
                        ]}
                      />
                    </ReportCard.Root>
                  </ReportCard.Group>
                  :
                  <></>
              )
            })}
          </>
        }
      </>
    </ReportContainer.Root>
  )
}