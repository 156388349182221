import React, { useState } from "react";
import axios from "axios";
import { Box, TextField } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { getPriceByProductItem } from "../../functions/calcCartPrice/calcCartPrice";
import moment from "moment";
import { ContentContainer } from "../layout/components/contentContainer";
import { TuimButton } from "../layout/Button";
import { Alert } from "../layout/Alert";

moment.locale("pt-br");
export default function ReturnProduct({
  contract,
  productSelectedReturn = {},
  getContract,
}) {
  /*
   * productSelectedReturn
   */
  const {
    firstName = "",
    lastName = "",
    category = "",
    urlImg = "",
    _id: productId = "",
  } = productSelectedReturn;
  const { _id = " ", id_subscription } = contract;

  let dateMoment = moment().format("YYYY-MM-DD");
  const [product, setProduct] = useState({
    contract: _id,
    name: `${firstName} ${lastName}`,
    category: category,
    qtd: 0,
    dateReturn: dateMoment,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [showAlert, setShowAlert] = useState({
    show: false,
    color: "",
    message: "",
  });

  const { qtd = 0, dateReturn = dateMoment } = product;

  /* 
    Get price from contract with active products
  */
  const getPriceContract = async () => {
    return await contract.products.reduce(function (prevValue, element) {
      return (
        prevValue +
        Number(getPriceByProductItem(element, contract.period)) * element.qtd
      );
    }, 0);
  };

  const editContract = async () => {
    let returnProduct = 1;

    //Check if product return quantity is valid
    if (qtd > productSelectedReturn.qtd) {
      setShowAlert({
        show: true,
        color: "#f50707",
        message: "Número maior que a quantidade do produto",
      });
      setProduct({ ...product, qtd: 0 });
      return;
    } else if (qtd <= 0) {
      setShowAlert({
        show: true,
        color: "#f50707",
        message: "Quantidade solicitada está inválida!",
      });
      setProduct({ ...product, qtd: 0 });
      return;
    }

    productSelectedReturn.qtd = productSelectedReturn.qtd - qtd;

    //If qtd is equal 0, remove product from contract
    if (productSelectedReturn.qtd == 0) {
      productSelectedReturn.status_product = false;
      let ProductRemove = contract.products.findIndex(
        (item) => item._id == productSelectedReturn._id
      );
      contract.products.splice(ProductRemove, 1);
    }

    //if the products is empty cancel the contract
    if (contract.products.length == 0) {
      contract.status = "finished";
    }
    //Update contract price
    contract.payment.price = await getPriceContract();

    const token = await localStorage.getItem("token");

    //Remove the quantity of product returned from contract
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/contract/${_id}`,
        { data_contract: contract, returnProduct },
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then(async () => {
        //Add product returned
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/v2/return-product`,
            {
              ...product,
              name: `${firstName} ${lastName}`,
              category: category,
              urlImg: urlImg,
              id_subscription,
              product: productId,
              contract: _id,
            },
            {
              headers: { Authorization: "bearer " + token },
            }
          )
          .then(async () => {
            successReturnProduct();
            setProduct({
              name: "",
              category: "",
              qtd: 0,
              dateReturn: dateMoment,
            });
          });
      })
      .catch((error) => {
        console.log(error);
        setShowAlert({
          show: true,
          color: "#f50707",
          message: "Não foi possível atualizar este contrato!",
        });
      });
  };

  const changeValue = (e) => {
    const { name, value } = e.target;

    setProduct({
      ...product,
      [name]: value,
    });
  };

  const successReturnProduct = () => {
    getContract();
    setShowAlert({
      show: true,
      color: "#4caf50",
      message: "Produto Devolvido com sucesso.",
    });
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          gap: 10,
          justifyContent: "center",
          alignItems: "center",
          marginRight: 10,
        }}
      >
        <TuimButton
          fullWidth
          text="Devolver"
          tertiary
          onClick={() => setModalVisible(true)}
        />
      </Box>

      <Modal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ContentContainer width="500px" label="Devolução de produto">
          <TextField
            id="standard-basic"
            label="Quantidade"
            type="number"
            value={qtd}
            name="qtd"
            autoComplete="off"
            onChange={changeValue}
            style={{ marginTop: 10, width: "100%" }}
          />

          <TextField
            id="standard-basic"
            type="date"
            label="Data de devolução"
            name="dateReturn"
            onChange={changeValue}
            value={dateReturn}
            style={{ marginTop: 10, width: "100%" }}
          />
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              padding: 15,
            }}
          >
            <TuimButton
              text="Devolver"
              primary
              onClick={() => editContract()}
            />
          </div>
        </ContentContainer>
      </Modal>

      <Alert
        msg_alert={showAlert.message}
        notificarionColor={showAlert.color}
        snackbar_alert={showAlert.show}
      />
    </>
  );
}
