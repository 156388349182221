import {
  Box,
  CssBaseline,
  FormControl,
  Link,
  TextField,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { config } from "../../config";
import { setUserData, startLoading, stopLoading } from "../../store/actions";
import { Colors } from "../../styles/Colors";
import { TuimButton } from "../layout/Button";
import AlertDialog from "../utils/AlertDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog";

class SignIn extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      email: "",
      password: "",
      classes,
      showLoginAlert: false,
      errorMsg: null,
      isForgotPasswordModalOpen: false,
      recaptcha_value: "",
    };
  }

  componentDidMount = async () => {
    this.ScriptRecaptcha();
  };

  ScriptRecaptcha = async () => {
    const script = document.createElement("script");

    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LdtA9AUAAAAAMxqeTn78cOgvxwFg9seDRK0sLwD";
    script.id = "recaptcha-v3-id";
    script.async = true;

    document.body.appendChild(script);
  };

  generateRecaptchaToken = async () => {
    return await window.grecaptcha
      .execute("6LdtA9AUAAAAAMxqeTn78cOgvxwFg9seDRK0sLwD", { action: "submit" })
      .then(function (token) {
        return token;
      });
  };

  checkCaptchaToken = async (token = "") => {
    return await axios
      .get(`${process.env.REACT_APP_API_URL}/recaptcha-valid?captcha=${token}`)
      .then(function (response) {
        const { success } = response.data;
        return success;
      })
      .catch(function (error) {
        return false;
      });
  };
  handleLogIn = async (e) => {
    e.preventDefault();
    this.props.startLoading();
    const _this = this;
    const token_recaptcha = await this.generateRecaptchaToken();

    const captcha = await this.checkCaptchaToken(token_recaptcha);

    if (!captcha) {
      _this.setState({
        errorMsg: "Falha na verificação do re-captcha",
        showLoginAlert: true,
      });
      _this.props.stopLoading();
      return false;
    }

    const { email, password } = this.state;

    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, {
        email,
        password,
      })
      .then(function (response) {
        const { token, data } = response.data;
        const localStorageUserData = {
          id: data._id,
          name: data.name,
          sapSellerId: data.sapSellerId,
          token,
          permission: data.permission,
          email: data.email,
        };

        localStorage.setItem(
          "tuimAdmin.user",
          JSON.stringify(localStorageUserData)
        );
        localStorage.setItem("token", token);
        localStorage.setItem("_id", data._id);
        localStorage.setItem("user", data.permission);

        _this.props.setUserData(data);
        window.location.href = "/app";
      })
      .catch(function (error) {
        const { message = null } = error.response.data;
        _this.setState({
          errorMsg: message,
          showLoginAlert: true,
        });
      })
      .finally(() => _this.props.stopLoading());
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onForgotPassword = (email) => {
    const _this = this;

    this.props.startLoading();
    this.setState({
      isForgotPasswordModalOpen: false,
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/forgotpassword`, {
        email,
      })
      .then(function (response) {
        // FALTA MSG QUE O E-MAIL FOI ENVIADO
        _this.props.stopLoading();
      })
      .catch(function (error) {
        _this.props.stopLoading();
        const { message = null } = error.response.data;
        _this.setState({
          errorMsg: message,
          showLoginAlert: true,
        });
      });
  };

  render() {
    const { errorMsg, showLoginAlert, isForgotPasswordModalOpen } = this.state;

    return (
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          backgroundColor: Colors.inputBackground,
          backgroundImage: config.backgroundPattern,
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            width: "80%",
            height: "80%",
            borderRadius: 20,
            overflow: "hidden",
            boxShadow: `rgba(149, 157, 165, 0.12) 0px 4px 24px`,
            backgroundColor: "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flex: 1,
              minWidth: 500,
              backgroundColor: "#FFF",
            }}
          >
            <Box
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px",
                gap: 50,
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url('./images/logo-mobile.svg')`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  width: 120,
                  height: 50,
                }}
              />
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    variant="outlined"
                    id="email"
                    label={`E-mail`}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={this.state.email}
                    onChange={this.onChange}
                    InputLabelProps={{
                      style: { marginRight: 30, marginLeft: 4 },
                    }}
                    InputProps={{
                      style: { borderRadius: 40, paddingLeft: 10 },
                    }}
                    inputProps={{
                      style: {
                        fontSize: 15,
                        borderRadius: 40,
                        paddingLeft: 10,
                        WebkitBoxShadow: `0 0 0 1000px ${Colors.inputBackground} inset`,
                      },
                    }}
                    style={{
                      backgroundColor: Colors.inputBackground,
                      borderRadius: 40,
                    }}
                  />
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <TextField
                    variant="outlined"
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={this.onChange}
                    InputLabelProps={{
                      style: { marginRight: 30, marginLeft: 4 },
                    }}
                    InputProps={{
                      style: { borderRadius: 40, paddingLeft: 10 },
                    }}
                    inputProps={{
                      style: {
                        borderRadius: 40,
                        paddingLeft: 10,
                        WebkitBoxShadow: `0 0 0 1000px ${Colors.inputBackground} inset`,
                      },
                    }}
                    style={{
                      backgroundColor: Colors.inputBackground,
                      borderRadius: 40,
                    }}
                  />
                </FormControl>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Link
                    href="#"
                    onClick={() =>
                      this.setState({ isForgotPasswordModalOpen: true })
                    }
                  >
                    Esqueci minha senha
                  </Link>
                </Box>
              </Box>
              <TuimButton
                type="submit"
                secondary
                fullWidth
                onClick={(e) => this.handleLogIn(e, this.props)}
                text="Entrar"
                dense
              />
            </Box>
          </Box>
          {showLoginAlert && (
            <AlertDialog
              msg={`${errorMsg}`}
              title="w3Lib Admin"
              onClose={() => this.setState({ showLoginAlert: false })}
              open={showLoginAlert}
              buttons={[
                {
                  label: "Esqueci minha senha",
                  onPress: () => {
                    this.setState({
                      isForgotPasswordModalOpen: true,
                    });
                  },
                },
                {
                  label: "Voltar",
                  onPress: () => {
                    this.setState({
                      showLoginAlert: false,
                    });
                  },
                },
              ]}
            />
          )}
          <ForgotPasswordDialog
            isForgotPasswordModalOpen={isForgotPasswordModalOpen}
            onClose={() => this.setState({ isForgotPasswordModalOpen: false })}
            onForgotPassword={(email) => this.onForgotPassword(email)}
          />
          <Box
            sx={{
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flex: 1,
              backgroundColor: Colors.darkGreen,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundImage: `url('./images/tuim-home-banner.jpg')`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                opacity: 0.15,
              }}
            />
          </Box>
        </Box>
      </Box>
    );
  }
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  loading: {
    display: "flex",
    width: "100%",
    height: "100%",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#3c4ebc14",
  },
  button: {
    marginTop: theme.spacing.unit * 3,
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (userData) => dispatch(setUserData(userData)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
  };
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(SignIn));
