import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow, Paper, Table, Typography, Grid, Fab, Toolbar, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import Loading from '../utils/Loading';

import { fade } from '@material-ui/core/styles/colorManipulator';

class ListWarns extends Component {

    constructor(props) {
        super(props)

        const { classes } = props;

        this.state = {
            classes,
            data: [],
            searchTerm: '',
            loading: true
        }

        this.onSearchChange = this.onSearchChange.bind(this);
    }

    componentDidMount = async () => {

        const _this = this

        const token = await localStorage.getItem('token');

        axios.get(`${process.env.REACT_APP_API_URL}/listWarns`, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {

                const { data } = response
                _this.setState({
                    data
                })

                this.setState({ loading: false });
            })
    }

    onSearchChange = (e) => {

        this.setState({
            searchTerm: e.target.value
        })
    }

    render() {

        const { classes, data, searchTerm,loading } = this.state

        return (
            <div>
                
                <Loading loading={loading} />

                <Grid container>
                    <Grid item xs >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}

                        >Listagem de Solicitações de Avisos
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Fab color="primary" aria-label="Add" className={classes.fab} size="small">
                            <AddIcon onClick={() => this.props.history.push(`/app/coupon/`)} />
                        </Fab>
                    </Grid>
                </Grid>

                <Paper className={classes.root}>
                    <Toolbar>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Digite o nome do cupom"
                                onChange={this.onSearchChange}
                                value={searchTerm}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                            />
                        </div>
                    </Toolbar>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nome</TableCell>
                                <TableCell>E-mail</TableCell>
                                <TableCell>Produto</TableCell>
                                <TableCell align="center">Tipo</TableCell>
                                <TableCell align="center">Data de Cadastro</TableCell>
                                <TableCell align="center">Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())).map(row => (
                                <TableRow
                                    key={row._id}
                                    hover
                                    onClick={() => this.props.history.push(`/app/warn/${row._id}`)}
                                    className={classes.row}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.email}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.product[0] ? row.product[0].name : ''}
                                    </TableCell>
                                    <TableCell align="center">{row.type}</TableCell>
                                    <TableCell align="center">{row.createdAt}</TableCell>
                                    <TableCell align="center">{row.status}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>

        );
    }
}

ListWarns.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        cursor: 'pointer'
    },
    fab: {
        margin: 0,
    }, 
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade('#ececec', 0.35),
        '&:hover': {
            backgroundColor: fade('#ececec', 0.80),
        },
        // marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        // width: theme.spacing.unit * 1,
        marginLeft: theme.spacing.unit * 1,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 5,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    }
});

export default withStyles(styles)(ListWarns);
