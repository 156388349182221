import { Box, Typography as Text } from "@material-ui/core"
import React from "react"
import { Colors } from "../../../styles/Colors"

export function ReportContainerTitle({ title }) {
   return (
      <Box style={{
         display: 'flex',
         flexDirection: 'column',
         gap: 12
      }}>
         <Text style={{ fontSize: 24, fontWeight: 500 }}>{title}</Text>
         <Box sx={{ display: 'flex', width: '100%', height: 1, backgroundColor: Colors.paleDarkerGreen }} />
      </Box>
   )
}