import { Box, Divider } from "@material-ui/core";
import React, { useReducer } from "react";
import { Colors } from "../../../styles/Colors";
import { TuimButton } from '../../layout/Button';
import { Text } from '../../layout/components/text';

const selectors = {
  'month': MonthSelector,
  'date': DateSelector,
}

export function ReportContainerFilters({ generateReport, dateSelectorType }) {
  const Selector = selectors[dateSelectorType]

  const reducer = (prev, next) => ({ ...prev, ...next });
  const [filters, setFilters] = useReducer(reducer, {
    startDate: '',
    endDate: '',
    baseDate: 'createdAt'
  });

  return (
    <Box style={styles.root}>
      <Selector onChange={setFilters} />
      <Divider orientation='vertical' />
      <DataType onChange={setFilters} selectedValue={filters.baseDate} />
      <Divider orientation='vertical' />
      <TuimButton
        secondary
        text='Gerar Relatório'
        onClick={() => generateReport(filters)}
      />
    </Box>
  )
}

function DataType({ selectedValue, onChange }) {
  const baseDates = [
    {
      label: 'Data do contrato',
      value: 'createdAt'
    },
    {
      label: 'Data de entrega',
      value: 'delivery_date'
    }
  ];

  const handleClick = ({ target }) => onChange({ baseDate: target.value });

  return (
    <Box style={{ display: "flex", alignItems: "center", gap: 20 }}>
      {baseDates.map(({ label, value }) =>
        <Box key={value} style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <input
            type="radio"
            id={value}
            value={value}
            onClick={handleClick}
            checked={selectedValue === value}
            style={{ margin: 0, height: 16, width: 16, cursor: "pointer", outline: 'none', accentColor: Colors.darkGreenAlt }}
          />
          <label for={value} style={{ cursor: "pointer" }}>
            <Text>{label}</Text>
          </label>
        </Box>
      )}
    </Box>
  )
}

function MonthSelector({ onChange }) {

  const inputMask = ({ value }) => {
    let currentValue = value

    currentValue = currentValue.replace(/\D/g, '');
    if (currentValue.length >= 4) {
      currentValue = currentValue.substring(0, 4) + '-' + currentValue.substring(4, 6);
    }
    return value = currentValue;
  }

  const handleChange = ({ target }) => {
    target.value = inputMask(target)

    const selectedDate = new Date(target.value);
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;

    const lastDay = new Date(year, month + 1, 0).getDate();

    const firstDayDate = new Date(year, month, 1);
    const lastDayDate = new Date(year, month, lastDay + 1);

    onChange({ startDate: firstDayDate, endDate: lastDayDate });
  }

  return (
    <Box style={{
      display: 'flex',
      gap: 16,
      alignItems: 'center',
      justifyContent: 'space-around',
    }}>
      <Box style={{ display: "flex", alignItems: "center", gap: 12 }}>
        <Text>Período:</Text>
        <input
          type='month'
          style={{ width: '165px', padding: 10, borderRadius: 6, border: `1px solid #ccc`, fontFamily: 'Ubuntu', color: '#444' }}
          name="period"
          onChange={handleChange}
        />
      </Box>
    </Box>
  )
}

function DateSelector({ selectedValues, onChange }) {
  return (
    <Box style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
      <Text>De:</Text>
      <input
        type='date'
        style={{ width: '165px', padding: 12, borderRadius: 6, border: `1px solid #bbb`, fontFamily: 'Ubuntu', color: '#444' }}
        onChange={({ target }) => {
          const startDate = new Date(target.value);
          startDate.setHours(startDate.getHours() + 3)
          onChange({ startDate });
        }}
      />

      <Text style={{ fontSize: 14 }}>Até:</Text>
      <input
        type='date'
        style={{ width: '165px', padding: 10, borderRadius: 6, border: `1px solid #bbb`, fontFamily: 'Ubuntu', color: '#444' }}
        onChange={({ target }) => {
          const endDate = new Date(target.value)
          endDate.setHours(endDate.getHours() + 3)
          onChange({ endDate });
        }}
      />
    </Box>
  )
}

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "flex-end",
    gap: 24
  }
}