import React, { Component } from 'react'
import axios from 'axios'
import {
    Paper, Typography, Button, FormControl, InputLabel, Input, CircularProgress,
    IconButton, Menu, MenuItem
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';
import Loading from '../utils/Loading';

import AlertDialog from '../utils/AlertDialog'

class EditSeo extends Component {

    constructor(props) {
        super(props)

        const { classes } = props;
        const { pathname } = props.location
        const arrayPath = pathname.split('/')
        const _id = arrayPath[arrayPath.length - 1];

        this.state = {
            classes,
            data: {
                _id,
                url: '',
                title: '',
                canonical: '',
                description: '',
                h1: '',
                h2: '',
            },
            parentCategory: '',
            categories: [],
            saving: false,
            success: false,
            error: null,
            anchorEl: null,
            open: false,
            showAlert: false,
            showDeleteSeoAlert: false,
            loading: true,
        }

        this.onChange = this.onChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onDeleteSeoPress = this.onDeleteSeoPress.bind(this);
        this.deleteSeo = this.deleteSeo.bind(this);
    }

    componentDidMount = async () => {

        const { _id } = this.state.data

        if (!_id) {

            this.setState({ loading: false });
            return;
        }


        const token = await localStorage.getItem('token');
        const _this = this

        axios.get(`${process.env.REACT_APP_API_URL}/tagSeo/${_id}`, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                const { data } = response

                _this.setState({
                    data: data
                })

                const { parentCategory_id } = data

                if (parentCategory_id != '') {
                    axios.get(`${process.env.REACT_APP_API_URL}/tagSeo/${parentCategory_id}`, {
                        headers: { 'Authorization': "bearer " + token }
                    })
                        .then(response_parent_id => {

                            _this.setState({
                                categories: [response_parent_id.data]
                            })
                        })
                }
                this.setState({ loading: false });
            })

        // const parentCategory_id = await _this.state.data.parentCategory_id;
        // console.log(parentCategory_id)
        // if(parentCategory_id != ''){ 

        //     const { parentCategory_id } = this.state.data

        //     axios.get(`${process.env.REACT_APP_API_URL}om/category/${parentCategory_id}`, {
        //         headers: { 'Authorization': "bearer " + token }
        //     })
        //         .then(response => {
        //             const { data } = response

        //             _this.setState({
        //                 parentCategory: data.title
        //             })
        //         })
        // }

    }

    onChange = (e) => {

        const { data } = this.state
        this.setState({
            data: {
                ...data,
                [e.target.name]: e.target.value
            },
            success: false
        })
    }

    onSavePress = async () => {

        const { data } = this.state

        if (!data._id) {
            this.newSeo()
            return;
        }

        this.setState({
            saving: true
        })

        const token = await localStorage.getItem('token');

        axios.patch(`${process.env.REACT_APP_API_URL}/tagSeo/${data._id}`, data, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                this.setState({
                    saving: false,
                    success: true
                })

                this.props.history.goBack()
            })
            .catch(error => {
                this.setState({
                    saving: false,
                    success: false,
                    error: error.response
                })
            })
    }

    newSeo = async () => {

        const { data } = this.state

        data._id = undefined

        this.setState({
            saving: true
        })

        const token = await localStorage.getItem('token');
        
        
        axios.post(`${process.env.REACT_APP_API_URL}/tagSeo`, data, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                this.setState({
                    saving: false,
                    success: true,
                    data: response.data
                })

                this.props.history.goBack()
                // console.log('response/',response.data);
                // _this.props.history.push(`${response.data._id}`)
                // _this.props.history.push(`/app/seo`)

            })
            .catch(error => {
                this.setState({
                    saving: false,
                    success: false,
                    error: error.response
                })
            })
    }

    deleteSeo = async () => {

        const { data } = this.state
        const token = await localStorage.getItem('token');

        axios.delete(`${process.env.REACT_APP_API_URL}/tagSeo/${data._id}`, {
            headers: { 'Authorization': "bearer " + token }
        }).then(response => {
            this.setState({
                saving: false,
                success: true
            })

            this.props.history.push('/app/seos')
        }).catch(error => {
            this.setState({
                saving: false,
                success: false,
                error: error.response
            })
        })
    }

    getCategories = async () => {

        const _this = this

        if ((_this.state.categories == '') || _this.state.categories.length <= 1) {

            const token = await localStorage.getItem('token');

            axios.get(`${process.env.REACT_APP_API_URL}/tagSeo/`, {
                headers: { 'Authorization': "bearer " + token }
            })
                .then(response => {
                    const { data } = response

                    _this.setState({
                        categories: data
                    })
                })
        }
    }

    onCancelPress = () => {
        this.props.history.goBack()
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget, open: true });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, open: false, showAlert: false });
    };

    onDeleteSeoPress = () => {
        this.setState({ anchorEl: null, open: false, showDeleteSeoAlert: true });
    };

    render() {
        const { classes, saving, success, anchorEl, open, showDeleteSeoAlert, loading } = this.state
        const { data } = this.state

        const buttonClassname = classNames({
            [classes.buttonSuccess]: success,
        });

        return (
            <Paper className={classes.paper}>

                <Loading loading={loading} />

                <Typography component="h1" variant="h5">
                    {data.title}
                    <IconButton
                        aria-label="More"
                        aria-owns={true}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={this.handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: 200,
                                width: 200,
                            },
                        }}
                    >
                        <MenuItem key={0} onClick={this.onDeleteSeoPress}>
                            Excluir Tag SEO
                        </MenuItem>
                    </Menu>

                </Typography>
                <form className={classes.form}>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="url">URL</InputLabel>
                        <Input id="url" name="url" autoFocus value={data.url}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="h1">H1</InputLabel>
                        <Input id="h1" name="h1" autoFocus value={data.h1}
                            onChange={this.onChange}
                        />

                    </FormControl>

                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="title">Título</InputLabel>
                        <Input id="title" name="title" autoFocus value={data.title}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="description">Description</InputLabel>
                        <Input id="description" name="description" autoFocus value={data.description}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="canonical">Canonical</InputLabel>
                        <Input id="canonical" name="canonical" autoFocus value={data.canonical}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <div className={classes.wrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            disabled={saving}
                            onClick={this.onSavePress}
                        >
                            <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                            Salvar
                            {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>
                        <Button variant="contained" color="default" className={classes.button}
                            onClick={this.onCancelPress}
                        >Cancelar
                    </Button>
                        {this.state.error && <Typography>{this.state.error}</Typography>}
                    </div>


                </form>

                {showDeleteSeoAlert && <AlertDialog
                    msg={`Deseja relmente excluir a categoria ${data.title}? Obs.: Esta ação è irreversível`}
                    title="Excluir Categoria"
                    open={showDeleteSeoAlert}
                    buttons={[
                        {
                            label: 'Cancelar',
                            onPress: () => {
                                this.setState({
                                    showDeleteSeoAlert: false
                                })
                            },

                        },
                        {
                            label: 'Excluir',
                            onPress: () => {
                                this.setState({
                                    showDeleteSeoAlert: false
                                })

                                this.deleteSeo()
                            },
                            color: 'primary'
                        }
                    ]} />}

            </Paper>
        )
    }
}


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        maxWidth: '450px',
        marginTop: theme.spacing.unit,
    },
    button: {
        marginLeft: theme.spacing.unit
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    success: {
        backgroundColor: green[600],
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        marginTop: theme.spacing.unit * 3,
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    titleName: {
        flex: 1
    },
    titleBar: {
        flexDirection: 'row'
    }
});

export default (withStyles(styles)(EditSeo))