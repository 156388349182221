import {
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Loading from "../utils/Loading";

import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader/index";

class ListLocation extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      searchTerm: "",
      loading: true,
      active: true,
      state: {
        checkedA: true,
        checkedB: true,
      },
    };

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {
    const _this = this;

    const token = await localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/serviceLocation`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          data,
        });

        this.setState({ loading: false });
      });
  };

  onSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };
  handleChange = (event) => {
    const { state, active } = this.state;
    this.setState({ ...state, [event.target.name]: event.target.checked });
    if (active) {
      this.setState({ active: false, state: { checkedB: false } });
    } else {
      this.setState({ active: true, state: { checkedB: true } });
    }
  };

  render() {
    const { classes, data, searchTerm, loading, active } = this.state;
    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title="Cidades"
          buttons={[
            {
              text: "Nova",
              secondary: true,
              onClick: () => this.props.history.push(`/app/location/edit`),
            },
          ]}
        />

        <ContentContainer>
          <div style={{ display: "flex", gap: 12 }}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Digite o nome da filial"
                onChange={this.onSearchChange}
                value={searchTerm}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
            <Switch
              checked={this.state.state.checkedB}
              onChange={this.handleChange}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Título</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .filter((item) =>
                  searchTerm.length == 2
                    ? item.state
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    : item.city.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((row) => {
                  return active
                    ? row.active && (
                        <TableRow
                          key={row._id}
                          hover
                          onClick={() =>
                            this.props.history.push(
                              `/app/location/edit/${row._id}`
                            )
                          }
                          className={classes.row}
                        >
                          <TableCell component="th" scope="row">
                            {row.city}
                          </TableCell>
                          <TableCell align="center">{row.state}</TableCell>
                          <TableCell align="center">
                            {row.active == true ? (
                              <CheckCircleIcon />
                            ) : (
                              <BlockIcon />
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    : row.active == false && (
                        <TableRow
                          key={row._id}
                          hover
                          onClick={() =>
                            this.props.history.push(
                              `/app/location/edit/${row._id}`
                            )
                          }
                          className={classes.row}
                        >
                          <TableCell component="th" scope="row">
                            {row.city}
                          </TableCell>
                          <TableCell align="center">{row.state}</TableCell>
                          <TableCell align="center">
                            {row.active == true ? (
                              <CheckCircleIcon />
                            ) : (
                              <BlockIcon />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                })}
            </TableBody>
          </Table>
        </ContentContainer>
      </>
    );
  }
}

ListLocation.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade("#ececec", 0.35),
    "&:hover": {
      backgroundColor: fade("#ececec", 0.8),
    },
    marginLeft: 0,
    width: "100%",
    display: "flex",
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    // width: theme.spacing.unit * 1,
    marginLeft: theme.spacing.unit * 1,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
});

export default withStyles(styles)(ListLocation);
