import React, { useEffect, useLayoutEffect, useState } from "react";
import { ReportContainer } from "..";
import { formatter, uid } from "../../../../functions";
import { ReportCard } from "../../ReportCard";

export const PartnersPerformance = ({ data }) => {
  const [partnerships, setPartnerships] = useState(null)
  const [selectedPartnership, setSelectedPartnership] = useState(null)
  const [partners, setPartners] = useState(null)
  const [selectedPartner, setSelectedPartner] = useState(null)
  const [selectedData, setSelectedData] = useState(null)

  useEffect(() => {
    if (!data) return;

    const partnerships = Object.keys(data.data).sort();

    setPartnerships(partnerships)
    setSelectedPartnership(partnerships[0])
  }, [data])

  useLayoutEffect(() => {
    if (selectedPartnership) {
      setSelectedPartner(null)
      setPartners(['Todos', ...(selectedPartnership === 'Site' ? [] : Object.keys(data.data[selectedPartnership].contracts))])
    };
  }, [selectedPartnership])

  useLayoutEffect(() => {
    if (partners?.length) {
      setSelectedPartner(partners[0])
    };
  }, [partners])

  useLayoutEffect(() => {
    if (selectedPartner) {
      if (selectedPartner === 'Todos') {
        setSelectedData(data?.data[selectedPartnership])
      } else {
        setSelectedData(data?.data[selectedPartnership].contracts[selectedPartner])
      }
    }
  }, [selectedPartner])

  return (
    <ReportContainer.Root>
      {selectedPartnership && <ReportContainer.Tab tabs={partnerships} onClick={setSelectedPartnership} selectedTab={selectedPartnership} />}
      {partners && <ReportContainer.Tab tabs={partners} onClick={setSelectedPartner} selectedTab={selectedPartner} />}
      {selectedData ?
        (selectedPartner === 'Todos') ?
          <ReportCard.Group title={'Todos'} key={`${selectedPartner}_${uid()}`}>
            <ReportCard.Root>
              <ReportCard.Header value={formatter.number(selectedData.statistics.contracts.all)} caption='Assinaturas' />
              <ReportCard.Divider />
              {selectedData.contracts && <ReportCard.Table
                data={Object.keys(selectedData.contracts).map(item => ({
                  key: selectedPartnership === 'Site' ? 'Site' : item,
                  value: selectedData.contracts[item].statistics.contracts.all
                }))}
              />}
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(selectedData.statistics.income.estimated.all)} caption='Fechamento' />
              <ReportCard.Divider />
              {selectedData.contracts && <ReportCard.Table
                data={Object.keys(selectedData.contracts).map(item => ({
                  key: selectedPartnership === 'Site' ? 'Site' : item,
                  value: formatter.currency(selectedData.contracts[item].statistics.income.estimated.all)
                }))}
              />}
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(selectedData.statistics.income.all)} caption='Faturamento' />
              <ReportCard.Divider />
              {selectedData.contracts && <ReportCard.Table
                data={Object.keys(selectedData.contracts).map(item => ({
                  key: selectedPartnership === 'Site' ? 'Site' : item,
                  value: formatter.currency(selectedData.contracts[item].statistics.income.all)
                }))}
              />}
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(selectedData.statistics.average.ticket)} caption='Ticket Médio' />
              <ReportCard.Divider />
              {selectedData.contracts && <ReportCard.Table
                data={Object.keys(selectedData.contracts).map(item => ({
                  key: selectedPartnership === 'Site' ? 'Site' : item,
                  value: formatter.currency(selectedData.contracts[item].statistics.average.ticket)
                }))}
              />}
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.number(selectedData.statistics.average.period)} caption='Período Médio' />
              <ReportCard.Divider />
              {selectedData.contracts && <ReportCard.Table
                data={Object.keys(selectedData.contracts).map(item => ({
                  key: selectedPartnership === 'Site' ? 'Site' : item,
                  value: formatter.number(selectedData.contracts[item].statistics.average.period)
                }))}
              />}
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(selectedData.statistics.discount.sum)} caption='em descontos' />
              <ReportCard.Divider />
              {selectedData.contracts && <ReportCard.Table
                data={Object.keys(selectedData.contracts).map(item => ({
                  key: selectedPartnership === 'Site' ? 'Site' : item,
                  value: formatter.currency(selectedData.contracts[item].statistics.discount.sum)
                }))}
              />}
            </ReportCard.Root>

          </ReportCard.Group>
          :
          <ReportCard.Group title={selectedPartner} key={`${selectedPartner}_${uid()}`}>
            <ReportCard.Root>
              <ReportCard.Header value={formatter.number(selectedData.statistics.contracts.all)} caption='Assinaturas' />
              <ReportCard.Divider />
              <ReportCard.Table
                data={[
                  { key: 'Residencial', value: `${formatter.number(selectedData.statistics.contracts.residential.sum)} (${formatter.number(selectedData.statistics.contracts.residential.percentage)}%)` },
                  { key: 'Home Office', value: `${formatter.number(selectedData.statistics.contracts.homeOffice.sum)} (${formatter.number(selectedData.statistics.contracts.homeOffice.percentage)}%)` },
                  { key: 'Eletro', value: `${formatter.number(selectedData.statistics.contracts.eletro.sum)} (${formatter.number(selectedData.statistics.contracts.eletro.percentage)}%)` }
                ]}
              />
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(selectedData.statistics.income.estimated.all)} caption='Fechamento' />
              <ReportCard.Divider />
              <ReportCard.Table
                data={[
                  { key: 'Residencial', value: `${formatter.currency(selectedData.statistics.income.estimated.residential.sum)} (${formatter.number(selectedData.statistics.income.estimated.residential.percentage)}%)` },
                  { key: 'Home Office', value: `${formatter.currency(selectedData.statistics.income.estimated.homeOffice.sum)} (${formatter.number(selectedData.statistics.income.estimated.homeOffice.percentage)}%)` },
                  { key: 'Eletro', value: `${formatter.currency(selectedData.statistics.income.estimated.eletro.sum)} (${formatter.number(selectedData.statistics.income.estimated.eletro.percentage)}%)` },
                ]}
              />
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(selectedData.statistics.income.all)} caption='Faturamento' />
              <ReportCard.Divider />
              <ReportCard.Table
                data={[
                  { key: 'Residencial', value: `${formatter.currency(selectedData.statistics.income.residential.sum)} (${formatter.number(selectedData.statistics.income.residential.percentage)}%)` },
                  { key: 'Home Office', value: `${formatter.currency(selectedData.statistics.income.homeOffice.sum)} (${formatter.number(selectedData.statistics.income.homeOffice.percentage)}%)` },
                  { key: 'Eletro', value: `${formatter.currency(selectedData.statistics.income.eletro.sum)} (${formatter.number(selectedData.statistics.income.eletro.percentage)}%)` },
                ]}
              />
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(selectedData.statistics.average.ticket)} caption='Ticket Médio' />
              <ReportCard.Divider />
              <ReportCard.Table
                data={[
                  { key: 'Período médio', value: formatter.number(selectedData.statistics.average.period) },
                  { key: 'Top Ticket', value: formatter.currency(selectedData.statistics.average.topTicket) }
                ]}
              />
            </ReportCard.Root>

            <ReportCard.Root>
              <ReportCard.Header value={formatter.currency(selectedData.statistics.discount.sum)} caption='em descontos' />
              <ReportCard.Divider />
              <ReportCard.Table
                data={[
                  { key: 'Porcentagem', value: `${formatter.number(selectedData.statistics.discount.percentage)}%` },
                ]}
              />
            </ReportCard.Root>

          </ReportCard.Group>
        :
        <></>
      }
    </ReportContainer.Root>
  )
}