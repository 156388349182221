import {
  Box,
  Button,
  Grid,
  InputBase,
  Paper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import Numeral from "numeral";
import "numeral/locales/pt-br";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Moment from "react-moment";

import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Loading from "../utils/Loading";

Numeral.locale("pt-br");

class Relatorio extends Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.state = {
      classes,
      data: [],
      count: 0,
      activeContracts: 0,
      filteredContracts: false,
      searchTerm: "",
      page: 0,
      rowsPerPage: 12,
      order: "asc",
      orderBy: "calories",
      status_filter: "",
      data_inicio_filter: "",
      data_final_filter: "",
    };
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {
    const _this = this;

    const token = localStorage.getItem("token");
    this.setState({ loading: true });

    axios
      .get(`${process.env.REACT_APP_API_URL}/listAllContracts?limit=30`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data, count, activeContracts } = response.data;
        _this.setState({ data, count, activeContracts, loading: false });
      });
  };

  onSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  filtrarContracts = async () => {
    const { status_filter, data_inicio_filter, data_final_filter, searchTerm } =
      this.state;

    let data_filter = {};

    if (status_filter != "") {
      data_filter.status_contract = status_filter;
    }

    if (data_inicio_filter != "") {
      data_filter.data_inicio = data_inicio_filter;
    }

    if (data_final_filter != "") {
      data_filter.data_final = data_final_filter;
    }

    if (searchTerm) {
      data_filter.searchTerm = searchTerm;
    }
    const _this = this;

    const token = localStorage.getItem("token");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/getListContractsFilterReport`,
        data_filter,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        const { data, filteredContracts } = response.data;
        _this.setState({ data, filteredContracts, loading: false });
      });
  };

  render() {
    const {
      classes,
      data,
      searchTerm,
      status_filter,
      data_inicio_filter,
      data_final_filter,
      count,
      activeContracts,
      filteredContracts,
      loading,
    } = this.state;

    return (
      <div>
        <Loading loading={loading} />
        <style jsx global>
          {`
            .btn {
              color: rgba(0, 0, 0, 0.87);
              padding: 6px 16px;
              font-size: 0.875rem;
              min-width: 64px;
              box-sizing: border-box;
              transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)
                  0ms,
                box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              line-height: 1.75;
              font-weight: 500;
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              border-radius: 4px;
              border: none;
              text-transform: uppercase;
              color: rgba(0, 0, 0, 0.87);
              box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
                0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                0px 3px 1px -2px rgba(0, 0, 0, 0.12);
              background-color: #e0e0e0;
              color: rgba(0, 0, 0, 0.87);
              background-color: #00c830;
            }
          `}
        </style>
        <Grid container>
          <Grid item xs>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Contratos
            </Typography>
          </Grid>
        </Grid>

        <Paper className={classes.root}>
          <Toolbar>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Procurar por contratos"
                onChange={this.onSearchChange}
                value={searchTerm}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Toolbar>

          <div
            style={{ display: "flex", flexDirection: "column", padding: 20 }}
          >
            <span>Filtro</span>
            <div style={{ display: "flex", marginTop: 30 }}>
              <form className={classes.container} noValidate>
                <TextField
                  id="data-contract"
                  label="Data Inicio"
                  type="date"
                  value={data_inicio_filter}
                  onChange={(e) =>
                    this.setState({ data_inicio_filter: e.target.value })
                  }
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="data-contract"
                  label="Data Final"
                  type="date"
                  style={{ marginLeft: 20 }}
                  value={data_final_filter}
                  onChange={(e) =>
                    this.setState({ data_final_filter: e.target.value })
                  }
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl
                  className={classes.formControl}
                  style={{ marginLeft: 20 }}
                >
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status_filter}
                    onChange={(e) =>
                      this.setState({ status_filter: e.target.value })
                    }
                  >
                    <MenuItem value={"active"}>Ativo</MenuItem>
                    <MenuItem value={"pending"}>Pendente</MenuItem>
                    <MenuItem value={"canceled"}>Cancelado</MenuItem>
                    <MenuItem value={"finished"}>Finalizado</MenuItem>
                  </Select>
                </FormControl>

                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 10, marginLeft: 50 }}
                  onClick={() => this.filtrarContracts()}
                >
                  Filtrar
                </Button>
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: 10, mt: 2 }}
                >
                  <Typography>
                    Contratos:{" "}
                    <span style={{ fontWeight: "500" }}>{count}</span>
                  </Typography>
                  <Typography>
                    Contratos Ativos:{" "}
                    <span style={{ fontWeight: "500" }}>{activeContracts}</span>
                  </Typography>
                  {filteredContracts && status_filter !== "active" && (
                    <Typography>
                      Contratos Filtrados:{" "}
                      <span style={{ fontWeight: "500" }}>
                        {filteredContracts}
                      </span>
                    </Typography>
                  )}
                </Box>
              </form>
            </div>
          </div>
          <div
            style={{
              maxWidth: 1200,
              height: 500,
              overflow: "scroll",
              marginLeft: 20,
            }}
          >
            <table id="table_contracts">
              <thead>
                <tr style={{ display: "none" }}>
                  <th>Contratos: {count}</th>
                  <th>Contratos Ativos: {activeContracts}</th>
                </tr>
                <tr>
                  <th>Id</th>
                  <th>Inicio</th>
                  <th>Data Entrega</th>
                  <th>Data Retirada</th>
                  <th>Periodo</th>
                  <th>Término</th>
                  <th>Info Término</th>
                  <th>Status</th>
                  <th>Contratos Ativos</th>
                  <th>Contratos</th>
                  <th>CPF</th>
                  <th>Cliente</th>
                  <th>Nome Pref</th>
                  <th>Nascimento</th>
                  <th>Celular</th>
                  <th>Email</th>
                  <th>Total Produtos</th>
                  <th>Rua</th>
                  <th>Numero</th>
                  <th>Complemento</th>
                  <th>Bairro</th>
                  <th>CEP</th>
                  <th>Cidade</th>
                  <th>Estado</th>
                  <th>Valor Mensal</th>
                  <th>Cupom</th>
                  <th>Desconto</th>
                  <th>Tx Entrega</th>
                  <th>Entrega</th>
                  <th>Tipo de contrato</th>
                  <th>Bandeira</th>
                  <th>Produtos</th>
                  <th>Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, index) => {
                  let tipoContrato = "";
                  let date_split = new Date(item.createdAt);
                  var newDate = new Date(
                    date_split.setMonth(
                      date_split.getMonth() + Number(item.period)
                    )
                  );
                  let infoDate = new Date(newDate).setDate(
                    newDate.getDate() - 30
                  );

                  let deliveryDate = new Date(item?.delivery_date);

                  const returnDate =
                    item?.endingDetails?.collectDate ||
                    (item.return_date
                      ? item.return_date.split("-").reverse().join("/")
                      : "Sem data");

                  const a = item?.products?.forEach((p) => {
                    let value = "R";

                    if (p.category == "Home Office") {
                      value = "H";
                    } else if (p.category == "ELETROS") {
                      value = "E";
                    } else if (p.category == "Academia") {
                      value = "A";
                    } else {
                      value = "R";
                    }

                    if (!tipoContrato.includes(value)) {
                      tipoContrato = tipoContrato + value;
                    }

                    if (tipoContrato == "HR") {
                      tipoContrato = "RH";
                    } else if (tipoContrato == "ER") {
                      tipoContrato = "RE";
                    } else if (tipoContrato == "AR") {
                      tipoContrato = "RA";
                    } else if (tipoContrato == "HER") {
                      tipoContrato = "RHE";
                    } else if (tipoContrato == "ERH") {
                      tipoContrato = "RHE";
                    } else if (tipoContrato == "HER") {
                      tipoContrato = "RHE";
                    } else if (tipoContrato == "AER") {
                      tipoContrato = "REA";
                    } else if (tipoContrato == "AHR") {
                      tipoContrato = "RHA";
                    } else if (tipoContrato == "REH") {
                      tipoContrato = "RHE";
                    }
                  });

                  return (
                    <tr key={item._id}>
                      <td>{item?.id_subscription}</td>
                      <td>
                        <Moment
                          format="DD/MM/YYYY"
                          date={new Date(item?.createdAt)}
                        ></Moment>
                      </td>
                      <td>
                        <Moment format="DD/MM/YYYY" date={deliveryDate} />
                      </td>
                      <td>{returnDate}</td>
                      <td>{item?.period}</td>
                      <td>
                        <Moment format="DD/MM/YYYY" date={newDate}></Moment>
                      </td>
                      <td>
                        <Moment format="DD/MM/YYYY" date={infoDate}></Moment>
                      </td>

                      <td>{item?.payment.status}</td>
                      <td>{item?.userActiveContracts}</td>
                      <td>{item?.userTotalContracts}</td>
                      <td>{item.id_user == null ? "-" : item.id_user?.cpf}</td>
                      <td>{item.id_user == null ? "-" : item.id_user?.name}</td>
                      <td>
                        {item.id_user == null ? "-" : item.id_user?.nickname}
                      </td>
                      <td>
                        {item.id_user == null ? (
                          "-"
                        ) : (
                          <Moment
                            format="DD/MM/YYYY"
                            date={item?.id_user?.dateBirth}
                          ></Moment>
                        )}
                      </td>
                      <td>
                        {item.id_user == null ? "-" : item.id_user.mobileNumber}
                      </td>
                      <td>{item.id_user == null ? "-" : item.id_user.email}</td>
                      <td>{item?.products?.length}</td>
                      <td>
                        {item.address?.logradouro
                          ? item.address?.logradouro +
                            item?.address?.rua?.replace("Rua", "")
                          : item?.address?.rua}
                      </td>
                      <td>{item?.address?.numero}</td>
                      <td>
                        {item.address?.complemento
                          ? item.address?.complemento
                          : "-"}
                      </td>
                      <td>
                        {item.address?.bairro ? item.address?.bairro : "-"}
                      </td>
                      <td>{item?.address?.cep}</td>
                      <td>{item?.address?.cidade}</td>
                      <td>{item?.address?.estado}</td>
                      <td>
                        {item.payment?.price
                          ? item.payment.price.toString().replace(".", ",")
                          : item.payment.price}
                      </td>
                      <td>{item?.code}</td>
                      <td>{item?.discount_code}</td>
                      <td>{item?.payment?.deliveryPrice}</td>
                      <td>{item?.typeDelivery}</td>
                      <td>{tipoContrato}</td>
                      <td>
                        {item.payment.payment_profile != null
                          ? item.payment.payment_profile != "" &&
                            item.payment.payment_profile.payment_company?.name
                          : " - "}
                      </td>

                      {item?.products?.map((p, i) => {
                        return (
                          <>
                            <td>{p.firstName + " " + p.lastName}</td>
                            <td>{p.qtd}</td>
                          </>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{ paddingTop: 50, paddingBottom: 50, marginLeft: 20 }}>
            <ReactHTMLTableToExcel
              className="btn btn-info"
              table="table_contracts"
              style={{ backgroundColor: "#000" }}
              filename="ReportExcel"
              sheet="Sheet"
              buttonText="Exportar para Excel"
            />
          </div>
        </Paper>
      </div>
    );
  }
}

Relatorio.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  btn: {
    padding: "5px 20px",
    textAlign: "center",
    backgroundColor: "#00c830",
    color: "#000",
    borderRadius: 5,
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);",
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade("#ececec", 0.35),
    "&:hover": {
      backgroundColor: fade("#ececec", 0.8),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    marginLeft: theme.spacing.unit * 1,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
});

export default withStyles(styles)(Relatorio);
