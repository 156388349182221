import {
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import Loading from "../utils/Loading";

class ListArticles extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      loading: true,
      data: [],
      searchTerm: "",
      cont: 0,
      page: 0,
      rowsPerPage: 10,
    };

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {
    const token = localStorage.getItem("token");

    const { page, rowsPerPage, searchTerm } = this.state;

    axios
      .get(`${process.env.REACT_APP_API_URL}/listArticlesAll`, {
        params: {
          searchTerm,
          limit: rowsPerPage,
          page: page,
        },
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data, cont } = response.data;
        this.setState({
          data: data,
          cont: cont,
        });
        this.setState({ loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  articleFilter = async (newPage = 1) => {
    this.setState({ loading: true });
    const token = localStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/listArticlesAll`, {
        params: {
          limit: 25,
          page: newPage,
        },
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data, cont } = response.data;
        this.setState({
          data: data,
          cont: cont,
          page: newPage,
        });
        this.setState({ loading: false });
      })
      .catch((error) => this.setState({ loading: false }))
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleChangePage = (event, page) => {
    const { page: pagina } = this.state;
    let newPage = 0;
    if (page > pagina) {
      newPage = pagina + 1;
    } else {
      newPage = pagina - 1;
    }
    this.setState({ page: newPage });
    this.articleFilter(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
    this.componentDidMount();
  };

  render() {
    const { classes, data, searchTerm, rowsPerPage, cont, page, loading } =
      this.state;

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title="Artigos"
          buttons={[
            {
              text: "Novo",
              secondary: true,
              onClick: () => this.props.history.push(`/app/article/`),
            },
          ]}
        />

        <ContentContainer row>
          <div className={classes.searchIcon}>
            <SearchIcon htmlColor="#999" />
          </div>
          <InputBase
            placeholder="Digite o título do artigo"
            onChange={this.onSearchChange}
            value={searchTerm}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
          <TuimButton
            text="Filtrar"
            tertiary
            onClick={() => this.componentDidMount()}
            style={{ maxHeight: 40 }}
          />
        </ContentContainer>

        <ContentContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cont}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonText="voltar"
            backIconButtonProps={{ "aria-label": "Página anterior" }}
            nextIconButtonText="avançar"
            nextIconButtonProps={{ "aria-label": "Próxima página" }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Título</TableCell>
                <TableCell>Categoria</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  hover
                  onClick={() =>
                    this.props.history.push(`/app/article/${row._id}`)
                  }
                  className={classes.row}
                >
                  <TableCell component="th" scope="row">
                    {row.title}
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {row.id_category[0] ? row.id_category[0].title : ""}
                  </TableCell>

                  <TableCell align="center">
                    {row.active == true ? "Ativo" : "Desativado"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={cont}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonText="voltar"
            backIconButtonProps={{
              "aria-label": "Página anterior",
            }}
            nextIconButtonText="avançar"
            nextIconButtonProps={{
              "aria-label": "Próxima página",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </ContentContainer>
      </>
    );
  }
}

ListArticles.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
  search: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    padding: 16,
    borderRadius: 8,
    backgroundColor: "#fff",
    width: "100%",
  },
  searchIcon: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    color: "#888",
    // backgroundColor: 'bisque'
  },
});

export default withStyles(styles)(ListArticles);
