import { Box } from "@material-ui/core";
import React from "react";

export function ReportCardRoot({ children, background = '#f8f8f8', border = '#eee' }) {
  return (
    <Box sx={{
      ...styles.root,
      backgroundColor: background,
      border: `1px solid ${border}`
    }}>
      {children}
    </Box>
  )
}

const styles = {
  root: {
    padding: 24,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    minWidth: 280
  }
}