export const handleOpenDropDown = ({ id, openDropDown, setOpenDropDown }) => {
  const dropdownPosition = openDropDown !== id ? id : null;
  setOpenDropDown(dropdownPosition);
  localStorage.setItem("dropdownPositionTuim", dropdownPosition);
};

export const handlePath = (usePath) => {
  const pathname = window.location.pathname;
  const desiredPart = pathname.substring(pathname.indexOf("/"));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  usePath(desiredPart);
};
