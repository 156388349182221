import { Box, TextField } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { formatter } from "../../../../functions";
import { Colors } from "../../../../styles/Colors";
import { TuimButton } from "../../Button";
import { Text } from "../text";

const Spacer = () => <Box style={{ width: 30, height: 20 }} />

/*
- Reference id: the subject you're writing notes about.
- Reference type: is used not only as an indicator of the
   subject we're writing about, but also the reference
   database will use if you try to populate the reference id field.
   So you need to pass the reference type exactly as the model name.
*/

export const InternalNotes = (props) => {

  const { reference } = props;
  const { type: referenceType, id: referenceId } = reference;
  const token = localStorage.getItem('token');
  const headers = { headers: { 'Authorization': "Bearer " + token } };

  const authorId = localStorage.getItem('_id');

  const [showNewNoteInput, setShowNewNoteInput] = useState(false)
  const [notes, setNotes] = useState([])
  const [loading, setLoading] = useState(false)
  const [showDeleteNote, setShowDeleteNote] = useState({
    active: false,
    noteId: ''
  })
  const [editingNote, setEditingNote] = useState({
    active: false,
    noteId: '',
    note: ''
  })
  const [newNote, setNewNote] = useState({
    author: authorId,
    note: '',
    reference: {
      type: referenceType,
      id: referenceId
    }
  })

  const internalNotesContainer = useRef(null)

  useEffect(() => {
    if (typeof referenceId === 'undefined' || typeof referenceType === 'undefined') return;

    loadNotes()
  }, [])

  useEffect(() => {
    if (showNewNoteInput) {
      setEditingNote({
        active: false,
        noteId: '',
        note: ''
      })
      setShowDeleteNote({
        active: false,
        noteId: ''
      })
      let height = internalNotesContainer.current.scrollHeight;
      internalNotesContainer.current.scroll({ top: height, behavior: 'smooth' })
    } else {
      setNewNote({
        ...newNote,
        note: ''
      })
    }
  }, [showNewNoteInput])

  const onChange = ({ target }) => {
    const { value } = target;
    setNewNote({
      ...newNote,
      note: value
    })
  }

  const loadNotes = async () => {
    setLoading(true)
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/v2/admin/internalnotes/reference/${referenceId}?referenceType=${referenceType}`, headers).then(response => {
        setNotes(response.data.notes)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        console.log(error)
        console.log('Error trying to fetch notes')
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
      console.log('Caught error trying to fetch notes')
    }
  }

  const saveNote = async () => {
    if (newNote.note === '' || typeof newNote.author === 'undefined' ||
      typeof newNote.reference.type === 'undefined' ||
      typeof newNote.reference.id === 'undefined') {
      return
    }

    try {
      setLoading(true)
      axios.post(`${process.env.REACT_APP_API_URL}/v2/admin/internalnotes/create`, { newNote }, headers).then(async response => {
        setLoading(false)
        setShowNewNoteInput(false)
        await loadNotes()
        internalNotesContainer.current.scroll({ top: 0, behavior: 'smooth' })
      }).catch(error => {
        setLoading(false)
        console.log(error)
        console.log('Error trying to create new note')
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
      console.log('Caught error trying to create new note')
    }
  }

  const updateNote = () => {
    try {
      setLoading(true)
      axios.patch(`${process.env.REACT_APP_API_URL}/v2/admin/internalnotes/edit/${editingNote.noteId}`, { note: editingNote.note }, headers).then(async response => {
        await loadNotes();
        setEditingNote({
          active: false,
          noteId: '',
          note: ''
        })
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        console.log(error)
        console.log('Error trying to update')
      })
    } catch (error) {
      setLoading(false)
      console.log(error)
      console.log('Caught error trying to update')
    }
  }

  const deleteNote = async (noteId) => {
    try {
      setLoading(true)
      axios.delete(`${process.env.REACT_APP_API_URL}/v2/admin/internalnotes/delete/${noteId}`, headers).then(async response => {
        setShowDeleteNote({
          active: false,
          noteId: ''
        })
        await loadNotes()
      }).catch(error => {
        console.log('Error trying to delete')
        console.log(error)
      })
    } catch (error) {
      console.log(error)
      console.log('Caught error trying to delete')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box style={styles.mainContainer}>
      <Box
        ref={internalNotesContainer}
        style={styles.notesContainer}
        onClick={() => {
          setShowNewNoteInput(false);
          setShowDeleteNote({
            active: false,
            noteId: ''
          })
          setEditingNote({
            active: false,
            noteId: '',
            note: ''
          })
        }}
      >
        {notes.length > 0 ?
          <>
            {notes.map((note, index) => {
              const isAuthor = note.author._id === authorId;
              const editMode = editingNote.active && editingNote.noteId === note._id;

              return (
                <Box
                  key={`${index}-internal_notes-${index}`}
                  style={{
                    ...styles.singleNoteContainer,
                    ...(isAuthor ? { alignSelf: 'flex-end' } : { marginRight: 60 }),
                    ...(showDeleteNote.active && showDeleteNote.noteId === note._id && {
                      border: `1px solid ${Colors.red}`
                    })
                  }}
                >
                  <Box style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    {editMode ?
                      <TextField
                        value={editingNote.note}
                        multiline
                        rows={7}
                        onChange={({ target }) => setEditingNote({ ...editingNote, note: target.value })}
                        onClick={(event) => event.stopPropagation()}
                        InputProps={{ style: { fontSize: 14 } }}
                      />
                      :
                      <Text style={{ overflowWrap: 'break-word' }}>
                        {note.note}
                      </Text>
                    }
                    <Box style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                      <Text style={{ fontSize: 13, color: '#999' }}>{`${note?.author.name} em ${formatter.date(note?.createdAt, 'short')}`}</Text>
                    </Box>
                  </Box>
                  <Box style={{ display: 'flex', alignItems: 'center', marginTop: 4 }}>
                    {isAuthor &&
                      <>
                        {!editingNote.active &&
                          <Box style={{ display: 'flex', gap: 10 }}>
                            <Box
                              style={{ ...styles.iconContainer, backgroundImage: `url('/icons/edit_icon.png')` }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setShowDeleteNote({
                                  active: false,
                                  noteId: ''
                                })
                                setEditingNote({
                                  active: true,
                                  noteId: note._id,
                                  note: note.note
                                })
                              }}
                            />
                            <Box
                              style={{
                                ...styles.iconContainer,
                                width: 16,
                                height: 18,
                                backgroundImage: `url('/icons/trash_icon.png')`
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setShowDeleteNote({ active: true, noteId: note._id })
                              }}
                            />
                            {showDeleteNote.active && showDeleteNote.noteId === note._id &&
                              <Box style={{ display: 'flex' }}>
                                <Box
                                  onClick={() => deleteNote(note._id)}
                                  style={styles.deleteButton}>
                                  <Text style={{ color: '#eee', fontSize: 13 }}>Excluir definitivamente</Text>
                                </Box>
                              </Box>
                            }
                          </Box>
                        }
                        {editMode &&
                          <Box style={{ display: 'flex', gap: 10 }}>
                            <Box style={{
                              ...styles.iconContainer,
                              backgroundImage: `url('/icons/save_icon.png')`,
                            }}
                              onClick={(event) => {
                                event.stopPropagation();
                                updateNote()
                              }}
                            />
                            <Box style={{
                              ...styles.iconContainer,
                              backgroundImage: `url('/icons/x_icon.png')`,
                            }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setEditingNote({
                                  active: false,
                                  noteId: '',
                                  note: ''
                                })
                              }}
                            />
                          </Box>
                        }
                      </>
                    }
                  </Box>
                </Box>
              )
            })}
          </>
          :
          <Box style={styles.noNotesContainer}>
            <Text style={{ fontSize: 14, color: '#999' }}>Nenhuma observação registrada.</Text>
          </Box>
        }

        {showNewNoteInput &&
          <Box>
            <Spacer size='2' />
            <Box style={styles.newNoteInputContainer}
              onClick={(event) => event.stopPropagation()}>
              <TextField
                variant="outlined"
                multiline
                fullWidth
                // rows={7}
                onClick={(event) => event.stopPropagation()}
                onChange={onChange}
                InputProps={{ style: { fontSize: 14, backgroundColor: '#f4f4f4', borderRadius: 10 } }}
              />
              <Spacer />
              <Box style={{ display: 'flex', flex: 1, gap: 10 }}>
                <TuimButton fullWidth text='Salvar' onClick={() => saveNote()} />
                <TuimButton fullWidth tertiary text='Cancelar' onClick={() => setShowNewNoteInput(false)} />
              </Box>
            </Box>
          </Box>
        }
        {loading &&
          <Box style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#ffffff33',
            backdropFilter: 'blur(2px)',
            WebkitBackdropFilter: 'blur(2px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Text>Carregando...</Text>
          </Box>
        }
      </Box>
      {!showNewNoteInput &&
        <TuimButton
          style={styles.addNoteButton}
          onClick={(event) => {
            event.stopPropagation()
            setShowNewNoteInput(true)
          }}
          text='+'
        />
      }
    </Box>
  )
}

const styles = {
  mainContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
    gap: 24,
    minHeight: 80,
  },
  notesContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
    overflow: 'auto',
    position: 'relative'
  },
  singleNoteContainer: {
    position: 'relative',
    backgroundColor: '#f4f4f4',
    border: `1px solid #e5e5e5`,
    marginBottom: 16,
    padding: 16,
    borderRadius: 10,
    minWidth: 400,
    maxWidth: 600
  },
  iconContainer: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 18,
    height: 18,
    opacity: 0.60,
    cursor: 'pointer'
  },
  deleteButton: {
    backgroundColor: Colors.red,
    padding: `2px 8px`,
    borderRadius: 6,
    paddingLeft: 10,
    cursor: 'pointer'
  },
  noNotesContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  newNoteInputContainer: {
    width: '100%',
    boxSizing: 'border-box',
    padding: 2,
    borderRadius: 2,
  },
  addNoteButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 46,
    height: 46,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'auto',
    backgroundColor: Colors.darkGreen,
  }
}