import React from "react";

const selectOptions = [
  "Em processamento",
  "Reprovado",
  "Preparando para a Entrega",
  "Fechado",
  "Jurídico",
  "Finalizado",
  "Entregue",
  "Cancelado",
];

export const ContractDropdown = ({
  status_contract,
  contract,
  stateContract,
}) => {
  const changeValue = (e) => {
    const contractEdit = {
      ...contract,
      [e.target.name]: e.target.value,
    };

    stateContract(contractEdit);
  };

  return (
    <select
      name="status_contract"
      id="status_contract"
      value={status_contract}
      style={{
        backgroundColor: "#fff",
        cursor: "pointer",
        width: "auto",
        padding: "4px",
        borderRadius: "4px",
        border: "1px solid #dfeae2",
        color: "#444",
        fontFamily: "Ubuntu",
        outline: "none",
      }}
      onChange={changeValue}
    >
      {selectOptions.map((option) => (
        <option value={option} key={option}>
          {option}
        </option>
      ))}
    </select>
  );
};
