import React from "react";
import axios from "axios";
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Box,
  Link,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import green from "@material-ui/core/colors/green";
import Loading from "../utils/Loading";
import { TextField } from "../ui";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import TableSelectModal from "../utils/TableSelectModal";
import { IoClose } from "react-icons/io5";
import { formatter } from "../../functions/index";
import {
  getCartPriceItem,
  getDeliveryPrice,
  getPriceByProductItem,
} from "../../functions/calcCartPrice/calcCartPrice";
import Snackbar from "@material-ui/core/Snackbar";
import { Colors } from "../../styles/Colors";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer/index";
import ContentHeader from "../layout/components/contentHeader/index";
import { Text } from "../layout/components/text";
import { couponHandler } from "../../functions/calcCartPrice/coupon/coupon";

class EditKit extends React.Component {
  constructor(props) {
    super(props);

    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      loading: true,
      allVal: 0,
      discount: 0,
      allPrices: [],
      data: {
        _id,
        name: "",
        email: "",
        cep: "",
        delivery_price: 0,
        period: 6,
        products: [],
        send: true,
        unit: 1,
        coupon: {
          freightDiscount: null,
          cartDiscount: null,
          name: "",
        },
        price: "",
      },
      discounts: [],
      products: [],
      list_products: [],

      alert: {
        open: false,
        title: false,
        msg: "",
      },
      isDiscountFormOpen: false,
      isSelectorOpen: false,
      saving: false,
      isConfirmOpen: false,
      openModalProducts: false,
      itemsSelected: [],
      createDiscount: "",
      snackbar_alert: false,
      snackbar_alert_type: "",
      msg_alert: "",
      colorMsg: false,
      branch: {
        unit_id: 0,
        location: "",
      },
    };

    this.getRowsSelectedModal = this.getRowsSelectedModal.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.addAllProducts = this.addAllProducts.bind(this);
    this.getDiscount = this.getDiscount.bind(this);
  }

  arredondar = (nr) => {
    return Math.round(nr, -1).toFixed(2);
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  componentDidMount = async () => {
    this.getDiscount();
  };

  getDiscount = async () => {
    const _this = this;
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}/listDiscount`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          discounts: data,
          loading: false,
        });
      });
  };

  timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  calcProductDefaultDiscount = (product) => {
    if (product.product.discount > 0) {
      const productQtdTotal = product.product.price * product.qtd;
      const discount = (productQtdTotal * product.product.discount) / 100;

      return productQtdTotal - discount;
    }

    return product.product.price;
  };

  onSave = async () => {
    if (!this.validateForm()) return false;

    this.setState({
      saving: true,
      loading: true,
    });

    const { data } = this.state;
    const { send = false, products, period } = data;

    const formattedProduct = products.map((product) => ({
      qtd: product.qtd,
      ...product.product,
    }));

    await Promise.all(
      formattedProduct.map((item) => {
        let firstName = item.name.split(" ")[0];
        let lastName = item.name
          .split(" ")
          .slice(1, item.name.length)
          .toString()
          .replace(/,/gi, " ");
        item.firstName = firstName;
        item.lastName = lastName;
        item.unit = 1;
        return item;
      })
    );

    await this.verifyCep();

    const requestData = {
      email: this.state.data.email,
      name: this.state.data.name,
      period: this.state.data.period,
      cep: this.state.data.cep,
      price: this.state.data.price,
      delivery_price: this.state.data.delivery_price,
      coupon: this.state.data?.coupon,
      products: formattedProduct,
    };

    const token = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_URL}/saveBudget`, requestData, {
        headers: { Authorization: "bearer " + token },
      })
      .then(async () => {
        if (send) {
          let produtosCarrinho = `<table width='100%' style='color: #999999;margin-top: 40;padding-bottom: 40px'; align='left;padding-left: 20px;margin-left: 20px' >
            <thead>
            <tr style='color: #999999;text-align: left'>
              <th></th>
              <th>Nome</th>
              <th>Valor</th>
              <th>Qtd</th>
            </tr>
            </thead>
            <tbody>`;

          formattedProduct.map((item) => {
            return (produtosCarrinho =
              produtosCarrinho +
              `<tr style='text-align: left'>
                       <td><img src="${
                         item.urlImg
                       }" width="64" height="64" style='width: 64px;height: 64px' /></td>
                       <td>${item.firstName} ${item.lastName}</td>
                       <td>R$ ${getPriceByProductItem(item, period)}</td>
                       <td>${item.qtd}</td>
                   </tr>`);
          });
          produtosCarrinho =
            produtosCarrinho +
            `</tbody>
        </table>`;

          if (produtosCarrinho.length >= 3900) {
            // tratando o tamanho da string do e-mail
            produtosCarrinho = `<table width='100%' style='color: #999999;margin-top: 40;padding-bottom: 40px'; align='left;padding-left: 20px;margin-left: 20px' ><thead><tr style='color: #999999;text-align: left'><th>Nome</th><th>Valor</th><th>Qtd</th></tr></thead><tbody>`;

            formattedProduct.map((item) => {
              return (produtosCarrinho =
                produtosCarrinho +
                `<tr><td>${item.firstName}${
                  item.lastName
                }</td><td>R$ ${getPriceByProductItem(item, period)}
                )}</td><td>${item.qtd}</td></tr>`);
            });
          }
          if (produtosCarrinho.length >= 3800) {
            alert("Orçamento muito grande, havera um problema ao enviar!");
            this.setState({
              loading: false,
            });
            return false;
          }

          this.setState({
            snackbar_alert_type: "success",
            msg_alert: "Salvo e enviado com sucesso! ",
            snackbar_alert: true,
            colorMsg: true,
            loading: false,
          });
          this.props.history.goBack();
          return;
        }
        this.setState({
          snackbar_alert_type: "success",
          msg_alert: "Salvo com sucesso! ",
          snackbar_alert: true,
          colorMsg: true,
        });

        this.setState({
          loading: false,
        });

        this.props.history.goBack();
      })
      .catch((error) => {
        alert("Tivemos um problema ao salvar o orçamento");
        this.setState({
          saving: false,
          success: false,
          loading: false,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  verifyCep = async () => {
    const { cep } = this.state.data;

    if (cep.length < 9 || cep.includes("_")) {
      this.setState({
        snackbar_alert_type: "success",
        msg_alert:
          ":( Infelizmente o CEP informado não é válido. Por favor verifique os dados e tente novamente ",
        snackbar_alert: true,
      });

      return false;
    }

    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const { data } = response;

      if (data.erro) {
        this.setState({
          snackbar_alert_type: "success",
          msg_alert: `Cep inválido`,
          snackbar_alert: true,
        });

        return false;
      }

      const location = await axios.get(
        `https://api.tuim.com.br/serviceLocation/${data.uf}/${data.localidade}?cep=${cep}`
      );

      if (location.data == null) {
        if (data.uf == "SP") {
          this.setState({
            snackbar_alert_type: "success",
            msg_alert: `A cidade de ${data.localidade} ainda não está na nossa rota!`,
            snackbar_alert: true,
          });
          return false;
        }

        this.setState({
          snackbar_alert_type: "success",
          msg_alert: "Ainda não chegamos a este estado!",
          snackbar_alert: true,
        });
        return false;
      }

      let productsHomeOffice = this.state.data.products.filter(
        (item) => item.category === "Home Office"
      );
      const homeOffice = productsHomeOffice.length != 0 ? true : false;

      let [delivery_exemption] = location.data.delivery_exemption.filter(
        (item) =>
          this.state.data.period >= item.min_month &&
          this.state.data.period <= item.max_month
      );

      const fullPrice = getCartPriceItem(this.state.data);
      let delivery_price = await getDeliveryPrice({
        cart: this.state.data,
        checkoutPriceWithoutDiscount: fullPrice,
        freightPriceWithoutDiscount: location.data.delivery_price,
        homeOffice,
        delivery_exemption,
      });

      const couponsDiscount = await couponHandler({
        period: this.state.data.period,
        checkoutPriceWithoutDiscount: fullPrice,
        freightPriceWithoutDiscount: delivery_price,
        coupon: this.state.data?.coupon,
      });

      this.setState({
        data: {
          ...this.state.data,
          delivery_price,
          price: fullPrice,
          coupon: couponsDiscount || this.state.data.coupon,
        },
      });
      return true;
    } catch (error) {
      console.log(error);
      this.setState({
        snackbar_alert_type: "success",
        msg_alert: "Essa região ainda não é atendida.",
        snackbar_alert: true,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  validateForm = () => {
    const {
      name = "",
      period,
      products = [],
      cep = "",
      send = true,
      email = "",
    } = this.state.data;

    if (name == "") {
      this.setState({
        snackbar_alert_type: "success",
        msg_alert: 'Por favor preencha o campo "Nome do cliente" ',
        snackbar_alert: true,
      });

      return false;
    }

    if (email == "" && send == true) {
      this.setState({
        snackbar_alert_type: "success",
        msg_alert: 'Por favor preencha o campo "E-mail" ',
        snackbar_alert: true,
      });

      return false;
    }

    if (cep == "") {
      this.setState({
        snackbar_alert_type: "success",
        msg_alert: 'Por favor preencha o campo "CEP" ',
        snackbar_alert: true,
      });
      return false;
    }

    if (period == 0) {
      this.setState({
        snackbar_alert_type: "success",
        msg_alert: 'Por favor preencha o campo "Período" ',
        snackbar_alert: true,
      });
      return false;
    }

    if (products.length == 0) {
      this.setState({
        snackbar_alert_type: "success",
        msg_alert: "Por favor escolha um ou mais produtos ",
        snackbar_alert: true,
      });
      return false;
    }

    const productWithoutQuantity = this.state.data.products.find(
      (product) => typeof product?.qtd !== "number" || product?.qtd === 0
    );
    if (!!productWithoutQuantity) {
      this.setState({
        snackbar_alert_type: "success",
        msg_alert: "Por favor preencha a quantidade de produtos",
        snackbar_alert: true,
      });
      return false;
    }

    return true;
  };
  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getRowsSelectedModal = async (selectedProducts) => {
    // Filter the selected products to include only those that are not already present in the current state
    const newSelected = selectedProducts.filter(
      (product) =>
        !this.state.products.some((p) => p.id === product.id) &&
        !this.state.data.products.some((p) => p.id === product.id)
    );

    // Update the state with the new selected products
    this.setState((prevState) => ({
      products: [...prevState.products, ...newSelected],
      data: {
        ...prevState.data,
        products: [...prevState.data.products, ...newSelected],
      },
      openModalProducts: !prevState.openModalProducts,
    }));

    await this.addAllProducts();
  };

  onChange = (e) => {
    let { name, value } = e.target;
    const { data } = this.state;

    if (name === "cep") {
      value = value.replace(/\D/g, "");
      value = value.replace(/(\d{5})(\d{3})/, "$1-$2");
    }

    if (name === "coupon")
      return this.setState({
        data: { ...data, coupon: { ...data.coupon, name: value } },
      });

    this.setState({ data: { ...data, [name]: value } });
  };

  onChangeQtdProduct = async (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    let { products } = data;

    let product = data.products[name];
    product.qtd = Number(value);

    products[name] = await product;

    this.setState({ data: { ...data, products: products } });
    await this.addAllProducts();
  };

  onDiscountAdd = (data) => {
    let { discounts } = this.state.data;
    const { percentual, parametro } = data;

    discounts.push({ percentual, parametro });

    this.setState({
      data: {
        ...this.state.data,
        discounts,
      },
      isDiscountFormOpen: false,
    });
  };

  openModalProducts = () =>
    this.setState({ openModalProducts: !this.state.openModalProducts });

  getProducts = async () => {
    if (this.state.data.cep.length !== 9) return;

    const _this = this;
    const token = localStorage.getItem("token");
    _this.setState({ loading: true });

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v2/new-budget/stock/${this.state.data.cep}`,
        { headers: { Authorization: "bearer " + token } }
      );

      this.setState({
        branch: {
          location: data.content.location,
          unit_id: data.content.unit_id,
        },
      });
      this.setState({ list_products: data.content.data });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  addAllProducts = async () => {
    let { data } = this.state;
    let allPrices = [];
    let qtd = "";
    let val = "";
    let res = 0;

    if (allPrices.length == 0) {
      return;
    }
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    data.products.map((p) => {
      qtd = p.qtd;
      val = p.price;
      res = val * qtd;
      allPrices.push(res);
    });

    let resultado = await allPrices.reduce(reducer);
    this.setState({
      allVal: resultado,
    });
  };
  setItemsSelected = (itemsSelected) => {
    this.setState({ data: { ...this.state.data, products: itemsSelected } });
  };

  changeDiscount = (e) => {
    const { discounts } = this.state;
    let index = e.target.value;
    if (index === "") {
      this.setState({
        createDiscount: "on",
        data: {
          ...this.state.data,
          discounts: [],
        },
      });
    } else {
      let descontos = discounts[index].discounts;
      let descontoNome = discounts[index].name;
      this.setState({
        createDiscount: "",
        data: {
          ...this.state.data,
          discounts: descontos,
          discountName: descontoNome,
        },
      });
    }
  };
  deleteProduct = (index) => {
    const { products } = this.state.data;
    products.splice(index, 1);
    this.setState({
      data: {
        ...this.state.data,
        products,
      },
    });
  };

  render() {
    const {
      loading,
      data,
      saving,
      list_products,
      openModalProducts,
      snackbar_alert,
      msg_alert,
      colorMsg,
      branch,
    } = this.state;
    const { classes } = this.props;

    const columnsModalProducts = [
      { id: "name", numeric: false, disablePadding: true, label: "Nome" },
      { id: "id_sap", numeric: false, disablePadding: false, label: "ID SAP" },
      {
        id: "estoque",
        numeric: false,
        disablePadding: false,
        label: "Estoque",
      },
    ];

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title="Novo Orçamento"
          buttons={[
            {
              text: "Salvar",
              secondary: true,
              onClick: () => this.onSave(),
            },
          ]}
        />

        <ContentContainer label="Dados gerais">
          <TextField
            value={data.name}
            onChange={this.onChange}
            name="name"
            placeholder="Digite o nome do cliente"
            title="Nome do cliente"
          />
          <TextField
            value={data.email}
            onChange={this.onChange}
            name="email"
            placeholder="Digite o E-mail"
            title="E-mail"
          />
          <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <TextField
              value={data.cep}
              onChange={this.onChange}
              onBlur={async () => await this.getProducts()}
              name="cep"
              placeholder="Digite o CEP do cliente"
              title="CEP"
              inputProps={{ maxLength: 9 }}
              disabled={Boolean(!data.name || !data.email)}
            />
            {branch.location && <Text detail>Filial: {branch.location}</Text>}
          </Box>
          <TextField
            onChange={this.onChange}
            name="coupon"
            placeholder="Digite o cupom"
            title="Cupom"
          />
          <FormControl style={{ margin: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-label">{"Período"}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={6}
              value={this.state.period}
              onChange={(e) =>
                this.setState({
                  data: {
                    ...data,
                    period: e.target.value,
                  },
                })
              }
            >
              <MenuItem value={1}>1 Mês</MenuItem>
              <MenuItem value={2}>2 Meses</MenuItem>
              <MenuItem value={3}>3 Meses</MenuItem>
              <MenuItem value={4}>4 Meses</MenuItem>
              <MenuItem value={5}>5 Meses</MenuItem>
              <MenuItem value={6}>6 Meses</MenuItem>
              <MenuItem value={7}>7 Meses</MenuItem>
              <MenuItem value={8}>8 Meses</MenuItem>
              <MenuItem value={9}>9 Meses</MenuItem>
              <MenuItem value={10}>10 Meses</MenuItem>
              <MenuItem value={11}>11 Meses</MenuItem>
              <MenuItem value={12}>12 Meses</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={data.send}
                onChange={this.onCheckChange("send")}
                value="send"
                color="secondary"
              />
            }
            label="Enviar orçamento por e-mail"
          />
        </ContentContainer>
        <ContentContainer label={`Produtos (${data.products.length})`}>
          <>
            {data.products.length > 0 ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 28,
                  overflow: "hidden",
                }}
              >
                <Table aria-label="simple table">
                  <TableBody>
                    {data.products.map((product, index) => {
                      return (
                        <TableRow key={product.product._id} sx={{ margin: 0 }}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              display: "flex",
                              flex: 1,
                              position: "relative",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                gap: 30,
                                flex: 1,
                              }}
                            >
                              <div
                                style={{
                                  backgroundImage: `url(${product.product.urlImg})`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                  width: 120,
                                  height: 80,
                                  borderRadius: 8,
                                }}
                              />
                              <button
                                style={{
                                  background: "#CB2027",
                                  position: "absolute",
                                  top: 5,
                                  left: 5,
                                  borderRadius: "50%",
                                  padding: "0 2px",
                                  border: "none",
                                  cursor: "pointer",
                                  boxShadow:
                                    "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
                                }}
                                onClick={() => this.deleteProduct(index)}
                              >
                                <IoClose
                                  color="#fff"
                                  size={18}
                                  style={{ marginTop: 2 }}
                                />
                              </button>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  gap: 12,
                                }}
                              >
                                <Box>
                                  <Link
                                    href={`/app/product/${product.product._id}`}
                                    target="_blank"
                                  >
                                    <Text
                                      strong
                                      style={{
                                        fontSize: 18,
                                        maxWidth: 420,
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                      color="#444"
                                    >
                                      {product.product.name}
                                    </Text>
                                  </Link>
                                  <Text detail>
                                    Estoque: {product.product.estoque}
                                  </Text>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      backgroundColor: Colors.paleDarkGreen,
                                      padding: `2px 18px`,
                                      borderRadius: 20,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text
                                      detail
                                      strong
                                      color="#444"
                                      style={
                                        product.product.discount > 0 && {
                                          textDecoration: "line-through",
                                        }
                                      }
                                    >
                                      {formatter.currency(
                                        Math.round(
                                          product.product.price * product.qtd
                                        )
                                      )}
                                    </Text>
                                  </Box>
                                  {product.product.discount ? (
                                    <Box
                                      style={{
                                        display: "flex",
                                        gap: 10,
                                        alignItems: "center",
                                      }}
                                    >
                                      <span>Por</span>
                                      <Box
                                        style={{
                                          display: "flex",
                                          backgroundColor: Colors.darkGreen,
                                          padding: `2px 16px`,
                                          borderRadius: 20,
                                          alignItems: "center",
                                          gap: 20,
                                        }}
                                      >
                                        <Text style={{ color: "#f1f1f1" }}>
                                          {formatter.currency(
                                            Math.round(
                                              this.calcProductDefaultDiscount(
                                                product
                                              )
                                            )
                                          )}
                                        </Text>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <div></div>
                                  )}
                                  <Box
                                    style={{
                                      display: "flex",
                                      backgroundColor: Colors.paleYellow,
                                      padding: `2px 16px`,
                                      borderRadius: 20,
                                      alignItems: "center",
                                      gap: 20,
                                    }}
                                  >
                                    <Box
                                      onClick={() =>
                                        product?.qtd > 1
                                          ? this.onChangeQtdProduct({
                                              target: {
                                                name: index,
                                                value: product?.qtd - 1,
                                              },
                                            })
                                          : {}
                                      }
                                      style={{
                                        display: "flex",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Text strong>{`-`}</Text>
                                    </Box>
                                    <Text strong>{product?.qtd}</Text>

                                    <Box
                                      onClick={() =>
                                        this.onChangeQtdProduct({
                                          target: {
                                            name: index,
                                            value: product?.qtd + 1,
                                          },
                                        })
                                      }
                                      style={{
                                        display: "flex",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Text strong>{`+`}</Text>
                                    </Box>
                                  </Box>
                                </Box>
                              </div>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  minHeight: 120,
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Text style={{ fontSize: 14, color: "#999" }}>
                  Nenhum produto selecionado.
                </Text>
              </Box>
            )}
          </>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <TuimButton
              disabled={!Boolean(branch.location)}
              secondary
              text="Adicionar Produtos"
              onClick={this.openModalProducts}
            />
          </Box>
        </ContentContainer>
        <TableSelectModal
          itemsSelected={data.products}
          items={list_products}
          order="asc"
          open={openModalProducts}
          refName="name"
          orderBy="name"
          page={0}
          rowsPerPage={10}
          selected={data.products}
          title="Produtos"
          nameButton="Selecionar"
          columns={columnsModalProducts}
          getRowsSelectedModal={this.getRowsSelectedModal}
          setItemSelected={this.setItemsSelected.bind(this)}
        />
        <Snackbar
          open={snackbar_alert}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackbar_alert: false })}
        >
          <div
            style={{
              textAlign: "center",
              width: "100%",
              color: "#fff",
              fontWeight: 500,
              backgroundColor: `${colorMsg ? "#4caf50" : Colors.red}`,
              display: "flex",
              padding: "20px 26px",
              fontSize: "18px",
              borderRadius: 4,
              marginTop: -150,
              boxShadow:
                "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
            }}
          >
            {msg_alert}
          </div>
        </Snackbar>
      </>
    );
  }
}

const styles = (theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  success: {
    backgroundColor: green[600],
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  dropZone: {
    display: "flex",
    flex: 1,
    padding: 15,
    justifyContent: "center",
    alignItems: "center",
    margin: 15,
    borderRadius: 10,
    flexDirection: "column",
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: "gray",
    maxHeight: 150,
    cursor: "pointer",
  },
});

export default withStyles(styles)(EditKit);
