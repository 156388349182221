import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import Dropzone from 'react-dropzone'

const DropzoneComponent = (props) => (
    <Dropzone accept={typeof props.accept != 'undefined' ? props.accept : "image/*"} onDrop={props.onDrop} >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <div {...getRootProps()}
                className={props.classes.dropZone}
                style={{
                    backgroundColor: isDragActive && !isDragReject ? '#00c83075' : isDragReject ? '#ff000042' : ''
                }}
            >
                <input {...getInputProps()} />
                <div style={{ textAlign: 'center', display: 'flex', fontSize: 12 }}>
                    {typeof props.txt != 'undefined' ?
                        props.txt
                        :
                        'Arraste aqui as imagens do produto ou clique para selecionar os arquivos'

                    }
                </div>
            </div>
        )}
    </Dropzone >
)

const styles = theme => ({

    dropZone: {
        display: 'flex',
        flex: 1,
        padding: 15,
        justifyContent: 'center', alignItems: 'center',
        borderRadius: 10,
        marginBottom: 15,
        flexDirection: 'column',
        borderWidth: 1, borderStyle: 'dashed',
        borderColor: 'gray',
        maxHeight: 85,
        cursor: 'pointer'
    }
});

export default (withStyles(styles)(DropzoneComponent))