import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow, Paper, Table, Typography, Grid, Toolbar, InputBase, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import Loading from '../utils/Loading';
import TextField from '@material-ui/core/TextField';
import Moment from 'react-moment';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import LogVisible from './logs';

import { fade } from '@material-ui/core/styles/colorManipulator';

class ListLogs extends Component {

    constructor(props) {
        super(props)

        const { classes } = props;

        this.state = {
            classes,
            data: [],
            page: 0,
            rowsPerPage: 12,
            searchTerm: '',
            loading: true,
            data_inicio_filter: "",
            data_final_filter: '',
            order: 'asc',
            orderBy: 'calories',
            id_log: '',
            openLog: false,
            ip: "",
            email: ''
        }

        this.onSearchChange = this.onSearchChange.bind(this);
    }

    componentDidMount = async () => {

        const { data_inicio_filter, data_final_filter,ip, email } = this.state
        const _this = this
        const token = await localStorage.getItem('token');
        let data_filter = {}

        /*   if (status_filter != '') {
              data_filter.status = status_filter
          }
   */
          if (data_inicio_filter != '') {
  
              data_filter.date_start = data_inicio_filter
          }
  
          if (data_final_filter != '') {
  
              data_filter.date_finished = data_final_filter
          }
          if(ip && ip != ""){   
              data_filter.ip = ip;
          }

          if(email && email != ""){   
            data_filter.email = email;
        }

        axios.get(`${process.env.REACT_APP_API_URL}/logsAll`,{
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {

                const { data } = response
                _this.setState({
                    data
                })

                this.setState({ loading: false });
            })
           /*  let url = new URL(window.location.href);

            if (url.searchParams.get('page')) {
                this.setState({ page: url.searchParams.get('page') })
            } */
    }

    filtrarLogs = async (e) => {
        const { data_inicio_filter, data_final_filter, ip, email } = this.state
        const _this = this
        this.setState({ loading: true })
        let data_filter = {}

      /*   if (status_filter != '') {
            data_filter.status = status_filter
        }
 */
        if (data_inicio_filter != '') {

            data_filter.date_start = data_inicio_filter
        }

        if (data_final_filter != '') {

            data_filter.date_finished = data_final_filter
        }
        if(ip && ip != ""){   
            data_filter.ip = ip;
        }
        if(email && email != ""){   
            data_filter.email = email;
        }

        const token = await localStorage.getItem('token');

        axios.post(`${process.env.REACT_APP_API_URL}/log`, data_filter, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {

                const { data } = response
                
                _this.setState({
                    data
                })
                window.history.pushState('atualpage', 'TUIM - Admin', window.location.pathname + '?page=' + 0);
                this.setState({ page: 0, rowsPerPage: 10 });
               

                this.setState({ loading: false });
            })

    }
    onSearchChange = (e) => {

        this.setState({
            searchTerm: e.target.value
        })
    }
    handleChangePage = (event, page) => {
        window.history.pushState('atualpage', 'TUIM - Admin', window.location.pathname + '?page=' + page);
        this.setState({ page });
    };
    handleChangeRowsPerPage = event => {
        this.setState({ page: 0, rowsPerPage: event.target.value });
    };
    createSortHandler = property => event => {
        this.handleRequestSort(event, property);
    };
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }

        this.setState({ order, orderBy });
    };

    render() {

        const { classes, data, searchTerm, loading, data_final_filter, page, rowsPerPage, orderBy, order,openLog,id_log,ip, email} = this.state

        const coluns = [
            { id: 'type', numeric: true, disablePadding: false, label: 'Tipo',align: 'center' },
            { id: 'description', numeric: false, disablePadding: false,align: 'center', label: 'Description' },
            { id: 'ip', numeric: true, disablePadding: false, align: 'center', label: 'ip' },
            { id: 'e-mail', numeric: false, disablePadding: false, align: 'center', label: 'e-mail' },
            { id: 'createdAt', numeric: true, align: 'center', disablePadding: false, label: 'Data' },
          
        ];
        let logs = []
        console.log("searchTerm.length",searchTerm.length)
        if (searchTerm.length > 0) {

            const regex = /[0-9]/;

            if (regex.test(searchTerm)) {
                logs = stableSort(data, getSorting(order, orderBy))
                .filter(item => item._id.toString().includes(searchTerm.toString()))
            } else {
                logs = stableSort(data, getSorting(order, orderBy))
                    .filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()))

            }


        } else {

            logs = stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .filter(item => item.description.toLowerCase().includes(searchTerm.toLowerCase()))
        }

        return (
            <div>

                <Loading loading={loading} />
                {
                    openLog &&
                <LogVisible openLog={openLog} id_log={id_log} onCloseModal={() => this.setState({openLog: false})}/>
                }

                <Grid container>
                    <Grid item xs >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}

                        >Listagem de Logs dos sistemas
                        </Typography>
                    </Grid>                 
                </Grid>
                <Paper className={classes.root}>

                <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                <Toolbar>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Digite a descrição do log"
                                onChange={this.onSearchChange}
                                value={searchTerm}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                            />
                        </div>
                    </Toolbar>

                    <span>Filtro</span>
                    <div style={{ display: 'flex', marginTop: 30 }}>

                        <form className={classes.container} noValidate>
                            <TextField
                                id="data-contract"
                                label="Data Inicio"
                                type="date"
                              //  value={data_inicio_filter}
                                onChange={(e) => this.setState({ data_inicio_filter: e.target.value })}

                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <TextField
                                id="data-contract"
                                label="Data Final"
                                type="date"
                                style={{ marginLeft: 20 }}
                                value={data_final_filter}
                                onChange={(e) => this.setState({ data_final_filter: e.target.value })}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                             <TextField
                                id="id"
                                label="ip"
                                type="text"
                                style={{ marginLeft: 20 }}
                                value={ip}
                                onChange={(e) => this.setState({ ip: e.target.value })}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="email"
                                label="email"
                                type="text"
                                style={{ marginLeft: 20 }}
                                value={email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <Button variant="contained" color="primary" style={{ marginTop: 10, marginLeft: 50 }} onClick={() => this.filtrarLogs()}>
                                Filtrar
                            </Button>
                        </form>
                    </div>

                </div>



            
                  
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Página anterior',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Próxima página',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />


                    <Table className={classes.table}>
                    <TableHead>
                            <TableRow>
                                {coluns.map(
                                    row => (
                                        <TableCell
                                            key={row.id}
                                            align={row.align}
                                            padding={row.disablePadding ? 'none' : 'default'}
                                            sortDirection={orderBy === row.id ? order : false}
                                        >
                                            <Tooltip
                                                title="Sort"
                                                placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                                enterDelay={300}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === row.id}
                                                    direction={order}
                                                    onClick={this.createSortHandler(row.id)}
                                                >
                                                    {row.label}
                                                </TableSortLabel>
                                            </Tooltip>
                                        </TableCell>
                                    ),
                                    this,
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logs.map(row => (
                                <TableRow
                                        key={row._id}
                                        hover
                                        onClick={() => this.setState({id_log: row._id, openLog: true})}
                                        
                                        className={classes.row}
                                    >
                                    <TableCell component="th" scope="row">
                                        {row.type}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.description}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row.ip}
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        {row?.email ? row?.email : '-'}
                                    </TableCell>
                                   
                                    <TableCell align="center"> <Moment format="DD/MM/YYYY HH:mm:ss" date={new Date(row.createdAt)} ></Moment></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Página anterior',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Próxima página',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Paper>
            </div>

        );
    }
}

ListLogs.propTypes = {
    classes: PropTypes.object.isRequired,
};
function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function stableSort(array, cmp) {    
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);

}


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        cursor: 'pointer'
    },
    fab: {
        margin: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade('#ececec', 0.35),
        '&:hover': {
            backgroundColor: fade('#ececec', 0.80),
        },
        // marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        // width: theme.spacing.unit * 1,
        marginLeft: theme.spacing.unit * 1,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 5,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    }
});

export default withStyles(styles)(ListLogs);
