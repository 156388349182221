import React from "react";

export const RemoveButton = (props) => (
  <div
    onClick={props.onClick}
    style={{
      ...{
        width: 15,
        height: 15,
        backgroundColor: "#545454",
        fontWeight: "600",
        cursor: "pointer",
        fontSize: 10,
        borderRadius: 10,
        color: "#FFF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      ...props.style,
    }}
  >
    x
  </div>
);

export const AddButton = (props) => (
  <div
    onClick={props.onClick}
    style={{
      padding: 7,
      borderWidth: 0.5,
      borderStyle: "dashed",
      cursor: "pointer",
      borderRadius: 5,
      fontSize: 12,
    }}
  >
    {props.text}
  </div>
);
