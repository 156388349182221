import { Box } from "@material-ui/core";
import React from "react";
import { Text } from "../layout/components/text";

export const Error = ({ msg = "Houve um erro ao carregar os dados" }) => (
  <Box style={styles.container}>
    <Text>{msg}</Text>
  </Box>
);

const styles = {
  container: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
