import { Box, Typography as Text } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../styles/Colors";

export function ReportCardTable({ data = [] }) {
   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
         {data.map((item, index) => {
            if (item === 'space') return <Box sx={{ display: 'flex', width: '100%', height: 10 }} />;

            return (
               <Box key={`${item?.key}_${index}`} sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Box sx={{ display: 'flex', flex: 1 }}>
                     <Text style={{ fontSize: 14, color: Colors.textDetails }}>
                        {item?.key}
                     </Text>
                  </Box>
                  <Text style={{ fontSize: 14, color: Colors.textDetails }}>
                     {item?.value}
                  </Text>
               </Box>
            )
         }
         )}

      </Box>
   )
}