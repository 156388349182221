import {
  Box,
  Button,
  Checkbox,
  Dialog,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import React, { Component } from "react";
import { pluralize } from "../../functions";
import { Text } from "../layout/components/text";

function stableSort(array) {
  if (!array) return [];

  // Create a new array that contains the elements of the original array and their indices
  const stabilized = array.map((el, index) => [el, index]);

  // Extract the original elements from the sorted array
  return stabilized.map((el) => el[0]);
}

const EnhancedTableHead = ({ order, columns }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {columns.map((row) => (
          <TableCell key={row.id} sortDirection={order ? order : false}>
            {row.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const toolbarStyles = ({ palette: { type, secondary, text }, spacing }) => {
  const highlight =
    type === "light"
      ? {
          color: secondary.main,
          backgroundColor: secondary.light,
        }
      : { color: text.primary, backgroundColor: secondary.dark };

  return {
    root: {
      paddingRight: spacing.unit,
    },
    highlight,
    spacer: {
      flex: "1 1 100%",
    },
    actions: {
      color: text.secondary,
    },
    title: {
      flex: "0 0 auto",
    },
  };
};

let EnhancedTableToolbar = ({ numSelected }) => (
  <div
    style={{
      marginTop: 50,
      padding: 20,
      display: "flex",
      justifyContent: "flex-end",
    }}
  >
    <Text
      strong
      style={{
        backgroundColor: "#f0f0f0",
        borderRadius: 20,
        padding: `8px 20px`,
      }}
    >
      {numSelected > 0
        ? `${numSelected} ${pluralize("produto", numSelected)}
      ${pluralize("selecionado", numSelected)}`
        : `Nenhum produto selecionado`}
    </Text>
  </div>
);

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

class TableSelectModal extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      checked: false,
      _open: true,
      rowsPerPage: this.props.rowsPerPage,
      nameButton: this.props.nameButton,
      title: this.props.title,
      selected: this.props.itemsSelected,
      page: this.props.page,
      refName: this.props.refName,
      searchTerm: "",
      itemsSelected: !this.props.itemsSelected ? [] : this.props.itemsSelected,
      order: this.props.order,
    };
  }

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({ selected: state.data.map((n) => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.props;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  getItemsClickClose = () => {
    const { itemsSelected } = this.props;
    this.props.getRowsSelectedModal(itemsSelected);
  };

  handleChangePage = (event, page) => {
    this.setState({ page: page });
  };

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  selectedItemClick = (event, product) => {
    const { itemsSelected = [] } = this.props;
    const addProduct = event.target.checked;

    const updatedItemsSelected = addProduct
      ? itemsSelected.concat({ qtd: 1, product })
      : itemsSelected.filter((item) => item?.product?._id !== product._id);

    this.props.setItemSelected(updatedItemsSelected);

    this.setState({
      itemsSelected: updatedItemsSelected,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  isSelected(id) {
    const { itemsSelected = [] } = this.props;

    if (
      itemsSelected.indexOf(id) !== -1 ||
      itemsSelected.find((item) => item?.product?._id === id)
    )
      return true;

    return false;
  }

  searchProductsItems = async (e) => {
    const { value } = e.target;
    this.setState({ searchTerm: value, page: 0 });
  };

  render() {
    const { classes, nameButton, title, page, searchTerm, order, rowsPerPage } =
      this.state;
    const { items, open, itemsSelected, orderBy, selected, columns } =
      this.props;
    let count = items?.filter((product) => product.name)?.length;
    let productInfo = [];

    // Check if search term exists and is not just whitespace
    if (searchTerm.length > 0 && searchTerm.trim().length > 0 && items) {
      // Convert search term to lowercase and split into array of words
      const searchTermLower = searchTerm.toLowerCase();
      const searchTermWords = searchTermLower.split(" ");

      // Sort items and filter to only include those where every search term word appears in the lowercase version of the name
      productInfo = stableSort(items, order, orderBy).filter((item) =>
        searchTermWords.every((word) => item.name?.toLowerCase().includes(word))
      );
      // Set count to number of items with defined name property
      count = productInfo.filter((product) => product.name)?.length;
    } else {
      // If no search term or search term is empty/whitespace, filter items to only include those with defined name property
      if (items && items.length > 0)
        productInfo = items?.filter((product) => product.name);
    }

    return (
      <Dialog
        fullScreen
        open={open}
        onClose={this.getItemsClickClose}
        TransitionComponent={Transition}
        onClick={this.getProducts}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={this.getItemsClickClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
              style={{ flex: "1" }}
              color="inherit"
              className={classes.flex}
            >
              {title}
            </Typography>
            <Button color="inherit" onClick={this.getItemsClickClose}>
              {nameButton}
            </Button>
          </Toolbar>
        </AppBar>

        <Paper className={classes.root}>
          <EnhancedTableToolbar
            numSelected={itemsSelected.length}
            classes={classes}
          />
          <Box
            style={{
              padding: 20,
            }}
          >
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Procurar por produtos"
                onChange={(e) => this.searchProductsItems(e)}
                value={searchTerm}
                fullWidth
              />
            </div>
          </Box>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Página anterior",
            }}
            nextIconButtonProps={{
              "aria-label": "Próxima página",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                rowCount={productInfo.length}
                columns={columns}
                handleSort={() => this.handleSort()}
              />

              <TableBody>
                {productInfo
                  .filter((product) => product.name)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((product) => {
                    return (
                      <TableRow
                        onClick={(event) =>
                          this.selectedItemClick(event, product)
                        }
                        selected={this.isSelected(product._id)}
                      >
                        <TableCell key={product._id} padding="checkbox">
                          <Checkbox checked={this.isSelected(product._id)} />
                        </TableCell>
                        <TableCell>{product.name}</TableCell>
                        <TableCell>{product.id_sap || "Sem ID"}</TableCell>
                        <TableCell>{product.estoque}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Página anterior",
            }}
            nextIconButtonProps={{
              "aria-label": "Próxima página",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade("#ececec", 0.35),
    "&:hover": {
      backgroundColor: fade("#ececec", 0.8),
    },
    padding: `12px 32px`,
    width: "100%",
    borderRadius: 60,
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    marginLeft: theme.spacing.unit * 1,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: 20,
    top: 0,
    bottom: 0,
    margin: "auto",
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default withStyles(styles)(TableSelectModal);
