import axios from "axios";

export const useCep = async (zipCode) => {
  try {
    if (zipCode.length !== 9) return;

    const response = await axios.get(
      ` https://viacep.com.br/ws/${zipCode}/json`
    );
    if (!response.data)
      throw new Error("Error when trying to search for zip code.");

    return response;
  } catch (error) {
    console.log(error);
  }
};
