import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const Stock = (props) => {
  const [unit, setUnit] = React.useState("");
  const [stock, setStock] = React.useState("");

  const onChange = (e) => {
    if (e.target.name == "unit") {
      setUnit(e.target.value);
    }

    if (e.target.name == "stock") {
      setStock(e.target.value);
    }
  };
  const { units } = props;

  return (
    <Dialog
      open={true}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
    >
      <DialogContent>
        <InputLabel id="units_stock">Filial</InputLabel>
        <br />
        <Select
          labelId="units_stock"
          name="unit"
          id="select_units"
          fullWidth
          onChange={onChange}
          value={unit}
        >
          <MenuItem value=""></MenuItem>
          {units.map((unit, index) => {
            return <MenuItem value={unit._id}>{unit.title}</MenuItem>;
          })}
        </Select>
      </DialogContent>
      <DialogContent>
        <TextField
          onChange={onChange}
          value={stock}
          margin="dense"
          name="stock"
          label="Estoque"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{ cursor: "pointer", marginRight: 15 }}
          onClick={() => props.onStockAdd({ unit, stock })}
          color="primary"
        >
          Adicionar
        </Button>
        <Button
          style={{ cursor: "pointer" }}
          onClick={props.onClose}
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = (theme) => ({
  container: {},
});

export default withStyles(styles)(Stock);
