import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import axios from 'axios';

export default function FormDialog({ open = false, handleClose, onSuccess, data }) {
    const [state, setState] = useState({
        ...data,
        uf: data.address.uf
    })
    console.log("data", data)
    const { active = true, cep, description, uf, _id = "" } = state

    const onChange = (e) => {
        const { name, value } = e.target
        setState({ ...state, [name]: value });

    }

    const onChangeCEP = (e) => {
        let { name, value } = e.target
        value = value.replace(/\D/g, "")
        value = value.replace(/^(\d{5})(\d)/, "$1-$2")
        if(value.length < 10){

            setState({ ...state, cep: value });
        }
    }
    const handleChange = (e) => {
        const { name, checked } = e.target
        setState({ ...state, [name]: checked });
    }

    const checkForm = () => {

        console.log("cep", cep, uf, description)
        if (!cep || cep.length < 8) {
            alert("CEP Inválido, favor preencher corretamente.");
            return false
        }

        if (!uf || uf == "") {
            alert("Estado Inválido, favor preencher corretamente.");
            return false;
        }

        if (!description || description == "") {
            alert("Descrição Inválido, favor preencher corretamente.");
            return false;
        }

        return true;

    }
    const onEditLocation = async () => {
        if (!await checkForm()) {
            return;
        }
        const token = await localStorage.getItem('token');
       
        await axios.patch(`${process.env.REACT_APP_API_URL}/cep/blacklist/${_id}`, {
            _id,
            cep,
            address: {
                uf: uf.toUpperCase()
            },
            description,
            active
        }, {
            headers: { 'Authorization': "Bearer " + token }
        }).then((response) => {

            handleClose();
            onSuccess();

        }).catch((error) => {
            alert("Ocorreu um erro inesperado!")
        })
    }

    return (

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Editar área de risco</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="cep"
                    name="cep"
                    label="CEP"
                    type="text"
                    value={cep}
                    onChange={onChangeCEP}
                />

                <TextField
                    margin="dense"
                    id="uf"
                    name="uf"
                    label="Estado"
                    type="text"
                    value={uf}
                    style={{ marginLeft: 20 }}
                    onChange={onChange}

                />
                <TextField
                    margin="dense"
                    id="description"
                    name="description"
                    label="Descrição"
                    type="text"
                    value={description}
                    multiline
                    rows={3}
                    fullWidth
                    onChange={onChange}

                />
                <br />
                <FormGroup row>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={active}
                                onChange={handleChange}
                                name="active"
                                color="primary"
                            />
                        }
                        label="Ativo"
                    />
                </FormGroup>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={onEditLocation} color="primary">
                    Editar
                </Button>
            </DialogActions>
        </Dialog>

    );
}