import styled from 'styled-components';


const Container = styled.div`
width: 100%;

.icon{
    width: 100%;
    position: relative;
}

.icon .add{
    float: right;
    color: green;
}
.containerOne {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.containerOne .items{
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.containerOne .items label{    
    font-family: Arial, Helvetica, sans-serif;
    
}
.containerOne .items input{    
    height: 33px;
    background-color: #F3F3F3;
    width: 700px;
    border: none;

}
.containerButton{
    display: flex;
    padding: 20px;
    position: relative;
}
.containerOne .itemsDiscount{
    display: flex;
    flex-direction: column;
    padding: 20px;
    color:  #000;
}
.containerOne .itemsDiscount label{
    padding: 4px 0px;
    font-family: Arial,Helvetica,sans-serif;
    font-size: 13px;
    
}

.containerOne .itemsDiscount input{
    background-color: #F3F3F3;
}

`

export default Container