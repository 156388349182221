import React from "react";
import { Box } from "@material-ui/core";
import { formatter } from "../../../../../functions";
import { ReportCard } from "../../../../Reports/ReportCard";

export function CartsData({ average, counts }) {
  const periodCount = counts[0];
  const totalCount = counts[1];

  return (
    <>
      {average && counts && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: "20px",
          }}
        >
          <ReportCard.Root>
            <ReportCard.Header
              value={totalCount.total}
              caption="Quantidade de Carrinhos no Periodo"
            />
            <ReportCard.Divider />
            <ReportCard.Table
              data={[
                {
                  key: "Media produtos por carrinho",
                  value: `${(average.totalProducts / totalCount.total).toFixed(
                    0
                  )}`,
                },
              ]}
            />
          </ReportCard.Root>

          <ReportCard.Root>
            <ReportCard.Header
              value={formatter.currency(average.averagePrice)}
              caption="Ticket Médio"
            />
            <ReportCard.Divider />
            <ReportCard.Table
              data={[
                {
                  key: "Top Ticket",
                  value: `${formatter.currency(average.topCarts)}`,
                },
              ]}
            />
          </ReportCard.Root>

          <ReportCard.Root>
            <ReportCard.Header
              value={average.periodAverage}
              caption="Periodo Médio Mensal"
            />
            <ReportCard.Divider />
            <ReportCard.Table
              data={[
                { key: "Periodo mais utilizado", value: `${periodCount._id}` },
              ]}
            />
          </ReportCard.Root>
        </Box>
      )}
    </>
  );
}
