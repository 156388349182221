import axios from "axios";
import React, { createContext, useEffect, useReducer, useState } from "react";
import { io } from "socket.io-client";

const admin = JSON.parse(localStorage.getItem("tuimAdmin.user" || "{}"));
const API_URL = process.env.REACT_APP_API_URL;

export const ChatContext = createContext({});

const ChatProvider = ({ children }) => {
  const [webSocket, setWebSocket] = useState(null);

  const [chats, setChats] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState({});
  const [newMessage, setNewMessage] = useState({});
  const [unreadAuthors, setUnreadAuthors] = useState([]);
  const [cartInfos, setCartInfos] = useState(null);
  const [currentChat, setCurrentChat] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    {
      guest: null,
      chat: {},
    }
  );

  const resetChatWith = () =>
    setCurrentChat({
      guest: null,
      chat: {},
    });

  const excludeChat = async (currentChat) => {
    // setRemoveChat(currentChat.guest);
    const token = localStorage.getItem("token");
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/v2/delete-chat/${currentChat.chat._id}`,
      {
        headers: { Authorization: "bearer " + token },
      }
    );
    resetChatWith();
    webSocket.emit("refresh");
    // webSocket.on("allChats", setChats);
  };

  const chatWith = (guest) => {
    if (guest === currentChat.guest) return resetChatWith();
    setCurrentChat({ guest });
  };

  const sendMessage = (message) => {
    const data = {
      to: currentChat.guest?._id || currentChat.guest,
      message: {
        author: admin?.id,
        body: message,
        createdAt: new Date(),
      },
    };

    webSocket.emit("msg", data);
  };

  useEffect(() => {
    if (!Boolean(newMessage?.message?.body)) return;
    const currentChatGuestId = currentChat.guest?._id || currentChat.guest;

    if ((newMessage.guest?._id || newMessage.guest) !== currentChatGuestId) {
      return setUnreadAuthors((prev) => [
        ...new Set([...prev, newMessage.guest]),
      ]);
    }

    if (currentChat.chat?.messages?.length > 0) {
      return setCurrentChat({
        chat: {
          ...currentChat.chat,
          messages: [...currentChat.chat.messages, newMessage.message],
        },
      });
    }
    getChat(currentChatGuestId);
  }, [newMessage]);

  const onlineWithNoChat = Object.keys(onlineUsers).filter(
    (user) =>
      !Boolean(
        chats.find((chat) => chat.guest?._id === user || chat.guest === user)
      )
  );

  const allChats = () => {
    return [
      ...onlineWithNoChat.map((guest) => ({
        guest,
      })),
      ...chats,
    ];
  };

  const getChat = (guest) =>
    webSocket.emit("getChat", { emitTo: webSocket.id, guest });

  useEffect(() => {
    const socket = io(API_URL, {
      transports: ["websocket"],
      query: {
        admin: true,
        email: admin?.email,
      },
    });

    socket.on("connect", () => setWebSocket(socket));
    socket.on("connect_error", (error) => console.log(error));
    socket.on("connectedUsers", setOnlineUsers);
    socket.on("allChats", setChats);
    socket.on("chatData", (data) => setCurrentChat({ chat: data }));
    socket.on("msg", setNewMessage);
    socket.on("cart", setCartInfos);

    socket.on("disconnect", (reason) => {
      if (reason === "io server disconnect") socket.connect();
    });

    return () => socket.disconnect();
  }, []);

  useEffect(() => {
    if (!currentChat.guest) return;
    getChat(currentChat.guest);
  }, [currentChat?.guest]);

  useEffect(() => {
    if (Boolean(currentChat.chat)) {
      const guestId = currentChat.guest?._id || currentChat.guest;

      setUnreadAuthors((prev) => prev.filter((author) => author !== guestId));
    }
  }, [currentChat?.chat]);

  const context = {
    chats: allChats(),
    onlineUsers,
    sendMessage,
    getChat,
    chatWith,
    resetChatWith,
    currentChat,
    unreadAuthors,
    excludeChat,
    cartInfos,
  };

  return (
    <ChatContext.Provider value={context}>{children}</ChatContext.Provider>
  );
};

export default ChatProvider;
