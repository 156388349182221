import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow, Paper, Table, Typography, Grid, Button, Box } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import axios from 'axios';
import Loading from '../utils/Loading';
import TablePagination from '@material-ui/core/TablePagination';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-text-mask'
import Moment from 'react-moment';
import ContentHeader from '../layout/components/contentHeader';
import { ContentContainer } from '../layout/components/contentContainer';
import { TuimButton } from '../layout/Button';
import { Colors } from '../../styles/Colors';

const columns = [
  { id: 'nome', numeric: true, disablePadding: false, label: 'Nome', },
  { id: 'email', numeric: false, disablePadding: false, label: 'E-mail' },
  { id: 'createdAt', numeric: true, disablePadding: false, label: 'Data' },
  { id: 'active', numeric: true, disablePadding: false, label: 'Status' },
];
class ListUsers extends Component {
  constructor(props) {
    super(props)

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      loading: true,
      searchTerm: '',
      data_inicio_filter: "",
      data_final_filter: '',
      order: 'asc',
      orderBy: 'calories',
      page: 0,
      rowsPerPage: 12,
      cpf: '',
      email: '',
      count_data: 0,
      name: ''
    }
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {
    const _this = this
    const token = await localStorage.getItem('token');

    axios.get(`${process.env.REACT_APP_API_URL}/clients`, {
      headers: { 'Authorization': "bearer " + token }
    })
      .then(response => {
        const { data, cont } = response.data
        _this.setState({
          data: data,
          count_data: cont,
          loading: false
        })


      })
  }
  nextPage = async () => {
    this.setState({ loading: true })
    const { page = 0, limit = 25 } = this.state
    const _this = this
    const token = await localStorage.getItem('token');
    axios.get(`${process.env.REACT_APP_API_URL}/clients?page=${page}&limit=${limit}`, {
      headers: { 'Authorization': "bearer " + token }
    })
      .then(response => {
        const { data, cont } = response.data
        _this.setState({
          data: data,
          count_data: cont,
          loading: false

        })

      })

  }
  filtrarLogs = async (e) => {
    const { data_inicio_filter, data_final_filter, email, cpf, name, page } = this.state
    const _this = this
    this.setState({ loading: true })
    let url = new URL(window.location.href);
    let returnPage = url.searchParams.get('page')
    if (url.searchParams.get('page')) {
      if (!url.searchParams.get('page')) {
        this.setState({ page: url.searchParams.get('page') })
      } else {
        let returnPage = url.searchParams.get('page')
        this.setState({ page: parseInt(returnPage) })
      }
    }
    let data_filter = {}
    if (data_inicio_filter != '') {

      data_filter.date_start = data_inicio_filter
    }
    if (data_final_filter != '') {
      data_filter.date_finished = data_final_filter
    }
    if (email && email != "") {
      data_filter.email = email;
    }
    if (cpf && cpf != "") {
      data_filter.cpf = cpf;
    }
    if (name && name != "") {
      data_filter.name = name;
    }
    const token = await localStorage.getItem('token');
    axios.get(`${process.env.REACT_APP_API_URL}/clientsList`, {
      params: {
        data_filter: data_filter,
        limit: 25,
        page: parseInt(returnPage) ? parseInt(returnPage) : page
      },
      headers: { 'Authorization': "bearer " + token }
    })
      .then(response => {

        const { data } = response

        _this.setState({
          data
        })
        this.setState({ email: '', cpf: '', data_inicio_filter: '', data_final_filter: '', searchTerm: '' })
        window.history.pushState('atualpage', 'TUIM - Admin', window.location.pathname + '?page=' + 0);
        this.setState({ page: 0, rowsPerPage: 10 });


        this.setState({ loading: false });
      })

  }

  //ordenação de A a Z
  compare(a, b) {

    const bandA = typeof a.name == 'undefined' ? "" : a.name.toUpperCase();
    const bandB = typeof b.name == 'undefined' ? "" : b.name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }
  onSearchChange = (e) => {

    this.setState({
      searchTerm: e.target.value
    })
  }
  handleChangePage = (event, page) => {
    // window.history.pushState('atualpage', 'TUIM - Admin', window.location.pathname + '?page=' + page);
    this.setState({ page });
    this.nextPage()
  };
  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
    this.nextPage()
  };
  createSortHandler = property => event => {
    this.handleRequestSort(event, property);
  };
  handleRequestSort = async (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    let orderName = property
    let orderNumber = order === 'desc' ? -1 : 1

    const { page = 0, limit = 25 } = this.state
    const _this = this
    const token = await localStorage.getItem('token');
    console.log('token', token)
    axios.get(`${process.env.REACT_APP_API_URL}/clients?page=${page}&limit=${limit}&orderName=${orderName}&order=${orderNumber}`, {
      headers: { 'Authorization': "bearer " + token }
    })
      .then(response => {
        const { data, cont } = response.data
        _this.setState({
          data: data,
          count_data: cont,
          loading: false

        })

      })

    this.setState({ order, orderBy });
  };

  render() {
    const { classes, data, loading, data_final_filter, data_inicio_filter, page, rowsPerPage, orderBy, order, cpf, email, count_data, name } = this.state

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title='Clientes'
        />
        <ContentContainer label='Filtros'>
          <Box style={{ display: 'flex', gap: 10, marginTop: 5 }}>
            <TextField
              id="data-contract"
              label="Data Inicio"
              type="date"
              value={data_inicio_filter}
              onChange={(e) => this.setState({ data_inicio_filter: e.target.value })}
              variant='outlined'
              InputLabelProps={styles.InputLabelProps}
              InputProps={styles.InputProps}
              inputProps={styles.inputProps}
              style={{ borderRadius: 40 }}
              fullWidth
            />
            <TextField
              id="data-contract"
              label="Data Final"
              type="date"
              variant='outlined'
              value={data_final_filter}
              onChange={(e) => this.setState({ data_final_filter: e.target.value })}
              InputLabelProps={styles.InputLabelProps}
              InputProps={styles.InputProps}
              inputProps={styles.inputProps}
              style={{ borderRadius: 40 }}
              fullWidth
            />
            <TextField
              id="Nome"
              label="Nome"
              type="text"
              variant='outlined'
              value={name}
              onChange={(e) => this.setState({ name: e.target.value })}
              InputLabelProps={styles.InputLabelProps}
              InputProps={styles.InputProps}
              inputProps={styles.inputProps}
              style={{ borderRadius: 40 }}
              fullWidth
            />
            <TextField
              id="email"
              label="E-mail"
              type="text"
              variant='outlined'
              value={email}
              onChange={(e) => this.setState({ email: e.target.value })}
              InputLabelProps={styles.InputLabelProps}
              InputProps={styles.InputProps}
              inputProps={styles.inputProps}
              style={{ borderRadius: 40 }}
              fullWidth
            />
            <MaskedInput
              style={{ borderRadius: 40, fontSize: 14, padding: 10, border: '1px solid #ccc', outlineColor: Colors.darkGreen }}
              mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
              name="cpf"
              className="form-control"
              placeholder="CPF"
              guide={false}
              id="cpf"
              onChange={(e) => this.setState({ cpf: e.target.value })}
              value={cpf}
            />
          </Box>
          <Box style={{ display: 'flex', width: '100%', gap: 10, justifyContent: 'flex-end' }}>
            <TuimButton
              secondary
              text='Filtrar'
              onClick={this.filtrarLogs}
            />
          </Box>
        </ContentContainer>

        <ContentContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            count={count_data}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima página',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />


          <Table>
            <TableHead>
              <TableRow>
                {columns.map(
                  row => (
                    <TableCell
                      key={row.id}
                      padding={row.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === row.id ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={this.createSortHandler(row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ),
                  this,
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <TableRow
                  key={row._id}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.history.push(`/app/user/${row._id}?page=${page}`)}
                >
                  <TableCell component="th" scope="row">{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>
                    <Moment format="DD/MM/YYYY HH:mm:ss" date={new Date(row.createdAt)} ></Moment>
                  </TableCell>
                  <TableCell>{row.active ? <CheckCircle /> : '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            count={count_data}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Página anterior',
            }}
            nextIconButtonProps={{
              'aria-label': 'Próxima página',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </ContentContainer>
      </>
    );
  }
}

ListUsers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  InputProps: {
    style: {
      borderRadius: 40,
      paddingLeft: 10
    }
  },
  inputProps: {
    style: {
      fontSize: 14,
      borderRadius: 40,
      paddingLeft: 10,
      WebkitBoxShadow: `0 0 0 1000px #fff inset`
    }
  },
  InputLabelProps: {
    shrink: true,
    style: {
      marginRight: 30,
      marginLeft: 4,
    }
  }
}

export default withStyles(styles)(ListUsers);
