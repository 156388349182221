import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { Text } from "../text";
import { uid } from "../../../../functions";


export const Rows = ({ data = [], columnGap = 1, rowGap = 1.2, contained = false }) => {

  const [labelColumnLength, setLabelColumnLength] = useState(1)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: rowGap, ...(!contained && { width: '100%' }) }}>
      {data.map(({ label, description, labelPosition = 'center' }) => {
        if (!label && !description) return <></>;

        if (label?.length > labelColumnLength) setLabelColumnLength(label.length);
        return (
          <Box key={`row_label_${uid()}`} sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: labelPosition, width: `${(8 + columnGap) * labelColumnLength}px` }}>
              <Text strong>
                {label}
              </Text>
            </Box>
            <Box sx={{ flex: 1 }}>
              {typeof description === 'string' ?
                <Text>
                  {description}
                </Text>
                :
                <>{description}</>
              }
            </Box>
          </Box>
        )
      }
      )}
    </Box>
  )
};