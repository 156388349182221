import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import { IoIosChatbubbles, IoMdClose, IoMdSend } from "react-icons/io";
import { ChatContext } from "../../contexts/chatContext";
import { formatter, pluralize } from "../../functions";
import { Colors } from "../../styles/Colors";
import { TuimButton } from "../layout/Button";
import { Modal } from "../layout/components/modal/Modal";
import { Text } from "../layout/components/text";

const admin = JSON.parse(localStorage.getItem("tuimAdmin.user" || "{}"));
const communicationAllowedFor = "jason@tuim.com.br";

export const Chat = () => {
  const [showChats, setShowChats] = useState(false);

  const {
    chats,
    onlineUsers,
    sendMessage,
    chatWith,
    currentChat,
    unreadAuthors,
    resetChatWith,
    excludeChat,
    cartInfos,
  } = useContext(ChatContext);

  const toggleView = () => {
    if (!showChats && chats.length === 0) return;

    if (currentChat.guest) resetChatWith();
    setShowChats((prev) => !prev);
  };

  const chatIcon = showChats ? IoMdClose : IoIosChatbubbles;

  useEffect(() => {
    if (
      Boolean(currentChat?.chat?.cart) &&
      Boolean(cartInfos) &&
      currentChat.chat.cart._id === cartInfos._id
    ) {
      currentChat.chat.cart.period = cartInfos.period;
      currentChat.chat.cart.priceWithDiscount = cartInfos.price;
      currentChat.chat.cart.qtdOfProducts = cartInfos.productsLength;
    }
  }, [currentChat, cartInfos]);

  return (
    <Box style={{ zIndex: 99999999 }}>
      <MainIcon
        chatIcon={chatIcon}
        toggleView={toggleView}
        onlineUsersCount={Object.keys(onlineUsers || {}).length}
        unreadMessages={unreadAuthors?.length}
      />
      {showChats && (
        <ChatList
          chats={chats}
          onlineUsers={onlineUsers}
          unreadAuthors={unreadAuthors}
          chatWith={chatWith}
          sendMessage={sendMessage}
          currentChat={currentChat}
          excludeChat={excludeChat}
        />
      )}
    </Box>
  );
};

const ConnectionIcon = ({ online = false }) => (
  <Box
    style={{
      ...styles.connectionIconRoot,
      backgroundColor: online ? Colors.lightGreen : "#ddd",
    }}
  />
);

const NewMessageBadge = () => (
  <Box style={styles.newMessageBadge}>
    <Text small strong>
      {`!`}
    </Text>
  </Box>
);

const UnreadMessagesBadge = ({ count }) => (
  <Box
    style={{
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: Colors.darkGreen,
      position: "absolute",
      right: 0,
      top: -8,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Text strong small color="#fff">
      {count}
    </Text>
  </Box>
);

const OnlineUsersBadge = ({ count }) => (
  <Box
    style={{
      borderRadius: 20,
      padding: `2px 8px`,
      backgroundColor: Colors.darkGreen,
      position: "absolute",
      right: 0,
      bottom: -24,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Text strong small color="#fff" style={{ whiteSpace: "nowrap" }}>
      Online: {count}
    </Text>
  </Box>
);

const MainIcon = ({
  chatIcon: ChatIcon,
  toggleView,
  unreadMessages,
  onlineUsersCount,
}) => (
  <button
    style={{
      zIndex: 10,
      cursor: "pointer",
      position: "fixed",
      right: 20,
      bottom: 40,
      height: 50,
      width: 50,
      borderRadius: "50%",
      background: Colors.lightGreen,
      textAlign: "center",
      border: "none",
    }}
    onClick={toggleView}
  >
    <ChatIcon size={25} color="#f2f2f2" style={{ marginTop: 4 }} />
    {unreadMessages > 0 && <UnreadMessagesBadge count={unreadMessages} />}
    {onlineUsersCount > 0 && <OnlineUsersBadge count={onlineUsersCount} />}
  </button>
);

const ChatList = ({
  chats,
  onlineUsers,
  unreadAuthors,
  sendMessage,
  chatWith,
  currentChat,
  excludeChat,
}) => {
  return (
    <Box style={styles.chatListRoot}>
      <Box style={styles.chatListContainer}>
        {chats.map((chat) => {
          const guestId = chat.guest?._id || chat.guest;
          const online = Boolean(onlineUsers[guestId]);
          const hasUnreadMessages = unreadAuthors.includes(guestId);
          const selectedChat = currentChat.guest === guestId;

          return (
            <ChatItem
              key={chat._id}
              chat={chat}
              online={online}
              hasUnreadMessages={hasUnreadMessages}
              chatWith={chatWith}
              selectedChat={selectedChat}
            />
          );
        })}
        {currentChat.guest && (
          <Conversation
            userId={admin.id}
            currentChat={currentChat}
            sendMessage={sendMessage}
            excludeChat={excludeChat}
          />
        )}
      </Box>
    </Box>
  );
};

const ChatItem = ({
  chat,
  chatWith,
  online,
  hasUnreadMessages,
  selectedChat,
}) => {
  const userName = chat.guest?.name || `Desconhecido`;
  return (
    <button
      onClick={() => chatWith(chat.guest?._id || chat.guest)}
      style={styles.chatItemRoot}
    >
      <Box style={styles.chatItemContainer}>
        <ConnectionIcon online={online} />
        <Text
          strong={Boolean(hasUnreadMessages)}
          color={online ? "#444" : "#999"}
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            ...(selectedChat && { textDecoration: "underline" }),
          }}
        >
          {userName}
        </Text>
        {hasUnreadMessages && <NewMessageBadge />}
      </Box>
    </button>
  );
};

const Conversation = ({ userId, currentChat, sendMessage, excludeChat }) => {
  const newMessageRef = useRef(null);
  const messagesRef = useRef(null);
  const [showExcludeChatModal, setShowExcludeChatModal] = useState(false);
  const userName = currentChat.chat?.guest?.name || "Desconhecido";
  const cartPrice = currentChat.chat?.cart
    ? currentChat.chat?.cart?.priceWithDiscount ||
      currentChat.chat?.cart?.priceWithoutDiscount
    : null;
  const showExcludeButton = currentChat.chat?.messages || cartPrice;

  const handleSendMessage = () => {
    if (!Boolean(newMessageRef.current.value)) return;

    sendMessage(newMessageRef.current.value);
    newMessageRef.current.value = "";
  };

  useEffect(() => {
    messagesRef.current.scroll({
      top: messagesRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [currentChat]);

  return (
    <Box style={styles.conversationRoot}>
      {showExcludeChatModal && (
        <Modal
          actionButtonFunction={async () => {
            await excludeChat(currentChat);
            setShowExcludeChatModal(false);
          }}
          actionButtonText="Excluir"
          actionTuimButtonType="destructive"
          cancelButtonFunction={() => setShowExcludeChatModal(false)}
          itemName={userName + "?"}
          message="Você deseja excluir o chat com"
        />
      )}
      <Box style={styles.conversationHeader}>
        <Text strong style={{ fontSize: 20 }}>
          {userName}
        </Text>
        <Box style={styles.conversationCartContainer}>
          {currentChat.chat?.cart?.qtdOfProducts > 0 ? (
            <>
              <Text detail>
                {`${currentChat.chat?.cart.qtdOfProducts} ${pluralize(
                  "produto",
                  currentChat.chat?.cart.qtdOfProducts
                )}, ${currentChat.chat?.cart.period} ${pluralize(
                  "mês",
                  currentChat.chat?.cart.period
                )}, ${formatter.currency(cartPrice)}`}
              </Text>
              <TuimButton
                text="Ver carrinho"
                tertiary
                style={{
                  padding: `0px`,
                  width: 180,
                  minWidth: 180,
                }}
                onClick={() =>
                  window.open(
                    `/app/carts/${currentChat.chat?.cart._id}`,
                    "_blank"
                  )
                }
              />
            </>
          ) : (
            <Text detail>{`Carrinho vazio. =(`}</Text>
          )}

          {showExcludeButton && (
            <TuimButton
              text="Excluir chat"
              tertiary
              destructive
              style={{
                padding: `0px`,
                width: 180,
                minWidth: 180,
              }}
              onClick={() => setShowExcludeChatModal(true)}
            />
          )}
        </Box>
      </Box>

      <Box ref={messagesRef} style={styles.conversationMessagesContainer}>
        {currentChat.chat?.messages?.map((msg) => {
          const author = msg.author?._id === userId || msg.author === userId;
          return (
            <Box
              key={msg._id}
              style={{
                ...styles.singleConversationContainer,
                justifyContent: author ? "end" : "start",
              }}
            >
              <Box style={styles.singleConversationItemsWrapper}>
                <Text
                  strong
                  style={{
                    color: author ? Colors.darkGreenAlt : "#e0a52b",
                  }}
                >
                  {msg.author?.nickname || "Desconhecido"}
                </Text>
                <Box style={{ marginBottom: 4 }}>
                  <Text style={styles.singleConversationBody}>{msg?.body}</Text>
                </Box>

                <Text detail small style={{ textAlign: "right" }}>
                  {formatter.date(msg?.createdAt, "short")}
                </Text>
              </Box>
            </Box>
          );
        })}
      </Box>
      {admin.email === communicationAllowedFor && (
        <Box style={{ display: "flex", width: "100%", gap: 4 }}>
          <textarea
            autofocus
            rows="3"
            ref={newMessageRef}
            onKeyDown={(event) => {
              if (event.key === "Enter" && !event.shiftKey) {
                handleSendMessage();
                event.preventDefault();
              }
            }}
            style={styles.conversationTextArea}
          />
          <button
            onClick={handleSendMessage}
            style={styles.conversationSendButton}
          >
            <IoMdSend color={Colors.lightGreen} size={22} />
          </button>
        </Box>
      )}
    </Box>
  );
};

const styles = {
  chatListRoot: {
    position: "fixed",
    top: 0,
    left: 0,
    backgroundImage: "linear-gradient(to left,#00000044,#00000020,transparent)",
    width: "100vw",
    height: "100%",
  },
  chatListContainer: {
    width: 230,
    maxHeight: "70%",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    right: 50,
    bottom: 75,
    border: `1px solid #ddd`,
    borderBottom: "none",
    backgroundColor: "#fff",
    overflowY: "auto",
    borderRadius: 8,
  },
  chatItemRoot: {
    width: "100%",
    border: "none",
    borderBottom: "1px solid #ddd",
    background: "#fff",
    cursor: "pointer",
    textAlign: "left",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  chatItemContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    maxWidth: 172,
  },
  connectionIconRoot: {
    display: "flex",
    width: 8,
    minWidth: 8,
    height: 8,
    minHeight: 8,
    borderRadius: "50%",
  },
  conversationRoot: {
    width: "450px",
    maxWidth: "450px",
    height: "500px",
    maxHeight: "500px",
    display: "flex",
    flexDirection: "column",
    padding: 12,
    borderRadius: 10,
    backgroundColor: "#fff",
    border: `1px solid #ddd`,
    position: "fixed",
    right: 290,
    bottom: 75,
    gap: 10,
  },
  conversationHeader: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  conversationMessagesContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f0f0f0",
    border: `2px solid #f0f0f0`,
    width: "100%",
    flex: "1",
    gap: 10,
    overflowY: "auto",
    overflowX: "hidden",
    padding: 12,
    borderRadius: 8,
  },
  conversationCartContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: 2,
  },
  singleConversationContainer: {
    width: "100%",
    display: "flex",
  },
  singleConversationItemsWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    width: "fit-content",
    padding: 10,
    borderRadius: 8,
    maxWidth: "80%",
  },
  singleConversationBody: {
    overflowWrap: "break-word",
    hyphens: "auto",
    whiteSpace: "break-spaces",
    color: "#444",
  },
  conversationTextArea: {
    fontFamily: "Ubuntu",
    display: "flex",
    flex: 1,
    backgroundColor: "#f0f0f0",
    borderRadius: 8,
    width: "100%",
    padding: 10,
    paddingRight: 45,
    color: "#444",
    border: "none",
    outline: "none",
    resize: "none",
    maxHeight: 60,
  },
  conversationSendButton: {
    cursor: "pointer",
    border: "none",
    height: "100%",
    backgroundColor: "#fff",
  },
  newMessageBadge: {
    backgroundColor: Colors.paleGreen,
    padding: `2px 8px`,
    borderRadius: 20,
  },
};
