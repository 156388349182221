import React from "react";
import { ReportContainer } from "..";
import { formatter } from "../../../../functions";
import { ReportCard } from "../../ReportCard";
import { monthlyStatusOrder } from "./Helpers";

export const Monthly = ({ data }) => (
  <ReportContainer.Root>
    <>
      <ReportCard.Group title="Aprovados" key={`approved_contracts-report`}>
        <ReportCard.Root>
          <ReportCard.Header
            value={formatter.number(data.statistics.contracts.approved)}
            caption="Assinaturas"
          />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              {
                key: "Residencial",
                value: `${formatter.number(
                  data.statistics.contracts.residential.sum
                )} (${formatter.number(
                  data.statistics.contracts.residential.percentage
                )}%)`,
              },
              {
                key: "Home Office",
                value: `${formatter.number(
                  data.statistics.contracts.homeOffice.sum
                )} (${formatter.number(
                  data.statistics.contracts.homeOffice.percentage
                )}%)`,
              },
              {
                key: "Eletro",
                value: `${formatter.number(
                  data.statistics.contracts.eletro.sum
                )} (${formatter.number(
                  data.statistics.contracts.eletro.percentage
                )}%)`,
              },
            ]}
          />
        </ReportCard.Root>

        <ReportCard.Root>
          <ReportCard.Header
            value={formatter.currency(data.statistics.income.estimated.all)}
            caption="Fechamento"
          />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              {
                key: "Residencial",
                value: `${formatter.currency(
                  data.statistics.income.estimated.residential.sum
                )} (${formatter.number(
                  data.statistics.income.estimated.residential.percentage
                )}%)`,
              },
              {
                key: "Home Office",
                value: `${formatter.currency(
                  data.statistics.income.estimated.homeOffice.sum
                )} (${formatter.number(
                  data.statistics.income.estimated.homeOffice.percentage
                )}%)`,
              },
              {
                key: "Eletro",
                value: `${formatter.currency(
                  data.statistics.income.estimated.eletro.sum
                )} (${formatter.number(
                  data.statistics.income.estimated.eletro.percentage
                )}%)`,
              },
            ]}
          />
        </ReportCard.Root>

        <ReportCard.Root>
          <ReportCard.Header
            value={formatter.currency(data.statistics.income.all)}
            caption="Faturamento"
          />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              {
                key: "Residencial",
                value: `${formatter.currency(
                  data.statistics.income.residential.sum
                )} (${formatter.number(
                  data.statistics.income.residential.percentage
                )}%)`,
              },
              {
                key: "Home Office",
                value: `${formatter.currency(
                  data.statistics.income.homeOffice.sum
                )} (${formatter.number(
                  data.statistics.income.homeOffice.percentage
                )}%)`,
              },
              {
                key: "Eletro",
                value: `${formatter.currency(
                  data.statistics.income.eletro.sum
                )} (${formatter.number(
                  data.statistics.income.eletro.percentage
                )}%)`,
              },
            ]}
          />
        </ReportCard.Root>

        <ReportCard.Root>
          <ReportCard.Header
            value={formatter.currency(data.statistics.average.ticket)}
            caption="Ticket Médio"
          />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              {
                key: "Período médio",
                value: formatter.number(data.statistics.average.period),
              },
              {
                key: "Top Ticket",
                value: formatter.currency(data.statistics.average.topTicket),
              },
            ]}
          />
        </ReportCard.Root>

        <ReportCard.Root>
          <ReportCard.Header
            value={formatter.currency(data.statistics.discount.sum)}
            caption="em descontos"
          />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              {
                key: "Porcentagem",
                value: `${formatter.number(
                  data.statistics.discount.percentage
                )}%`,
              },
            ]}
          />
        </ReportCard.Root>
      </ReportCard.Group>

      {monthlyStatusOrder.map(({ status, display, colors }, index) => {
        const [month] = Object.keys(data.data);
        const itemStatistics = data.data[month].contracts?.[status]?.statistics;
        return itemStatistics ? (
          <ReportCard.Group title={display} key={`${status}_${index}`}>
            <ReportCard.Root
              background={colors.background}
              border={colors.border}
            >
              <ReportCard.Header
                value={formatter.number(itemStatistics.contracts.all)}
                caption="Assinaturas"
              />
              <ReportCard.Divider />
              <ReportCard.Table
                data={[
                  {
                    key: "Residencial",
                    value: `${formatter.number(
                      itemStatistics.contracts.residential.sum
                    )} (${formatter.number(
                      itemStatistics.contracts.residential.percentage
                    )}%)`,
                  },
                  {
                    key: "Home Office",
                    value: `${formatter.number(
                      itemStatistics.contracts.homeOffice.sum
                    )} (${formatter.number(
                      itemStatistics.contracts.homeOffice.percentage
                    )}%)`,
                  },
                  {
                    key: "Eletro",
                    value: `${formatter.number(
                      itemStatistics.contracts.eletro.sum
                    )} (${formatter.number(
                      itemStatistics.contracts.eletro.percentage
                    )}%)`,
                  },
                ]}
              />
            </ReportCard.Root>

            <ReportCard.Root
              background={colors.background}
              border={colors.border}
            >
              <ReportCard.Header
                value={formatter.currency(itemStatistics.income.estimated.all)}
                caption="Fechamento"
              />
              <ReportCard.Divider />
              <ReportCard.Table
                data={[
                  {
                    key: "Residencial",
                    value: `${formatter.currency(
                      itemStatistics.income.estimated.residential.sum
                    )} (${formatter.number(
                      itemStatistics.income.estimated.residential.percentage
                    )}%)`,
                  },
                  {
                    key: "Home Office",
                    value: `${formatter.currency(
                      itemStatistics.income.estimated.homeOffice.sum
                    )} (${formatter.number(
                      itemStatistics.income.estimated.homeOffice.percentage
                    )}%)`,
                  },
                  {
                    key: "Eletro",
                    value: `${formatter.currency(
                      itemStatistics.income.estimated.eletro.sum
                    )} (${formatter.number(
                      itemStatistics.income.estimated.eletro.percentage
                    )}%)`,
                  },
                ]}
              />
            </ReportCard.Root>

            <ReportCard.Root
              background={colors.background}
              border={colors.border}
            >
              <ReportCard.Header
                value={formatter.currency(itemStatistics.income.all)}
                caption="Faturamento"
              />
              <ReportCard.Divider />
              <ReportCard.Table
                data={[
                  {
                    key: "Residencial",
                    value: `${formatter.currency(
                      itemStatistics.income.residential.sum
                    )} (${formatter.number(
                      itemStatistics.income.residential.percentage
                    )}%)`,
                  },
                  {
                    key: "Home Office",
                    value: `${formatter.currency(
                      itemStatistics.income.homeOffice.sum
                    )} (${formatter.number(
                      itemStatistics.income.homeOffice.percentage
                    )}%)`,
                  },
                  {
                    key: "Eletro",
                    value: `${formatter.currency(
                      itemStatistics.income.eletro.sum
                    )} (${formatter.number(
                      itemStatistics.income.eletro.percentage
                    )}%)`,
                  },
                ]}
              />
            </ReportCard.Root>

            <ReportCard.Root
              background={colors.background}
              border={colors.border}
            >
              <ReportCard.Header
                value={formatter.currency(itemStatistics.average.ticket)}
                caption="Ticket Médio"
              />
              <ReportCard.Divider />
              <ReportCard.Table
                data={[
                  {
                    key: "Período médio",
                    value: formatter.number(itemStatistics.average.period),
                  },
                  {
                    key: "Top Ticket",
                    value: formatter.currency(itemStatistics.average.topTicket),
                  },
                ]}
              />
            </ReportCard.Root>

            <ReportCard.Root
              background={colors.background}
              border={colors.border}
            >
              <ReportCard.Header
                value={formatter.currency(itemStatistics.discount.sum)}
                caption="em descontos"
              />
              <ReportCard.Divider />
              <ReportCard.Table
                data={[
                  {
                    key: "Porcentagem",
                    value: `${formatter.number(
                      itemStatistics.discount.percentage
                    )}%`,
                  },
                ]}
              />
            </ReportCard.Root>
          </ReportCard.Group>
        ) : (
          <></>
        );
      })}
    </>
  </ReportContainer.Root>
);
