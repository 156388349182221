import React from 'react';
import axios from 'axios'
import {
    Button, FormControl, InputLabel,
    MenuItem, Select
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { uid } from '../../functions';

export default function EditCart({ AlterCart, contract, onCloseModal, payment, getSubscription }) {
    let cartao = '';
    let idSubscription = '';
    let vindi = '';
    const editCartao = async () => {
        axios.post(`${process.env.REACT_APP_API_URL}/vindi/subscription/paymentprofile`, {
            id: idSubscription,
            payment_profile: cartao,
            customer: vindi

        }).then(response => {
            alert("Cartão alterado com sucesso!")
            onCloseModal(false)
            getSubscription()
        })
            .catch(error => {
                alert("Não foi possível alterar o cartão")
            })
    }
    const changeValue = (e) => {
        idSubscription = contract.id_subscription
        vindi = contract.id_user.idVindi
        cartao = e.target.value;
    }
    return (
        <>
            <Modal
                open={AlterCart}
                onClose={() => onCloseModal()}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={{
                    position: 'absolute',
                    width: 500,
                    backgroundColor: '#fff',
                    padding: 20,
                    left: '35%',
                    top: '25%',
                    height: 195,
                    border: 'none'
                }}>
                    <label><b>Editar Cartão </b>#{contract.id_subscription}</label>
                    <br />
                    <div style={{ width: '100%', marginTop: 10 }}>

                        <FormControl style={{ width: '100%' }}>
                            <InputLabel htmlFor="age-native-simple">Cartões</InputLabel>
                            <Select name="cartao" onChange={changeValue} >
                                <MenuItem></MenuItem>
                                {payment.map((p) => {

                                    return (<MenuItem key={uid()} value={p.id} selected={p.payment_method.id == contract.payment.payment_method.id}>**** **** ****{p.card_number_last_four}</MenuItem>)

                                })

                                }


                            </Select>
                        </FormControl>
                    </div>
                    <br />
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => editCartao()}
                    >

                        Editar Cartão

                    </Button>
                </div>
            </Modal>
        </>
    )
}
