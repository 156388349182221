import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import CheckCircle from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Moment from "react-moment";
import { formatter } from "../../functions";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import Loading from "../utils/Loading";

class ListProducts extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      searchTerm: "",
      loading: true,
      page: 0,
      rowsPerPage: 25,
      order: "asc",
      orderBy: "calories",
      openModal: false,
      handleClose: false,
      saving: false,
      estoque: 0,
      name: "",
      active: false,
      id_edit: "",
      id_sap: "",
      products: 0,
      openModalDelete: false,
      product_selected_delete: 0,
      status_filter: "Todos",
      sap_filter: "",
      count: 0,
      nameProduct: "",
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
  }

  onCheckChange = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
      success: false,
    });
  };

  componentDidMount = async () => {
    let url = new URL(window.location.href);
    let returnPage = url.searchParams.get("page");
    if (url.searchParams.get("page")) {
      if (!url.searchParams.get("page")) {
        this.setState({ page: url.searchParams.get("page") });
      } else {
        let returnPage = url.searchParams.get("page");
        this.setState({ page: parseInt(returnPage) });
      }
    }
    const { page, rowsPerPage } = this.state;

    const _this = this;

    const token = localStorage.getItem("token");

    await axios
      .get(`${process.env.REACT_APP_API_URL}/products/admin`, {
        params: {
          fields: {
            name: 1,
            estoque: 1,
            price: 1,
            createdAt: 1,
            active: 1,
            urlImg: 1,
            id_sap: 1,
            visible: 1,
          },
          limit: rowsPerPage,
          page: parseInt(returnPage) ? parseInt(returnPage) : page,
        },
        headers: { Authorization: "bearer " + token },
      })
      .then(async (response) => {
        const { data, count } = response.data;
        _this.setState({ data, count });
      })
      .finally(() => {
        _this.setState({ loading: false });
      });
  };

  editProduct = (id, active, name, estoque, id_sap, index) => {
    this.setState({
      id_edit: id,
      openModal: true,
      active: active,
      name: name,
      estoque: estoque,
      id_sap: id_sap,
      product_selected: index,
    });
  };

  openModaldeleteProduct = (id, name, index) => {
    this.setState({
      id_edit: id,
      openModalDelete: true,
      name: name,
      product_selected_delete: index,
    });
  };

  filtrarProducts = async (pageFilter = 0, property = "") => {
    const { sap_filter, status_filter, nameProduct, rowsPerPage } = this.state;
    let data_filter = {};
    let order = "desc";
    const orderBy = property;

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    if (sap_filter != "") data_filter.id_sap = sap_filter;

    if (status_filter == true) {
      data_filter.status = status_filter;
    } else if (status_filter == false && status_filter != "Todos") {
      data_filter.status = false;
    }

    if (nameProduct != "") data_filter.name = nameProduct;

    const _this = this;
    const token = await localStorage.getItem("token");

    await axios
      .get(`${process.env.REACT_APP_API_URL}/products/admin`, {
        params: {
          data_filter,
          fields: {
            name: 1,
            estoque: 1,
            price: 1,
            createdAt: 1,
            active: 1,
            urlImg: 1,
            id_sap: 1,
            visible: 1,
          },
          limit: rowsPerPage,
          page: pageFilter,
          orderName: property,
          order: order === "desc" ? -1 : 1,
        },
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data, count } = response.data;
        _this.setState({
          data: data,
          count,
          order,
          orderBy,
          loading: false,
        });
      });
  };

  onSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  handleChangePage = (event, page) => {
    const { page: pagina } = this.state;
    let pageGo = 0;
    if (page > pagina) {
      pageGo = pagina + 1;
    } else {
      pageGo = pagina - 1;
    }
    window.history.pushState(
      "atualpage",
      "TUIM - Admin",
      window.location.pathname + "?page=" + page
    );
    this.setState({ page: pageGo });
    this.filtrarProducts(pageGo);
  };

  createSortHandler = (property) => (event) => {
    this.filtrarProducts(this.state.page, property);
  };

  showAlert = (alertData) => {
    const { msg = "" } = alertData;
    alert(msg);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
    this.filtrarProducts();
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };
  rand() {
    return Math.round(Math.random() * 20) - 10;
  }
  getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  deleteProduct = async () => {
    let { name, id_edit, product_selected_delete, data } = this.state;

    await data.map((item, index) => {
      if (item._id == id_edit) {
        product_selected_delete = index;
      }
    });

    data.splice(product_selected_delete, 1);

    this.setState({
      saving: true,
      loading: false,
      openModalDelete: false,
      data: data,
    });

    const token = await localStorage.getItem("token");

    await axios
      .delete(`${process.env.REACT_APP_API_URL}/product/${id_edit}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({ saving: false, loading: false });
      });
  };
  updateProduct = async () => {
    let { name, active, id_edit, estoque, id_sap, product_selected } =
      this.state;

    let products = this.state.data;

    await products.map((item, index) => {
      if (item._id == id_edit) {
        product_selected = index;
      }
    });
    this.setState({
      saving: true,
      loading: true,
      openModal: false,
    });

    let data = {
      estoque: estoque,
      active: active,
      id_sap: id_sap,
    };

    const token = localStorage.getItem("token");

    products[product_selected].estoque = estoque;
    products[product_selected].active = active;
    products[product_selected].id_sap = id_sap;

    axios
      .patch(`${process.env.REACT_APP_API_URL}/product/${id_edit}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          loading: false,
          data: products,
        });
        this.showAlert({ msg: response.data.message });
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
          loading: false,
        });
      });
  };

  render() {
    const {
      classes,
      data = [],
      loading,
      page,
      rowsPerPage,
      order,
      orderBy,
      saving,
      name,
      active,
      estoque,
      id_sap,
      status_filter,
      sap_filter,
      count,
      nameProduct,
      stockAlert,
    } = this.state;

    const columns = [
      { id: "name", numeric: false, disablePadding: false, label: "Nome" },
      {
        id: "quantity",
        numeric: true,
        disablePadding: false,
        label: "Quantidade",
      },
      { id: "price", numeric: true, disablePadding: false, label: "Preço" },
      {
        id: "createdAt",
        numeric: true,
        disablePadding: false,
        label: "Data de Cadastro",
        align: "center",
      },
      {
        id: "active",
        numeric: true,
        disablePadding: false,
        label: "Ativo",
        align: "center",
      },
      {
        id: "img",
        numeric: true,
        disablePadding: false,
        label: "Img",
        align: "center",
      },
      {
        id: "edit",
        numeric: true,
        disablePadding: false,
        label: "Editar",
        align: "center",
      },
      {
        id: "rm",
        numeric: true,
        disablePadding: false,
        label: "Exibir",
        align: "center",
      },
    ];

    return (
      <>
        <Loading loading={loading} />
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openModal}
          onClose={this.handleClose}
        >
          <div style={this.getModalStyle()} className={classes.paper}>
            <Typography variant="h6" id="modal-title">
              Edição Rápida
            </Typography>
            <Typography variant="h8" id="modal-title" style={{ marginTop: 10 }}>
              {name}
            </Typography>

            <div style={{ width: "100%" }}>
              <TextField
                id="standard-name"
                label="Quantidade em Estoque"
                className={classes.textField}
                value={estoque}
                onChange={(e) => this.setState({ estoque: e.target.value })}
                margin="normal"
              />

              <br />
              <TextField
                id="standard-sap"
                label="ID SAP"
                className={classes.textField}
                value={id_sap}
                onChange={(e) => this.setState({ id_sap: e.target.value })}
                margin="normal"
              />
              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={active}
                    onChange={this.onCheckChange("active")}
                    value="active"
                    color="primary"
                  />
                }
                label="Ativo"
              />
              <br />

              <Button
                variant="contained"
                color="primary"
                // className={classe.buttonClassname}
                disabled={saving}
                onClick={this.updateProduct}
              >
                Salvar
                {saving && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>

            <div style={{ width: "100%" }}></div>
          </div>
        </Modal>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.openModalDelete}
          onClose={() => this.setState({ openModalDelete: false })}
        >
          <div style={this.getModalStyle()} className={classes.paper}>
            <Typography variant="h6" id="modal-title">
              Excluir Produto
            </Typography>
            <Typography
              variant="h8"
              id="modal-title"
              style={{ marginTop: 10, marginBottom: 30 }}
            >
              {name}
            </Typography>

            <div style={{ width: "100%" }}>
              <Button
                variant="contained"
                color="danger"
                // className={classe.buttonClassname}
                disabled={saving}
                onClick={this.deleteProduct}
                style={{ backgroundColor: "red", color: "#fff" }}
              >
                Excluir
                {saving && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </div>

            <div style={{ width: "100%" }}></div>
          </div>
        </Modal>
        <ContentHeader
          title="Produtos"
          buttons={[
            {
              text: "Novo",
              secondary: true,
              onClick: () => this.props.history.push(`/app/product/`),
            },
          ]}
        />

        <ContentContainer>
          <div style={{ display: "flex", marginTop: 0 }}>
            <form
              className={classes.container}
              noValidate
              style={{ display: "flex", width: "100%", marginRight: 20 }}
            >
              <TextField
                id="nameProduct"
                label="Nome do Produto"
                type="text"
                placeholder="Sofá-cama, cadeira, cama..."
                fullWidth
                value={nameProduct}
                onChange={(e) => this.setState({ nameProduct: e.target.value })}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                id="data-contract"
                label="IV SAP"
                type="text"
                placeholder="IV002319"
                style={{ marginLeft: 20, width: 200 }}
                value={sap_filter}
                onChange={(e) => this.setState({ sap_filter: e.target.value })}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <FormControl
                className={classes.formControl}
                style={{ marginLeft: 20, width: 200 }}
              >
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status_filter}
                  onChange={(e) =>
                    this.setState({ status_filter: e.target.value })
                  }
                >
                  <MenuItem value="Todos" selected={true}>
                    Todos
                  </MenuItem>
                  <MenuItem value={true}>Ativo</MenuItem>
                  <MenuItem value={false}>Inativo</MenuItem>
                </Select>
              </FormControl>
            </form>
            <TuimButton
              text="Filtrar"
              onClick={() => {
                this.setState({ order: "", orderBy: null, page: 0 });
                window.history.pushState(
                  "atualpage",
                  "TUIM - Admin",
                  window.location.pathname
                );
                this.filtrarProducts(0);
              }}
            />
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonText="voltar"
            backIconButtonProps={{
              "aria-label": "Página anterior",
            }}
            nextIconButtonText="avanca"
            nextIconButtonProps={{
              "aria-label": "Próxima página",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {columns.map(
                  (row) => (
                    <TableCell
                      key={row.id}
                      align={row.align}
                      padding={row.disablePadding ? "none" : "default"}
                      sortDirection={orderBy === row.id ? order : false}
                    >
                      <Tooltip
                        title="Sort"
                        placement={row.numeric ? "bottom-end" : "bottom-start"}
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order}
                          onClick={this.createSortHandler(row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  ),
                  this
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {data.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())).map(row => ( */}
              {data.map((row, index) => (
                <TableRow
                  key={row._id}
                  hover
                  className={classes.row}
                  onClick={() =>
                    window.open(`/app/product/${row._id}`, "_blank")
                  }
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.estoque}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatter.currency(row.price)}
                  </TableCell>
                  <TableCell align="center">
                    <Moment format="DD/MM/YYYY">{row.createdAt}</Moment>
                  </TableCell>
                  <TableCell align="center">
                    {row.active == true ? <CheckCircle /> : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {row.urlImg != null ? <CheckCircle /> : "-"}
                  </TableCell>
                  <TableCell align="center">
                    <EditIcon
                      onClick={() =>
                        this.editProduct(
                          row._id,
                          row.active,
                          row.name,
                          row.estoque,
                          row.id_sap,
                          index
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    {row.visible == true ? (
                      <CheckCircle style={{ color: "green" }} />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Página anterior",
            }}
            nextIconButtonProps={{
              "aria-label": "Próxima página",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </ContentContainer>
      </>
    );
  }
}

// function desc(a, b, orderBy) {
//    if (b[orderBy] < a[orderBy]) {
//       return -1;
//    }
//    if (b[orderBy] > a[orderBy]) {
//       return 1;
//    }
//    return 0;
// }

// function stableSort(array, cmp) {
//    const stabilizedThis = array.map((el, index) => [el, index]);
//    stabilizedThis.sort((a, b) => {
//       const order = cmp(a[0], b[0]);
//       if (order !== 0) return order;
//       return a[1] - b[1];
//    });
//    return stabilizedThis.map(el => el[0]);
// }

// function getSorting(order, orderBy) {
//    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
// }

ListProducts.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade("#ececec", 0.35),
    "&:hover": {
      backgroundColor: fade("#ececec", 0.8),
    },
    // marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing.unit * 3,
      width: "auto",
    },
  },
  searchIcon: {
    // width: theme.spacing.unit * 1,
    marginLeft: theme.spacing.unit * 1,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
  },
});

export default withStyles(styles)(ListProducts);
