import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';

const Discount = (props) => {

    const [parametro, setParametro] = React.useState('');
    const [percentual, setPercentual] = React.useState('');

    const onChange = (e)=> {
        if(e.target.name=='percentual'){
            setPercentual(e.target.value)
        }
        
        if(e.target.name=='parametro'){
            setParametro(e.target.value)
        }
    }

    return (
        <Dialog open={true} onClose={props.onClose} aria-labelledby="form-dialog-title" fullWidth={true}>
            <DialogContent>
                <TextField
                    onChange={(e)=>setParametro(e.target.value)}
                    autoFocus
                    value={parametro}
                    margin="dense"
                    name="parametro"
                    label="Aplicar após (x) meses"
                    fullWidth
                />
            </DialogContent>
            <DialogContent>
                <TextField
                    onChange={onChange}
                    value={percentual}
                    margin="dense"
                    name="percentual"
                    label="Percentual de desconto (%)"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button style={{cursor: 'pointer', marginRight: 15}} onClick={()=> props.onDiscountAdd({percentual: percentual, parametro})} color="primary">Criar</Button>
                <Button style={{cursor: 'pointer'}} onClick={props.onClose} color="primary">Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

const styles = theme => ({
    container: {

    }
});

export default (withStyles(styles)(Discount))