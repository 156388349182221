export class ImportProductModel {
  constructor(
    id_sap,
    description,
    technicalDescription,
    category,
    features,
    units,
    ambients,
  ) {

    this.product = {
      id_sap,
      description,
      technicalDescription,
      category,
      features,
      units,
      ambients,
    };
  }

  get = async () => {
    if (!this.product.id_sap) {
      return undefined;
    }

    await Promise.all(Object.keys(this.product).map((item, index) => {
        if(typeof this.product[item] === 'undefined' 
            || this.product[item] === "" 
            || this.product[item] === '-'
            || this.product[item] === null
            ){
            delete this.product[item]
        }
    }))
    return this.product
  };
}
