import { Colors } from "../../../../../styles/Colors";

export const cardStyle = {
  root: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    padding: 12,
  },
  barContainer: {
    backgroundColor: "#eee",
    minWidth: 440,
    borderRadius: 12,
    height: 8,
    overflow: "hidden",
  },
  bar: {
    display: "flex",
    height: "100%",
    borderRadius: 12,
    backgroundImage: `linear-gradient(to right, #699053, #90cc69)`,
  },
  kitContainer: {
    backgroundColor: Colors.paleDarkGreen,
    padding: `0px 12px`,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
  },
  kitText: {
    fontSize: 12,
    fontWeight: 500,
    color: "#687a6d",
  },
};
