import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Grid, Toolbar, InputBase, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Numeral from "numeral";
import "numeral/locales/pt-br";
import Moment from 'react-moment';
import TextField from '@material-ui/core/TextField';
import Loading from '../utils/Loading';

import ReactHTMLTableToExcel from 'react-html-table-to-excel';

Numeral.locale('pt-br');

const CustomTable = (data) => {
   return (
      <tr>
         <td>{data.id_subscription}</td>

         <td>{data.price}</td>
      </tr>
   )
}
class Relatorio extends Component {

   constructor(props) {
      super(props)

      const { classes } = props;

      this.state = {
         classes,
         data: [],
         searchTerm: '',
         page: 0,
         rowsPerPage: 12,
         order: 'asc',
         orderBy: 'calories',
         status_filter: '',
         data_inicio_filter: '',
         data_final_filter: '',
         loading: true
      }

      this.onSearchChange = this.onSearchChange.bind(this);

   }

   componentDidMount = async () => {
      const _this = this
      const token = await localStorage.getItem('token')
      console.log("TUIMBOX")
      axios.get(`${process.env.REACT_APP_API_URL}/vindi/reports/bills`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response
            const { bills } = data
            _this.setState({
               data: bills, loading: false
            })
         })

   }

   getBills = async () => {
      const { data_inicio_filter } = this.state
      this.setState({ loading: true })
      const _this = this
      const token = await localStorage.getItem('token')


      await axios.get(`${process.env.REACT_APP_API_URL}/vindi/reports/bills?created_at=${data_inicio_filter}`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response
            const { bills } = data
            _this.setState({
               data: bills, loading: false
            })
         })

      // console.log("DAT AINICIO", data_inicio_filter)
   }
   onSearchChange = (e) => {

      this.setState({
         searchTerm: e.target.value
      })
   }
   handleChange = (event) => {
      this.setState({ status_filter: event.target.value })
   }
   handleChangePage = (event, page) => {
      this.setState({ page });
   };

   createSortHandler = property => event => {
      this.handleRequestSort(event, property);
   };

   handleRequestSort = (event, property) => {
      const orderBy = property;
      let order = 'desc';

      if (this.state.orderBy === property && this.state.order === 'desc') {
         order = 'asc';
      }

      this.setState({ order, orderBy });
   };

   handleChangeRowsPerPage = event => {
      this.setState({ page: 0, rowsPerPage: event.target.value });
   };

   filterBills = () => {

   }

   getStatus = (status) => {
      if (status == 'paid') {
         return 'Pagamento efetuado'
      }
      else if (status == 'canceled') {
         return 'Cancelado'
      }
      else if (status == 'pending') {
         return 'Aguardando Pagamento'
      } else {
         return status;
      }
   }

   render() {


      const { classes, data, searchTerm, data_inicio_filter, loading } = this.state

      const coluns = [
         { id: 'id_subscription', numeric: true, disablePadding: false, label: 'Pedido' },
         { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
         { id: 'period', numeric: true, disablePadding: false, label: 'Periodo' },
         { id: 'price', numeric: true, disablePadding: false, label: 'Preço (R$)' },
         { id: 'createdAt', numeric: true, disablePadding: false, label: 'Data Contrato', align: 'center' },
         { id: 'status', numeric: true, disablePadding: false, label: 'Status', align: 'center' },
         { id: 'status_contract', numeric: true, disablePadding: false, label: 'Status Interno', align: 'center' },
      ];

      return (
         <div>
            <Loading loading={loading} />


            <style jsx global>
               {`
                        .btn {
                            color: rgba(0, 0, 0, 0.87);
                            padding: 6px 16px;
                            font-size: 0.875rem;
                            min-width: 64px;
                            box-sizing: border-box;
                            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                            line-height: 1.75;
                            font-weight: 500;
                            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                            border-radius: 4px;
                            border: none;
                            text-transform: uppercase;
                            color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
    background-color: #e0e0e0;
color: rgba(0, 0, 0, 0.87);
background-color: #00c830;
                          }
                        `}
            </style>
            <Grid container>
               <Grid item xs >
                  <Typography
                     component="h1"
                     variant="h6"
                     color="inherit"
                     noWrap
                     className={classes.title}

                  >Faturas - Tuimbox
                  </Typography>
               </Grid>

            </Grid>


            <Paper className={classes.root}>
               <Toolbar>
                  <div className={classes.search}>
                     <div className={classes.searchIcon}>
                        <SearchIcon />
                     </div>
                     <InputBase
                        placeholder="Procurar por contratos"
                        onChange={this.onSearchChange}
                        value={searchTerm}
                        classes={{
                           root: classes.inputRoot,
                           input: classes.inputInput,
                        }}
                     />
                  </div>
               </Toolbar>




               <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>

                  <span>Filtro</span>
                  <div style={{ display: 'flex', marginTop: 30 }}>

                     <form className={classes.container} noValidate>
                        <TextField
                           id="data-contract"
                           label="Data Inicio"
                           type="date"
                           value={data_inicio_filter}
                           onChange={(e) => this.setState({ data_inicio_filter: e.target.value })}

                           className={classes.textField}
                           InputLabelProps={{
                              shrink: true,
                           }}
                        />

                        {/* <TextField
                                    id="data-contract"
                                    label="Data Final"
                                    type="date"
                                    style={{ marginLeft: 20 }}
                                    value={data_final_filter}
                                    onChange={(e) => this.setState({ data_final_filter: e.target.value })}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /> */}

                        {/* <FormControl className={classes.formControl} style={{ marginLeft: 20 }}>
                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={status_filter}
                                        onChange={(e) => this.setState({ status_filter: e.target.value })}
                                    >
                                        <MenuItem value={'paid'}>Ativo</MenuItem>
                                        <MenuItem value={'canceled'}>Inativo</MenuItem>

                                    </Select>
                                </FormControl> */}

                        <Button variant="contained" color="primary" style={{ marginTop: 10, marginLeft: 50 }} onClick={() => this.getBills()}>
                           Filtrar
                        </Button>
                     </form>
                  </div>

               </div>
               <div style={{ maxWidth: 1200, height: 500, overflow: 'scroll', marginLeft: 20 }}>

                  <table id="table_contracts">
                     <thead>

                        <tr>
                           <th>ID</th>
                           <th>Cliente</th>
                           <th>E-mail</th>
                           <th>Valor</th>
                           <th>Emissão</th>
                           <th>Vencimento</th>
                           <th>Pagamento</th>
                           <th>Status</th>
                           <th>Products</th>
                           <th>Sub Produto</th>

                        </tr>
                     </thead>
                     <tbody>
                        {
                           data.map((item, index) => {
                              const { bill_items, customer, charges } = item;
                              return (

                                 <tr>
                                    <td>{item.id}</td>
                                    <td>{customer.name}</td>
                                    <td>{customer.email}</td>
                                    <td>R$ {item.amount}</td>
                                    <td><Moment format="DD/MM/YYYY" date={new Date(item.created_at)} ></Moment></td>
                                    <td>{item.due_at ? <Moment format="DD/MM/YYYY" date={new Date(item.due_at)} ></Moment> : "Não Tem"}</td>
                                    <td>{charges.length > 0 ? <Moment format="DD/MM/YYYY" date={new Date(charges[0].paid_at)} ></Moment> : "Não Efetuado"}</td>
                                    <td>{this.getStatus(item.status)}</td>
                                    {
                                       bill_items.map((bill_item) => {
                                          return (
                                             <td>{bill_item.product.name}</td>
                                          )
                                       })
                                    }

                                 </tr>
                              )

                           })
                        }
                     </tbody>
                  </table>
               </div>
               <div style={{ paddingTop: 50, paddingBottom: 50, marginLeft: 20 }}>

                  <ReactHTMLTableToExcel
                     className="btn btn-info"
                     table="table_contracts"
                     style={{ backgroundColor: '#000' }}
                     filename="Tuimbox-Faturas"
                     sheet="Sheet"
                     buttonText="Exportar para Excel" />
               </div>
            </Paper >
         </div >



      );
   }
}


function stableSort(array, cmp) {
   const stabilizedThis = array.map((el, index) => [el, index]);
   stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
   });
   return stabilizedThis.map(el => el[0]);
}


Relatorio.propTypes = {
   classes: PropTypes.object.isRequired,
};

const styles = theme => ({
   btn: {
      padding: '5px 20px',
      textAlign: 'center',
      backgroundColor: '#00c830',
      color: "#000",
      borderRadius: 5,
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
      boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);'
   },
   root: {
      width: '100%',
      marginTop: theme.spacing.unit * 2,
      overflowX: 'auto',
   },
   table: {
      minWidth: 700,
   },
   row: {
      cursor: 'pointer'
   },
   fab: {
      margin: 0,
   },
   search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade('#ececec', 0.35),
      '&:hover': {
         backgroundColor: fade('#ececec', 0.80),
      },
      // marginRight: theme.spacing.unit * 2,
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
         // marginLeft: theme.spacing.unit * 3,
         width: 'auto',
      },
   },
   searchIcon: {
      // width: theme.spacing.unit * 1,
      marginLeft: theme.spacing.unit * 1,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   inputRoot: {
      color: 'inherit',
      width: '100%',
   },
   inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 5,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
         width: 200,
      },
   }
});

export default withStyles(styles)(Relatorio);
