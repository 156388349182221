import { ReportCardDivider } from "./ReportCardDivider";
import { ReportCardGroup } from "./ReportCardGroup";
import { ReportCardHeader } from "./ReportCardHeader";
import { ReportCardRoot } from "./ReportCardRoot";
import { ReportCardTable } from "./ReportCardTable";

export const ReportCard = {
   Group: ReportCardGroup,
   Root: ReportCardRoot,
   Header: ReportCardHeader,
   Table: ReportCardTable,
   Divider: ReportCardDivider,
}