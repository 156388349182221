export default {
    preto: '#000000',
    branco: '#ffffff',
    cinza: '#808080',
    cinza11: '#1C1C1C',
    cinza21: '#363636',
    cinza31: '#4F4F4F',
    cinzaEscuro: '#696969',
    prata: '#C0C0C0',
    cinzaClaro: '#D3D3D3',
    gainsboro: '#DCDCDC',
    azulArdosia: '#6A5ACD',
    azulArdosia1: '#836FFF',
    azulGalaxy: '#26457A',
    azulArdosia3: '#6959CD',
    azulArdosiaEescuro: '#483D8B',
    azulMeiaNoite: '#191970',
    azulMarinha: '#000080',
    azulEscuro: '#00008B',
    azulMedio: '#0000CD',
    azul: '#0000FF',
    centaureaAzul: '#6495ED',
    azulReal: '#4169E1',
    azulProfundo: '#00BFFF',
    azulClaro: '#ADD8E6',
    azulAco: '#4682B4',
    azulAcoClaro: '#B0C4DE',
    cinzaArdosia: '#708090',
    cinzaArdosiaClaro: '#778899',
    ciano: '#00FFFF',
    turquesaEscura: '#00CED1',
    turquesa: '#40E0D0',
    turquesaMedia: '#48D1CC',
   //  verdeMar: '#20B2AA',
    cianoEscuro: '#008B8B',
    cerceta: '#008080',
    aquamarinaClara: '#7FFFFD4',
    aquamarinaMedia: '#66CDAA',
    azulCadete: '#5F9EA0',
    verdePrimaveraMedio: '#00FA9A',
    verdePrimavera: '#00FF7F',
    verdePalido: '#98FB98',
    verdeLuz: '#90EE90',
    verdeMarEscuro: '#3CB371',
    verdeMar: '#2E8B57',
    verdeEscuro: '#006400',
    verde: '#008000',
    musgo: '#228B22',
    verdeFloresta: '#228B22',
    verdeLimao: '#32CD32',
    lima: '#00FF00',
    verdeGramado: '#7CFC00',
    verdeAmarelado: '#ADFF2F',
    oliva: '#808000',
    caquiEscuro: '#BDB76B',
    marrom: '#800000',
    siena: '#A0522D',
    marromRosado: '#BC8F8F',
    chocolate: '#D2691E',
    brancoNavajo: '#FFDEAD',
    trigo: '#F5DEB3',
    roxoMedio: '#9370DB',
    violeta: '#8A2BE2',
    violetaEscura: '#9400D3',
    orquideaMedia: '#BA55D3',
    roxo: '#A020F0',
    magentaEscura: '#8B008B',
    magenta: '#FF00FF',
    orquidea: '#DA70D6',
    ameixa: '#DDA0DD',
    rosaEscuro: '#FF1493',
    rosaQuente: '#FF69B4',
    rosaLuz: '#FFB6C1',
    rosa: '#FFC0CB',
    vermelhoEscuro: '#8B0000',
    castanho: '#A52A2A',
    salmao: '#FA8072',
    salmaoEscuro: '#E9967A',
    salmaoClaro: '#FFA07A',
    coral: '#FF7F50',
    tomate: '#FF6347',
    vermelho: '#FF0000',
    laranjaVermelho: '#FF4500',
    laranjaEscuro: '#FF8C00',
    laranja: '#FFA500',
    ouro: '#FFD700',
    amarelo: '#FFFF00',
    caqui: '#F0E68C',
    amendoa: '#bd9e7a',
    nozes: '#E6C795',
    terracota: '#e2725b',
    salvia: '#999e92',
    camelo: '#C1A982',
    tauari: '#C29467'

}
