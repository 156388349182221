import React, { Component } from 'react';
import axios from 'axios';
import PriceList from './PriceList.styled'
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Discount } from '../ui';
import { RemoveButton } from '../ui/components/buttons';
import SaveIcon from '@material-ui/icons/Save';
import { Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Confirm from '../utils/Confirm'

class ListPrice extends Component {
    constructor(props) {
        super(props)

        const { pathname } = props.location
        const arrayPath = pathname.split('/')
        const _id = arrayPath[arrayPath.length - 1];

        const { classes } = props;

        this.state = {
            classes,
            discount: 0,
            data: {
                _id,
                name: '',
                discounts: []
            },
            isDiscountFormOpen: false,
            setOpen: false,
            open: false,   
            editar: false,       
            handleClose: false,
            loading: true,
            searchTerm: ''
        }

        this.onSearchChange = this.onSearchChange.bind(this);
    }

    componentDidMount = () => {

        this.getDiscount()


    }
    getDiscount = async () => {
        const { _id } = this.state.data


        if (!_id) {
            this.setState({ loading: false });
            return;
        }
        const token = await localStorage.getItem('token');
        axios.get(`${process.env.REACT_APP_API_URL}/listDiscount/${_id}`, {
            // axios.get('http://127.0.0.1:3000/kit', { 
            headers: { 'Authorization': "bearer " + token }
        }).then(response => {
            let { data } = response
            this.setState({ data: { ...this.state.data, ...data }, loading: false })
            console.log("oque vem de la", data)
        })

    }
    onDeletePress = async () => {

        const { _id } = this.state.data
        const token = await localStorage.getItem('token');

        axios.delete(`${process.env.REACT_APP_API_URL}/listDiscount/${_id}`, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                this.props.history.push(`/app/listDiscount/`)
            })

    }

    onSearchChange = (e) => {

        this.setState({
            searchTerm: e.target.value
        })
    }
    handleOpen = () => {
        this.setState({
            setOpen: true,
            open: true
        })

    }
    handleClose = () => {
        this.setState({
            setOpen: false,
            open: false
        })
    }
    editDiscount = () => {
        const { data } = this.state
        const { _id } = this.state.data
        const _this = this
        const token = localStorage.getItem('token');
        axios.patch(`${process.env.REACT_APP_API_URL}/listDiscount/${_id}`, data, {
            headers: { 'Authorization': "bearer " + token }
        }).then(response => {
            _this.setState({
                setOpen: true,
                open: true,
                editar: true,

            })
           
        }).catch(error => {

        })



    }
    save = async () => {
        const { data } = this.state
        const token = await localStorage.getItem('token');
        const _this = this

        axios.post(`${process.env.REACT_APP_API_URL}/addDiscount/`, data, {
            headers: { 'Authorization': "bearer " + token },
        })
            .then(response => {
                console.log('data save ', data)
                _this.setState({
                    setOpen: true,
                    open: true,

                })
            }).catch(error => {
                console.log("erro", error)

            })



    }
    onChange = (e) => {

        this.setState({
            data: {
                ...this.state.data,
                [e.target.name]: e.target.value
            }

        })

    }
    removeDiscount = (index) => {
        let { discounts } = this.state.data

        discounts.splice(index, 1)

        this.setState({
            data: {
                ...this.state.data,
                discounts
            }
        })
    }
    compare( a, b ) {
        let numberA = Number(a.parametro);
        let numberB = Number(b.parametro)
        if ( numberA < numberB){
          return -1;
        }
        if ( numberA > numberB){
          return 1;
        }
        return 0;
      }
    onDiscountAdd = (data) => {
        let { discounts = [] } = this.state.data
        const { percentual, parametro } = data



        discounts.push({ percentual, parametro })
        discounts.sort(this.compare)
        this.setState({
            data: {
                ...this.state.data,
                discounts
            },
            isDiscountFormOpen: false
        })
      
       
    }
 
    onChangeDiscount = (e) => {
        const { id, value, name } = e.target
      
        let { data } = this.state
        let { discounts } = data

        discounts[id].percentual = Number(value);
        this.setState({ data: data })
      
    }
    /*  bubbleSort() {
         const {discounts} = this.state.data
         let arr = discounts
         let length = discounts.length
         let parametro = discounts.parametro    
        for (let j = 1; j< length; j++) {
            for (let i = 1; i < length; i++) {                
                if (arr[ i ].parametro > arr[i + 1].parametro) {
                    let temp = arr[ i ].parametro;
                    arr[ i ].parametro = arr[i + 1].parametro;
                    arr[i + 1].parametro = temp
                }
                console.log('aq3',i,length)
                console.log('aq3',arr)
            }
            console.log('aq2',j,length)
           length--;
        }
        this.setState({
            data: {
                ...this.state.data,
                discounts: arr
            }
        })
        console.log('aq1',arr)
       
      
        
       
    } */
    
 

    render() {

        const { classes, data, searchTerm, open, isDiscountFormOpen, handleClose,editar} = this.state
        console.log("salve ", data)
       //console.log(this.bubbleSort([3,9,2,29,1]))

        return (
            <PriceList>
                <Paper className={classes.root}>
                    <div className='containerOne'>
                        <div className='items'>
                            <label>Nome</label>
                            <input name='name' onChange={this.onChange} value={data.name} type='text' placeholder='nome' />
                        </div>
                        <div className='itemsDiscount'>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: `wrap` }}>
                                {data.discounts.map((item, index) => (
                                    <div key={index} style={{ position: 'relative', display: 'flex', flexDirection: 'column', marginRight: 20 }}>
                                        <label>{`Acima de ${item.parametro} meses`}</label>
                                        <div style={{ position: 'relative', width: '100%' }}>
                                            {data._id == '' ?
                                                <TextField
                                                    key={index}
                                                    value={item.percentual}
                                                    onChange={this.onChange}
                                                    name='price' placeholder='%'
                                                    id="teste"
                                                    title={`Acima de ${item.parametro} meses`}
                                                    style={{ maxWidth: 120, marginRight: 15 }}
                                                />
                                                :
                                                <TextField
                                                    key={index}
                                                    value={item.percentual}
                                                    // onClick={() => this.setState({ editar: true })}
                                                    name='price' placeholder='%'
                                                    id={index}
                                                    index={index}
                                                    onChange={this.onChangeDiscount}
                                                    title={`Acima de ${item.parametro} meses`}
                                                    style={{ maxWidth: 120, marginRight: 15 }}
                                                />
                                            }
                                            <RemoveButton onClick={() => this.removeDiscount(index)} style={{ position: 'absolute', top: '-3px', right: 10 }} />
                                        </div>                                   
                                    </div>

                                ))}
                                <div onClick={() => this.setState({ isDiscountFormOpen: true })} style={{ padding: 7, borderWidth: .5, borderStyle: 'dashed', cursor: 'pointer', borderRadius: 5, marginBottom: 15, fontSize: 12 }}>+ Desconto</div>
                            </div>
                            {isDiscountFormOpen &&
                            <Discount
                                onClose={() => this.setState({ isDiscountFormOpen: false })}
                                onDiscountAdd={this.onDiscountAdd}
                            />
                        }



                        </div>


                    </div>
                    <div className="containerButton">
                        <Button
                            variant="contained"
                            color="primary"
                            // className={classe.buttonClassname}
                            /* disabled={saving} */
                            onClick={data._id == '' ? this.save : this.editDiscount}>
                            <SaveIcon /* className={classNames(classes.leftIcon, classes.iconSmall)}  */ />
                        Salvar

                    </Button>

                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            open={open}
                            onClose={handleClose}

                        >
                            <Fade in={open}>
                                <div style={{
                                    backgroundColor: '#fff',
                                    border: '2px solid #000',
                                    boxShadow: 5,
                                    padding: 10,
                                    color: '#000',
                                }}>
                                    {
                                        editar == true ? <p id="transition-modal-description">Desconto editado com sucesso</p> : <p id="transition-modal-description">Desconto criado com sucesso</p>

                                    }
                                    
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Button style={{ color: '#000' }} onClick={() => this.setState({ open: false, setOpen: false })}>Ok</Button>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>


                        <Button variant="contained"
                            style={{ marginLeft: 10, backgroundColor: '#f44336', color: '#FFF', left: 836 }}
                            className={classes.button}
                            onClick={() => this.setState({ isConfirmOpen: true })}>
                            Excluir
                                </Button>
                        {this.state.error && <Typography>{this.state.error}</Typography>}

                        <Confirm open={this.state.isConfirmOpen} title='Exclusão de desconto' text='Deseja realmente excluir este desconto?'
                            onDeletePress={this.onDeletePress} onClose={() => this.setState({ isConfirmOpen: false })}
                        />

                    </div>

                </Paper>
            </PriceList>

        );
    }
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        cursor: 'pointer'
    },
    fab: {
        margin: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade('#ececec', 0.35),
        '&:hover': {
            backgroundColor: fade('#ececec', 0.80),
        },
        // marginRight: theme.spacing.unit * 2,
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing.unit * 3,
            width: 'auto',
        },
    },
    searchIcon: {
        // width: theme.spacing.unit * 1,
        marginLeft: theme.spacing.unit * 1,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        paddingTop: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 5,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    }
});




export default withStyles(styles)(ListPrice);
