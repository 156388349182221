import { Box } from "@material-ui/core";
import React from "react";
import { ContentContainer } from '../../layout/components/contentContainer';

export function ReportCardGroup({ children, title = '' }) {
  return (
    <ContentContainer label={title}>
      <Box style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
        gap: '14px',
      }}>
        {children}
      </Box>
    </ContentContainer>
  )
}