import { Box } from "@material-ui/core";
import axios from "axios";
import React, { useRef, useState } from "react";
import { formatter } from "../../functions";
import { useFetch } from "../../hooks/useFetch";
import { Error } from "../error/error";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Rows } from "../layout/components/rows";
import Loading from "../utils/Loading";

const additionalServices = {
  appliances: "Eletrodomésticos",
  decoration: "Decoração",
  kits: "Kits",
};

const QuotationDetails = (props) => {
  const quotationId = props.match.params.id;
  const token = localStorage.getItem("token");
  const quotaionDataRef = useRef(null);
  const [modal, setModal] = useState(false);

  const {
    data = {},
    error,
    isLoading,
  } = useFetch({
    url: `v2/quotations/${quotationId}`,
  });
  if (error) return <Error />;

  const handleModal = () => setModal((prev) => !prev);

  const excludeQuotation = async () => {
    handleModal();
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/v2/quotations/${quotationId}`,
        {
          headers: { Authorization: "bearer " + token },
        }
      );

      if (!data.success) return alert("Erro ao tentar excluir cotação.");

      alert(data.msg);
      props.history.push("/app/quotations");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Loading loading={isLoading} />
      <ContentHeader
        title={
          data.id_user?.name ? `Cotação de ${data.id_user.name}` : "Cotação"
        }
        subtitle={
          data?.createdAt
            ? `Registrada em ${formatter.date(data.createdAt)}`
            : ""
        }
        buttons={[
          {
            text: "Excluir",
            destructive: true,
            onClick: handleModal,
          },
        ]}
      />
      <Box
        style={{
          display: "flex",
          gap: 24,
        }}
      >
        <Box
          ref={quotaionDataRef}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
            flex: 1,
          }}
        >
          <ContentContainer label="Cliente" fullWidth>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 28,
              }}
            >
              <Rows
                rowGap={8}
                data={[
                  {
                    label: "Nome",
                    description: data.id_user?.name,
                  },
                  {
                    label: "E-mail",
                    description: data.id_user?.email,
                  },
                  {
                    label: "Celular",
                    description: data.id_user?.mobileNumber,
                  },
                  {
                    label: "Período",
                    description: data.period,
                  },
                  {
                    label: "Empresa",
                    description: data.company,
                  },
                  ...(data.notes
                    ? [
                        {
                          label: "Observações",
                          labelPosition: "flex-start",
                          description: data.notes,
                        },
                      ]
                    : []),
                ]}
              />
            </Box>
          </ContentContainer>
          <ContentContainer label="Imóvel">
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 28,
              }}
            >
              <Rows
                rowGap={8}
                data={[
                  {
                    label: "Endereço",
                    description: data.address,
                  },
                  ...(data.propertyValueRange
                    ? [
                        {
                          label: "Valor aprox.",
                          description: data.propertyValueRange,
                        },
                      ]
                    : []),
                  {
                    label: "Metragem",
                    description: data.dimension,
                  },
                  {
                    label: "Cômodos",
                    description:
                      data.quotationFor === "allRooms"
                        ? "Todos"
                        : data.quotationFor,
                  },
                  ...(data.furnishedRooms
                    ? [
                        {
                          label: "Cômodos mobiliados",
                          description: data.furnishedRooms,
                        },
                      ]
                    : []),
                  ...(data.additionalServices?.length > 0
                    ? [
                        {
                          label: "Adicionais",
                          description: data.additionalServices
                            ?.map((service) => additionalServices[service])
                            ?.join(", "),
                        },
                      ]
                    : []),
                ]}
              />
            </Box>
          </ContentContainer>
        </Box>

        <ContentContainer
          label={`Imagens (${
            data?.imgs?.length + data?.blueprints?.length || 0
          })`}
          style={{
            width: 300,
            maxHeight: quotaionDataRef?.current?.clientHeight || 0,
            overflow: "auto",
          }}
        >
          <Box
            style={{
              flex: 1,
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            {data.blueprints?.concat(data?.imgs)?.map((item) => (
              <Box
                key={item}
                style={{
                  position: "relative",
                  marginRight: 4,
                }}
              >
                <img
                  src={item}
                  width={"100%"}
                  style={{ cursor: "pointer", borderRadius: 8 }}
                  onClick={() => window.open(item, "_blank")}
                />
                {/* <Box
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    // height: 20,
                    backgroundColor: "lightblue",
                    display: "flex",
                    gap: 8,
                  }}
                >
                </Box> */}
              </Box>
            ))}
          </Box>
        </ContentContainer>
      </Box>

      {modal && (
        <>
          <Box style={{ zIndex: 9999 }}>
            <Box
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "#00000044",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 99999,
              }}
            >
              <ContentContainer
                style={{ maxHeight: "80%", position: "relative" }}
                gap={24}
              >
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 14,
                    minWidth: 360,
                    marginTop: 32,
                  }}
                >
                  <h3 style={{ textAlign: "center" }}>
                    Tem certeza que deseja excluir essa cotação?
                  </h3>
                </Box>

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 10,
                    marginTop: 12,
                  }}
                >
                  <TuimButton text={"Cancelar"} onClick={handleModal} />
                  <TuimButton
                    destructive
                    text={"Excluir cotação"}
                    onClick={excludeQuotation}
                  />
                </Box>
              </ContentContainer>
            </Box>
            )
          </Box>
        </>
      )}
    </>
  );
};

export default QuotationDetails;
