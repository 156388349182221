import axios from "axios";
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TextEditor = ({ doc, setDoc, uploadImg }) => {
  const token = localStorage.getItem("token");

  const customContentStateConverter = (contentState) => {
    const newBlockMap = contentState.getBlockMap().map((block) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey !== null) {
        const entityBlock = contentState.getEntity(entityKey);
        const entityType = entityBlock.getType();
        switch (entityType) {
          case 'IMAGE': {
            const newBlock = block.merge({
              type: 'atomic',
              text: 'img',
            });
            return newBlock;
          }
          default:
            return block;
        }
      }
      return block;
    });
    const newContentState = contentState.set('blockMap', newBlockMap);
    return newContentState;
  }

  const [editorState, setEditorState] = useState(() => {
    if (doc.htmlContent) {
      const blocksFromHTML = convertFromHTML(doc.htmlContent);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const newContentState = customContentStateConverter(contentState);
      return EditorState.createWithContent(newContentState);
    } else {
      return EditorState.createEmpty();
    }
  });


  const processUpload = async (uploadedFile) => {
    const data = new FormData();
    data.append("file", uploadedFile.file, uploadedFile.name);

    const upload = await axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        return data.url
      })
      .catch((error) => console.log("error", error));

    return upload
  };


  async function uploadCallback(file) {
    const newFile = {
      file,
      id: 0,
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }

    const linkFile = await processUpload(newFile).then(response => {
      return response
    })

    return new Promise(
      (resolve, reject) => {
        resolve({ data: { link: linkFile } });
      }
    );
  }


  useEffect(() => {
    setDoc({
      ...doc,
      htmlContent: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState]);


  return (
    <>
      <style jsx>
        {`.demo-editor {
          background-color: #fff;
        }
        .demo-editor img {
          max-width: 100%;
        }
        `}
      </style>

      <div>
        <Editor
          editorState={editorState}
          editorStyle={{
            border: "1px solid #ddd",
            borderRadius: 6,
            minHeight: 280,
          }}
          localization={{ locale: 'pt' }}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={setEditorState}
          toolbar={{
            image: {
              urlEnabled: true,
              uploadEnabled: true,
              previewImage: true,
              uploadCallback
            }
          }}
        />
      </div>
    </>
  );
};

export default TextEditor;
