export const getCartPrice = (cart) => {
  const { products, period } = cart;

  const discountProducts = products.map((item) => {
    return {
      ...item,
      discounts: item.discounts
        .filter((item) => {
          if (item.parametro <= period) return item;
        })
        .reduce(function (max, x) {
          return x.percentual > max ? x.percentual : max;
        }, 0),
    };
  });

  return parseFloat(
    discountProducts
      .map(
        (item) =>
          item.price * item.qtd -
          ((item.price * item.qtd) / 100) * item.discounts
      )
      .reduce((a, b) => a + b, 0)
      .toFixed(2),
    2
  );
};

export const getQtdProductsCart = (cart) => {
  let qtd = 0;
  cart.products.map((product) => (qtd += product.qtd));

  return qtd;
};

const applyDiscountByPeriod = (period, price, discounts, qtd) => {
  //caso for 3 meses acrescentar a % de disconto
  if (period <= 5) {
    return Math.abs(discounts) > 0
      ? (price + price * (Math.abs(discounts) / 100)) * qtd
      : price * qtd;
  }

  if (period >= 6) {
    return Math.abs(discounts) > 0
      ? (price - price * (Math.abs(discounts) / 100)) * qtd
      : price * qtd;
  }
};

export const getCartPriceItem = (cart) => {
  let price = 0;
  const { products, period } = cart;

  products.map((product, i) => {
    const discountProduct = {
      ...product,
      discounts: product?.product?.discounts
        ?.filter((item) => {
          if (item.parametro <= period) {
            return item;
          }
        })
        .reduce(function (max, x) {
          return x.percentual > max ? x.percentual : x.percentual;
        }, 0),
    };
    const productPrice = discountProduct.product.price;
    const productDiscounts = discountProduct.discounts;
    const productQtd = product.qtd;

    if (product.product.discount > 0) {
      const priceByPeriod = applyDiscountByPeriod(
        period,
        productPrice,
        productDiscounts,
        productQtd
      );
      const discount = (priceByPeriod * product.product.discount) / 100;

      return (price += priceByPeriod - discount);
    } else if (period <= 5) {
      return (price += applyDiscountByPeriod(
        period,
        productPrice,
        productDiscounts,
        productQtd
      ));
    } else {
      return (price += applyDiscountByPeriod(
        period,
        productPrice,
        productDiscounts,
        productQtd
      ));
    }
  });

  return Math.ceil(price);
};

export const getDeliveryPrice = async ({
  cart,
  homeOffice = false,
  delivery_exemption,
  checkoutPriceWithoutDiscount,
  freightPriceWithoutDiscount,
}) => {
  if (homeOffice) return 0;

  if (
    Boolean(
      (checkoutPriceWithoutDiscount * cart.period -
        (delivery_exemption?.price || 0)) /
        cart.period >
        0
    )
  ) {
    return 0;
  }
  return freightPriceWithoutDiscount;
};

export const arredondar = (nr) => Math.round(nr, -1).toFixed(2);

export const getPriceByProductItem = (product, period, qtd = 1) => {
  const discountProduct = {
    ...product,
    discounts: product?.discounts
      ?.filter((item) => {
        if (item.parametro <= period) {
          return item;
        }
      })
      .reduce(function (max, x) {
        return x.percentual > max ? x.percentual : x.percentual;
      }, 0),
  };

  //caso for 3 meses acrescentar a % de desconto
  if (period <= 5) {
    return Math.floor(
      qtd *
        (discountProduct.price +
          discountProduct.price * (Math.abs(discountProduct.discounts) / 100))
    );
  }
  return discountProduct.discounts > 0
    ? Math.floor(
        qtd *
          (discountProduct.price -
            discountProduct.price * (discountProduct.discounts / 100))
      )
    : Math.floor(qtd * discountProduct.price);
};
