export const masks = (value, field) => {

  if (field === "cep") {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{5})(\d{3})/, "$1-$2");
    return value;
  }

  if (field === "receivePhoneDelivery" || field === "mobileNumber") {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");

    return value;
  }

  if (field === "cpf") {
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

    return value;
  }

  if (field === "uf") {
    value = value.toUpperCase();

    return value;
  }

  return value;
};
