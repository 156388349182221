import { Box, Typography as Text } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ReportContainer } from "..";
import { formatter } from "../../../../functions";
import { Colors } from "../../../../styles/Colors";
import { ContentContainer } from "../../../layout/components/contentContainer";

export const Evolution = ({ data }) => {
  const [paymentsAccumulator, setPaymentsAccumulator] = useState(null);
  const [xaxis, setXaxis] = useState(null);

  useEffect(() => {
    if (!data) return setXaxis(null);

    const xaxis = [];
    Object.keys(data.data).forEach((month) => {
      xaxis.push(month);
    });
    setXaxis(xaxis.sort());
    setPaymentsAccumulator(
      data.paymentsAccumulator.sort((a, b) => (a.month < b.month ? -1 : 1))
    );
  }, [data]);

  if (!xaxis) return <></>;

  return (
    <ReportContainer.Root>
      <>
        <ContentContainer label="Fechamento">
          <Chart
            options={{
              chart: {
                id: "faturamento",
                toolbar: { show: false },
              },
              xaxis: { categories: xaxis },
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return formatter.currency(value);
                  },
                },
              },
              stroke: {
                curve: "smooth",
                width: 2,
              },
              colors: [Colors.lightGreen],
              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.7,
                  opacityTo: 0.9,
                  stops: [0, 90, 100],
                },
              },
              dataLabels: { enabled: false },
            }}
            series={[
              {
                name: "Faturamento",
                data: xaxis.map(
                  (month) => data.data[month].statistics.income.estimated.all
                ),
              },
            ]}
            type="area"
            width="100%"
            height={320}
          />
        </ContentContainer>

        <ContentContainer label="Faturamento">
          <Chart
            options={{
              chart: {
                id: "faturamento",
                toolbar: { show: false },
              },
              xaxis: { categories: xaxis },
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return formatter.currency(value);
                  },
                },
              },
              stroke: {
                curve: "smooth",
                width: 2,
              },
              colors: [Colors.lightGreen],
              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.7,
                  opacityTo: 0.9,
                  stops: [0, 90, 100],
                },
              },
              dataLabels: { enabled: false },
            }}
            series={[
              {
                name: "Faturamento",
                data: xaxis.map(
                  (month) => data.data[month].statistics.income.all
                ),
              },
            ]}
            type="area"
            width="100%"
            height={320}
          />
        </ContentContainer>
        {paymentsAccumulator && (
          <ContentContainer label={`Faturamento (acumulado)`}>
            <Chart
              options={{
                chart: {
                  id: "faturamento",
                  toolbar: { show: false },
                },
                xaxis: { categories: xaxis },
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return formatter.currency(value);
                    },
                  },
                },
                stroke: {
                  curve: "smooth",
                  width: 2,
                },
                colors: [Colors.lightGreen],
                fill: {
                  type: "gradient",
                  gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100],
                  },
                },
                dataLabels: { enabled: false },
              }}
              series={[
                {
                  name: "Faturamento",
                  data: paymentsAccumulator.map((item) => item.sum),
                },
              ]}
              type="area"
              width="100%"
              height={320}
            />
          </ContentContainer>
        )}
        <ContentContainer label="Ticket Médio">
          <Chart
            options={{
              chart: {
                id: "ticket-medio",
                stacked: true,
                toolbar: {
                  show: false,
                },
              },
              xaxis: {
                categories: xaxis,
              },
              yaxis: {
                labels: {
                  formatter: function (value) {
                    return formatter.currency(value);
                  },
                },
              },
              stroke: {
                curve: "smooth",
                width: 2,
              },
              colors: [Colors.lightGreen, Colors.darkGreen],
              fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.7,
                  opacityTo: 0.9,
                  stops: [0, 90, 100],
                },
              },
              dataLabels: {
                enabled: false,
              },
            }}
            series={[
              {
                name: "Ticket Médio",
                data: xaxis.map(
                  (month) => data.data[month].statistics.average.ticket
                ),
              },
              {
                name: "Ticket Médio Residencial",
                data: xaxis.map(
                  (month) =>
                    data.data[month].statistics.average.residentialTicket
                ),
              },
            ]}
            type="area"
            width="100%"
            height={320}
          />
        </ContentContainer>
      </>
    </ReportContainer.Root>
  );
};
