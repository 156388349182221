import React from "react";
import { Box } from "@material-ui/core";
import { Text } from "../text";
import { TuimButton } from "../../Button";

export const Modal = ({
  message,
  itemName,
  cancelButtonFunction,
  actionButtonFunction,
  actionButtonText,
  actionTuimButtonType,
  cancelTuimButtonType,
}) => {
  return (
    <Box
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9,
        background: "rgba(0, 0, 0, 0.50)",
      }}
    >
      <Box
        style={{
          padding: 30,
          borderRadius: 20,
          background: "#fff",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          textAlign: "center",
        }}
      >
        <Text>{message}</Text>
        {itemName && (
          <Text strong style={{ fontSize: 20, margin: "30px 0" }}>
            {itemName}
          </Text>
        )}

        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: 40,
          }}
        >
          {cancelTuimButtonType ? (
            <TuimButton
              onClick={cancelButtonFunction}
              text="Cancelar"
              cancelTuimButtonType
            />
          ) : (
            <TuimButton text="Cancelar" onClick={cancelButtonFunction} />
          )}

          {actionTuimButtonType === "destructive" && (
            <TuimButton
              onClick={actionButtonFunction}
              text={actionButtonText}
              destructive
            />
          )}

          {!actionTuimButtonType && (
            <TuimButton
              onClick={actionButtonFunction}
              text={actionButtonText}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
