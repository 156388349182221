import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CheckCircle from "@material-ui/icons/CheckCircle";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Colors } from "../../styles/Colors";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Text } from "../layout/components/text";
import Loading from "../utils/Loading";

class ListPartners extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      loading: true,
      keys: [],
      saveOrderChange: false,
    };
  }

  componentDidMount = async () => {
    const _this = this;

    const token = await localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/partners/all`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          data,
          keys: data,
        });

        _this.setState({
          loading: false,
        });
      });
  };

  reorder = ({ partnerId, target, sideEffect }) => {
    const _keys = this.state.keys;

    _keys.splice(sideEffect, 1, _keys[target]);
    _keys.splice(target, 1, partnerId);

    this.setState({ keys: _keys, saveOrderChange: true });
  };

  move = {
    up: (args) =>
      this.reorder({
        partnerId: args.partnerId,
        target: args.order - 1,
        sideEffect: args.order,
      }),

    down: (args) =>
      this.reorder({
        partnerId: args.partnerId,
        target: args.order + 1,
        sideEffect: args.order,
      }),
  };

  handleSaveOrder = async () => {
    const token = await localStorage.getItem("token");

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/v2/partners/reorder`,
        {
          keys: this.state.keys,
        },
        {
          headers: { Authorization: "bearer " + token },
        }
      );

      if (!response.data.success)
        throw new Error("Error when try reorder partners");
      alert("Atualizado com sucesso!");
    } catch (error) {
      console.log(error);
      alert("Erro ao tentar atualizar a orderm dos parceiros");
    }
  };

  render() {
    const { classes, loading, keys, saveOrderChange } = this.state;

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title="Parceiros"
          buttons={[
            {
              text: "Novo",
              onClick: () => this.props.history.push(`/app/partner/`),
            },
            ...(saveOrderChange
              ? [
                  {
                    text: "Salvar",
                    onClick: this.handleSaveOrder,
                    secondary: true,
                  },
                  {
                    text: "Cancelar",
                    onClick: () => window.location.reload(),
                    tertiary: true,
                  },
                ]
              : []),
          ]}
        />

        <ContentContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Ordem</TableCell>
                <TableCell>Parceiros</TableCell>
                <TableCell align="center">Nome</TableCell>
                <TableCell align="center">URL</TableCell>
                <TableCell align="center">Ativo</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {keys.map((row, index) => (
                <TableRow
                  key={row._id}
                  hover
                  onClick={() =>
                    this.props.history.push(`/app/partner/${row._id}`)
                  }
                  className={classes.row}
                >
                  <TableCell component="th" scope="row" align="center">
                    <Text>{row.order}</Text>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Text>{row.name}</Text>
                  </TableCell>
                  <TableCell align="center">
                    <Text>{row.name}</Text>
                  </TableCell>
                  <TableCell align="center">
                    <Text>{row.urlSeo}</Text>
                  </TableCell>
                  <TableCell align="center">
                    {row.active ? (
                      <CheckCircle style={{ color: Colors.lightGreen }} />
                    ) : (
                      <Text>-</Text>
                    )}
                  </TableCell>
                  <TableCell align="center" aria-label="Data de Criação">
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      {index > 0 && (
                        <IoIosArrowUp
                          style={{ color: "#aaa" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.move.up({ partnerId: row, order: index });
                          }}
                          size={18}
                        />
                      )}
                      {keys.length - 1 !== index && (
                        <IoIosArrowDown
                          style={{ color: "#aaa" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.move.down({ partnerId: row, order: index });
                          }}
                          size={18}
                        />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ContentContainer>
      </>
    );
  }
}

ListPartners.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
});

export default withStyles(styles)(ListPartners);
