import { Box, Link } from "@material-ui/core";
import React from "react";
import { cardStyle } from "./card.styles";
import { KitLabel } from "./components/KitLabel";
import { Text } from "../../../../layout/components/text";

export const Card = ({ data, rankPosition }) => {
  return (
    <Box style={cardStyle.root}>
      <Text strong style={{ width: 36 }}>
        {`${rankPosition}º`}
      </Text>
      <Box style={{ display: "flex", gap: 20, flex: 1 }}>
        <Link
          href={
            data.product.kit
              ? `/app/kit/${data.product._id}`
              : `/app/product/${data.product._id}`
          }
        >
          <Text>{data.product.name}</Text>
        </Link>
        {data.product.kit && <KitLabel />}
      </Box>
      <Box style={cardStyle.barContainer}>
        <Box
          style={{
            ...cardStyle.bar,
            width: data.barWidth,
          }}
        ></Box>
      </Box>
      <Text detail style={{ width: 32, textAlign: "right" }}>
        {data.sum}
      </Text>
    </Box>
  );
};
