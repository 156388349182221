import React, { Component } from 'react'
import axios from 'axios'
import {
   Paper, Typography, Button, FormControl, InputLabel, Input, CircularProgress
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';

class EditProfile extends Component {

   constructor(props) {
      super(props)

      const { classes } = props;

      this.state = {
         classes,
         data: {
            _id: null,
            name: '',
            email: '',
            password: ''
         },
         saving: false,
         success: false,
         error: null,
         anchorEl: null,
         open: false,
         showAlert: false,
         showDeleteUserAlert: false
      }

      this.onChange = this.onChange.bind(this);
   }

   componentDidMount = async () => {

      const token = await localStorage.getItem('token');
      const _id = await localStorage.getItem('_id');
      const _this = this

      axios.get(`${process.env.REACT_APP_API_URL}/user/${_id}`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response

            console.log('data', data)

            _this.setState({
               data
            })
         })
   }

   onChange = (e) => {


      const { data } = this.state
      this.setState({
         data: {
            ...data,
            [e.target.name]: e.target.value
         },
         success: false
      })
   }

   onCheckChange = name => event => {

      const { data } = this.state

      this.setState({
         data: {
            ...data,
            [name]: event.target.checked
         },
         success: false
      });
   };

   onSavePress = async () => {

      const { data } = this.state

      this.setState({
         saving: true
      })

      const token = await localStorage.getItem('token');

      axios.patch(`${process.env.REACT_APP_API_URL}/user/${data._id}`, data, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            this.setState({
               saving: false,
               success: true
            })

            this.props.history.goBack()
         })
         .catch(error => {
            this.setState({
               saving: false,
               success: false,
               error: error.response
            })
         })
   }

   onCancelPress = () => {
      this.props.history.goBack()
   }

   render() {

      const { classes, saving, success } = this.state
      const { data } = this.state

      const buttonClassname = classNames({
         [classes.buttonSuccess]: success,
      });

      return (
         <Paper className={classes.paper}>
            <Typography component="h1" variant="h5">
               {data.name}
            </Typography>
            <form className={classes.form}>
               <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">Nome</InputLabel>
                  <Input id="name" name="name" autoFocus value={data.name}
                     onChange={this.onChange}
                  />
               </FormControl>
               <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email">E-mail</InputLabel>
                  <Input id="email" name="email" autoComplete="email" value={data.email}
                     onChange={this.onChange}
                  />
               </FormControl>
               <div className={classes.wrapper}>
                  <Button
                     variant="contained"
                     color="primary"
                     className={buttonClassname}
                     disabled={saving}
                     onClick={this.onSavePress}
                  >
                     <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                     Salvar
                     {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                  <Button variant="contained" color="default" className={classes.button}
                     onClick={this.onCancelPress}
                  >Cancelar
                  </Button>
                  {this.state.error && <Typography>{this.state.error}</Typography>}
               </div>
            </form>
         </Paper>
      )
   }
}

const styles = theme => ({
   main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
         width: 400,
         marginLeft: 'auto',
         marginRight: 'auto',
      },
   },
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
   },
   avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing.unit,
   },
   button: {
      marginLeft: theme.spacing.unit
   },
   leftIcon: {
      marginRight: theme.spacing.unit,
   },
   success: {
      backgroundColor: green[600],
   },
   buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   wrapper: {
      marginTop: theme.spacing.unit * 3,
      position: 'relative',
   },
   buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
         backgroundColor: green[700],
      },
   },
   titleName: {
      flex: 1
   },
   titleBar: {
      flexDirection: 'row'
   }
});

export default (withStyles(styles)(EditProfile))