import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow, Paper, Table, Typography, Grid } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import axios from 'axios';
import Loading from '../utils/Loading';

class ListHs extends Component {
   constructor(props) {
      super(props)

      const { classes } = props;

      this.state = {
         classes,
         data: [],
         loading: true
      }
   }

   componentDidMount = async () => {

      const _this = this

      const token = await localStorage.getItem('token');

      axios.get('https://api.tuim.com.br/partnershipByType/home-staging', {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {

            const { data } = response
            _this.setState({
               data
            })

            _this.setState({
               loading: false
            })
         })
   }

   render() {

      const { classes, data, loading } = this.state

      return (
         <div>
            <Loading loading={loading} />

            <Grid container>
               <Grid item xs >
                  <Typography
                     component="h1"
                     variant="h6"
                     color="inherit"
                     noWrap
                     className={classes.title}

                  >Corretores
                  </Typography>
               </Grid>

            </Grid>

            <Paper className={classes.root}>
               <Table className={classes.table}>
                  <TableHead>
                     <TableRow>
                        <TableCell>Corretores</TableCell>
                        <TableCell align="center">Nome</TableCell>
                        <TableCell align="center">E-mail</TableCell>

                        <TableCell align="center">CPF</TableCell>
                        <TableCell align="center">RG</TableCell>
                        <TableCell align="center">Ativo</TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {data.map(row => (
                        <TableRow
                           key={row._id}
                           hover
                           onClick={() => this.props.history.push(`/app/partnership/home-staging/${row._id}`)}
                           className={classes.row}
                        >
                           <TableCell component="th" scope="row">
                              {row.name}
                           </TableCell>
                           <TableCell align="center">{row.name}</TableCell>
                           <TableCell align="center">{row.email}</TableCell>

                           <TableCell align="center">{row.cpf}</TableCell>
                           <TableCell align="center">{row.rg}</TableCell>

                           <TableCell align="center">{row.active ? <CheckCircle /> : '-'}</TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </Paper>
         </div>

      );
   }
}

ListHs.propTypes = {
   classes: PropTypes.object.isRequired,
};

const styles = theme => ({
   root: {
      width: '100%',
      marginTop: theme.spacing.unit * 2,
      overflowX: 'auto',
   },
   table: {
      minWidth: 700,
   },
   row: {
      cursor: 'pointer'
   },
   fab: {
      margin: 0,
   }
});

export default withStyles(styles)(ListHs);
