import React, { useEffect, useState } from "react";
import ContentHeader from "../layout/components/contentHeader";
import Loading from "../utils/Loading";
import { ReportContainer } from "./ReportContainer";
import {
  ByCategory,
  Evolution,
  History,
  Monthly,
  PartnersPerformance,
  SitePerformance,
} from "./ReportContainer/Sections";
import { loadReport } from "./ReportContainer/Sections/Helpers";
import { Text } from "../layout/components/text";
import { Box } from "@material-ui/core";

const sectionComponents = {
  monthly: {
    Component: (props) => <Monthly {...props} />,
    dateSelectorType: "month",
    title: "Relatório Mensal",
    group: "month",
    subGroup: "status",
  },
  byCategory: {
    Component: (props) => <ByCategory {...props} />,
    dateSelectorType: "month",
    title: "Relatório Categorizado",
    group: "partnership",
    subGroup: "status",
  },
  history: {
    Component: (props) => <History {...props} />,
    dateSelectorType: "date",
    title: "Compilado Histórico",
    group: "status",
    subGroup: "status",
    status: "active,finished",
  },
  evolution: {
    Component: (props) => <Evolution {...props} />,
    dateSelectorType: "date",
    title: "Relatório Evolutivo",
    group: "month",
    subGroup: "status",
    status: "active,finished",
    includesAccumulator: true,
  },
  sitePerformance: {
    Component: (props) => <SitePerformance {...props} />,
    dateSelectorType: "month",
    title: "",
    group: "",
  },
  partnersPerformance: {
    Component: (props) => <PartnersPerformance {...props} />,
    dateSelectorType: "month",
    title: "Desempenho de Parceiros",
    group: "partnership",
    subGroup: "partner",
    status: "active,finished",
  },
};

export default function ContractReport(props) {
  const { section = "monthly" } = props;

  const {
    Component,
    dateSelectorType,
    group,
    subGroup,
    status,
    title,
    includesAccumulator = false,
  } = sectionComponents[section];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    document.title = `Tuim Admin - ${title}`;
  }, []);

  async function generateReport(filters) {
    if (data) setData(null);

    const { startDate, endDate, baseDate } = filters;
    if (!startDate || !endDate) return;

    setLoading(true);
    const response = await loadReport({
      startDate,
      endDate,
      baseDate,
      group,
      subGroup,
      status,
      includesAccumulator,
    });
    setData(response);
    setLoading(false);
  }

  return (
    <>
      <Loading loading={loading} />
      <ContentHeader title={title} />
      <ReportContainer.Filters
        dateSelectorType={dateSelectorType}
        generateReport={generateReport}
      />
      {Boolean(data) ? (
        data?.data ? (
          <Component data={data} />
        ) : (
          <Box
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Text style={{ color: "#999" }}>Nenhum dado encontrado</Text>
          </Box>
        )
      ) : (
        <></>
      )}
    </>
  );
}
