import axios from "axios";
import { useEffect, useState } from "react";

const user = JSON.parse(localStorage.getItem("tuimAdmin.user") || "{}");
const headers = { headers: { Authorization: "bearer " + user?.token } };

export function useFetch({ url, loadingMsg: msg }) {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dataLength = data?.length;

  async function fetchData(url) {
    try {
      if (!Boolean(url)) throw new Error("Url is missing.");

      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${url}`,
        headers
      );
      setData(response.data);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function refreshData() {
    fetchData();
  }

  useEffect(() => {
    fetchData(formatUrl(url));
  }, [url]);

  return { data, dataLength, error, refreshData, isLoading: loading };
}

const formatUrl = (url) => `${("/" + url).replace(/\/+/g, "/")}`;
