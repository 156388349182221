export const Colors = {
  darkGreen: '#004146',
  lightGreen: '#79b751',
  paleGreen: '#bfe030',
  red: '#d84d38',
  yellow: '#e6c428',

  darkGreenAlt: '#54878a',

  paleDarkGreen: '#dfeae2',
  paleDarkerGreen: '#c5d4c9',

  textRegular: '#444',
  textDetails: '#666',

  paleRed: '#f5e3df',
  paleDarkRed: '#f0d6d0',

  paleYellow: '#f6f5df',
  paleDarkYellow: '#d5cfb1',

  inputBackground: '#edf2ef',
  rootBackground: '#212f3107'
}