import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect, useReducer, useState } from "react";
import { ContentContainer } from "../../layout/components/contentContainer";
import ContentHeader from "../../layout/components/contentHeader";
import { Text } from "../../layout/components/text";
import { Card } from "./components/card/Card";
import { AbandonedMetricsTable } from "./components/abandonedMetricsTable/AbandonedMetricsTable";
import { useFetch } from "../../../hooks/useFetch";
import Loading from "../../utils/Loading";
import { CartsData } from "./components/cartsData";

const limit = 10;

export const CartMetrics = () => {
  const [period, setPeriod] = useState(7);
  const reducer = (prev, next) => ({ ...prev, ...next });
  const [pagination, setPagination] = useReducer(reducer, {
    page: 0,
  });
  const skip = pagination.rowsPerPage * pagination.page;
  const [url, setUrl] = useState("");

  useEffect(() => {
    const rowsPerPage = localStorage.getItem(
      "tuimAdmin.cartsMetricsRowsPerPage"
    );
    setPagination({ rowsPerPage: Number(rowsPerPage) * 1 || 10 });
  }, []);

  useEffect(() => {
    const queryStrings = [limit, period, pagination.rowsPerPage, skip];

    if (queryStrings.some((item) => typeof item !== "number")) return;
    setUrl(
      `/v2/carts/product-ranking?limit=${limit}&period=${period}&itemsPerPage=${pagination.rowsPerPage}&skip=${skip}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, period, pagination?.rowsPerPage, skip]);

  const { data, isLoading } = useFetch({ url });

  return (
    <>
      <Loading loading={isLoading} />
      <ContentHeader title="Métricas" />
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <FormControl>
          <InputLabel id="demo-simple-select-label">Período</InputLabel>
          <Select
            style={{ width: "80px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={period}
            label="Período"
            onChange={(e) => setPeriod(e.target.value)}
          >
            <MenuItem value={7}>7 dias</MenuItem>
            <MenuItem value={15}>15 dias</MenuItem>
            <MenuItem value={30}>30 dias</MenuItem>
            <MenuItem value={60}>60 dias</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {data?.averageCarts && (
        <ContentContainer label="Médias">
          {data.averageCarts?.map((item) => {
            return (
              <CartsData
                average={item}
                counts={[...data?.periodCount, ...data?.totalCount]}
              />
            );
          })}
        </ContentContainer>
      )}

      <ContentContainer label={`Top ${limit} produtos`}>
        {data?.ranking?.length > 0 ? (
          <Box>
            {data?.ranking?.map((item, index) => {
              return (
                <Card
                  key={item.product._id}
                  data={item}
                  rankPosition={index + 1}
                />
              );
            })}
          </Box>
        ) : (
          <Text detail>Nenhum produto encontrado</Text>
        )}
      </ContentContainer>

      {data?.abandonDetails && (
        <AbandonedMetricsTable
          data={data}
          setPagination={setPagination}
          pagination={pagination}
        />
      )}
    </>
  );
};
