import { ReportContainerData } from "./ReportContainerData";
import { ReportContainerFilters } from "./ReportContainerFilters";
import { ReportContainerRoot } from "./ReportContainerRoot";
import { ReportContainerTab } from "./ReportContainerTab";
import { ReportContainerTitle } from "./ReportContainerTitle";

export const ReportContainer = {
  Root: ReportContainerRoot,
  Title: ReportContainerTitle,
  Filters: ReportContainerFilters,
  Tab: ReportContainerTab,
  Data: ReportContainerData,
};