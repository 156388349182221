import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';

const PermissionsComboBox = (props) => {

    const [open, setOpen] = React.useState(false);
    console.log('props.value',props.value)
    return (
        <div style={{...{ position: 'relative', display: 'inline-block' }, ...props.style}} >
            <div className={props.classes.label}>{props.title}</div>
            <input
                type="text" 
                className={props.classes.field}
                value={props.value}
                onChange={(e) => props.onChange ? props.onChange(e) : false}
                name={props.name}
                placeholder={props.placeholder}
                onFocus={() => setOpen(true)}
                onBlur={()=>setTimeout(()=>setOpen(false), 200)}
            />
            <div className={open? props.classes.openDropdown: props.classes.dropdown}>

                
                {props.permissions.map((item, index)=>(


                        <option onClick={(e)=>{ 
                            e.target.name=props.name
                            props.onChange(e)
                        }} className={props.classes.item} 
                        value={item.permission} key={index}>{item.permission}</option>
                    
                ))}
            </div>
        </div >)
}

const styles = theme => ({
    field: {
        fontSize: 14,
        padding: 10,
        borderWidth: 0,
        backgroundColor: '#f3f3f3',
        width: '100%'
    },
    label: {
        fontSize: 12,
        fontWeight: '500',
        marginBottom: 5
    },
    item: {
        borderBottomStyle: 'solid',
        borderBottomWidth: .5,
        borderColor: '#b5b5b5',
        padding: 5,
        fontSize: 12
    },
    dropdown: {
        display: 'none',        
        position: 'absolute',
        zIndex: 1,
        backgroundColor: '#FFF',
        minWidth: 300,
        cursor: 'pointer'
    },
    openDropdown: {
        display: 'block',        
        position: 'absolute',
        zIndex: 1,
        backgroundColor: '#FFF',
        minWidth: 300,
        cursor: 'pointer'
    }
});

export default (withStyles(styles)(PermissionsComboBox))