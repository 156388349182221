import React, { Component } from 'react'
import axios from 'axios'
import {
   Paper, Typography, Button, FormControl, InputLabel, Input, CircularProgress, Checkbox,
   IconButton, Menu, MenuItem, FormControlLabel
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';
import green from '@material-ui/core/colors/green';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import Confirm from '../utils/Confirm'
import AlertDialog from '../utils/AlertDialog'

class EditUnit extends Component {

   constructor(props) {
      super(props)

      const { classes } = props;
      const { pathname } = props.location
      const arrayPath = pathname.split('/')
      const _id = arrayPath[arrayPath.length - 1];

      this.state = {
         classes,
         data: {
            _id,
            name: '',
            title: '',
            description: '',
            urlSeo: '',
            active: 0,
            address: {
               rua: "",
               complemento: "",
               numero: "",
               cidade: "",
               estado: "",
            }
         },
         saving: false,
         success: false,
         error: null,
         anchorEl: null,
         open: false,
         isConfirmOpen: false,
         showAlert: false,
         showDeleteUnitAlert: false
      }

      this.onChange = this.onChange.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.onDeleteUnitPress = this.onDeleteUnitPress.bind(this);
      this.deleteUnit = this.deleteUnit.bind(this);
      this.onCheckChange = this.onCheckChange.bind(this);
   }

   componentDidMount = async () => {

      const { _id } = this.state.data

      if (!_id)
         return;

      const token = await localStorage.getItem('token');
      const _this = this

      axios.get(`${process.env.REACT_APP_API_URL}/unit/${_id}`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response

            if (!data.address) {
               data.address = {
                  rua: "",
                  complemento: "",
                  numero: "",
                  cidade: "",
                  estado: "",
               }
            }
            _this.setState({
               data: data
            })
         })

   }


   onChange = (e) => {

      const { data } = this.state
      let { urlSeo } = data
      let value

      if (typeof e.target.value == 'object') {
         value = e.target.value.join(',');
      } else {
         value = e.target.value;
      }

      if (e.target.name == 'title') {
         urlSeo = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
         urlSeo = urlSeo.replace('?', '')
         urlSeo = urlSeo.replace(' - ', ' ')
         urlSeo = urlSeo.toLowerCase().trim().split(' ').join('-')
         this.setState({
            data: {
               ...this.state.data,
               urlSeo: urlSeo,
               title: e.target.value,
            },
         })
      } else if (e.target.name == 'cep') {
         let cep = e.target.value
         this.eliminateCaracter(cep, 'cep')
         cep = cep.normalize('NFD').replace(/([\u0300-\u036f]|[^0-9])/g, '')
         if (cep.length >= 8) {
            let firstNumber = cep.substring(0, 5)
            let lastNumber = cep.substring(5, 8)
            let completeCep = firstNumber + '-' + lastNumber
            document.getElementById("cep").value = completeCep
            console.log('cep', completeCep)
            this.setState({
               data: {
                  ...this.state.data,
                  address: {
                     ...this.state.data.address,
                     cep: completeCep

                  }


               }

            })


         }


      } else {
         this.setState({
            data: {
               ...this.state.data,
               unit_id: e.target.value
            }
         })

      }
   }
   onChangeAddress = (e) => {
      this.setState({
         data: {
            ...this.state.data,
            address: {
               ...this.state.data.address,
               [e.target.name]: e.target.value
            }
         }
      })

   }
   onChangeAddressRua = (e) => {
      const { data } = this.state
      this.setState({
         data: {
            ...this.state.data,
            address: {
               ...this.state.data.address,
               rua: e.target.value
            }
         }
      })


   }
   eliminateCaracter = (caracter, idCampo) => {
      let acceptCaracter = caracter.replace(/a|b|c|d|e|f|g|h|i|j|k|l|m|n|o|p|q|r|s|t|u|v|w|x|y|z|ç|(|)|;|:|>|<|,|"|!|@|#|%|¨|&|_/gi, "");
      let acceptCaracter2 = acceptCaracter.replace(/\./g, "")

      document.getElementById(idCampo).value = acceptCaracter2;

   }

   onSavePress = async () => {

      const { data } = this.state

      console.log('save data', data)

      if (!data._id) {
         this.newUnit()
         return;
      }

      this.setState({
         saving: true
      })

      const token = await localStorage.getItem('token');

      axios.patch(`${process.env.REACT_APP_API_URL}/unit/${data._id}`, data, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            this.setState({
               saving: false,
               success: true
            })
            console.log('response ', response)

            this.props.history.goBack()
         })
         .catch(error => {
            this.setState({
               saving: false,
               success: false,
               error: error.response
            })
         })
   }

   newUnit = async () => {

      const { data } = this.state

      data._id = undefined

      this.setState({
         saving: true
      })

      const token = await localStorage.getItem('token');
      const _this = this

      axios.post(`${process.env.REACT_APP_API_URL}/unit`, data, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            this.setState({
               saving: false,
               success: true,
               data: response.data.data
            })

            this.props.history.goBack()

            _this.props.history.push(`${response.data.data._id}`)
         })
         .catch(error => {
            this.setState({
               saving: false,
               success: false,
               error: error.response
            })
         })
   }

   deleteUnit = async () => {

      const { data } = this.state
      const token = await localStorage.getItem('token');

      axios.delete(`${process.env.REACT_APP_API_URL}/unit/${data._id}`, {
         headers: { 'Authorization': "bearer " + token }
      }).then(response => {
         this.setState({
            saving: false,
            success: true
         })
         this.props.history.goBack()
         this.props.history.push('/app/units')
      }).catch(error => {
         this.setState({
            saving: false,
            success: false,
            error: error.response
         })
      })
   }

   onCancelPress = () => {
      this.props.history.goBack()
   }

   handleClick = event => {
      this.setState({ anchorEl: event.currentTarget, open: true });
   };

   handleClose = () => {
      this.setState({ anchorEl: null, open: false, showAlert: false });
   };

   onDeleteUnitPress = () => {
      this.setState({ anchorEl: null, open: false, showDeleteUnitAlert: true });
   };

   onCheckChange = name => event => {

      const { data } = this.state

      this.setState({
         data: {
            ...data,
            [name]: event.target.checked
         },
         success: false
      });
   };
   render() {

      const { classes, saving, success, anchorEl, open, showDeleteUnitAlert, data } = this.state
      console.log('daata ', data)
      const buttonClassname = classNames({
         [classes.buttonSuccess]: success,
      });

      return (
         <Paper className={classes.paper}>
            <Typography component="h1" variant="h5">
               {data.title}
               <IconButton
                  aria-label="More"
                  aria-owns={true}
                  aria-haspopup="true"
                  onClick={this.handleClick}
               >
                  <MoreVertIcon />
               </IconButton>
               <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={this.handleClose}
                  PaperProps={{
                     style: {
                        maxHeight: 200,
                        width: 200,
                     },
                  }}
               >
                  <MenuItem key={0} onClick={this.onDeleteUnitPress}>
                     Excluir Filial
                  </MenuItem>
               </Menu>

            </Typography>
            <form className={classes.form}>
               <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email" >Titulo</InputLabel>
                  <Input id="title" name="title" autoFocus value={data.title}
                     onChange={this.onChange}
                  />
               </FormControl>
               <br />
               <FormControl>
                  <InputLabel htmlFor="email" >Id Filial no SAP</InputLabel>
                  <Input type='number' id="idFilial" name="idFilial" autoFocus value={data.unit_id}
                     onChange={this.onChange}
                  />
               </FormControl>
               <br />
               <FormControl>
                  <InputLabel htmlFor="email" >Url SEO</InputLabel>
                  <Input id="urlSeo" name="urlSeo" autoFocus value={data.urlSeo}

                  />
               </FormControl>
               <br />
               <FormControl>
                  <InputLabel htmlFor="email" >Rua</InputLabel>
                  <Input id="rua" name="rua" autoFocus value={data.address.rua}
                     onChange={this.onChangeAddressRua}
                  />
               </FormControl>
               <br />
               <FormControl>
                  <InputLabel htmlFor="email" >Cep</InputLabel>
                  <Input id="cep" name="cep" autoFocus value={data.address.cep}
                     onChange={this.onChange}
                  />
               </FormControl>
               <br />
               <FormControl>
                  <InputLabel htmlFor="email" >Bairro</InputLabel>
                  <Input id="bairro" name="bairro" autoFocus value={data.address.bairro}
                     onChange={this.onChangeAddress}
                  />
               </FormControl>
               <br />
               <FormControl>
                  <InputLabel htmlFor="email" >Cidade</InputLabel>
                  <Input id="cidade" name="cidade" autoFocus value={data.address.cidade}
                     onChange={this.onChangeAddress}
                  />
               </FormControl>
               <br />
               <FormControl>
                  <InputLabel htmlFor="email" >Estado</InputLabel>
                  <Input id="estado" name="estado" autoFocus value={data.address.estado}
                     onChange={this.onChangeAddress}
                  />
               </FormControl>
               <br />
               <FormControl>
                  <InputLabel htmlFor="email" >Numero</InputLabel>
                  <Input id="numero" type='number' name="numero" autoFocus value={data.address.numero}
                     onChange={this.onChangeAddress}
                  />
               </FormControl>
               <br />
               <FormControl>
                  <InputLabel htmlFor="email" >Complemento</InputLabel>
                  <Input id="complemento" name="complemento" autoFocus value={data.address.complemento}
                     onChange={this.onChangeAddress}
                  />
               </FormControl>
               <br />

               <FormControlLabel
                  control={
                     <Checkbox
                        checked={data.active}
                        onChange={this.onCheckChange('active')}
                        value="active"
                        color="primary"
                     />
                  }
                  label="Ativo"
               />
               <div className={classes.wrapper}>
                  <Button
                     variant="contained"
                     color="primary"
                     className={buttonClassname}
                     disabled={saving}
                     onClick={this.onSavePress}
                  >
                     <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                     Salvar
                     {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                  <Button variant="contained" color="default" className={classes.button}
                     onClick={this.onCancelPress}
                  >Cancelar
                  </Button>
                  {this.state.error && <Typography>{this.state.error}</Typography>}


                  <Button variant="contained"
                     style={{ backgroundColor: '#f44336', color: '#FFF', left: 667 }}
                     className={classes.button}
                     onClick={() => this.setState({ isConfirmOpen: true })}>
                     Excluir
                  </Button>
                  {this.state.error && <Typography>{this.state.error}</Typography>}

                  <Confirm open={this.state.isConfirmOpen} title='Exclusão de Filial' text='Deseja realmente excluir essa filial?'
                     onDeletePress={this.deleteUnit} onClose={() => this.setState({ isConfirmOpen: false })}
                  />

               </div>


            </form>

            {
               showDeleteUnitAlert && <AlertDialog
                  msg={`Deseja relmente excluir esta filial ${data.title}? Obs.: Esta ação è irreversível`}
                  title="Excluir Filial"
                  open={showDeleteUnitAlert}
                  buttons={[
                     {
                        label: 'Cancelar',
                        onPress: () => {
                           this.setState({
                              showDeleteUnitAlert: false
                           })
                        },

                     },
                     {
                        label: 'Excluir',
                        onPress: () => {
                           this.setState({
                              showDeleteUnitAlert: false
                           })

                           this.deleteUnit()
                        },
                        color: 'primary'
                     }
                  ]} />
            }

         </Paper >
      )
   }
}

const styles = theme => ({
   main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
         width: 400,
         marginLeft: 'auto',
         marginRight: 'auto',
      },
   },
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
   },
   avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      maxWidth: '450px',
      marginTop: theme.spacing.unit,
   },
   button: {
      marginLeft: theme.spacing.unit
   },
   leftIcon: {
      marginRight: theme.spacing.unit,
   },
   success: {
      backgroundColor: green[600],
   },
   buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   wrapper: {
      marginTop: theme.spacing.unit * 3,
      position: 'relative',
   },
   buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
         backgroundColor: green[700],
      },
   },
   titleName: {
      flex: 1
   },
   titleBar: {
      flexDirection: 'row'
   },
   chips: {
      display: 'flex',
      flexWrap: 'wrap',
   },
   chip: {
      margin: theme.spacing.unit / 4,
   },
});

export default (withStyles(styles)(EditUnit))