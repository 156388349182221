import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow, Paper, Table, Typography, Grid, Fab, Button } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import Loading from '../utils/Loading';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import TablePagination from '@material-ui/core/TablePagination';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'react-moment';
class ListCoupons extends Component {

   constructor(props) {
      super(props)

      const { classes } = props;

      this.state = {
         classes,
         data: [],
         searchTerm: '',
         loading: true,
         order: 'asc',
         orderBy: 'calories',
         page: 0,
         limit: 25,
         cont: 0,
         rowsPerPage: 12,
         data_inicio_filter: '',
         data_final_filter: '',
         title: '',
         status: 'Todos',
         code: ''
      }

      this.onSearchChange = this.onSearchChange.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeAll = this.handleChangeAll.bind(this);
      this.Deactivate = this.Deactivate.bind(this);
      this.delete = this.delete.bind(this);
      this.active = this.active.bind(this);
   }

   componentDidMount = async () => {
      this.getCoupons();
   }
   getCoupons = async (pageGo = 0) => {
      const { limit } = this.state
      let url = new URL(window.location.href);
      let returnPage = url.searchParams.get('page') ? url.searchParams.get('page') : 0
      if (url.searchParams.get('page')) {
         if (!url.searchParams.get('page')) {
            this.setState({ page: url.searchParams.get('page') })

         } else {
            let returnPage = url.searchParams.get('page')
            this.setState({ page: parseInt(returnPage) })


         }
      }

      const _this = this
      const token = await localStorage.getItem('token');

      axios.get(`${process.env.REACT_APP_API_URL}/coupon`, {
         params: {
            limit: limit,
            page: pageGo == 0 ? parseInt(returnPage) : pageGo
         },
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {

            const { data, cont } = response.data
            _this.setState({
               data: data,
               cont: cont,
               loading: false
            })

         })

   }


   onSearchChange = (e) => {

      this.setState({
         searchTerm: e.target.value
      })
   }
   handleChangeAll = async (e) => {
      let isChecked = e.target.checked;
      let { data } = this.state;

      await data.map((item, index) => {
         data[index].checkedItem = isChecked
      })

      this.setState({ data })
   }

   handleChange = async (e) => {
      let isChecked = e.target.checked;
      let couponId = e.target.value;

      let { data } = this.state
      await data.map((item, index) => {
         if (item._id == couponId) {
            data[index].checkedItem = isChecked
            //console.log("data[index] ", data)
         }
      })

      this.setState({ data })


   }
   Deactivate = async () => {
      const { data } = this.state;
      let checkItemArray = [];
      const token = await localStorage.getItem('token');
      await data.map((i) => {
         if (i.checkedItem == true) {
            checkItemArray.push(i._id)
         }
      })
      if (window.confirm('Deseja Desativar esse(s) coupon(s)')) {
         axios.patch(`${process.env.REACT_APP_API_URL}/changeStatusCoupon`, { coupons: checkItemArray, status: false }, {
            headers: { 'Authorization': "bearer " + token }
         }).then(response => {
            this.getCoupons();
         })


      }


   }
   active = async () => {
      const { data } = this.state
      let checkItemArray = [];
      const token = await localStorage.getItem('token');
      await data.map((i) => {
         if (i.checkedItem == true) {
            checkItemArray.push(i._id)
         }
      })
      if (window.confirm('Deseja ativar esse(s) coupon(s)')) {
         axios.patch(`${process.env.REACT_APP_API_URL}/changeStatusCoupon`, { coupons: checkItemArray, status: true }, {
            headers: { 'Authorization': "bearer " + token }
         }).then(response => {
            this.getCoupons();
            console.log("response.data ", response.data)
            console.log("checkItemArray ", data)
         })

      }

   }
   delete = async () => {
      const { data } = this.state;
      let checkItemArray = [];
      await data.map((d) => {
         if (d.checkedItem == true) {
            checkItemArray.push(d._id)
         }
      })
      if (window.confirm('Deseja excluir permanentemente esse(s) coupon(s)')) {


      }
   }
   handleChangePage = (event, page) => {
      const { page: pagina } = this.state
      let pageGo = 0
      pageGo = page > pagina ? pagina + 1 : pagina - 1
      window.history.pushState('atualpage', 'TUIM - Admin', window.location.pathname + '?page=' + page);
      this.setState({ page: pageGo });
      this.getCoupons(pageGo);
   };
   handleChangeRowsPerPage = event => {
      this.setState({ page: 0, rowsPerPage: event.target.value });
   };
   createSortHandler = property => event => {
      this.handleRequestSort(event, property);
   };
   handleRequestSort = async (event, property) => {
      const orderBy = property;
      let order = 'desc';

      if (this.state.orderBy === property && this.state.order === 'desc') {
         order = 'asc';
      }
      const { page } = this.state
      const _this = this
      const token = localStorage.getItem('token');
      let url = new URL(window.location.href);
      let returnPage = url.searchParams.get('page')
      if (url.searchParams.get('page')) {
         if (!url.searchParams.get('page')) {
            this.setState({ page: url.searchParams.get('page') })
         } else {
            let returnPage = url.searchParams.get('page')
            this.setState({ page: parseInt(returnPage) })
         }
      }
      await axios.get(`${process.env.REACT_APP_API_URL}/coupon`, {
         params: {
            limit: 25,
            page: parseInt(returnPage) ? parseInt(returnPage) : page,
            orderName: property,
            order: order === 'desc' ? -1 : 1
         },
         headers: { 'Authorization': "bearer " + token },
      })
         .then(response => {
            const { data, cont } = response.data
            _this.setState({
               data: data,
               cont: cont
            })
            _this.setState({ loading: false });

         })


      this.setState({ order, orderBy });
   };
   filtrarCumpom = async (e) => {
      const {
         data_inicio_filter,
         data_final_filter,
         title,
         status,
         code } = this.state
      const _this = this
      this.setState({ loading: true })
      let filters = {}

      /*   if (status_filter != '') {
            data_filter.status = status_filter
        }
 */
      if (data_inicio_filter != '') {

         filters.date_start = data_inicio_filter
      }
      if (title != '') {

         filters.title = title
      }

      if (status == true) {
         filters.status = true
      } else if (status == false && status != 'Todos') {
         filters.status = false
      }
      if (code != '') {

         filters.code = code
      }
      if (data_final_filter != '') {

         filters.date_finished = data_final_filter
      }


      const token = await localStorage.getItem('token');

      axios.get(`${process.env.REACT_APP_API_URL}/filterCoupon`, {
         params: {
            filters,
            page: 0,
            limit: 25
         },
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {

            const { data, cont } = response.data

            _this.setState({
               data: data,
               cont: cont
            })
            window.history.pushState('atualpage', 'TUIM - Admin', window.location.pathname + '?page=' + 0);
            this.setState({ page: 0, rowsPerPage: 10 });


            this.setState({ loading: false });
         })

   }



   render() {

      const { classes, data, searchTerm, loading, order, orderBy, rowsPerPage, page, cont, data_final_filter, title, status, code } = this.state

      const coluns = [
         { id: 'check', numeric: true, disablePadding: false, label: '', align: 'center' },
         { id: 'title', numeric: false, disablePadding: false, align: 'center', label: 'Título' },
         { id: 'code', numeric: true, disablePadding: false, align: 'center', label: 'Código' },
         { id: 'discount', numeric: true, align: 'center', disablePadding: false, label: 'Desconto' },
         { id: 'createdAt', numeric: true, align: 'center', disablePadding: false, label: 'Data' },
         { id: 'active', numeric: true, align: 'center', disablePadding: false, label: 'Ativo' },

      ];

      return (
         <div>

            <Loading loading={loading} />

            <Grid container>
               <Grid item xs >
                  <Typography
                     component="h1"
                     variant="h6"
                     color="inherit"
                     noWrap
                     className={classes.title}

                  >Cupons de Desconto
                  </Typography>
               </Grid>
               <Grid item>
                  <Fab color="primary" aria-label="Add" className={classes.fab} size="small">
                     <AddIcon onClick={() => this.props.history.push(`/app/coupon/`)} />
                  </Fab>
               </Grid>
            </Grid>

            <Paper className={classes.root}>
               <div style={{ width: `100%`, display: 'none' }}>
                  <div style={{ marginLeft: 25 }}>
                     <IconButton aria-label="delete" onClick={this.delete}>
                        <DeleteIcon />
                        <p style={{ color: `#000`, fontSize: 13 }}>Excluir</p>
                     </IconButton>
                     <IconButton color="#000" aria-label="add an alarm" onClick={this.Deactivate}>
                        <HighlightOffIcon />
                        <p style={{ color: `#000`, fontSize: 13 }}>Desativar</p>
                     </IconButton>
                     <IconButton color="#000" aria-label="add an alarm" onClick={this.active}>
                        <OfflinePinIcon />
                        <p style={{ color: `#000`, fontSize: 13 }}>Ativar</p>
                     </IconButton>

                  </div>
               </div>
               <div style={{ display: 'flex', flexDirection: 'column', padding: 20, width: '100%' }}>
                  <span>Filtro</span>
                  <div style={{ display: 'flex', marginTop: 30 }}>

                     <form style={{ display: 'flex', width: '100%' }}>
                        <TextField
                           id="data-contract"
                           label="Data Inicio"
                           type="date"
                           //  value={data_inicio_filter}
                           onChange={(e) => this.setState({ data_inicio_filter: e.target.value })}

                           className={classes.textField}
                           InputLabelProps={{
                              shrink: true,
                           }}
                        />

                        <TextField
                           id="data-contract"
                           label="Data Final"
                           type="date"
                           style={{ marginLeft: 20 }}
                           value={data_final_filter}
                           onChange={(e) => this.setState({ data_final_filter: e.target.value })}
                           className={classes.textField}
                           InputLabelProps={{
                              shrink: true,
                           }}
                        />
                        <TextField
                           id="id"
                           label="Titulo"
                           type="text"
                           style={{ marginLeft: 20 }}
                           value={title}
                           onChange={(e) => this.setState({ title: e.target.value })}
                           className={classes.textField}
                           InputLabelProps={{
                              shrink: true,
                           }}
                        />

                        <TextField
                           id="id"
                           label="Código"
                           type="text"
                           style={{ marginLeft: 20 }}
                           value={code}
                           onChange={(e) => this.setState({ code: e.target.value })}
                           className={classes.textField}
                           InputLabelProps={{
                              shrink: true,
                           }}
                        />
                        <div style={{ marginLeft: 20 }}>
                           <InputLabel id="demo-simple-select-label">Status</InputLabel>
                           <Select
                              style={{ width: 145 }}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={status}
                              onChange={(e) => this.setState({ status: e.target.value })}
                           >
                              <MenuItem value={true}>Ativo</MenuItem>
                              <MenuItem value={false}>Inativo</MenuItem>
                              <MenuItem value={'Todos'} selected={true}>Todos</MenuItem>
                           </Select>

                        </div>




                        <Button variant="contained" color="primary" style={{ marginTop: 10, marginLeft: 50 }} onClick={() => this.filtrarCumpom()}>
                           Filtrar
                        </Button>
                     </form>
                  </div>
               </div>
               <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={cont}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                     'aria-label': 'Página anterior',
                  }}
                  nextIconButtonProps={{
                     'aria-label': 'Próxima página',
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
               />
               <Table className={classes.table}>
                  <TableHead>
                     <TableRow>
                        {coluns.map(
                           row => (
                              <TableCell
                                 key={row.id}
                                 align={row.align}
                                 padding={row.disablePadding ? 'none' : 'default'}
                                 sortDirection={orderBy === row.id ? order : false}
                              >
                                 <Tooltip
                                    title="Sort"
                                    placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                 >
                                    <TableSortLabel
                                       active={orderBy === row.id}
                                       direction={order}
                                       onClick={this.createSortHandler(row.id)}
                                    >
                                       {row.label}
                                    </TableSortLabel>
                                 </Tooltip>
                              </TableCell>
                           ),
                           this,
                        )}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {data.filter(item => item.title.toLowerCase().includes(searchTerm.toLowerCase())).map((row, index) => (


                        <TableRow
                           key={index}
                           className={classes.row}
                        >
                           <TableCell component="th" scope="row">
                              <Checkbox inputProps={{ 'aria-label': 'uncontrolled-checkbox' }} checked={typeof row.checkedItem != 'undefined' ? row.checkedItem : false} value={row._id} onChange={this.handleChange} />
                           </TableCell>
                           <TableCell component="th" scope="row"

                              hover="true"
                              onClick={() => this.props.history.push(`/app/coupon/${row._id}`)}                                    >
                              {row.title}
                           </TableCell>

                           <TableCell component="th" scope="row"
                              hover="true"
                              onClick={() => this.props.history.push(`/app/coupon/${row._id}`)}
                           >
                              {row.code}
                           </TableCell>
                           <TableCell component="th" scope="row"
                              hover="true"
                              onClick={() => this.props.history.push(`/app/coupon/${row._id}`)}
                           >
                              {row.discount}%
                           </TableCell>
                           <TableCell align="center"
                              hover="true"
                              onClick={() => this.props.history.push(`/app/coupon/${row._id}`)}
                           >
                              <Moment format="DD/MM/YYYY HH:mm:ss" date={new Date(row.createdAt)} ></Moment>
                           </TableCell>
                           <TableCell align="center"
                              hover="true"
                              onClick={() => this.props.history.push(`/app/coupon/${row._id}`)}
                           >{row.active ? <CheckCircle /> : '-'}</TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
               <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={cont}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                     'aria-label': 'Página anterior',
                  }}
                  nextIconButtonProps={{
                     'aria-label': 'Próxima página',
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
               />
            </Paper>
         </div>

      );
   }
}

ListCoupons.propTypes = {
   classes: PropTypes.object.isRequired,
};
// function getSorting(order, orderBy) {
//    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
// }
// function desc(a, b, orderBy) {
//    if (b[orderBy] < a[orderBy]) {
//       return -1;
//    }
//    if (b[orderBy] > a[orderBy]) {
//       return 1;
//    }
//    return 0;
// }
// function stableSort(array, cmp) {
//    const stabilizedThis = array.map((el, index) => [el, index]);
//    stabilizedThis.sort((a, b) => {
//       const order = cmp(a[0], b[0]);
//       if (order !== 0) return order;
//       return a[1] - b[1];
//    });
//    return stabilizedThis.map(el => el[0]);

// }

const styles = theme => ({
   root: {
      width: '100%',
      marginTop: theme.spacing.unit * 2,
      overflowX: 'auto',
   },
   table: {
      minWidth: 700,
   },
   row: {
      cursor: 'pointer'
   },
   fab: {
      margin: 0,
   },
   search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade('#ececec', 0.35),
      '&:hover': {
         backgroundColor: fade('#ececec', 0.80),
      },
      // marginRight: theme.spacing.unit * 2,
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
         // marginLeft: theme.spacing.unit * 3,
         width: 'auto',
      },
   },
   searchIcon: {
      // width: theme.spacing.unit * 1,
      marginLeft: theme.spacing.unit * 1,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   inputRoot: {
      color: 'inherit',
      width: '100%',
   },
   inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 5,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
         width: 200,
      },
   }
});

export default withStyles(styles)(ListCoupons);
