import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import Clear from "@material-ui/icons/Clear";
import axios from "axios";
import classNames from "classnames";
import { uniqueId } from "lodash";
import React, { Component } from "react";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import Dropzone from "../ui/components/upload/Dropzone";
import AlertDialog from "../utils/AlertDialog";
import Loading from "../utils/Loading";
import TableSelectModal from "../utils/TableSelectModal";

class EditBuilding extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        name: "",
        urlSeo: "",
        title: "",
        description: "",
        description: "",
        email: "",
        active: false,
        products: [],
        partner_ref: {},
        type: true,
        partner_ref: "",
        typeCheckout: true,
        approvalPurchase: false,
        showAllProducts: false,
        approvalEmail: "",
      },
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      showAlert: false,
      showErrorNewUser: false,
      showDeleteUserAlert: false,
      loading: true,
      showAlertNewUser: false,
      products: [],
      selectorData: [],
      list_products: [],
      partners: [],
      permissions: [{ permission: "Administrador" }, { permission: "SEO" }],
      itemsSelected: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onResetPasswordPress = this.onResetPasswordPress.bind(this);
    this.onDeleteUserPress = this.onDeleteUserPress.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  async componentDidMount() {
    const { _id } = this.state.data;

    const token = localStorage.getItem("token");
    const _this = this;

    this.setState({ loading: true });

    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/partners/all`, {
          headers: { Authorization: "bearer " + token },
        })
        .then((response) => {
          const { data } = response;
          _this.setState({ partners: data });
        });

      if (!_id) return;

      await axios
        .get(`${process.env.REACT_APP_API_URL}/buildingId/${_id}`, {
          headers: { Authorization: "bearer " + token },
        })
        .then((response) => {
          const { data } = response;

          const products =
            data?.products?.map((item) => ({
              qtd: 1,
              product: item,
            })) || [];

          _this.setState({ data: { ...data, products } });
        });
    } catch (error) {
      console.log(error);
      alert("Houve um erro ao carregar os dados.");
    } finally {
      this.setState({ loading: false });
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    let { urlSeo } = data;

    if (name == "name") {
      urlSeo = value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[-.:\/\s]/g, "-")
        .replace(/-+/g, "-")
        .replace(/\?/g, "")
        .toLowerCase()
        .trim();
    }

    this.setState({ data: { ...data, [name]: value, urlSeo } });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  onSavePress = async () => {
    const { data } = this.state;

    if (!data._id) {
      this.newUser();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = localStorage.getItem("token");

    const body = {
      ...data,
      products: data.products.map(({ product }) => product),
    };

    axios
      .patch(`${process.env.REACT_APP_API_URL}/building/${data._id}`, body, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.goBack();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newUser = async () => {
    const { data } = this.state;

    data._id = undefined;

    this.setState({
      saving: true,
    });

    const token = localStorage.getItem("token");
    const _this = this;

    const formattedData = {
      ...data,
      products: data.products.map((item) => ({
        ...item.product,
        qtd: item.qtd,
      })),
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/partner/building`,
        formattedData,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          showAlertNewUser: true,
          data: response.data.data,
        });

        _this.props.history.push(`${response.data.data._id}`);
      })
      .catch((error) => {
        console.log("responserror", error.response.data.message);

        this.setState({
          saving: false,
          success: false,
          showErrorNewUser: true,
          error: error.response.data.message,
        });
      });
  };

  getRowsSelectedModal = (selectedProducts) => {
    // Filter the selected products to include only those that are not already present in the current state
    const newSelected = selectedProducts.filter(
      (product) =>
        !this.state.products.some((p) => p.id === product.id) &&
        !this.state.data.products.some((p) => p.id === product.id)
    );

    // Update the state with the new selected products
    this.setState((prevState) => ({
      products: [...prevState.products, ...newSelected],
      data: {
        ...prevState.data,
        products: [...prevState.data.products, ...newSelected],
      },
      openModalProducts: !prevState.openModalProducts,
    }));
  };

  onDropFiles = (files) => {
    const uploadedFiles = files.map((file, index) => ({
      file,
      id: uniqueId(),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(this.processUpload);
  };

  processUpload = async (uploadedFile, mainImg = true) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    const token = localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.setState({
          data: {
            ...this.state.data,
            urlImg: data.url,
          },
        });
      })
      .catch((error) => console.log("error", error));
  };
  processUploadSecondary = async (uploadedFile, mainImg = false) => {
    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    const token = localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        if (mainImg == true) {
          this.setState({
            data: {
              ...this.state.data,
              urlImg: data.url,
            },
          });
        } else {
          this.setState({
            data: {
              ...this.state.data,
              secondaryImg: data.url,
            },
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  updateFile = (id, data) => {
    this.setState({
      data: {
        ...this.state.data,
        images: this.state.data.images.map((image) => {
          if (image.id == id) {
            return { ...image, ...data, ...{ uploaded: true } };
          }

          return image;
        }),
      },
    });
  };

  onDeleteImage = (index) => {
    let { images } = this.state.data;

    images.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        images,
      },
    });
  };
  openModalProducts = () =>
    this.setState({ openModalProducts: !this.state.openModalProducts });

  getProdutosClickOpen = async () => {
    this.openModalProducts();

    const _this = this;
    const token = localStorage.getItem("token");
    _this.setState({ loading: true });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/products-kits`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({ list_products: data });
      })
      .finally(() => _this.setState({ loading: false }));
  };

  deleteUser = async () => {
    const { data } = this.state;
    const token = localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}/building/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
    this.props.history.push("/app/partners/buildings");
  };
  deleteProductKit = async (index) => {
    const { data } = this.state;
    let { products } = data;

    delete products[index];

    let products_ = products.filter(function (el) {
      return el != null;
    });

    this.setState({ data: { ...data, products: products_ } });
  };

  onChangeSelect = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value,
      },
      success: false,
    });
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, showAlert: false });
  };

  onResetPasswordPress = () => {
    this.setState({ anchorEl: null, open: false, showAlert: true });
  };

  onDeleteUserPress = () => {
    this.setState({ anchorEl: null, open: false, showDeleteUserAlert: true });
  };

  onDrop = (files) => {
    const uploadedFiles = files.map((file, index) => ({
      file,
      id: uniqueId(),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(this.processUpload, true);
  };

  onDropSecondary = (files) => {
    const uploadedFiles = files.map((file, index) => ({
      file,
      id: uniqueId(),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(this.processUploadSecondary);
  };

  setItemsSelected = (itemsSelected) => {
    this.setState({
      data: {
        ...this.state.data,
        products: itemsSelected,
      },
    });
  };

  render() {
    const {
      classes,
      success,
      showAlert,
      showDeleteUserAlert,
      loading,
      showAlertNewUser,
      showErrorNewUser,
      partners,
      error,
      list_products,
      openModalProducts,
    } = this.state;
    const { data } = this.state;
    const buttonClassname = classNames({
      [classes.buttonSuccess]: success,
    });

    const columnsModalProducts = [
      { id: "name", numeric: false, disablePadding: true, label: "Nome" },
      { id: "id_sap", numeric: false, disablePadding: true, label: "ID SAP" },
    ];

    return (
      <>
        <Loading loading={loading} />

        <ContentHeader
          title={data.name}
          buttons={[{ text: "Salvar", onClick: this.onSavePress }]}
          dotsMenuItems={[
            { text: "Resetar senha", action: this.onResetPasswordPress },
            { text: "Excluir imóvel", action: this.onDeleteUserPress },
          ]}
        />
        <ContentContainer>
          <form className={classes.form}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Nome</InputLabel>
              <Input
                id="name"
                name="name"
                autoFocus
                value={data.name}
                onChange={this.onChange}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="title">Titulo</InputLabel>
              <Input
                id="title"
                name="title"
                autoFocus
                value={data.title}
                onChange={this.onChange}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="urlSeo">URL Seo</InputLabel>
              <Input
                disabled
                id="urlSeo"
                name="urlSeo"
                autoFocus
                value={data.urlSeo}
                onChange={this.onChange}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="url">URL</InputLabel>
              <Input
                id="url"
                name="url"
                autoFocus
                value={data.url}
                onChange={this.onChange}
              />
            </FormControl>

            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="cpf">Descrição</InputLabel>
              <textarea
                value={data.description}
                onChange={this.onChange}
                name="description"
                id="description"
                style={{
                  width: "100%",
                  height: 50,
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              ></textarea>
            </FormControl>

            <div className="form-group">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginBottom: 10, flex: 1 }}>
                  Imagem Principal
                </div>
                <i
                  className="fa fa-times-circle"
                  style={{ color: "gray", fontSize: 16, cursor: "pointer" }}
                  onClick={() => this.onDelete(0)}
                ></i>
              </div>
              <Dropzone onDrop={this.onDrop} />
              {data.urlImg ? (
                <img src={data.urlImg} style={{ maxWidth: 400, height: 300 }} />
              ) : (
                ""
              )}
            </div>

            <div className="form-group">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginBottom: 10, flex: 1 }}>
                  Imagem Secondaria
                </div>
                <i
                  className="fa fa-times-circle"
                  style={{ color: "gray", fontSize: 16, cursor: "pointer" }}
                  onClick={() => this.onDelete(1)}
                ></i>
              </div>
              <Dropzone onDrop={this.onDropSecondary} />
              {data.secondaryImg ? (
                <img
                  src={data.secondaryImg}
                  style={{ maxWidth: 400, height: 300 }}
                />
              ) : (
                ""
              )}
            </div>

            <select
              name="partner_ref"
              id="partner_ref"
              value={data.partner_ref}
              style={{ minWidth: 200 }}
              onChange={this.onChangeSelect}
            >
              <option></option>
              {partners.map((item, index) => {
                return <option value={item._id}>{item.name}</option>;
              })}
            </select>
            <br />

            {data?.products?.length > 0 && (
              <div style={{ float: "left", width: "100%" }}>
                {data.products.map((item, index) => {
                  const product = item?.product || item;
                  return (
                    <>
                      <div
                        style={{
                          marginTop: 6,
                          backgroundImage: `url(${product?.urlImg})`,
                          backgroundSize: "cover",
                          float: "left",
                          position: "relative",
                          height: 186,
                          width: "100%",
                          maxWidth: "32%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          overflow: "hidden",
                          backgroundPosition: "center",
                        }}
                      >
                        <div
                          onClick={() => this.deleteProductKit(index)}
                          style={{
                            background: "#FFF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 17,
                            position: "absolute",
                            top: 6,
                            padding: 3,
                            cursor: "pointer",
                            right: 6,
                          }}
                        >
                          <Clear style={{ fontSize: 18 }} />
                        </div>

                        <span
                          style={{
                            position: "absolute",
                            width: "100%",
                            textAlign: "center",
                            background: "rgba(0, 200, 48, 0.68)",
                            padding: "5px 0px",
                            color: "white",
                            bottom: 36,
                          }}
                        >
                          {product.name}
                        </span>
                      </div>

                      <div
                        style={{ float: "left", width: "0.5%", height: 1 }}
                      ></div>
                    </>
                  );
                })}
              </div>
            )}

            <br />
            <br />
            <Button
              variant="outlined"
              style={{ marginTop: 25, marginBottom: 25 }}
              color="primary"
              onClick={() => this.getProdutosClickOpen()}
            >
              {data.products.length > 0
                ? "Editar Produtos Selecionados"
                : "Selecionar Produtos"}
            </Button>

            <TableSelectModal
              itemsSelected={data.products}
              items={list_products}
              order="asc"
              open={openModalProducts}
              refName="name"
              orderBy="name"
              page={0}
              rowsPerPage={10}
              selected={data.products}
              title="Produtos e Kits"
              nameButton="Selecionar"
              columns={columnsModalProducts}
              getRowsSelectedModal={this.getRowsSelectedModal}
              setItemSelected={this.setItemsSelected.bind(this)}
            />

            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.typeCheckout}
                  onChange={this.onCheckChange("typeCheckout")}
                  value="active"
                  color="primary"
                />
              }
              label="Checkout Completo"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.approvalPurchase}
                  onChange={this.onCheckChange("approvalPurchase")}
                  value="active"
                  color="primary"
                />
              }
              label="Enviar Aprovação de Pedidos por email"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.showAllProducts}
                  onChange={this.onCheckChange("showAllProducts")}
                  value="active"
                  color="primary"
                />
              }
              label="Exibir todos os produtos"
            />

            <br />
            {data.approvalPurchase ? (
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="approvalEmail">
                  E-mail de Aprovação
                </InputLabel>
                <Input
                  id="approvalEmail"
                  name="approvalEmail"
                  autoFocus
                  value={data.approvalEmail}
                  onChange={this.onChange}
                />
              </FormControl>
            ) : (
              ""
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={data.active}
                  onChange={this.onCheckChange("active")}
                  value="active"
                  color="primary"
                />
              }
              label="Ativo"
            />
          </form>
        </ContentContainer>

        {showAlert && (
          <AlertDialog
            msg={`Deseja gerar uma nova senha e enviar para o e-mail ${data.email}`}
            title="Reenviar Senha"
            open={showAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showAlert: false,
                  });
                },
              },
              {
                label: "Reenviar",
                onPress: () => {
                  this.setState({
                    showAlert: false,
                  });

                  this.resetPassword();
                },
              },
            ]}
          />
        )}

        {showAlertNewUser && (
          <AlertDialog
            msg={`Novo Parceiro Adicionado.`}
            title="Novo Parceiro"
            open={showAlertNewUser}
            buttons={[
              {
                label: "Fechar",
                onPress: () => {
                  this.setState({
                    showAlertNewUser: false,
                  });
                },
              },
            ]}
          />
        )}

        {showErrorNewUser && (
          <AlertDialog
            msg={error}
            title="Falha ao Cadastrar Parceiro"
            open={showErrorNewUser}
            buttons={[
              {
                label: "Fechar",
                onPress: () => {
                  this.setState({
                    showErrorNewUser: false,
                  });
                },
              },
            ]}
          />
        )}

        {showDeleteUserAlert && (
          <AlertDialog
            msg={`Deseja relmente excluir este imóvel ${data.name}? Obs.: Esta ação è irreversível`}
            title="Excluir Parceiro"
            open={showDeleteUserAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showDeleteUserAlert: false,
                  });
                },
              },
              {
                label: "Excluir",
                onPress: () => {
                  this.setState({
                    showDeleteUserAlert: false,
                  });

                  this.deleteUser();
                },
                color: "primary",
              },
            ]}
          />
        )}
      </>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
});

export default withStyles(styles)(EditBuilding);
