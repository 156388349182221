import { Box } from "@material-ui/core";
import React from "react";

export function ReportContainerRoot({ children }) {
   return (
      <Box style={{
         display: 'flex',
         flexDirection: 'column',
         gap: 20
      }}>
         {children}
      </Box>)

}