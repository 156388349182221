import Axios from "axios";

export const handleDownloadXLS = async ({ token, xlsFileName, url }) => {
  await Axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
    headers: { Authorization: "bearer " + token },
    responseType: "blob",
  })
    .then((response) => {
      if (!response.data) throw "Erro ao carregar dados.";

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = xlsFileName.concat(".xls");

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.log(error);
      alert(error);
    });
};
