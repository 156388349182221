import { FormControl, Input, InputLabel } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import Dropzone from "../ui/components/upload/Dropzone";
import AlertDialog from "../utils/AlertDialog";
import Loading from "../utils/Loading";

import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";

class EditCategory extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        title: "",
        parentCategory_id: "",
        urlImg: null,
      },
      parentCategory: "",
      categories: [],
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      showAlert: false,
      showDeleteCategoryAlert: false,
      loading: true,
      uploadedFile: null,
    };

    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onDeleteCategoryPress = this.onDeleteCategoryPress.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
  }

  componentDidMount = async () => {
    const { _id } = this.state.data;

    if (!_id) {
      this.setState({ loading: false });
      return;
    }

    const token = await localStorage.getItem("token");
    const _this = this;

    axios
      .get(`${process.env.REACT_APP_API_URL}/category/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        _this.setState({
          data: data,
        });

        const { parentCategory_id } = data;

        if (parentCategory_id != "") {
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/category/${parentCategory_id}`,
              {
                headers: { Authorization: "bearer " + token },
              }
            )
            .then((response_parent_id) => {
              _this.setState({
                categories: [response_parent_id.data],
              });
            });
        }
        this.setState({ loading: false });
      });
  };

  onChange = (e) => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value,
      },
      success: false,
    });
  };

  onSavePress = async () => {
    const { data } = this.state;

    if (!data._id) {
      this.newCategory();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = localStorage.getItem("token");
    axios
      .patch(`${process.env.REACT_APP_API_URL}/category/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.goBack();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  newCategory = async () => {
    const { data } = this.state;

    data._id = undefined;

    this.setState({
      saving: true,
    });

    const token = localStorage.getItem("token");
    const _this = this;

    axios
      .post(`${process.env.REACT_APP_API_URL}/category`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
          data: response.data.data,
        });

        this.props.history.goBack();

        _this.props.history.push(`${response.data.data._id}`);
      })
      .catch((error) => {
        const errorMsg =
          error?.response?.data?.error?.code === 11000
            ? "Esta categoria já existe"
            : "Desculpe, tivemos um problema";

        this.setState({
          saving: false,
          success: false,
        });
        alert(errorMsg);
      });
  };

  deleteCategory = async () => {
    const { data } = this.state;
    const token = localStorage.getItem("token");

    axios
      .delete(`${process.env.REACT_APP_API_URL}/category/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({
          saving: false,
          success: true,
        });

        this.props.history.push("/app/categories");
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
        });

        alert("Desculpe, tivemos um problema.");
      });
  };

  getCategories = async () => {
    const _this = this;

    if (_this.state.categories == "" || _this.state.categories.length <= 1) {
      const token = await localStorage.getItem("token");

      axios
        .get(`${process.env.REACT_APP_API_URL}/category/`, {
          headers: { Authorization: "bearer " + token },
        })
        .then((response) => {
          const { data } = response;

          _this.setState({
            categories: data,
          });
        });
    }
  };

  onCancelPress = () => {
    this.props.history.goBack();
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false, showAlert: false });
  };

  onDeleteCategoryPress = () => {
    this.setState({
      anchorEl: null,
      open: false,
      showDeleteCategoryAlert: true,
    });
  };

  onDropFiles = async (files) => {
    const file = files[0];

    this.setState({
      uploadedFile: {
        file,
        name: file.name,
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        error: false,
        url: null,
      },
    });

    const data = new FormData();
    data.append(
      "file",
      this.state.uploadedFile.file,
      this.state.uploadedFile.name
    );
    const token = await localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.setState({
          data: {
            ...this.state.data,
            urlImg: data.url,
          },
          uploadedFile: { ...this.state.uploadedFile, uploaded: true },
        });
      })
      .catch((error) => console.log("error", error));
  };

  onDeleteImage = () => {
    this.setState({
      data: {
        ...this.state.data,
        urlImg: null,
      },
    });
  };

  render() {
    const { classes, showDeleteCategoryAlert, loading } = this.state;
    const { data } = this.state;

    return (
      <>
        <Loading loading={loading} />

        <ContentHeader
          title={data.title || "Nova Categoria"}
          buttons={[
            { text: "Salvar", secondary: true, onClick: this.onSavePress },
            ...(data._id
              ? [
                  {
                    text: "Excluir",
                    destructive: true,
                    onClick: this.onDeleteCategoryPress,
                  },
                ]
              : []),
          ]}
        />

        <ContentContainer>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="categoria">Nome</InputLabel>
            <Input
              id="title"
              name="title"
              autoFocus
              value={data.title}
              onChange={this.onChange}
            />
          </FormControl>
          <Dropzone
            txt="Arraste aqui a imagem da categoria ou clique para selecionar o arquivo."
            onDrop={this.onDropFiles}
          />

          {!!this.state.data.urlImg && !this.state.uploadedFile && (
            <div style={{ position: "relative", width: 200 }}>
              <img
                src={this.state.data.urlImg}
                style={{ maxWidth: 200 }}
                alt="file-uploaded"
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: 8,
                  right: "5%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "red",
                    color: "#FFF",
                    borderRadius: 10,
                    width: 20,
                    height: 20,
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => this.onDeleteImage()}
                >
                  x
                </div>
              </div>
            </div>
          )}
          {!!this.state.uploadedFile && (
            <div style={{ position: "relative", width: 200 }}>
              <LoadingOverlay
                active={!this.state.uploadedFile.uploaded}
                spinner
                text=""
              >
                <div
                  className={classes.img}
                  style={{
                    backgroundImage: `url(${
                      this.state.uploadedFile?.url
                        ? this.state.uploadedFile?.url
                        : this.state.uploadedFile?.preview
                    })`,
                  }}
                />
              </LoadingOverlay>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: 8,
                  right: "5%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "red",
                    color: "#FFF",
                    borderRadius: 10,
                    width: 20,
                    height: 20,
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => this.setState({ uploadedFile: null })}
                >
                  x
                </div>
              </div>
            </div>
          )}
        </ContentContainer>

        {showDeleteCategoryAlert && (
          <AlertDialog
            msg={`Deseja relmente excluir a categoria ${data.title}?`}
            title="Ação Irreversível"
            open={showDeleteCategoryAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showDeleteCategoryAlert: false,
                  });
                },
              },
              {
                label: "Excluir",
                onPress: () => {
                  this.setState({
                    showDeleteCategoryAlert: false,
                  });

                  this.deleteCategory();
                },
                color: "primary",
              },
            ]}
          />
        )}
      </>
    );
  }
}

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    maxWidth: "450px",
    marginTop: theme.spacing.unit,
  },
  button: {
    marginLeft: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  success: {
    backgroundColor: green[600],
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  titleName: {
    flex: 1,
  },
  titleBar: {
    flexDirection: "row",
  },
  img: {
    maxWidth: 200,
    height: 200,
    backgroundColor: "gray",
    borderRadius: 8,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
});

export default withStyles(styles)(EditCategory);
