import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow, Paper, Table, Typography, Grid, Fab, Toolbar, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';

import { fade } from '@material-ui/core/styles/colorManipulator';

class ListCalendar extends Component {

  constructor(props) {
    super(props)

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      searchTerm: ''
    }

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {

    const { data } = this.state
    const _this = this

    const token = await localStorage.getItem('token');
    axios.get(`${process.env.REACT_APP_API_URL}/deliveryCalender`, {
      // axios.get('http://127.0.0.1:3000/kit', { 
      headers: { 'Authorization': "bearer " + token }
    })
      .then(response => {
        const { data } = response
        _this.setState({
          data
        })
        console.log('data ', data)
      })
  }

  onSearchChange = (e) => {

    this.setState({
      searchTerm: e.target.value
    })
  }

  render() {

    const { classes, data, searchTerm } = this.state


    return (
      <div>
        <Grid container>
          <Grid item xs >
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}

            >Calendário
            </Typography>
          </Grid>
          <Grid item>
            <Fab color="primary" aria-label="Add" className={classes.fab} size="small">
              <AddIcon onClick={() => this.props.history.push(`/app/calendar/edit/`)} />
            </Fab>
          </Grid>
        </Grid>

        <Paper className={classes.root}>
          <Toolbar>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Digite o titulo do Desconto"
                onChange={this.onSearchChange}
                value={searchTerm}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Toolbar>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="center">Data de Cadastro</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {data.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())).map(row => (

                <TableRow
                  key={row._id}
                  hover
                  onClick={() => this.props.history.push(`/app/calendar/edit/${row._id}`)}
                  className={classes.row}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>

    );
  }
}

ListCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 2,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: 'pointer'
  },
  fab: {
    margin: 0,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade('#ececec', 0.35),
    '&:hover': {
      backgroundColor: fade('#ececec', 0.80),
    },
    // marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    // width: theme.spacing.unit * 1,
    marginLeft: theme.spacing.unit * 1,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  }
});

export default withStyles(styles)(ListCalendar);
