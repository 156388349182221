import { Box, Button, FormControl, Input, Typography as Text, TextField } from "@material-ui/core";
import axios from 'axios';
import React, { useEffect, useReducer, useState } from "react";
import { formatter } from "../../functions";

const INITIAL_FORM_DATA = {
   monthYear: '',
   amount: null
}

export default function EditAdInvestment() {

   const reducer = (prev, next) => ({ ...prev, ...next });
   const [formData, setFormData] = useReducer(reducer, INITIAL_FORM_DATA);
   const [investmentId, setInvestmentId] = useState(null)
   const [newInvestment, setNewInvestment] = useState(false)

   function handleChange({ target: { name, value } }) {
      setFormData({ [name]: value })
   }

   async function submitForm() {
      try {
         const token = localStorage.getItem('token');
         const httpCall = newInvestment ? axios.post(`${process.env.REACT_APP_API_URL}/v2/adinvestment`, formData, { headers: { 'Authorization': "bearer " + token } }) : axios.patch(`${process.env.REACT_APP_API_URL}/v2/adinvestment/${investmentId}`, formData, { headers: { 'Authorization': "bearer " + token } });
         const response = await httpCall;

         if ((response?.status === 200) || (response?.status === 201)) { window.history.back() }
      } catch (error) {
         alert('Houve um prolema ao salvar os dados.')
      }
   }

   async function fetchInvestment(id) {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/adinvestment/${id}`, { headers: { 'Authorization': "bearer " + token } })

      setFormData(response.data.data)
   }

   useEffect(() => {
      const id = window.location.pathname.split('/').at(-1);

      if (id === 'new') {
         setNewInvestment(true)
         return
      }
      setInvestmentId(id);
      fetchInvestment(id);
   }, [])

   return (
      <Box style={{
         display: 'flex',
         flexDirection: 'column',
         gap: 24
      }}>
         <Text style={{ fontSize: 24, fontWeight: 500 }}>Investimento em {formData?.monthYear.split('-').reverse().join(' de ') || 'Ads'}</Text>
         <Box style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
            maxWidth: 300
         }}>
            <FormControl margin="normal"
               required style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 20
               }}>
               <TextField
                  variant="outlined"
                  name='monthYear'
                  type="month"
                  value={formData.monthYear}
                  onChange={handleChange}
               />
               <Input
                  variant="outlined"
                  type="number"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
               />
               <Button variant="contained" color="secondary" onClick={submitForm}>Salvar Investimento</Button>
            </FormControl>
         </Box>
         {formData.updatedAt &&
            <Box>
               <Text style={{ fontSize: 14, color: '#888' }}>Atualizado em {formatter.date(formData?.updatedAt)} por {formData?.updated_by?.name}.</Text>
            </Box>
         }
      </Box >
   )
}