import { Box } from "@material-ui/core";
import React from "react";
import { cardStyle } from "../card.styles";
import { Text } from "../../../../../layout/components/text";

export const KitLabel = () => (
  <Box style={cardStyle.kitContainer}>
    <Text style={cardStyle.kitText}>KIT</Text>
  </Box>
);
