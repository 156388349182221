import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Box } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { config } from '../../config'
import EditPasswordDialog from '../user/EditPasswordDialog'
import { Colors } from '../../styles/Colors';
import { StockAlert } from '../stockAlert/stockAlert';


const styles = theme => ({
   appBar: {
      zIndex: theme.zIndex.drawer + 1,
   },
   link: {
      textDecoration: 'none',
      color: '#FFF'
   },
   grow: {
      flexGrow: 1,
   }
});

class Header extends Component {

   constructor(props) {
      super(props)

      const { classes } = props

      this.state = {
         anchorEl: null,
         mobileMoreAnchorEl: null,
         classes,
         isEditPasswordDialogOpen: false
      }
   }

   handleProfileMenuOpen = event => {
      this.setState({ anchorEl: event.currentTarget });
   };

   handleMenuClose = () => {
      this.setState({ anchorEl: null });
   };

   handleEditProfile = () => {
      this.setState({ anchorEl: null });
      this.props.history.push('/app/profile')
   }

   handleLogout = () => {
      localStorage.removeItem('token')
      localStorage.removeItem('_id')

      this.props.history.push('/')
   }

   handleEditPassword = () => {
      this.setState({ anchorEl: null, isEditPasswordDialogOpen: true });
   }

   renderMenu = () => {

      const { anchorEl, isEditPasswordDialogOpen } = this.state
      const isMenuOpen = Boolean(anchorEl);
      return (
         <div>
            <Menu
               anchorEl={anchorEl}
               anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
               transformOrigin={{ vertical: 'top', horizontal: 'right' }}
               open={isMenuOpen}
               onClose={this.handleMenuClose}
            >
               <MenuItem onClick={this.handleEditProfile}>Meus Dados</MenuItem>
               <MenuItem onClick={this.handleEditPassword}>Alterar Senha</MenuItem>
               <MenuItem onClick={this.handleLogout}>Deslogar</MenuItem>
            </Menu>
            <EditPasswordDialog
               open={isEditPasswordDialogOpen}
               onClose={() => this.setState({ isEditPasswordDialogOpen: false })}
            />
         </div>
      )
   }

   render() {

      const isMenuOpen = Boolean(this.state.anchorEl);
      const { classes } = this.state
      const { companyName } = config

      return (
         <Box style={{ display: 'flex', justifyContent: 'space-between', padding: `0px 48px`, backgroundColor: '#212f3107', borderBottom: `1px solid #e5e5e5` }}>
            <StockAlert />
            {this.renderMenu()}
            <IconButton
               aria-owns={isMenuOpen ? 'material-appbar' : undefined}
               aria-haspopup="true"
               onClick={this.handleProfileMenuOpen}
               color={Colors.darkGreen}
            >
               <AccountCircle />
            </IconButton>
         </Box>
      )
   }

}

Header.propTypes = {
   classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);