import React, { Component } from 'react'
import axios from 'axios'
import {
   Paper, Typography, Button, FormControl, InputLabel, Input, CircularProgress, TextField
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';
import green from '@material-ui/core/colors/green';
import classNames from 'classnames';
import Confirm from '../utils/Confirm'
import AlertDialog from '../utils/AlertDialog'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loading from '../utils/Loading';

class EditUnit extends Component {
   constructor(props) {
      super(props)

      const { classes } = props;
      const { pathname } = props.location
      const arrayPath = pathname.split('/')
      let _id = arrayPath[arrayPath.length - 4];

      if (_id === 'app') {
         _id = arrayPath[arrayPath.length - 1];
      }

      this.state = {
         classes,
         data: {
            _id,
            id_user: '',
            cpf: '',
            name: ''

         },
         data_user: [],
         saving: false,
         success: false,
         error: null,
         anchorEl: null,
         open: false,
         isConfirmOpen: false,
         showAlert: false,
         showDeleteUnitAlert: false,
         loading: false
      }

      this.onChange = this.onChange.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.onDeleteUnitPress = this.onDeleteUnitPress.bind(this);
      this.deleteUnit = this.deleteUnit.bind(this);
      this.onCheckChange = this.onCheckChange.bind(this);
   }

   componentDidMount = async () => {
      const { data } = this.state
      if (!data._id) {
         this.getClient();
         return;
      }
      this.getClient();
      this.getEditBlockCLient();



   }
   getEditBlockCLient = async () => {
      const { data } = this.state
      const token = await localStorage.getItem('token');
      const _this = this

      axios.get(`${process.env.REACT_APP_API_URL}/blockClient/${data._id}`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response.data

            _this.setState({
               data: data
            })
         })
         .catch(error => {
            this.setState({
               saving: false,
               success: false,
               error: error.response,
               data: []
            })
            console.log('error ', error)
         })

   }


   onChange = (e) => {
      this.setState({
         data: {
            ...this.state.data,
            [e.target.name]: e.target.value
         }
      })


   }
   onSavePress = async () => {

      const { data } = this.state



      if (!data._id) {
         this.newblock()
         return;
      }

      this.setState({
         saving: true
      })

      const token = await localStorage.getItem('token');
      const _this = this

      axios.patch(`${process.env.REACT_APP_API_URL}/blockClient/${data._id}`, data, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            this.setState({
               saving: false,
               success: true
            })
            this.props.history.goBack()
         })
         .catch(error => {
            this.setState({
               saving: false,
               success: false,
               error: error.response
            })
         })
   }

   newblock = async () => {

      const { data } = this.state

      data._id = undefined

      this.setState({
         saving: true
      })

      const token = await localStorage.getItem('token');
      const _this = this
      this.setState({ ...this.state, loading: true })
      axios.post(`${process.env.REACT_APP_API_URL}/blockClient`, data, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            this.setState({
               saving: false,
               success: true,
               data: response.data.data,
               loading: false
            })

            this.props.history.goBack()
            _this.props.history.push(`${response.data.data._id}`)
         })
         .catch(error => {
            this.setState({
               saving: false,
               success: false,
               error: error.response,
               loading: false
            })
         })
   }

   deleteUnit = async () => {

      const { data } = this.state
      const token = await localStorage.getItem('token');
      this.setState({ ...this.state, loading: true })
      axios.delete(`${process.env.REACT_APP_API_URL}/blockClient/${data._id}`, {
         headers: { 'Authorization': "bearer " + token }
      }).then(response => {
         this.setState({
            saving: false,
            success: true,
            loading: false
         })
         this.props.history.goBack()
         this.props.history.push('/app/block/')
      }).catch(error => {
         this.setState({
            saving: false,
            success: false,
            error: error.response,
            loading: false
         })
      })
   }
   getClient = () => {
      const _this = this
      const token = localStorage.getItem('token');
      this.setState({ ...this.state, loading: true })
      axios.get(`${process.env.REACT_APP_API_URL}/clients`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response
            _this.setState({
               data_user: data,
               loading: false
            })
         })
   }

   onCancelPress = () => {
      this.props.history.goBack()
   }

   handleClick = event => {
      this.setState({ anchorEl: event.currentTarget, open: true });
   };

   handleClose = () => {
      this.setState({ anchorEl: null, open: false, showAlert: false });
   };

   onDeleteUnitPress = () => {
      this.setState({ anchorEl: null, open: false, showDeleteUnitAlert: true });
   };

   onCheckChange = name => event => {
      const { data } = this.state
      this.setState({
         data: {
            ...data,
            [name]: event.target.checked
         },
         success: false
      });
   };
   changeClient = (params) => {
      let value = params.inputProps.value
      this.setState({
         data: {
            ...this.state.data,
            name: value
         }
      })
   }

   render() {

      const { classes, saving, success, showDeleteUnitAlert, data, data_user, loading } = this.state
      const buttonClassname = classNames({ [classes.buttonSuccess]: success, });

      return (
         <Paper className={classes.paper}>
            <Loading loading={loading} />
            <Typography component="h1" variant="h5">
               Bloquear Cliente

            </Typography>
            <form className={classes.form}>
               <FormControl margin="normal" required fullWidth>

                  <Autocomplete
                     id='free-solo-demo'
                     freeSolo
                     onChange={(event, value_user) => { this.setState({ data: { ...data, name: value_user?.name, cpf: value_user?.cpf || '', id_user: value_user?._id } }) }}
                     options={data_user.data}
                     getOptionLabel={(option) => option?.name || ''}
                     renderInput={(params) => (
                        <TextField {...params} label="Nome" margin="normal" variant="outlined" name="nome" value={data.name} />
                     )}
                  />

               </FormControl>
               <br />
               <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email" >Id Usuario</InputLabel>
                  <Input type='text' id="id_user" name="id_user" autoFocus value={data.id_user}
                     onChange={this.onChange}
                     disabled
                  />
               </FormControl>
               <br />
               <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="email" >Cpf</InputLabel>
                  <Input id="cpf" name="cpf" autoFocus value={data.cpf}
                     onChange={this.onChange}
                  />
               </FormControl>
               <br />

               <div className={classes.wrapper}>
                  <Button
                     variant="contained"
                     color="primary"
                     className={buttonClassname}
                     disabled={saving}
                     onClick={this.onSavePress}
                  >
                     <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                     Salvar
                     {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Button>
                  <Button variant="contained" color="default" className={classes.button}
                     onClick={this.onCancelPress}
                  >Cancelar
                  </Button>
                  {this.state.error && <Typography>{this.state.error}</Typography>}


                  <Button variant="contained"
                     style={{ backgroundColor: '#f44336', color: '#FFF' }}
                     className={classes.button}
                     onClick={() => this.setState({ isConfirmOpen: true })}>
                     Excluir
                  </Button>
                  {this.state.error && <Typography>{this.state.error}</Typography>}

                  <Confirm open={this.state.isConfirmOpen} title='Exclusão de Bloqueio cliente' text='Deseja realmente excluir esse cadastro de bloqueio de cliente?'
                     onDeletePress={this.deleteUnit} onClose={() => this.setState({ isConfirmOpen: false })}
                  />

               </div>
            </form>

            {
               showDeleteUnitAlert && <AlertDialog
                  msg={`Deseja relmente excluir este  cadastro de Bloqueio de cliente ${data.title}? Obs.: Esta ação è irreversível`}
                  title="Excluir Filial"
                  open={showDeleteUnitAlert}
                  buttons={[
                     {
                        label: 'Cancelar',
                        onPress: () => {
                           this.setState({
                              showDeleteUnitAlert: false
                           })
                        },

                     },
                     {
                        label: 'Excluir',
                        onPress: () => {
                           this.setState({
                              showDeleteUnitAlert: false
                           })

                           this.deleteUnit()
                        },
                        color: 'primary'
                     }
                  ]} />
            }
         </Paper >
      )
   }
}

const styles = theme => ({
   main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
         width: 400,
         marginLeft: 'auto',
         marginRight: 'auto',
      },
   },
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
   },
   avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      maxWidth: '450px',
      marginTop: theme.spacing.unit,
   },
   button: {
      marginLeft: theme.spacing.unit
   },
   leftIcon: {
      marginRight: theme.spacing.unit,
   },
   success: {
      backgroundColor: green[600],
   },
   buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   wrapper: {
      marginTop: theme.spacing.unit * 3,
      position: 'relative',
   },
   buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
         backgroundColor: green[700],
      },
   },
   titleName: {
      flex: 1
   },
   titleBar: {
      flexDirection: 'row'
   },
   chips: {
      display: 'flex',
      flexWrap: 'wrap',
   },
   chip: {
      margin: theme.spacing.unit / 4,
   },
});

export default (withStyles(styles)(EditUnit))