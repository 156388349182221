import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';

const Title = (props) => (
    <div className={props.classes.title}>
        {props.text}
    </div>
)

const styles = theme => ({
    title: {
        fontSize: 20,
        fontWeight: '500',
        marginBottom: 45
    }
});

export default (withStyles(styles)(Title))