import { Box, Button, Typography as Text } from "@material-ui/core";
import axios from 'axios';
import React, { useEffect, useState } from "react";
import Chart from 'react-apexcharts';
import { Link } from "react-router-dom";
import { formatter, uid } from "../../functions";
import { Colors } from "../../styles/Colors";

export default function ListAdInvestment() {
   const [data, setData] = useState([])

   async function getAdInvestments() {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/adinvestment`, { headers: { 'Authorization': "bearer " + token } });

      setData(response?.data?.data)
   }

   useEffect(() => { getAdInvestments() }, [])

   return (
      <Box style={{
         display: 'flex',
         flexDirection: 'column',
         gap: 24
      }}>
         <Text style={{ fontSize: 24, fontWeight: 500 }}>Investimento em Ads</Text>
         <Chart
            options={{
               chart: {
                  id: 'Investimento em ads',
                  toolbar: { show: false }
               },
               xaxis: { categories: data.map(item => item.monthYear) },
               yaxis: {
                  labels: {
                     formatter: function (value) {
                        return formatter.currency(value)
                     }
                  },
               },
               stroke: {
                  curve: 'smooth',
                  width: 2
               },
               colors: [Colors.lightGreen],
               fill: {
                  type: 'gradient',
                  gradient: {
                     shadeIntensity: 1,
                     opacityFrom: 0.7,
                     opacityTo: 0.9,
                     stops: [0, 90, 100]
                  }
               },
               dataLabels: { enabled: false }
            }}

            series={[{
               name: 'Investimento em ads',
               data: data.map(item => item.amount)
            }]}
            type="area" width='100%' height={320}
         />
         <Box style={{ display: 'flex', flexDirection: 'column', gap: 18 }}>
            <Text style={{ fontSize: 14, fontWeight: 500 }}>Mensal</Text>
            <Box sx={{
               display: 'flex',
               flexWrap: 'wrap',
               gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
               gap: '20px'
            }}>
               {[...data, 'add'].reverse().map(month => {
                  const addButton = month === 'add';
                  return (
                     <Link key={uid()} to={`/app/marketing/adinvestment/edit/${addButton ? 'new' : month._id}`} style={{ display: 'flex', flex: 1, textDecoration: 'none', minWidth: 150 }}>
                        <Button
                           variant="outlined"
                           color='secondary'
                           style={{ flex: 1, padding: `16px 10px` }}>
                           <Box style={{ display: 'flex', flexDirection: 'column' }}>
                              {addButton ?
                                 <span>+</span>
                                 :
                                 <>
                                    <span>{month.monthYear}</span>
                                    <span>{formatter.currency(month.amount)}</span>
                                 </>
                              }
                           </Box>
                        </Button>
                     </Link>
                  )
               })}
            </Box>
         </Box>
      </Box >
   )
}