import React from "react";
import { Box } from "@material-ui/core";
import { Text } from "../layout/components/text";

export const ExternalLink = ({ data, icon, action }) =>
  data ? (
    <Box style={{ display: "flex", alignItems: "center", gap: 8 }}>
      <Text>{data}</Text>
      <Box onClick={action} style={{ cursor: "pointer" }}>
        {icon}
      </Box>
    </Box>
  ) : (
    "-"
  );
