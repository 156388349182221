import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import Axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer";
import Loading from "../utils/Loading";

const validateDates = (dates) =>
  dates.some((date) => !/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/.test(date));

export const EndContract = (props) => {
  const token = localStorage.getItem("token");
  const { contract, handleClose, endingDetails } = props;
  const reducer = (prev, next) => ({ ...prev, ...next });

  const [endContractData, setEndContractData] = useReducer(reducer, {
    collectResquestedAt: endingDetails?.collectResquestedAt,
    collectDate: endingDetails?.collectDate,
    collectReason: endingDetails?.collectReason,
    endSubscription: true,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target }) => {
    const dateFields = [
      "collectResquestedAt",
      "collectDate",
      "billing_at",
      "due_at",
    ];
    const checkboxFields = ["endSubscription"];

    const { name, value } = target;
    let formatedValue = value;

    if (dateFields.includes(name)) formatedValue = formatDate(value);
    if (checkboxFields.includes(name)) formatedValue = target.checked;

    setEndContractData({ [name]: formatedValue });
  };

  const formatDate = (date) => {
    return date
      .replace(/[^\d/\\]/g, "")
      .slice(0, 10)
      .replace(/^(\d{2})\/?/, "$1")
      .replace(/^(\d{2})\/?(\d{1,2})\/?/, "$1/$2")
      .replace(/^(\d{2})\/?(\d{2})\/?(\d{1,4})$/, "$1/$2/$3");
  };

  const validateFields = () => {
    const fieldsToBeValidated = { ...endContractData, endSubscription: true };
    const missingFields = !Object.values(fieldsToBeValidated).every(Boolean);

    if (missingFields) return true;

    const dates = [
      endContractData.collectResquestedAt,
      endContractData.collectDate,
    ];

    const invalidDates = validateDates(dates);
    return invalidDates;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const hasInvalidFields = validateFields();
      if (hasInvalidFields)
        return alert(
          "Dados inválidos\n\nPor favor, verifique se todos os campos estão preenchidos corretamente."
        );

      await Axios.post(
        `${process.env.REACT_APP_API_URL}/v2/admin/endcontract`,
        endContractData,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
        .then((response) => {
          if (response?.data?.errors?.length > 0) {
            const errors = response.data.errors.join("\n");
            return alert(`Desculpe\n\n${errors}`);
          }
          handleClose();
          alert("Tudo certo!\n\nContrato finalizado com sucesso.");
          window.location.reload();
        })
        .catch((error) => {
          alert(
            "Desculpe\n\nTivemos um problema ao processar sua solicitação."
          );
        });
    } catch (error) {
      alert("Desculpe\n\nTivemos um problema.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contract.id) {
      setEndContractData({
        contractId: contract.id,
        subscriptionId: contract.subscriptionId,
      });
    }
  }, [contract]);

  return (
    <>
      <Loading loading={loading} />
      <Box style={styles.root} onClick={handleClose}>
        <ContentContainer
          label="Finalizar Contrato"
          style={{ overflow: "auto", maxHeight: "90%" }}
          onClick={(e) => e.stopPropagation()}
        >
          <Box style={{ display: "flex", gap: 30, flex: 1 }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 14,
                minWidth: 360,
              }}
            >
              <TextField
                variant="outlined"
                id="collectResquestedAt"
                label={`Data da Solicitação`}
                name="collectResquestedAt"
                autoFocus
                value={endContractData.collectResquestedAt}
                onChange={handleChange}
                InputLabelProps={styles.InputLabelProps}
                InputProps={styles.InputProps}
                inputProps={styles.inputProps}
                style={{ borderRadius: 40, marginTop: 5 }}
              />
              <TextField
                variant="outlined"
                id="collectDate"
                label={`Data da Retirada`}
                name="collectDate"
                value={endContractData.collectDate}
                onChange={handleChange}
                InputLabelProps={styles.InputLabelProps}
                InputProps={styles.InputProps}
                inputProps={styles.inputProps}
                style={{ borderRadius: 40 }}
              />
              <FormControl fullWidth>
                <InputLabel id="collectReasonLabel">
                  Motivo da Retirada
                </InputLabel>
                <Select
                  labelId="collectReasonLabel"
                  id="collectReason"
                  name="collectReason"
                  value={endContractData.collectReason}
                  label="Motivo da Retirada"
                  onChange={handleChange}
                >
                  <MenuItem value={`Necessidade Temporária Finalizada`}>
                    Necessidade Temporária Finalizada
                  </MenuItem>
                  <MenuItem
                    value={`Mudança para Imóvel Mobiliado (Locado)`}
                  >{`Mudança para Imóvel Mobiliado (Locado)`}</MenuItem>
                  <MenuItem value={`Mudança para Imóvel Próprio`}>
                    Mudança para Imóvel Próprio
                  </MenuItem>
                  <MenuItem value={`Reavaliação de Custos`}>
                    Reavaliação de Custos
                  </MenuItem>
                  <MenuItem value={`Inadimplência`}>Inadimplência</MenuItem>
                  <MenuItem value={`Compra de Móvel`}>Compra de Móvel</MenuItem>
                  <MenuItem value={`Projeto (Período Estabelecido)`}>
                    Projeto (Período Estabelecido)
                  </MenuItem>
                  <MenuItem value={`Não Identificado`}>
                    Não Identificado
                  </MenuItem>
                </Select>
              </FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={endContractData.endSubscription} />
                  }
                  name="endSubscription"
                  label="Encerrar assinatura Vindi"
                  onChange={handleChange}
                />
              </FormGroup>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 10,
              marginTop: 12,
            }}
          >
            <TuimButton text={"Finalizar"} onClick={handleSubmit} />
            <TuimButton tertiary text={"Cancelar"} onClick={handleClose} />
          </Box>
        </ContentContainer>
      </Box>
    </>
  );
};

const styles = {
  root: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000044",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  main: {
    backgroundColor: "#fff",
    borderRadius: 14,
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  InputProps: {
    style: {
      borderRadius: 40,
      paddingLeft: 10,
    },
  },
  inputProps: {
    style: {
      fontSize: 15,
      borderRadius: 40,
      paddingLeft: 10,
      WebkitBoxShadow: `0 0 0 1000px #fff inset`,
    },
  },
  InputLabelProps: {
    style: {
      marginRight: 30,
      marginLeft: 4,
    },
  },
};
