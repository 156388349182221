import React from "react";
import { ReportContainer } from "..";
import { formatter } from "../../../../functions";
import { ReportCard } from "../../ReportCard";
import { statusOrderByHistory } from "./Helpers";

export const History = ({ data }) => (
  <ReportContainer.Root>
    <>
      <ReportCard.Group title={'Total'}>
        <ReportCard.Root>
          <ReportCard.Header value={formatter.number(data.statistics.contracts.all)} caption='Assinaturas' />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              { key: 'Residencial', value: `${formatter.number(data.statistics.contracts.residential.sum)} (${formatter.number(data.statistics.contracts.residential.percentage)}%)` },
              { key: 'Home Office', value: `${formatter.number(data.statistics.contracts.homeOffice.sum)} (${formatter.number(data.statistics.contracts.homeOffice.percentage)}%)` },
              { key: 'Eletro', value: `${formatter.number(data.statistics.contracts.eletro.sum)} (${formatter.number(data.statistics.contracts.eletro.percentage)}%)` }
            ]}
          />
        </ReportCard.Root>

        <ReportCard.Root>
          <ReportCard.Header value={formatter.currency(data.statistics.income.estimated.all)} caption='Fechamento' />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              { key: 'Residencial', value: `${formatter.currency(data.statistics.income.estimated.residential.sum)} (${formatter.number(data.statistics.income.estimated.residential.percentage)}%)` },
              { key: 'Home Office', value: `${formatter.currency(data.statistics.income.estimated.homeOffice.sum)} (${formatter.number(data.statistics.income.estimated.homeOffice.percentage)}%)` },
              { key: 'Eletro', value: `${formatter.currency(data.statistics.income.estimated.eletro.sum)} (${formatter.number(data.statistics.income.estimated.eletro.percentage)}%)` },
            ]}
          />
        </ReportCard.Root>

        <ReportCard.Root>
          <ReportCard.Header value={formatter.currency(data.statistics.income.all)} caption='Faturamento' />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              { key: 'Residencial', value: `${formatter.currency(data.statistics.income.residential.sum)} (${formatter.number(data.statistics.income.residential.percentage)}%)` },
              { key: 'Home Office', value: `${formatter.currency(data.statistics.income.homeOffice.sum)} (${formatter.number(data.statistics.income.homeOffice.percentage)}%)` },
              { key: 'Eletro', value: `${formatter.currency(data.statistics.income.eletro.sum)} (${formatter.number(data.statistics.income.eletro.percentage)}%)` },
            ]}
          />
        </ReportCard.Root>

        <ReportCard.Root>
          <ReportCard.Header value={formatter.currency(data.statistics.average.ticket)} caption='Ticket Médio' />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              { key: 'Período médio', value: formatter.number(data.statistics.average.period) },
              { key: 'Top Ticket', value: formatter.currency(data.statistics.average.topTicket) }
            ]}
          />
        </ReportCard.Root>

        <ReportCard.Root>
          <ReportCard.Header value={formatter.currency(data.statistics.discount.sum)} caption='em descontos' />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              { key: 'Porcentagem', value: `${formatter.number(data.statistics.discount.percentage)}%` },
            ]}
          />
        </ReportCard.Root>

        <ReportCard.Root>
          <ReportCard.Header value={formatter.currency(data.statistics.income.estimated.earnByExtension - data.statistics.income.estimated.lossByAnticipation)} caption='Balanço Antecipação/Renovação' />
          <ReportCard.Divider />
          <ReportCard.Table
            data={[
              { key: 'Perda Estim. por Antecipação', value: formatter.currency(data.statistics.income.estimated.lossByAnticipation) },
              { key: 'Ganho Estim. por Renovação', value: formatter.currency(data.statistics.income.estimated.earnByExtension) },
            ]}
          />
        </ReportCard.Root>
      </ReportCard.Group>

      {statusOrderByHistory.map(({ status, display, colors }, index) => {
        const itemStatistics = data.data?.[status]?.contracts?.[status];
        return (
          itemStatistics ?
            <ReportCard.Group key={`${status}_${index}`} title={display}>
              <ReportCard.Root background={colors.background} border={colors.border}>
                <ReportCard.Header value={formatter.number(data.data[status].statistics.contracts.all)} caption='Assinaturas' />
                <ReportCard.Divider />
                <ReportCard.Table
                  data={status === 'active' ?
                    [
                      { key: 'No prazo', value: `${formatter.number(data.data[status].statistics.contracts.withinPeriod.sum)} (${formatter.number(data.data[status].statistics.contracts.withinPeriod.percentage)}%)` },
                      { key: 'Prorrogadas', value: `${formatter.number(data.data[status].statistics.contracts.extended.sum)} (${formatter.number(data.data[status].statistics.contracts.extended.percentage)}%)` },
                      { key: 'Prorrog. média', value: `${formatter.number(data.data[status].statistics.contracts.extended.averagePeriod)} meses` }
                    ]
                    :
                    [
                      { key: 'No prazo', value: `${formatter.number(data.data[status].statistics.contracts.finishedWithinPeriod.sum)} (${formatter.number(data.data[status].statistics.contracts.finishedWithinPeriod.percentage)}%)` },
                      { key: 'Prorrogadas', value: `${formatter.number(data.data[status].statistics.contracts.extended.sum)} (${formatter.number(data.data[status].statistics.contracts.extended.percentage)}%)` },
                      { key: 'Prorrog. média', value: `${formatter.number(data.data[status].statistics.contracts.extended.averagePeriod)} meses` },
                      { key: 'Antecipadas', value: `${formatter.number(data.data[status].statistics.contracts.anticipated.sum)} (${formatter.number(data.data[status].statistics.contracts.anticipated.percentage)}%)` },
                      { key: 'Antecip. média', value: `${formatter.number(data.data[status].statistics.contracts.anticipated.averagePeriod)} meses` },
                    ]
                  }
                />
              </ReportCard.Root>

              {status === 'active' ?
                <>
                  <ReportCard.Root background={colors.background} border={colors.border}>
                    <ReportCard.Header value={formatter.currency(data.data[status].statistics.income.estimated.all)} caption='Faturamento Estimado' />
                    <ReportCard.Divider />
                    <ReportCard.Table
                      data={[
                        { key: 'Residencial', value: `${formatter.currency(data.data[status].statistics.income.estimated.residential.sum)} (${formatter.number(data.data[status].statistics.income.estimated.residential.percentage)}%)` },
                        { key: 'Home Office', value: `${formatter.currency(data.data[status].statistics.income.estimated.homeOffice.sum)} (${formatter.number(data.data[status].statistics.income.estimated.homeOffice.percentage)}%)` },
                        { key: 'Eletro', value: `${formatter.currency(data.data[status].statistics.income.estimated.eletro.sum)} (${formatter.number(data.data[status].statistics.income.estimated.eletro.percentage)}%)` },
                      ]}
                    />
                  </ReportCard.Root>
                </>
                :
                <></>
              }

              <ReportCard.Root background={colors.background} border={colors.border}>
                <ReportCard.Header value={formatter.currency(data.data[status].statistics.income.all)} caption='Total Faturado' />
                <ReportCard.Divider />
                <ReportCard.Table
                  data={[
                    { key: 'Residencial', value: `${formatter.currency(data.data[status].statistics.income.residential.sum)} (${formatter.number(data.data[status].statistics.income.residential.percentage)}%)` },
                    { key: 'Home Office', value: `${formatter.currency(data.data[status].statistics.income.homeOffice.sum)} (${formatter.number(data.data[status].statistics.income.homeOffice.percentage)}%)` },
                    { key: 'Eletro', value: `${formatter.currency(data.data[status].statistics.income.eletro.sum)} (${formatter.number(data.data[status].statistics.income.eletro.percentage)}%)` },
                  ]}
                />
              </ReportCard.Root>

              <ReportCard.Root background={colors.background} border={colors.border}>
                <ReportCard.Header value={formatter.currency(data.data[status].statistics.average.ticket)} caption='Ticket Médio' />
                <ReportCard.Divider />
                <ReportCard.Table
                  data={[
                    { key: 'Período médio', value: formatter.number(data.data[status].statistics.average.period) },
                    { key: 'Top Ticket', value: formatter.currency(data.data[status].statistics.average.topTicket) }
                  ]}
                />
              </ReportCard.Root>

              <ReportCard.Root background={colors.background} border={colors.border}>
                <ReportCard.Header value={formatter.currency(data.data[status].statistics.discount.sum)} caption='em descontos' />
                <ReportCard.Divider />
                <ReportCard.Table
                  data={[
                    { key: 'Porcentagem', value: `${formatter.number(data.data[status].statistics.discount.percentage)}%` },
                  ]}
                />
              </ReportCard.Root>
            </ReportCard.Group>
            :
            <></>
        )
      })}
    </>
  </ReportContainer.Root>
);