import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { useFetch } from "../../hooks/useFetch";
import { Error } from "../error/error";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import Loading from "../utils/Loading";
import { pluralize } from "../../functions";
import { handleDownloadXLS } from "../utils/downloadXLS";

const ListQuotations = (props) => {
  const { data = [], error, isLoading } = useFetch({ url: `v2/quotations` });
  const token = localStorage.getItem("token");

  if (error) return <Error />;

  return (
    <>
      <Loading loading={isLoading} />

      <ContentHeader
        title="Cotações"
        subtitle={`${data.length} ${pluralize("registro", data.length)}`}
        buttons={[
          {
            text: "Exportar para Excel",
            secondary: true,
            onClick: async () =>
              await handleDownloadXLS({
                url: "/v2/quotation-download-xls",
                xlsFileName: "Tabela_de_Cotação",
                token,
              }),
          },
        ]}
      />

      <ContentContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          // count={count}
          count={10}
          // rowsPerPage={rowsPerPage}
          rowsPerPage={5}
          page={1}
          backIconButtonProps={{
            "aria-label": "Página anterior",
          }}
          nextIconButtonProps={{
            "aria-label": "Próxima página",
          }}
          // onChangePage={this.handleChangePage}
          // onChangeRowsPerPage={this.handleChangeRowsPerPage}
        /> */}

        <Table style={{ maxWidth: "100%" }} id="quotationTable">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Período</TableCell>
              <TableCell>Empresa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow
                key={item._id}
                hover
                onClick={() =>
                  props.history.push(`/app/quotations/${item._id}`)
                }
                style={{ cursor: "pointer" }}
              >
                <TableCell component="th" scope="row">
                  {item.id_user.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.period}
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.company}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          // count={count}
          count={10}
          // rowsPerPage={rowsPerPage}
          rowsPerPage={5}
          page={1}
          backIconButtonProps={{
            "aria-label": "Página anterior",
          }}
          nextIconButtonProps={{
            "aria-label": "Próxima página",
          }}
          // onChangePage={this.handleChangePage}
          // onChangeRowsPerPage={this.handleChangeRowsPerPage}
        /> */}
      </ContentContainer>
    </>
  );
};

export default ListQuotations;
