import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Moment from 'react-moment';
import Numeral from "numeral";
Numeral.locale('pt-br');

export default function LogVisible({ onCloseModal, openLog, id_log = "" }) {
   const [data, setData] = useState({ description: "", system: "", type: "", ip: "", createdAt: "", onCloseModal, email: "" })
   const { description = "", system = "", type = "", ip = "", createdAt = "", email = "" } = data

   const getLog = async () => {
      const token = await localStorage.getItem('token');
      axios.post(`${process.env.REACT_APP_API_URL}/getLog`, { id_log: id_log }, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(async (response) => {
            const { data } = response
            await setData({ ...data })

         })
   }
   useEffect(() => { getLog() }, [])

   return (
      <>
         <Modal
            open={openLog}
            onClose={() => onCloseModal()}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
         >
            <div style={{
               position: 'absolute',
               width: 500,
               backgroundColor: '#fff',
               border: '2px solid #000',
               padding: 20,
               left: '35%',
               top: '25%',
               minHeight: 308,
               border: 'none'
            }}>
               <label><b>Detalhes do Log </b></label>
               <br />
               <div style={{ width: '100%', marginTop: 10 }}>
                  <div style={{ display: 'flex', padding: 10 }}>
                     <label style={{ marginRight: 5 }}><b>Descrição:</b></label>
                     <label>{description}</label>

                  </div>
                  <div style={{ display: 'flex', padding: 10 }}>
                     <label style={{ marginRight: 5 }}><b>Sistema:</b></label>
                     <label>{system}</label>

                  </div>
                  <div style={{ display: 'flex', padding: 10 }}>
                     <label style={{ marginRight: 5 }}><b>Tipo:</b></label>
                     <label>{type}</label>

                  </div>
                  <div style={{ display: 'flex', padding: 10 }}>
                     <label style={{ marginRight: 5 }}><b>Ip:</b></label>
                     <label>{ip}</label>

                  </div>
                  <div style={{ display: 'flex', padding: 10 }}>
                     <label style={{ marginRight: 5 }}><b>E-mail:</b></label>
                     <label>{email}</label>

                  </div>
                  <div style={{ display: 'flex', padding: 10 }}>
                     <label style={{ marginRight: 5 }}><b>Data:</b></label>
                     <label><Moment format="DD/MM/YYYY" date={new Date(createdAt)} ></Moment></label>

                  </div>


               </div>
               <br />
               <br />
               <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onCloseModal()}
               >

                  Fechar

               </Button>
            </div>
         </Modal>
      </>
   )
}
