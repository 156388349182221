import React, {Component} from 'react';
import {DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, TextField, Button} from '@material-ui/core';

export default class ForgotPasswordDialog extends Component {

	constructor(props){
		super(props)

		this.state= {
			email: ''
		};

		this.onChange = this.onChange.bind(this);
	}

	onChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	handleClose = () => {
		this.props.onClose()
		this.setState({ open: false });
	};

	render() {
		const {isForgotPasswordModalOpen} = this.props

		return (
			<div>
				<Dialog
					open={isForgotPasswordModalOpen}
					aria-labelledby="form-dialog-title"
				>
					<DialogTitle id="form-dialog-title">w3Lib Admin</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Insira seu e-mail para receber uma nova senha. Verifique também caixa de SPAM
            			</DialogContentText>
						<TextField
							autoFocus
							margin="dense"
							id="email"
							label="E-mail"
							type="email"
							name='email'
							fullWidth
							value={this.state.email} 
							onChange={this.onChange}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose} color="primary">
							Cancelar
            			</Button>
						<Button onClick={() => this.props.onForgotPassword(this.state.email)} color="primary">
							Resetar Senha
            			</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
