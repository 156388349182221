import { Box } from "@material-ui/core";
import React from "react";
import { Colors } from "../../styles/Colors";
import { Text } from "./components/text";

export const TuimButton = (props) => {
  const {
    text,
    fullWidth = false,
    onClick = () => {},
    secondary = false,
    tertiary = false,
    destructive = false,
    disabled = false,
    dense = false,
    style = {},
  } = props;

  return (
    <Box
      style={{
        ...styles.main,
        ...(fullWidth ? {} : { maxWidth: 200 }),
        ...(secondary && {}),
        ...(secondary && secondaryButtonStyle),
        ...(tertiary && tertiaryButtonStyle),
        ...(destructive && dangerButtonStyle),
        ...(disabled && { opacity: 0.5, cursor: "default" }),
        ...(dense && { padding: 16 }),
        ...style,
      }}
      onClick={disabled ? () => {} : onClick}
    >
      <Text strong color="inherit">
        {text}
      </Text>
    </Box>
  );
};
const secondaryButtonStyle = {
  backgroundColor: Colors.lightGreen,
  color: "#fff",
  border: `1px solid ${Colors.lightGreen}`,
};

const tertiaryButtonStyle = {
  backgroundColor: "transparent",
  color: Colors.darkGreenAlt,
  border: `1px solid ${Colors.darkGreenAlt}`,
};

const dangerButtonStyle = {
  backgroundColor: "transparent",
  color: Colors.red,
  border: `1px solid ${Colors.red}`,
};

const styles = {
  main: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 40,
    padding: `8px 18px`,
    width: "100%",
    color: "#fff",
    backgroundColor: Colors.darkGreen,
    cursor: "pointer",
    minWidth: 100,
    border: `1px solid ${Colors.darkGreen}`,
    "&:hover": {
      backgroundColor: `${Colors.lightGreen}`,
    },
  },
};
