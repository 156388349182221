export const setDropdownAfterReloaded = ({ setOpenDropDown }) => {
  const pathname = window.location.pathname;

  const dropdownMap = {
    "/app/brokers": 1,
    "/app/partnership/home-stagings": 1,
    "/app/partners": 1,
    "/app/partners/buildings": 1,
    "/app/clients": 2,
    "/app/contracts": 2,
    "/app/listBugets": 2,
    "/app/warns": 2,
    "/app/block/blockClientList": 2,
    "/app/products": 3,
    "/app/kits": 3,
    "/app/features": 3,
    "/app/ambients": 3,
    "/app/styles": 3,
    "/app/categories": 3,
    "/app/units": 3,
    "/app/listDiscount": 3,
    "/app/calendar": 3,
    "/app/articles": 4,
    "/app/press": 4,
    "/app/faqs": 4,
    "/app/location/blacklist": 5,
    "/app/coupons": 5,
    "/app/seos": 5,
    "/app/logs": 5,
    "/app/tasks": 5,
    "/app/location": 5,
    "/app/import/products": 5,
    "/app/import/productsUpdate": 5,
    "/app/marketing/adinvestment": 6,
    "/app/relatorio/contract": 7,
    "/app/relatorio/products": 7,
    "/app/report/month": 7,
    "/app/report/category": 7,
    "/app/report/history": 7,
    "/app/report/evolution": 7,
    "/app/report/performance/partners": 7,
    "/app/settings/contract": 8,
  };

  const dropdownValue = dropdownMap[pathname];

  if (dropdownValue) return setOpenDropDown(dropdownValue);
};
