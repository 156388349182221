import React from 'react';
import axios from 'axios'
import { Button, TextField } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';

export default function EditPressCategory({ modalVisible, onCloseModal, updateCategories }) {

   let category = {};
   const addCategory = async () => {

      const token = await localStorage.getItem('token');
      const _this = this

      axios.post(`${process.env.REACT_APP_API_URL}/press/category`, category, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(async response => {
            alert("Categoria cadastrada com sucesso!")
         })
         .catch(error => {
            alert("Não foi possível cadastrar esta categoria!")
         })

      onCloseModal();
   }


   const changeValue = (e) => {
      category = {
         ...category,
         [e.target.name]: e.target.value
      }
   }


   return (
      <>
         <Modal
            open={modalVisible}
            onClose={() => onCloseModal()}
            aria-labelledby="simple-modal-title"
            style={{ border: 'none' }}
            aria-describedby="simple-modal-description"
         >
            <div style={{
               position: 'absolute',
               width: 500,
               backgroundColor: '#fff',
               border: 'none',
               padding: 20,
               left: '35%',
               top: '25%',
               overflowY: 'scroll',
               height: 210,
               border: 'none'
            }}>
               <h4 style={{ marginBottom: 0 }}>Cadastro de Categoria</h4>

               <TextField id="standard-basic" label="Nome" value={category.title} name="title" onChange={changeValue} style={{ marginTop: 10, width: '100%' }} />

               <br />
               <br />
               <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addCategory()}
               >
                  Cadastrar
               </Button>
            </div>
         </Modal>
      </>
   )
}
