import React from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import "moment/locale/pt-br";
import { Text } from "../../../../layout/components/text";
import { formatter } from "../../../../../functions";
import { ContentContainer } from "../../../../layout/components/contentContainer";

export const AbandonedMetricsTable = ({ setPagination, pagination, data }) => {
  const listColumns = [
    { label: "Usuário", field: "id_user.name" },
    { label: "Razão", field: "data.reason" },
    { label: "Criado em", field: "createdAt" },
  ];

  const handleChangePage = (page) => {
    setPagination({ page: page * 1 });
  };

  const handleChangeRowsPerPage = ({ target }) => {
    localStorage.setItem("tuimAdmin.cartsMetricsRowsPerPage", target.value * 1);
    setPagination({ rowsPerPage: target.value * 1 });
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
          gap: 16,
        }}
      >
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.abandonDetailsTotalCount}
          rowsPerPage={pagination.rowsPerPage || 5}
          page={pagination.page}
          backIconButtonProps={{
            "aria-label": "Página anterior",
          }}
          nextIconButtonProps={{
            "aria-label": "Próxima página",
          }}
          labelDisplayedRows={({ from, to, count }) => (
            <Text>
              {from}-{to} de {count}
            </Text>
          )}
          size="small"
          labelRowsPerPage={<Text>Itens por página</Text>}
          onChangePage={(e, page) => handleChangePage(page)}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            variant: "outlined",
          }}
        />
      </Box>

      <ContentContainer label="Abandono de carrinho">
        <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                {listColumns.map(
                  ({ label }) => (
                    <TableCell key={label} style={{ color: "#444" }}>
                      {label}
                    </TableCell>
                  ),
                  this
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.abandonDetails.map((item) => {
                return (
                  <TableRow
                    key={item?.abandonDetails?._id}
                    style={{ position: "relative" }}
                  >
                    <TableCell
                      align="left"
                      style={{ maxWidth: 200, whiteSpace: "nowrap" }}
                    >
                      <Text
                        style={{
                          color: item?.userDetails ? "#444" : "#999",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {item?.userDetails?.name || "Desconhecido"}
                      </Text>
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ maxWidth: 200, whiteSpace: "nowrap" }}
                    >
                      <Text
                        style={{
                          color: "#444",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {item?.abandonDetails?.reason}
                      </Text>
                    </TableCell>
                    <TableCell align="left">
                      <Text>
                        {formatter.date(
                          item?.abandonDetails?.createdAt,
                          "short"
                        )}
                      </Text>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </ContentContainer>
    </>
  );
};
