import axios from "axios";
import React, { useEffect, useState } from "react";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Text } from "../layout/components/text";
import { TextArea } from "../ui";

const EditTranslation = () => {
  const translationId = window.location.pathname.split("/").at(-1);

  const [data, setData] = useState({});

  const getTranslation = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_TRANSLATION_API_URL}/translations/${translationId}?origin=admin`,
        {
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_TRANSLATION_TOKEN}`,
          },
        }
      )
      .then((response) => {
        setData(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateTranslation = async () => {
    await axios
      .patch(
        `${process.env.REACT_APP_TRANSLATION_API_URL}/translations/${translationId}`,
        data,
        {
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_TRANSLATION_TOKEN}`,
          },
        }
      )
      .then(() => {
        alert("Tradução salva com sucesso.");
        window.location.reload();
      })
      .catch(() => {
        alert("Tivemos um problema ao salvar a tradução.");
      });
  };

  useEffect(() => {
    if (translationId) getTranslation();
  }, [translationId]);

  const handleChange = ({ target }) => {
    const { name: locale, value } = target;

    setData({ ...data, locales: { ...data.locales, [locale]: value } });
  };

  return (
    <>
      <ContentHeader
        title="Editar tradução"
        buttons={[
          { text: "Salvar", secondary: true, onClick: updateTranslation },
        ]}
      />
      {!data?._id ? (
        <></>
      ) : (
        <ContentContainer>
          <Text strong>{data.text}</Text>
          {Object.entries(data.locales).map(([locale, translation]) => (
            <TextArea
              key={locale}
              value={translation}
              rows={2}
              name={locale}
              title={locale}
              textAreaStyle={{ resize: "none" }}
              onChange={handleChange}
            />
          ))}
        </ContentContainer>
      )}
    </>
  );
};

export default EditTranslation;
