export function setUserData(userData) {
    return {
        type: 'SET_USER_DATA',
        payload: userData
    }
}

export function startLoading() {
    return {
        type: 'START_LOADING'
    }
}

export function stopLoading() {
    return {
        type: 'STOP_LOADING'
    }
}