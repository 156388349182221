const defaultState = {
    isLoading: false
}

const appReducer = (state = defaultState, action) => {

    switch (action.type) {

        case 'START_LOADING':
            return { isLoading: true }

        case 'STOP_LOADING':
            return { isLoading: false }

        default:
            return state
    }
}

export default appReducer