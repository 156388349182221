import React from "react";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loading = (props) => {
  const { classes, isLoading, loading } = props;

  if (isLoading || loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
  return null;
};

const styles = (theme) => ({
  loading: {
    display: "flex",
    width: "100%",
    zIndex: "1000000",
    height: "100%",
    position: "fixed",
    alignItems: "center",
    top: 0,
    left: 0,
    justifyContent: "center",
    backgroundColor: "#00000080",
  },
});

export default connect((state) => ({
  isLoading: state.appReducer.isLoading,
}))(withStyles(styles)(Loading));
