import { Box } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import ContractReport from "../Reports/ContractReport";
import EditAmbient from "../ambient/EditAmbient";
import ListAmbients from "../ambient/ListAmbients";
import EditArticle from "../articles/EditArticle";
import ArticlesList from "../articles/ListArticles";
import ListBlockedClient from "../blockedClient/blockClientList";
import EditBlockedClient from "../blockedClient/editBlockClient";
import EditBroker from "../broker/EditBroker";
import ListBrokers from "../broker/ListBrokers";
import Budget from "../budget/Budget";
import ListBudgets from "../budget/ListBudgets";
import NewBudget from "../budget/NewBudget";
import EditCalendar from "../calendar/editCalenderDelivery";
import ListCalendar from "../calendar/listCalendar";
import { Cart } from "../carts/Cart";
import { ListCart } from "../carts/ListCarts";
import { CartMetrics } from "../carts/cartMetrics/CartMetrics";
import EditCategory from "../category/EditCategory";
import ListCategories from "../category/ListCategories";
import { Chat } from "../chat/Chat";
import EditClient from "../clients/EditClient";
import ListClients from "../clients/ListClients";
import Contract from "../contracts/Contract";
import ListContracts from "../contracts/ListContracts";
import Relatorio from "../contracts/Relatorio";
import EditCoupon from "../coupon/EditCoupon";
import ListCoupons from "../coupon/ListCoupons";
import Dashboard from "../dashboard/Dashboard";
import Forbidden from "../error/forbidden";
import EditFaq from "../faq/EditFaq";
import ListFaq from "../faq/ListFaq";
import EditFeature from "../feature/EditFeature";
import ListFeatures from "../feature/ListFeatures";
import EditHs from "../homeStaging/EditHs";
import ListHs from "../homeStaging/ListHs";
import EditKit from "../kit/EditKit";
import ListKits from "../kit/ListKits";
import EditLocation from "../location/editLocation";
import ListLocation from "../location/listLocation";
import BlackList from "../locationBlacklist/List";
import ListLogs from "../logs/ListLogs";
import EditAdInvestment from "../marketing/editAdInvestment";
import ListAdInvestment from "../marketing/listAdInvestment";
import EditBuilding from "../partner/EditBuilding";
import EditPartner from "../partner/EditPartner";
import ListBuildings from "../partner/ListBuildings";
import ListPartners from "../partner/ListPartners";
import EditPress from "../press/EditPress";
import ListPress from "../press/ListPress";
import ListPrice from "../price/EditDiscount";
import ListDiscount from "../price/ListDiscount";
import EditProduct from "../product/EditProduct";
import ListProducts from "../product/ListProducts";
import RelatorioProduct from "../product/Relatorio";
import ImportProducts from "../product/importProducts";
import UpdateProduct from "../product/updateProduct";
import EditSeo from "../seo/EditSeo";
import ListSeoTags from "../seo/ListSeoTags";
import ContractTemplate from "../settings/contractTemplate";
import { StockListAlert } from "../stockAlert/stockList";
import EditStyle from "../style/EditStyle";
import ListStyles from "../style/ListStyles";
import EditTask from "../task/EditTask";
import ListTasks from "../task/ListTasks";
import ViewTask from "../task/ViewTask";
import tuimboxReports from "../tuimbox/Relatorio";
import EditUnit from "../unit/EditUnit";
import ListUnits from "../unit/ListUnits";
import EditProfile from "../user/EditProfile";
import EditUser from "../user/EditUser";
import ListUsers from "../user/ListUsers";
import EditWarn from "../warns/EditWarn";
import ListWarns from "../warns/ListWarns";
import Header from "./Header";
import LeftMenu from "./LeftMenu";
import { routes } from "./utils/auth";
import ListTranslations from "../translations/ListTranslations";
import EditTranslation from "../translations/EditTranslation";
import ListQuotations from "../quotations/listQuotations";
import QuotationDetails from "../quotations/quotationDetails";

function LoggedIn(props) {
  const { permission } = props.userData;
  const pathname = props?.location?.pathname;

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [pathname]);

  const checkPermission = (Component, ComponentName) =>
    routes[permission].includes(ComponentName) ? Component : Forbidden;

  return (
    <div style={{ display: "flex" }}>
      <CssBaseline />
      <LeftMenu />
      <Chat />

      <main
        style={{
          display: "flex",
          flex: 1,
          boxSizing: "border-box",
          maxWidth: "100%",
          flexDirection: "column",
        }}
      >
        <Header history={props.history} />
        <Box style={styles.content}>
          <Switch>
            <Route
              exact
              path="/app/users"
              component={checkPermission(ListUsers, "ListUsers")}
            />
            <Route
              path="/app/user/"
              component={checkPermission(EditUser, "EditUser")}
            />

            <Route
              exact
              path="/app/carts"
              component={checkPermission(ListCart, "ListCart")}
            />
            <Route
              strict
              path="/app/carts/product-metrics"
              component={checkPermission(CartMetrics, "CartMetrics")}
            />
            <Route
              path="/app/carts/:id"
              component={checkPermission(Cart, "Cart")}
            />

            <Route
              exact
              path="/app/stock"
              component={checkPermission(StockListAlert, "StockListAlert")}
            />

            <Route exact path="/app/profile/" component={EditProfile} />
            <Route
              exact
              path="/app/categories"
              component={checkPermission(ListCategories, "ListCategories")}
            />
            <Route
              path="/app/category/"
              component={checkPermission(EditCategory, "EditCategory")}
            />

            <Route
              exact
              path="/app/coupons"
              component={checkPermission(ListCoupons, "ListCoupons")}
            />
            <Route
              path="/app/coupon/"
              component={checkPermission(EditCoupon, "EditCoupon")}
            />

            <Route
              exact
              path="/app/products"
              component={checkPermission(ListProducts, "ListProducts")}
            />
            <Route
              exact
              path="/app/relatorio/products"
              component={checkPermission(RelatorioProduct, "RelatorioProduct")}
            />
            <Route
              path="/app/product/"
              component={checkPermission(EditProduct, "EditProduct")}
            />

            <Route
              exact
              path="/app/brokers"
              component={checkPermission(ListBrokers, "ListBrokers")}
            />
            <Route
              path="/app/broker/"
              component={checkPermission(EditBroker, "EditBroker")}
            />

            <Route
              exact
              path="/app/faqs"
              component={checkPermission(ListFaq, "ListFaq")}
            />
            <Route
              path="/app/faq/"
              component={checkPermission(EditFaq, "EditFaq")}
            />

            <Route
              exact
              path="/app/translations"
              component={checkPermission(ListTranslations, "ListTranslations")}
            />
            <Route
              exact
              path="/app/translations/:id"
              component={checkPermission(EditTranslation, "EditTranslation")}
            />
            <Route
              exact
              path="/app/seos"
              component={checkPermission(ListSeoTags, "ListSeoTags")}
            />
            <Route
              path="/app/seo/"
              component={checkPermission(EditSeo, "EditSeo")}
            />

            <Route
              exact
              path="/app/contracts"
              component={checkPermission(ListContracts, "ListContracts")}
            />
            <Route
              exact
              path="/app/relatorio/contract"
              component={checkPermission(Relatorio, "Relatorio")}
            />
            <Route
              path="/app/contract/"
              component={checkPermission(Contract, "Contract")}
            />

            <Route
              exact
              path="/app/tuimbox/reports"
              component={checkPermission(tuimboxReports, "tuimboxReports")}
            />

            <Route
              exact
              path="/app/kits"
              component={checkPermission(ListKits, "ListKits")}
            />
            <Route
              path="/app/kit/"
              component={checkPermission(EditKit, "EditKit")}
            />

            <Route
              exact
              path="/app/listDiscount"
              component={checkPermission(ListDiscount, "ListDiscount")}
            />
            <Route
              path="/app/editDiscount/"
              component={checkPermission(ListPrice, "ListPrice")}
            />

            <Route
              exact
              path="/app/articles"
              component={checkPermission(ArticlesList, "ArticlesList")}
            />
            <Route
              path="/app/article/"
              component={checkPermission(EditArticle, "EditArticle")}
            />

            <Route
              exact
              path="/app/ambients"
              component={checkPermission(ListAmbients, "ListAmbients")}
            />
            <Route
              path="/app/ambient/"
              component={checkPermission(EditAmbient, "EditAmbient")}
            />

            <Route
              exact
              path="/app/styles"
              component={checkPermission(ListStyles, "ListStyles")}
            />
            <Route
              path="/app/style/"
              component={checkPermission(EditStyle, "EditStyle")}
            />

            <Route
              exact
              path="/app/features"
              component={checkPermission(ListFeatures, "ListFeatures")}
            />
            <Route
              path="/app/feature/"
              component={checkPermission(EditFeature, "EditFeature")}
            />

            <Route
              exact
              path="/app/clients"
              component={checkPermission(ListClients, "ListClients")}
            />
            <Route
              path="/app/client/"
              component={checkPermission(EditClient, "EditClient")}
            />

            <Route
              exact
              path="/app/listBugets"
              component={checkPermission(ListBudgets, "ListBudgets")}
            />
            <Route
              path="/app/budgets/"
              component={checkPermission(Budget, "Budget")}
            />
            <Route
              path="/app/NewBudget/"
              component={checkPermission(NewBudget, "NewBudget")}
            />

            <Route
              exact
              path="/app/quotations"
              component={checkPermission(ListQuotations, "ListQuotations")}
            />
            <Route
              path="/app/quotations/:id"
              component={checkPermission(QuotationDetails, "QuotationDetails")}
            />

            <Route
              exact
              path="/app/partnership/home-stagings"
              component={checkPermission(ListHs, "ListHs")}
            />
            <Route
              path="/app/partnership/home-staging/"
              component={checkPermission(EditHs, "EditHs")}
            />

            <Route
              exact
              path="/app/warns"
              component={checkPermission(ListWarns, "ListWarns")}
            />
            <Route
              path="/app/warn/"
              component={checkPermission(EditWarn, "EditWarn")}
            />

            <Route
              exact
              path="/app/units"
              component={checkPermission(ListUnits, "ListUnits")}
            />
            <Route
              path="/app/unit/"
              component={checkPermission(EditUnit, "EditUnit")}
            />

            <Route
              exact
              path="/app/partners"
              component={checkPermission(ListPartners, "ListPartners")}
            />
            <Route
              exact
              path="/app/partners/buildings"
              component={checkPermission(ListBuildings, "ListBuildings")}
            />
            <Route
              path="/app/partner/"
              component={checkPermission(EditPartner, "EditPartner")}
            />
            <Route
              path="/app/partners/building/"
              component={checkPermission(EditBuilding, "EditBuilding")}
            />

            <Route path="/app/403" component={Forbidden} />
            <Route
              exact
              path="/app/dashboard"
              component={checkPermission(Dashboard, "Dashboard")}
            />

            <Route
              exact
              path="/app/logs"
              component={checkPermission(ListLogs, "ListLogs")}
            />
            <Route
              exact
              path="/app/tasks"
              component={checkPermission(ListTasks, "ListTasks")}
            />
            <Route
              path="/app/task"
              component={checkPermission(EditTask, "EditTask")}
            />
            <Route
              path="/app/view/task"
              component={checkPermission(ViewTask, "ViewTask")}
            />

            <Route
              exact
              path="/app/press"
              component={checkPermission(ListPress, "ListPress")}
            />
            <Route
              path="/app/press/edit/"
              component={checkPermission(EditPress, "EditPress")}
            />

            <Route
              exact
              path="/app/calendar"
              component={checkPermission(ListCalendar, "ListCalendar")}
            />
            <Route
              path="/app/calendar/edit"
              component={checkPermission(EditCalendar, "EditCalendar")}
            />

            <Route
              exact
              path="/app/location"
              component={checkPermission(ListLocation, "ListLocation")}
            />
            <Route
              path="/app/location/edit"
              component={checkPermission(EditLocation, "EditLocation")}
            />

            <Route
              exact
              path="/app/block/blockClientList"
              component={checkPermission(
                ListBlockedClient,
                "ListBlockedClient"
              )}
            />
            <Route
              path="/app/block/editBlockClient"
              component={checkPermission(
                EditBlockedClient,
                "EditBlockedClient"
              )}
            />

            <Route
              path="/app/location/blacklist"
              component={checkPermission(BlackList, "BlackList")}
            />
            <Route
              path="/app/import/products"
              component={checkPermission(ImportProducts, "ImportProducts")}
            />
            <Route
              path="/app/import/productsUpdate"
              component={checkPermission(UpdateProduct, "UpdateProduct")}
            />

            <Route
              path="/app/report/month"
              component={(props) =>
                checkPermission(
                  <ContractReport {...props} section="monthly" />,
                  "ContractReport"
                )
              }
            />
            <Route
              path="/app/report/category"
              component={(props) =>
                checkPermission(
                  <ContractReport {...props} section="byCategory" />,
                  "ContractReport"
                )
              }
            />
            <Route
              path="/app/report/history"
              component={(props) =>
                checkPermission(
                  <ContractReport {...props} section="history" />,
                  "ContractReport"
                )
              }
            />
            <Route
              path="/app/report/evolution"
              component={(props) =>
                checkPermission(
                  <ContractReport {...props} section="evolution" />,
                  "ContractReport"
                )
              }
            />
            <Route
              path="/app/report/performance/site"
              component={(props) =>
                checkPermission(
                  <ContractReport {...props} section="sitePerformance" />,
                  "ContractReport"
                )
              }
            />
            <Route
              path="/app/report/performance/partners"
              component={(props) =>
                checkPermission(
                  <ContractReport {...props} section="partnersPerformance" />,
                  "ContractReport"
                )
              }
            />

            <Route
              exact
              path="/app/marketing/adinvestment"
              component={checkPermission(ListAdInvestment, "ListAdInvestment")}
            />
            <Route
              path="/app/marketing/adinvestment/edit"
              component={checkPermission(EditAdInvestment, "EditAdInvestment")}
            />

            <Route
              path="/app/settings/contract"
              component={checkPermission(ContractTemplate, "ContractTemplate")}
            />
          </Switch>
        </Box>
      </main>
    </div>
  );
}

LoggedIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  content: {
    boxSizing: "border-box",
    width: "100%",
    padding: `34px 42px 88px 42px`,
    display: "flex",
    flexDirection: "column",
    gap: "28px",
    backgroundColor: "#212f3107",
    minHeight: "100vh",
  },
};

export default withStyles(styles)(LoggedIn);
