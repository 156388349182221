import React, { Component } from 'react'
import axios from 'axios'
import {
    Paper, Typography, Button, FormControl, InputLabel, Input, CircularProgress, Checkbox,
    IconButton, Menu, MenuItem, FormControlLabel, FormGroup
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import SaveIcon from '@material-ui/icons/Save';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';
import Loading from '../utils/Loading';
import AlertDialog from '../utils/AlertDialog'

class EditUser extends Component {

    constructor(props) {
        super(props)

        const { classes } = props;
        const { pathname } = props.location
        const arrayPath = pathname.split('/')
        const _id = arrayPath[arrayPath.length - 1];

        this.state = {
            classes,
            data: {
                _id,
                name: '',
                email: '',
                active: false,
                email_credentials: false,
                admin: true,
                realState: '',
                cpf: '',
                rg: '',
                dateBirth: '',
                creci: '',
                creciImg: '',
                broker: true,
                partnershipType: 'broker',
                partnership: true,
                coupon: [],
                status_contract_partnership: ''
            },
            coupon: [],
            saving: false,
            success: false,
            error: null,
            anchorEl: null,
            open: false,
            showAlert: false,
            showErrorNewUser: false,
            showDeleteUserAlert: false,
            loading: true,
            showAlertNewUser: false,
            showAlertCupomSend: false,
            permissions: [{ permission: 'Administrador' }, { permission: 'SEO' }]
        }

        this.onChange = this.onChange.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onResetPasswordPress = this.onResetPasswordPress.bind(this);
        this.onDeleteUserPress = this.onDeleteUserPress.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentDidMount = async () => {

        const { _id } = this.state.data

        if (!_id) {
            this.setState({ loading: false })
            return;
        }


        const token = await localStorage.getItem('token');
        const _this = this

        axios.get(`${process.env.REACT_APP_API_URL}/partnership/${_id}`, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                const { data } = response
                console.log('data', data);
                _this.setState({
                    data: data[0]
                })

                _this.setState({
                    loading: false
                })
                // _this.setState({
                //     loading: false
                // })
            })

        axios.get(`${process.env.REACT_APP_API_URL}/couponByUser/${_id}`, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                const { data } = response
                console.log('data', data);
                _this.setState({
                    coupon: data
                })

                _this.setState({
                    loading: false
                })
            })



    }

    onChange = (e) => {


        const { data } = this.state
        this.setState({
            data: {
                ...data,
                [e.target.name]: e.target.value
            },
            success: false
        })


    }

    onCheckChange = name => event => {

        const { data } = this.state

        this.setState({
            data: {
                ...data,
                [name]: event.target.checked
            },
            success: false
        });
    };

    sendCupom = async () => {
        const { data } = this.state
        let dataCupom = {
            name: data.name,
            email: data.email,
            cupom: data.coupon[0].code
        }
        if (!data._id) {
            this.newUser()
            return;
        }

        this.setState({
            saving: true
        })

        const token = await localStorage.getItem('token');
        const _this = this

        axios.post(`https://api.tuim.com.br/partnership/sendCupom/${data._id}`, dataCupom, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                this.setState({
                    saving: false,
                    success: true,
                    showAlertCupomSend: true
                })

            })
            .catch(error => {
                this.setState({
                    saving: false,
                    success: false,
                    error: error.response
                })
            })


    }
    onSavePress = async () => {

        let { data } = this.state


        if (!data._id) {
            this.newUser()
            return;
        }

        this.setState({
            saving: true
        })

        let dataCustom = data;
        dataCustom.partnership = true;
        dataCustom.partnershipType = 'broker';

        const token = await localStorage.getItem('token');
        const _this = this
        console.log('editando', dataCustom);
        axios.patch(`https://api.tuim.com.br/broker/${data._id}`, dataCustom, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                this.setState({
                    saving: false,
                    success: true
                })

                this.props.history.goBack()

            })
            .catch(error => {
                this.setState({
                    saving: false,
                    success: false,
                    error: error.response
                })
            })
    }


    sendContract = async () => {
        const token = await localStorage.getItem('token');
        const _this = this
        let { data } = this.state
        this.setState({ loading: true })

        data.dateBirth = data.dateBirth.split('T')[0]

        let dataCustom = {
            name: data.name,
            email: data.email,
            dateBirth: data.dateBirth,
            cpf: data.cpf,
            rg: data.rg,
            creci: data.creci
        }
        // return;
        const contract_detail = await axios.post(`${process.env.REACT_APP_API_URL}/partnership/sendContract`, dataCustom, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                return response.data
            })
            .catch(error => {
                _this.setState({ loading: false })
                this.setState({
                    saving: false,
                    success: false,
                    error: error.response
                })
            })

        let dataLead = {
            conversion_identifier: 'Contrato Corretor - Site Tuim',
            email: data.email,
            name: data.name,
            cf_broker_contract_link: `https://${window.location.hostname == 'admin.tuim.com.br' ? 'tuim.com.br' : 'tuimprewebsite.herokuapp.com'}/parcerias/corretores/${contract_detail.list.request_signature_key}?u=${data._id}`
        }
        axios.post(`${process.env.REACT_APP_API_URL}/createLead`, dataLead, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                _this.setState({ loading: false })

                alert('E-mail enviado com Sucesso.')
                return response.data
            })
            .catch(error => {
                _this.setState({ loading: false })
                this.setState({
                    saving: false,
                    success: false,
                    error: error.response
                })
            })

    }

    newUser = async () => {

        const { data } = this.state

        data._id = undefined

        this.setState({
            saving: true
        })

        const token = await localStorage.getItem('token');
        const _this = this

        axios.post(`${process.env.REACT_APP_API_URL}/user`, data, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                // console.log('response', response);
                this.setState({
                    saving: false,
                    success: true,
                    showAlertNewUser: true,
                    data: response.data.data
                })

                // this.props.history.goBack()

                _this.props.history.push(`${response.data.data._id}`)
            })
            .catch(error => {
                console.log('responserror', error.response.data.message);

                this.setState({
                    saving: false,
                    success: false,
                    showErrorNewUser: true,
                    error: error.response.data.message
                })
            })
    }

    resetPassword = async () => {

        const { data } = this.state

        const token = await localStorage.getItem('token');

        axios.patch(`${process.env.API_URL}login/${data._id}`, data, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {
                this.setState({
                    saving: false,
                    success: true
                })
            })
            .catch(error => {
                this.setState({
                    saving: false,
                    success: false,
                    error: error.response
                })
            })
    }

    deleteUser = async () => {

        const { data } = this.state
        const token = await localStorage.getItem('token');

        axios.delete(`${process.env.REACT_APP_API_URL}/user/${data._id}`, {
            headers: { 'Authorization': "bearer " + token }
        }).then(response => {
            this.setState({
                saving: false,
                success: true
            })

        }).catch(error => {
            this.setState({
                saving: false,
                success: false,
                error: error.response
            })
            this.props.history.push('/app/brokers')
        })
    }

    onCancelPress = () => {
        this.props.history.goBack()
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget, open: true });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, open: false, showAlert: false });
    };

    onResetPasswordPress = () => {
        this.setState({ anchorEl: null, open: false, showAlert: true });
    };

    onDeleteUserPress = () => {
        this.setState({ anchorEl: null, open: false, showDeleteUserAlert: true });
    };

    render() {

        const { classes, saving, permissions,coupon, success, anchorEl, open, showAlertCupomSend, showAlert, showDeleteUserAlert, loading, showAlertNewUser, showErrorNewUser, error } = this.state
        const { data } = this.state

        const buttonClassname = classNames({
            [classes.buttonSuccess]: success,
        });

        return (

            <Paper className={classes.paper}>
                <Loading loading={loading} />

                <Typography component="h1" variant="h5">
                    {data.name}
                    <IconButton
                        aria-label="More"
                        aria-owns={true}
                        aria-haspopup="true"
                        onClick={this.handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={this.handleClose}
                        PaperProps={{
                            style: {
                                maxHeight: 200,
                                width: 200,
                            },
                        }}
                    >
                        <MenuItem key={0} onClick={this.onResetPasswordPress}>
                            Resetar Senha
                        </MenuItem>
                        <MenuItem key={1} onClick={this.onDeleteUserPress}>
                            Excluir Usuário
                        </MenuItem>
                    </Menu>

                </Typography>
                <form className={classes.form}>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">Nome</InputLabel>
                        <Input id="name" name="name" autoFocus value={data.name}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">E-mail</InputLabel>
                        <Input id="email" name="email" autoComplete="email" value={data.email}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="realState">Imobiliaria</InputLabel>
                        <Input id="realState" name="realState" value={data.realState}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="mobileNumber">Celular</InputLabel>
                        <Input id="mobileNumber" name="mobileNumber" value={data.mobileNumber}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="cpf">CPF</InputLabel>
                        <Input id="cpf" name="cpf" value={data.cpf}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="rg">RG</InputLabel>
                        <Input id="rg" name="rg" value={data.rg}
                            onChange={this.onChange}
                        />
                    </FormControl>
                    {
                        coupon ?
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="coupon">Cupom</InputLabel>
                                <Input id="coupon" name="coupon" value={coupon.code}
                                    onChange={this.onChange}
                                />
                            </FormControl>
                            : ''
                    }
                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="creci">CRECI</InputLabel>
                        <Input id="creci" name="creci" autoComplete="creci" value={data.creci}
                            onChange={this.onChange}
                        />
                    </FormControl>

                    <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="partnershipType">Tipo</InputLabel>
                        <Input id="partnershipType" name="partnershipType" autoComplete="creci" value={data.partnershipType}
                            onChange={this.onChange}
                        />
                    </FormControl>

                    {/* <FormControl margin="normal" required fullWidth>
                        <InputLabel htmlFor="email">CRECI Img</InputLabel>
                        <Input id="email" name="email" autoComplete="email" value={data.email}
                            onChange={this.onChange}
                        />
                    </FormControl> */}

                    {
                        data.creciImg ? <img src={data.creciImg} style={{ width: 512, height: 512 }} /> : ''

                    }

                    {!data._id &&
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.email_credentials}
                                        onChange={this.onCheckChange('email_credentials')}
                                        value="active"
                                        color="primary"
                                    />
                                }
                                label="Enviar credenciais de acesso por e-mail?"
                            />
                        </FormGroup>
                    }
                    <div className={classes.wrapper} >
                        {/* <Button
                            variant="contained"
                            color="success"
                            className={buttonClassname}
                            disabled={saving}
                            style={{ float: 'left' }}
                            onClick={this.sendCupom}
                        >

                            Enviar Cupom via E-mail
                            {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button> */}

                        {
                            typeof data.status_contract_partnership == 'undefined' &&
                                data.status_contract_partnership == '' ?

                                <Button
                                    variant="contained"
                                    color="success"
                                    className={buttonClassname}
                                    disabled={saving}
                                    style={{ float: 'left' }}
                                    onClick={() => this.sendContract()}
                                >

                                    Aprovar e Enviar o Contrato
                            {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                                :

                                <Button
                                    variant="contained"
                                    color="success"
                                    className={buttonClassname}
                                    disabled={saving}
                                    style={{ float: 'left' }}
                                    onClick={() => this.sendContract()}
                                >

                                    Re-enviar Contrato
                            {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>

                        }

                     
                        <Button float
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            disabled={saving}
                            style={{ float: 'right',marginRight: 20 }}
                            onClick={this.onSavePress}
                        >
                            <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)} />
                            Salvar
                            {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </Button>

                        <Button float
                            variant="contained"
                            color="secondary"
                            className={buttonClassname}
                            disabled={saving}
                            style={{ float: 'right',marginRight: 20 }}
                            onClick={() => window.open(`/app/coupon/${coupon._id}`)}
                        >
                           
                            Editar Cupom
                     
                        </Button>

                        
                        <Button variant="contained" color="default" className={classes.button}
                            onClick={this.onCancelPress}
                            style={{ float: 'right',marginRight: 20  }}
                        >Cancelar
                    </Button>

                        {/* {this.state.error && <Typography>{this.state.error}</Typography>} */}
                    </div>


                </form>



                {
                    showAlert && <AlertDialog
                        msg={`Deseja gerar uma nova senha e enviar para o e-mail ${data.email}`}
                        title="Reenviar Senha"
                        open={showAlert}
                        buttons={[
                            {
                                label: 'Cancelar',
                                onPress: () => {
                                    this.setState({
                                        showAlert: false
                                    })
                                }
                            },
                            {
                                label: 'Reenviar',
                                onPress: () => {
                                    this.setState({
                                        showAlert: false
                                    })

                                    this.resetPassword()
                                }
                            }
                        ]} />
                }

                {
                    showAlertNewUser && <AlertDialog
                        msg={`Novo usuário cadastrado com sucesso, um e-mail com a senha foi enviado para ${data.email}.`}
                        title="Novo Usuário"
                        open={showAlertNewUser}
                        buttons={[
                            {
                                label: 'Fechar',
                                onPress: () => {
                                    this.setState({
                                        showAlertNewUser: false
                                    })
                                }
                            }
                        ]}
                    />
                }
                {
                    showAlertCupomSend && <AlertDialog
                        msg={`Cupom enviado com sucesso.`}
                        title="Cupom enviado com Sucesso"
                        open={showAlertCupomSend}
                        buttons={[
                            {
                                label: 'Fechar',
                                onPress: () => {
                                    this.setState({
                                        showAlertCupomSend: false
                                    })
                                }
                            }
                        ]}
                    />
                }

                {
                    showErrorNewUser && <AlertDialog
                        msg={error}
                        title="Falha ao Cadastrar Novo Usuário"
                        open={showErrorNewUser}
                        buttons={[
                            {
                                label: 'Fechar',
                                onPress: () => {
                                    this.setState({
                                        showErrorNewUser: false
                                    })
                                }
                            }
                        ]}
                    />
                }

                {
                    showDeleteUserAlert && <AlertDialog
                        msg={`Deseja relmente excluir o usuário ${data.name}? Obs.: Esta ação è irreversível`}
                        title="Excluir Usuário"
                        open={showDeleteUserAlert}
                        buttons={[
                            {
                                label: 'Cancelar',
                                onPress: () => {
                                    this.setState({
                                        showDeleteUserAlert: false
                                    })
                                },

                            },
                            {
                                label: 'Excluir',
                                onPress: () => {
                                    this.setState({
                                        showDeleteUserAlert: false
                                    })

                                    this.deleteUser()
                                },
                                color: 'primary'
                            }
                        ]} />
                }

            </Paper >
        )
    }
}

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    button: {
        marginLeft: theme.spacing.unit
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    success: {
        backgroundColor: green[600],
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        marginTop: theme.spacing.unit * 3,
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    titleName: {
        flex: 1
    },
    titleBar: {
        flexDirection: 'row'
    }
});

export default (withStyles(styles)(EditUser))