import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios'
import { Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Moment from 'react-moment';
import Numeral from "numeral";
Numeral.locale('pt-br');

export default function LogContract({ onCloseModal, modalVisible, id }) {


   const [data, setData] = useState([]);
   const getLogs = async () => {
      const token = await localStorage.getItem('token');
      await axios.post(`${process.env.REACT_APP_API_URL}/logCliente`, { id: id }, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(async response => {
            const { data } = response
            setData(data)
         }).catch((err) => {
            console.log('err', err)

         })
   }

   useEffect(() => {
      getLogs()
   }, [])


   return (
      <>
         <Modal
            open={modalVisible}
            onClick={() => onCloseModal()}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
         >
            <div style={{
               position: 'absolute',
               maxWidth: 1087,
               backgroundColor: '#fff',
               border: '2px solid #000',
               padding: 20,
               top: 0,
               bottom: 0,
               left: 0,
               right: 0,
               margin: 'auto',
               maxHeight: 400,
               border: 'none',
               overflow: 'hidden'

            }}>
               <label><b>Logs</b></label>

               <TableContainer style={{ maxHeight: 350, overflowY: 'auto' }}>
                  <Table size="small" aria-label="a dense table" >
                     <TableHead>
                        <TableRow>
                           <TableCell align="center">Descrição</TableCell>
                           <TableCell align="center">Campos Alterados</TableCell>
                           <TableCell align="center">Tipo</TableCell>
                           <TableCell align="center">Sistema</TableCell>
                           <TableCell align="center">E-mail</TableCell>
                           <TableCell align="center">Data</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {data.map((row, index) => (
                           <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                 {row.description}
                              </TableCell>
                              <TableCell align="center">{row?.updatedFields?.join(', ')}</TableCell>
                              <TableCell align="center">{row.type}</TableCell>
                              <TableCell align="center">{row.system}</TableCell>
                              <TableCell align="center">{row?.email || `-`}</TableCell>
                              <TableCell align="center">
                                 <Moment format="DD/MM/YYYY HH:mm:ss" date={new Date(row.createdAt)} ></Moment>
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </TableContainer>
               <div style={{ width: '100%', display: 'flex', padding: 15, alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                     variant="contained"
                     color="primary"
                     onClick={() => onCloseModal()}
                  >

                     Ok

                  </Button>
               </div>

            </div>
         </Modal>
      </>
   )
}
