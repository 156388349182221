import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow, Paper, Table, Typography, Grid, Fab, FormControl, InputLabel, Input, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import axios from 'axios';
import Loading from '../utils/Loading';
import Moment from 'react-moment';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';


class ListTasks extends Component {

    constructor(props) {
        super(props)

        const { classes, userData } = props;

        this.state = {
            classes,
            data: [],
            loading: true,
            userData,
            filter: {
                date_start: '',
                date_finish: '',
                status: '5ec01035b6062a3a5700c7e5'
            },
            taskStatus: []
        }
    }

    componentDidMount = async () => {

        const _this = this

   
        axios.get(`https://w3lib-manager-api.herokuapp.com/tasks/5eb706b5ae906327c265edd4`)
            .then(response => {

                const { data } = response
                _this.setState({
                    data
                })

                _this.setState({
                    loading: false
                })
            })


    }


    onChange = (e) => {


        const { filter } = this.state
        this.setState({
            filter: {
                ...filter,
                [e.target.name]: e.target.value
            },
            success: false
        })


    }
    onPressFilter = async () => {
        const { filter, userData } = this.state
        console.log("filter", filter)
        let params = '?'
        let _this = this

        if (filter.status != '') {
            params = `${params}status=${filter.status}`
        }

        const token = await localStorage.getItem('token');
        let url_task = userData.type == 'Cliente' ? `/getTasksExecutors/${userData.userId}${params}` : `/task${params}`
        axios.get(`${process.env.REACT_APP_API_URL}${url_task}`, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(response => {

                const { data } = response
                _this.setState({
                    data
                })

                _this.setState({
                    loading: false
                })
            })
    }


    render() {

        const { classes, data, loading, filter, success = true } = this.state

        const buttonClassname = classNames({
            [classes.buttonSuccess]: success,
        });

        return (
            <div>
                <Loading loading={loading} />

                <Grid container>
                    <Grid item xs >
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}

                        >Chamados
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Fab color="primary" aria-label="Add" className={classes.fab} size="small">
                            <AddIcon onClick={() => this.props.history.push(`/app/task/`)} />
                        </Fab>
                    </Grid>
                </Grid>

                <Paper className={classes.root}>

                    <div style={{ width: '100%', padding: 20 }}>
                        <Typography
                            component="h2"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}

                        >Filtro
                        </Typography>


                        <div style={{ width: '100%', display: 'flex' }}>

                            <FormControl margin="normal" >
                                <InputLabel htmlFor="title">Titulo</InputLabel>
                                <Input id="title" name="title" autoFocus value={filter.title}
                                    onChange={this.onChange}
                                />
                            </FormControl>
{/* 
                            <FormControl margin="normal" style={{ marginLeft: 10 }}>
                                <InputLabel htmlFor="date_start">Data de Inicio</InputLabel>
                                <Input id="date_start" name="date_start" type="date" value={filter.date_start}
                                    onChange={this.onChange}
                                />
                            </FormControl>

                            <FormControl margin="normal" style={{ marginLeft: 10 }}>
                                <InputLabel htmlFor="date_finished">Data Final</InputLabel>
                                <Input id="date_finished" name="date_finished" type="date" value={filter.date_finished}
                                    onChange={this.onChange}
                                />
                            </FormControl> */}

                            {/* <FormControl className={classes.formControl} style={{ marginTop: 16, marginLeft: 20, maxWidth: 200, width: '100%' }}>
                                <InputLabel id="status">Status</InputLabel>
                                <Select
                                    labelId="status"
                                    id="status"
                                    value={filter.status}
                                    name="status"
                                    onChange={this.onChange}
                                >
                                    <MenuItem value="">Todos</MenuItem>
                                    {
                                        taskStatus.map((item, index) => {
                                            return (<MenuItem value={item._id}>{item.status}</MenuItem>)
                                        })
                                    }

                                </Select>
                            </FormControl> */}


                        </div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            style={{ marginTop: 20 }}
                            onClick={this.onPressFilter}
                        >
                            Filtrar
                        </Button>
                    </div>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tarefa</TableCell>
                                <TableCell align="center">Prioridade</TableCell>
                                <TableCell align="center">Tipo</TableCell>
                                <TableCell align="center">Cadastro</TableCell>
                                <TableCell align="center">Prazo</TableCell>
                                <TableCell align="center">Status</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(row => (
                                <TableRow
                                    key={row._id}
                                    className={classes.row}
                                    onClick={() => this.props.history.push(`/app/view/task/${row._id}`)}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell align="center">{row.type.priority}</TableCell>
                                    <TableCell align="center">{row.type.name}</TableCell>
                                    <TableCell align="center">{<Moment format="DD/MM/YYYY HH:mm" date={new Date(row.createdAt)} ></Moment>}</TableCell>
                                    <TableCell align="center">{<Moment format="DD/MM/YYYY" date={new Date(row.deadline)} ></Moment>}</TableCell>
                                    <TableCell align="center">{row.status.status}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>

        );
    }
}

ListTasks.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        cursor: 'pointer'
    },
    fab: {
        margin: 0,
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
});

const mapStateToProps = state => ({
    userData: state.userReducer.userData.data
})

export default connect(
    mapStateToProps,
    null
)(withStyles(styles)(ListTasks))
