import { Typography } from "@material-ui/core";
import React from "react";

export const Text = (props) => {
  const {
    small = false,
    detail = false,
    strong = false,
    title = false,
    color = null,
    style = {},
  } = props;

  return (
    <Typography
      {...props}
      style={{
        color: "#444",
        fontSize: 14,
        ...(detail && { fontSize: 13, color: "#999" }),
        ...(small && { fontSize: 12 }),
        ...(strong && { fontWeight: "500" }),
        ...(title && { fontWeight: "500", fontSize: 26 }),
        ...(color && { color }),
        ...style,
      }}
    >
      {props.children}
    </Typography>
  );
};
