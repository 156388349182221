import { Box } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import Numeral from "numeral";
import "numeral/locales/pt-br";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdDownload, MdEdit } from "react-icons/md";
import Moment from "react-moment";
import { formatter, pluralize, uid } from "../../functions";
import { getPriceByProductItem } from "../../functions/calcCartPrice/calcCartPrice";
import { Colors } from "../../styles/Colors";
import { AdditionalBill } from "../AdditionalBill";
import { ExternalLink } from "../ExternalLink/ExternalLink";
import { Alert } from "../layout/Alert";
import { TuimButton } from "../layout/Button";
import { ProductCard } from "../layout/ProductCard";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { InternalNotes } from "../layout/components/internalNotes/internalNotes";
import { Rows } from "../layout/components/rows";
import { Text } from "../layout/components/text";
import AlertDialog from "../utils/AlertDialog";
import Loading from "../utils/Loading";
import Edit from "./EditCard";
import { EndContract } from "./EndContract";
import VisibilityReturnProduct from "./VisibilityReturnProduct";
import { ContractDropdown } from "./components/ContractDropdown";
import LogsContracts from "./logContracts";

Numeral.locale("pt-br");

const CONTRACT_STATUS = {
  active: "Ativo",
  pending: "Pendente",
  canceled: "Cancelado",
  finished: "Finalizado",
  declined: "Reprovado",
};

const origin = {
  website: "Site",
  "tuim-admin": "Admin",
};

const PaymentLabel = ({ status, background }) => (
  <Box
    style={{
      display: "flex",
      justifyContent: "center",
      padding: `2px`,
      backgroundColor: background,
      borderRadius: 20,
      alignItems: "center",
      width: "100%",
    }}
  >
    <Text strong color="#fff" style={{ fontSize: 12 }}>
      {status}
    </Text>
  </Box>
);

const paymentStatus = {
  paid: <PaymentLabel status="PAGO" background={Colors.lightGreen} />,
  pending: <PaymentLabel status="PENDENTE" background={Colors.red} />,
  canceled: <PaymentLabel status="CANCELADO" background="#bbb" />,
};

class Contract extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    const pathname = window.location.pathname;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      classes,
      data: {
        _id,
        name: "",
        description: "",
        id_user: {},
        price: 0,
        active: 0,
        id_subscription: 0,
        payment: {
          price: 0,
          deliveryPrice: 0,
        },
        products: [],
        delivery_date: "",
        address: {},
        status: "",
        status_contract: "",
        period: 0,
        contract_accept: {},
      },
      coupon: {},
      bills: [],
      products: [],
      saving: false,
      success: false,
      error: null,
      anchorEl: null,
      open: false,
      openMenuOptions: false,
      showAlert: false,
      showCancelContractAlert: false,
      showReactivateContractAlert: false,
      showModalAproveContract: false,
      showModalReproveContract: false,
      selected: [],
      loading: true,
      snackbar_alert: false,
      snackbar_alert_type: "success",
      msg_alert: "",
      showFinishContractAlert: false,
      subscriptions: [],
      AlterCart: false,
      payment: [],
      getCardPayment: "",
      colapse: false,
      notificarionColor: "",
      visibilityReturn: false,
      logsModal: false,
      logsIdSubscription: 0,
      showAdditionalBillModal: false,
      productReturn: [],
      showModalSaveContractChanges: false,
      changeContractValue: false,
      contractAlert: [],
      showPdfModal: false,
      contractTemplate: {},
    };

    this.onChange = this.onChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onCancelContractPress = this.onCancelContractPress.bind(this);
    this.onReactivateContractPress = this.onReactivateContractPress.bind(this);
    this.getRowsSelectedModal = this.getRowsSelectedModal.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.errorReturnProduct = this.errorReturnProduct.bind(this);
  }

  componentDidMount = async () => {
    const token = localStorage.getItem("token");
    const _this = this;

    await this.getContract();
    await this.getDownloadContract();

    axios
      .get(`${process.env.REACT_APP_API_URL}/products/admin`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          products: data,
        });
      });
  };

  getDownloadContract = async () => {
    const token = localStorage.getItem("token");
    const _this = this;
    const query = `?userId=${this.state?.data?.id_user?._id}&contractId=${this.state?.data?.id}`;
    const url = `/v2/create-contract-template${query}`;

    try {
      if (!this.state?.data?.id_user?._id || !this.state?.data?.id) return;

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}${url}`,
        {
          headers: { Authorization: "bearer " + token },
        }
      );

      if (!data.success) throw new Error("Contract Template not found!");

      _this.setState({ contractTemplate: data.content });
    } catch (error) {
      console.log(error);
    }
  };

  formattedDate(dateString) {
    const dateObj = new Date(dateString);
    const offset = dateObj.getTimezoneOffset();
    dateObj.setMinutes(dateObj.getMinutes() + offset);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  getContract = async () => {
    this.setState({ loading: true });

    const { _id } = this.state.data;

    if (!_id) return this.setState({ loading: false });

    const token = localStorage.getItem("token");
    const _this = this;
    await axios
      .get(`${process.env.REACT_APP_API_URL}/contract/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then(async (response) => {
        let { data } = response;
        data = {
          ...data,
          delivery_date: this.formattedDate(data.delivery_date),
        };
        _this.setState({ data });

        if (!Boolean(data.code)) return;

        let code_string = `${data.code}`;

        let caracter_special = "";
        if (code_string.charAt(0) == "#") {
          caracter_special = code_string.charAt(0);
        }

        let data_get = { caracter: caracter_special };
        this.getPaymentProfiles();
        this.getSubscription();

        await axios
          .post(
            `${
              process.env.REACT_APP_API_URL
              // }/couponByCode/${code_string.substring(1, code_string.length)}`,
            }/couponByCode/${data.code.replace("#", "")}`,
            data_get,
            {
              headers: { Authorization: "bearer " + token },
            }
          )
          .then(async (response_coupon) => {
            _this.setState({ coupon: response_coupon.data });
            return;
          });
      })
      .finally(() => this.setState({ loading: false }));
  };

  updateProduct = async (id, position) => {
    let { data, products } = this.state;

    await products.map((item, index) => {
      if (item._id == id) {
        data.products[position].id_sap = item.id_sap;
        return;
      }
    });

    this.setState({ data: data });
    const token = await localStorage.getItem("token");
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/contract/${data._id}`,
        { data_contract: data },
        { headers: { Authorization: "bearer " + token } }
      )
      .then(async () => alert("Contrato Atualizado com sucesso."))
      .catch((error) => {
        console.log(error);
        alert("Não foi possível atualizar o contrato.");
      });
  };

  updateProductStatus = async (id, position, status) => {
    const token = localStorage.getItem("token");
    let { data } = this.state;
    let _this = this;

    data.products[position].status_product = status;

    if (status) {
      data.contractAltern = `product_active ${
        data.products[position].firstName +
        " " +
        data.products[position].lastName
      }`;
    } else {
      data.contractAltern = `product_disabled ${
        data.products[position].firstName +
        " " +
        data.products[position].lastName
      }`;
    }
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/contract/${data._id}`,
        { data_contract: data },
        { headers: { Authorization: "bearer " + token } }
      )
      .then(async () =>
        _this.setState({
          data: data,
          snackbar_alert_type: "success",
          msg_alert: "Produto Atualizado com sucesso.",
          snackbar_alert: true,
        })
      )
      .catch((error) => {
        console.log(error);
        _this.setState({
          data: data,
          snackbar_alert_type: "success",
          msg_alert: "Não foi possível atualizar o Produto.",
          snackbar_alert: true,
        });
      });
  };

  editContract = async () => {
    const { data } = this.state;
    const token = await localStorage.getItem("token");
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/contract/${data._id}`,
        { data_contract: data },
        { headers: { Authorization: "bearer " + token } }
      )
      .catch((error) => console.log(error));
  };

  onChange = (e) => {
    const { data } = this.state;
    let value;

    if (typeof e.target.value == "object") {
      value = e.target.value.join(",");
    } else {
      value = e.target.value;
    }

    this.setState({
      data: {
        ...data,
        [e.target.name]: value,
      },
      success: false,
    });
  };

  onSavePress = async () => {
    const { data } = this.state;

    if (!data._id) {
      this.newKit();
      return;
    }

    this.setState({
      saving: true,
    });

    const token = await localStorage.getItem("token");
    let data_custom = {
      data_contract: {
        status_contract: data.status_contract,
      },
    };

    if (data.status_contract == "Cancelado")
      data_custom.data_contract.status = "canceled";

    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/contract/${data._id}`,
        data_custom,
        { headers: { Authorization: "bearer " + token } }
      )
      .then(async () => {
        this.setState({
          saving: false,
          success: true,
        });

        let data_format = data.delivery_date.split("-");

        axios.post(`https://api.tuim.com.br/createLead`, {
          conversion_identifier: "sistema-status-contrato",
          name: data.id_user.name,
          email: data.id_user.email,
          cf_contract_status: data.status_contract,
          cf_subscription: data.id_subscription.toString(),
          cf_delivery_date:
            data_format[2] + "/" + data_format[1] + "/" + data_format[0],
        });

        this.props.history.goBack();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          saving: false,
          success: false,
          error: error.response,
        });
      });
  };

  stateContract = async (dataContract) =>
    this.setState({ data: dataContract, changeContractValue: true });

  handleEndContract = async ({ type = null, cancelBills = false }) => {
    const endType = {
      finish: {
        status: "finished",
        status_contract: "Finalizado",
      },
      cancel: {
        status: "canceled",
        status_contract: "Cancelado",
      },
    };

    this.setState({ loading: true });
    const token = localStorage.getItem("token");
    const _this = this;

    const { data } = this.state;
    const { id_subscription, contract_info = {} } = data;

    const contractWasIntegrated =
      type === "cancel" && Boolean(contract_info?.docEntry);

    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/contract/${data._id}`,
        { data_contract: { ...data, ...endType[type] } },
        { headers: { Authorization: "bearer " + token } }
      )
      .then(async () => {
        _this.setState({
          snackbar_alert_type: "success",
          msg_alert: "Contrato finalizado com sucesso.",
          snackbar_alert: true,
          notificarionColor: "green",
        });

        await axios
          .delete(
            `${process.env.REACT_APP_API_URL}/vindi/cancelSubscription/${id_subscription}/${cancelBills}`,
            { headers: { Authorization: "bearer " + token } }
          )
          .then(() =>
            this.setState({ saving: false, success: true, data: data })
          )
          .catch((error) => {
            console.log(error);
            _this.setState({
              snackbar_alert_type: "success",
              msg_alert: "Error ao Finalizar contrato!!",
              snackbar_alert: true,
              notificarionColor: "red",
            });
            this.setState({
              saving: false,
              success: false,
              error: error.response,
              data: data,
            });
          });

        if (contractWasIntegrated) {
          await axios
            .delete(
              `${process.env.REACT_APP_API_URL}/deleteSapOrder/${contract_info.docEntry}`,
              { headers: { Authorization: "bearer " + token } }
            )
            .catch((error) => {
              console.log(error);
              this.setState({
                saving: false,
                success: false,
                error: error.response,
              });
            });
        }

        this.setState({ loading: false });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          saving: false,
          success: false,
          error: error.response,
          loading: false,
        });
      });
  };

  editContract = async () => {
    const { data } = this.state;

    const token = await localStorage.getItem("token");
    axios
      .patch(`${process.env.REACT_APP_API_URL}/contract/${data._id}`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then(async () => this.setState({ saving: false, success: true }))
      .catch((error) =>
        this.setState({ saving: false, success: false, error: error.response })
      );
  };

  reactivateContract = async () => {
    const { data } = this.state;
    const { id_subscription } = data;

    const token = await localStorage.getItem("token");
    await axios
      .patch(
        `${process.env.REACT_APP_API_URL}/vindi/reactivateSubscription/${id_subscription}`,
        { headers: { Authorization: "bearer " + token } }
      )
      .catch((error) =>
        this.setState({ saving: false, success: false, error: error.response })
      );
  };

  onCancelPress = () => this.props.history.goBack();

  arredondar = (nr) => Math.round(nr, -1).toFixed(2);

  openMenuOption = (event) =>
    this.setState({ anchorEl: event.currentTarget, openMenuOptions: true });

  handleClose = () =>
    this.setState({ anchorEl: null, openMenuOptions: false, showAlert: false });

  onCancelContractPress = () =>
    this.setState({
      anchorEl: null,
      open: false,
      showCancelContractAlert: true,
    });

  onReactivateContractPress = () =>
    this.setState({
      anchorEl: null,
      open: false,
      showReactivateContractAlert: true,
    });

  talkWhatsApp = () => {
    const url = `http://api.whatsapp.com/send/?phone=55${this.state.data.id_user.mobileNumber.replace(
      /\D/g,
      ""
    )}`;
    window.open(url, "_blank");
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({ selected: state.data.map((n) => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  getProdutosClickOpen = () => {
    this.setState({ open: true });

    this.getProducts();
  };

  getProducts = async () => {
    const _this = this;

    if (_this.state.products == "" || _this.state.products.length <= 1) {
      const token = await localStorage.getItem("token");

      axios
        .get(`${process.env.REACT_APP_API_URL}/product/`, {
          headers: { Authorization: "bearer " + token },
        })
        .then((response) => _this.setState({ products: response.data }))
        .catch((error) => console.log(error));
    }
  };

  handleChangePage = (event, page) => this.setState({ page });

  createSortHandler = (property) => (event) =>
    this.props.onRequestSort(event, property);

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  selectAllProductsClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({
        selected: state.products.map((n) => n._id),
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  selectedProductClick = (event, _id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangeRowsPerPage = (event) =>
    this.setState({ rowsPerPage: event.target.value });

  getRowsSelectedModal(_selected) {
    const _this = this;
    _this.state.data.products = _selected;

    _this.setState({
      data: _this.state.data,
      open: false,
    });
  }

  getStatusSubscription = (status) => {
    if (status == "active") {
      return "Ativo";
    } else if (status == "canceled") {
      return "Cancelado";
    } else if (status == "expired") {
      return "Encerrada";
    } else if (status == "future") {
      return "Programada";
    }
  };

  deleteProduct = (productDelected) => () => {
    const { data, selected } = this.state;
    const productsToDelete = data.products.indexOf(productDelected);
    data.products.splice(productsToDelete, 1);
    selected.splice(productsToDelete, 1);

    this.setState({ data, selected });
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  getMonth(month) {
    const months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    return months[month - 1];
  }

  onCloseModal = () => this.setState({ AlterCart: false });

  getPaymentProfiles = () => {
    const { data } = this.state;
    let id_vindi = data.id_user.idVindi;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/vindi/paymentprofile/` + id_vindi,
        {}
      )
      .then((response) =>
        this.setState({ payment: response.data.data.payment_profiles })
      );
  };

  getSubscription = async () => {
    const { data } = this.state;
    let idSubscription = data.id_subscription;
    const token = localStorage.getItem("token");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/vindi/subscription/` + idSubscription,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) =>
        this.setState({ getCardPayment: response.data.subscription })
      );
  };

  saveContractChanges = async (contractAlert, contract) => {
    let contractEdit = contract;
    let arr = [...new Set(contractAlert)];
    contractEdit.contractAlert = arr;

    const token = localStorage.getItem("token");
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/contract/${contractEdit._id}`,
        {
          data_contract: {
            status_contract: contractEdit.status_contract,
            delivery_date: new Date(contract.delivery_date),
          },
        },
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then(async () => {
        alert("Contrato Atualizado com sucesso!");

        if (contractAlert.status_contract != contract.status_contract) {
          let data_format = contractEdit.delivery_date.split("-");
          let return_dateFormat;
          if (contractEdit.return_date) {
            return_dateFormat = contractEdit.return_date.split("-");
          } else {
            return_dateFormat = "undefined";
          }
          axios.post(`https://api.tuim.com.br/createLead`, {
            conversion_identifier: "status-contrato-admin",
            name: contractEdit.id_user.name,
            email: contractEdit.id_user.email,
            cf_contract_status: contractEdit.status_contract,
            cf_subscription: contractEdit.id_subscription.toString(),
            cf_delivery_date:
              data_format[2] + "/" + data_format[1] + "/" + data_format[0],
            return_date: return_dateFormat,
          });
          contractEdit.contractAltern = [];
          this.setState({ contractConst: [] });
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Não foi possível atualizar este contrato!");
      });
  };

  aproveContract = async () => {
    this.setState({ loading: true });
    const { data } = this.state;
    const { _id = "" } = data;
    const token = localStorage.getItem("token");
    let _this = this;

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/contract/${_id}/approve`,
        { status_contract: "Preparando para a Entrega" },
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        if (response.status == 200) {
          _this.setState({
            snackbar_alert_type: "success",
            msg_alert: "Aprovação realizada com sucesso.",
            snackbar_alert: true,
          });
          this.setState({
            data: {
              ...data,
              status_contract: "Preparando para a Entrega",
              status: "active",
            },
          });
          window.location.reload();
        }
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          _this.setState({
            snackbar_alert_type: "error",
            msg_alert: "Falha na autenticação.",
            snackbar_alert: true,
          });
        } else if (error.response.status == 404) {
          _this.setState({
            snackbar_alert_type: "error",
            msg_alert: "Contrato não localizado ou contrato já foi aprovado.",
            snackbar_alert: true,
          });
        } else if (error.response.status == 500) {
          _this.setState({
            snackbar_alert_type: "error",
            msg_alert: "Ocorreu um erro inesperado.",
            snackbar_alert: true,
          });
        }
      })
      .finally(() => _this.setState({ loading: false }));
  };

  disapproveContract = async () => {
    this.setState({ loading: true });

    const { data } = this.state;
    const { _id = "", contract_info = {} } = data;
    const token = localStorage.getItem("token");

    let _this = this;

    try {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/contract/${_id}/disapprove`,
          null,
          {
            headers: { Authorization: "bearer " + token },
          }
        )
        .catch(function (error) {
          if (error.response.status == 401) {
            _this.setState({
              snackbar_alert_type: "error",
              msg_alert: "Falha na autenticação.",
              snackbar_alert: true,
            });
          } else if (error.response.status == 404) {
            _this.setState({
              snackbar_alert_type: "error",
              msg_alert: "Contrato não localizado ou contrato já foi aprovado.",
              snackbar_alert: true,
            });
          } else if (error.response.status == 500) {
            _this.setState({
              snackbar_alert_type: "error",
              msg_alert: "Ocorreu um erro inesperado.",
              snackbar_alert: true,
            });
          }
        });

      if (contract_info?.docEntry != null) {
        await axios
          .delete(
            `${process.env.REACT_APP_API_URL}/deleteSapOrder/${contract_info.docEntry}`,
            {
              headers: { Authorization: "bearer " + token },
            }
          )
          .catch((error) => {
            console.log(error);
            this.setState({
              saving: false,
              success: false,
              error: error.response,
            });
          });
      }

      _this.setState({
        snackbar_alert_type: "success",
        msg_alert: "Reprovação do contrato realizada com sucesso.",
        snackbar_alert: true,
      });
    } catch (error) {
      _this.setState({
        snackbar_alert_type: "error",
        msg_alert: "Ocorreu um erro inesperado.",
        snackbar_alert: true,
      });
      console.log({
        message: `Error while disapproving contract`,
        error,
      });
    } finally {
      this.setState({ loading: false });
      window.location.reload();
    }
  };

  sendContractToSAP = async () => {
    this.setState({ loading: true });

    let _this = this;
    const { data } = this.state;
    const { _id = "" } = data;
    const token = localStorage.getItem("token");
    const adminUserData = JSON.parse(localStorage.getItem("tuimAdmin.user"));

    const body = {
      sapSellerId: adminUserData.sapSellerId,
      sapSellerName: adminUserData.name,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/sap/contract/${_id}`, body, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        this.setState({ loading: false });
        if (response.status == 201) {
          _this.setState({
            snackbar_alert_type: "success",
            notificarionColor: "#4caf50",
            msg_alert:
              "Enviado com sucesso para a Fila de processamento do SAP.",
            snackbar_alert: true,
          });
        }
      })
      .catch(function (error) {
        this.setState({ loading: false });
        if (error.response.status == 401) {
          _this.setState({
            snackbar_alert_type: "error",
            notificarionColor: "#f50707",
            msg_alert: "Falha na autenticação.",
            snackbar_alert: true,
          });
        } else if (error.response.status == 404) {
          _this.setState({
            snackbar_alert_type: "error",
            notificarionColor: "#f50707",
            msg_alert: "Contrato não localizado.",
            snackbar_alert: true,
          });
        } else if (error.response.status == 500) {
          _this.setState({
            snackbar_alert_type: "error",
            notificarionColor: "#f50707",
            msg_alert: "Ocorreu um erro inesperado.",
            snackbar_alert: true,
          });
        }
      });
  };

  formatDate = (data = "") => {
    let dia = data.slice(0, 2);
    let mes = data.slice(3, 5);
    let ano = data.slice(6, 10);
    let horas = data.slice(11, 20);
    let DateFromat = dia + "/" + mes + "/" + ano + " - " + horas;
    return DateFromat;
  };

  visibleTerm = () => {
    const { colapse } = this.state;
    if (colapse) {
      this.setState({ colapse: false });
    } else {
      this.setState({ colapse: true });
    }
  };

  visibilityReturnProduct = () => {
    const { visibilityReturn } = this.state;
    if (visibilityReturn) {
      this.setState({ visibilityReturn: false });
    } else {
      this.setState({ visibilityReturn: true });
    }
  };

  errorReturnProduct = (msg) =>
    this.setState({
      snackbar_alert_type: "error",
      notificarionColor: "#f50707",
      msg_alert: msg,
      snackbar_alert: true,
    });

  formatTimestamp = (timestamp) => {
    let splitDate = timestamp.split("-");
    let splitTime = splitDate[3].split(":");

    let formatedDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`;
    let formatedTime = `${
      splitTime[0].length === 1 ? "0" + splitTime[0] : splitTime[0]
    }:${splitTime[1]}:${splitTime[2]}`;

    return new Date(`${formatedDate}T${formatedTime}`);
  };

  deliveryDateChangeValue = (e) => {
    // Sun Feb 04 2024 21:00:00 GMT-0300 (Horário Padrão de Brasília) - data.delivery_date
    // 2024-02-02 - Input
    // 2024-02-05T00:00:00.000+00:00 - DB

    this.setState({
      data: {
        ...this.state.data,
        delivery_date: e.target.value,
      },
      changeContractValue: true,
    });
  };

  downloadContractPDF = () => {
    const printWindow = window.open("", "", "width=800,height=600");

    printWindow.document.write(
      this.state.contractTemplate.contractTemplateDownload
    );
    printWindow.document.close();
    printWindow.print();
  };

  render() {
    let {
      AlterCart,
      saving,
      coupon,
      showCancelContractAlert,
      showReactivateContractAlert,
      data,
      loading,
      msg_alert,
      snackbar_alert,
      showFinishContractAlert,
      payment,
      showModalAproveContract,
      showModalReproveContract,
      notificarionColor,
      logsIdSubscription,
      logsModal,
      showAdditionalBillModal,
      showModalSaveContractChanges,
      changeContractValue,
      contractAlert,
      showPdfModal,
      contractTemplate,
    } = this.state;
    const { status = "", payments = [] } = data;

    const missingIdSap = !data?.contract_info?.docNum;
    const seller = {
      id: data?.contract_info?.sapSellerId,
      name: data?.contract_info?.sapSellerName,
    };

    if (typeof data.id_filial != "undefined") {
      if (data.id_filial == 8 || data.id_filial == 1) {
        data.filial = "SP";
      } else if (data.id_filial == 10) {
        data.filial = "RJ";
      } else {
        data.filial = data.id_filial;
      }
    } else {
      data.filial = "Não Contém filial";
    }

    const userInfos = {
      name: this.state.data.id_user.name || "-",
      email: this.state.data.id_user.email || "-",
      phone: this.state.data.id_user.mobileNumber || "-",
      cpf: this.state.data.id_user.cpf || "-",
      rg: this.state.data.id_user.rg || "-",
      birthDay:
        formatter.date(this.state.data.id_user.dateBirth, "short", false) ||
        "-",
      partnership: this.state.data.id_user.partnership || "-",
      active: this.state.data.id_user.active ? "Ativo" : "Inativo",
    };

    return (
      <>
        <Loading loading={loading} />
        <Edit
          contract={data}
          payment={payment}
          getSubscription={() => this.getSubscription()}
          AlterCart={AlterCart}
          onCloseModal={() => this.onCloseModal()}
        />

        {logsModal && (
          <LogsContracts
            modalVisible={logsModal}
            id_subscription={logsIdSubscription}
            onCloseModal={() => this.setState({ logsModal: false })}
          />
        )}
        {!loading && (
          <>
            <ContentHeader
              title={data.id_user.name}
              link={`/app/client/${data.id_user._id}`}
              subtitle={
                <Box style={{ display: "flex", gap: 4 }}>
                  <Text>Contrato</Text>
                  <Text strong>{CONTRACT_STATUS[data.status]} |</Text>
                  <Text>Situação: </Text>
                  <Text strong>
                    {data.status_contract || "Em processamento"}
                  </Text>
                </Box>
              }
              buttons={
                !changeContractValue
                  ? [
                      {
                        text: "Histórico",
                        tertiary: true,
                        onClick: () =>
                          this.setState({
                            logsModal: true,
                            logsIdSubscription: data.id_subscription,
                          }),
                      },
                      ...(data.status === "active" || data.status === "pending"
                        ? [
                            {
                              text: "Finalizar",
                              destructive: true,
                              onClick: () =>
                                this.setState({
                                  showFinishContractAlert: true,
                                }),
                            },
                          ]
                        : []),
                    ]
                  : [
                      {
                        text: "Salvar",
                        onClick: () =>
                          this.setState({ showModalSaveContractChanges: true }),
                      },
                      {
                        text: "Histórico",
                        tertiary: true,
                        onClick: () =>
                          this.setState({
                            logsModal: true,
                            logsIdSubscription: data.id_subscription,
                          }),
                      },
                      ...(data.status === "active" || data.status === "pending"
                        ? [
                            {
                              text: "Finalizar",
                              destructive: true,
                              onClick: () =>
                                this.setState({
                                  showFinishContractAlert: true,
                                }),
                            },
                          ]
                        : []),
                    ]
              }
              dotsMenuItems={[
                { text: "Falar por WhatsApp", action: this.talkWhatsApp },
                ...(data.status === "canceled"
                  ? [
                      {
                        text: "Reativar Contrato",
                        action: this.onReactivateContractPress,
                      },
                    ]
                  : [
                      {
                        text: "Cancelar Contrato",
                        action: this.onCancelContractPress,
                      },
                    ]),
              ]}
            />
            {console.log(data)}
            <Box style={{ display: "flex", gap: 28, flex: 1 }}>
              <ContentContainer fullWidth label="Contrato">
                <Rows
                  data={[
                    { label: "Assinatura", description: data.id_subscription },
                    {
                      label: "Início",
                      description: formatter.date(data.createdAt, "short"),
                    },
                    data.endingDetails?.endedAt
                      ? {
                          label: "Término",
                          description: formatter.date(
                            data.endingDetails.endedAt,
                            "short"
                          ),
                        }
                      : {},
                    {
                      label: "Duração",
                      description: `${data.period}  ${pluralize(
                        "mês",
                        data.period
                      )}`,
                    },
                    {
                      label: "Valor",
                      description: formatter.currency(data.payment.price),
                    },
                    {
                      label: "Taxa adicional",
                      description: data.payment.deliveryPrice
                        ? `${formatter.currency(data.payment.deliveryPrice)}`
                        : "Não aplicada",
                    },
                    {
                      label: "Cupom",
                      description: data?.code
                        ? `${data?.code} ${discount({
                            discount_price: data?.discount_price,
                            discount_percentage: data?.discount_percentage,
                            discount_code: data?.discount_code,
                          })}${
                            data.cycles_code > 0
                              ? ` nos ${data.cycles_code} primeiros meses`
                              : ""
                          }. Desconto ${
                            coupon.type == "delivery"
                              ? "na taxa de etrega"
                              : "no carrinho"
                          }`
                        : "não aplicado",
                    },
                    {
                      label: "PV",
                      description: data?.contract_info?.docEntry || "-",
                    },
                    {
                      label: "ID SAP",
                      description: data?.contract_info?.docNum || "-",
                    },
                    {
                      label: "Vendedor",
                      description: seller.id
                        ? `${seller.name} (${seller.id})`
                        : "-",
                    },
                    {
                      label: "Status do Contrato",
                      description: (
                        <ContractDropdown
                          contract={data}
                          stateContract={this.stateContract}
                          status_contract={data.status_contract}
                        />
                      ),
                    },
                  ]}
                />
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    gap: 10,
                    flex: 1,
                  }}
                >
                  {missingIdSap && (
                    <Text color={Colors.red}>
                      Atenção! Este contrato ainda não está integrado com o SAP.
                    </Text>
                  )}
                  {status == "pending" && (
                    <Box
                      style={{
                        display: "flex",
                        gap: 10,
                      }}
                    >
                      <TuimButton
                        fullWidth
                        text="Aprovar"
                        secondary
                        onClick={() =>
                          this.setState({ showModalAproveContract: true })
                        }
                      />
                      <TuimButton
                        fullWidth
                        text="Reprovar"
                        tertiary
                        destructive
                        onClick={() =>
                          this.setState({ showModalReproveContract: true })
                        }
                      />
                    </Box>
                  )}

                  {missingIdSap && (
                    <TuimButton
                      fullWidth
                      text="Integrar com SAP"
                      disabled={saving}
                      onClick={() => this.sendContractToSAP()}
                    />
                  )}
                </Box>
                <MdDownload
                  style={{
                    position: "absolute",
                    top: 31,
                    right: 30,
                    cursor: "pointer",
                  }}
                  size={20}
                  color={Colors.darkGreenAlt}
                  onClick={() => this.setState({ showPdfModal: true })}
                />
              </ContentContainer>

              {showPdfModal && (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    width: "100%",
                    zIndex: 99,
                    position: "fixed",
                    top: 0,
                    left: 0,
                    backgroundColor: "#00000044",
                  }}
                >
                  <ContentContainer
                    center
                    style={{
                      width: "fit-content",
                      maxWidth: "43.75rem",
                      maxHeight: "90%",
                    }}
                  >
                    <TuimButton
                      text="DOWNLOAD"
                      onClick={this.downloadContractPDF}
                    />
                    <Text style={{ whiteSpace: "break-spaces" }}>
                      {contractTemplate.contractTemplate}
                    </Text>
                    <TuimButton
                      text="OK"
                      onClick={() => this.setState({ showPdfModal: false })}
                    />
                  </ContentContainer>
                </Box>
              )}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 28,
                  flex: 1,
                }}
              >
                <ContentContainer fullWidth label="Entrega">
                  <Rows
                    data={[
                      { label: "Filial", description: data.filial },
                      {
                        label: "Endereço",
                        description: `${data.address.rua}, ${
                          data.address.numero
                        } ${data.address.complemento || ""} - ${
                          data.address.cidade
                        }`,
                      },
                      { label: "Cep", description: data.address.cep },
                      {
                        label: "Quem recebe",
                        description: data?.address?.receiveUser
                          ? "Eu mesmo receberei"
                          : `${data.address.receiveDelivery} (${data.address.receivePhoneDelivery})`,
                      },
                      {
                        label: "Data de Entrega",
                        description: (
                          <input
                            type="date"
                            onChange={this.deliveryDateChangeValue}
                            value={data.delivery_date.split("T")[0]}
                            style={{
                              padding: `0px`,
                              border: `none`,
                              fontFamily: "Ubuntu",
                              color: "#444",
                            }}
                          />
                        ),
                      },
                      data?.address?.observacoes
                        ? {
                            label: "Observações",
                            description: (
                              <Box style={{ maxHeight: 80, marginRight: 8 }}>
                                <span style={{ color: "#444" }}>
                                  {data.address.observacoes}
                                </span>
                              </Box>
                            ),
                          }
                        : {},
                    ]}
                  />
                </ContentContainer>
                {data.endingDetails?.endedAt && (
                  <ContentContainer label="Retirada">
                    <Rows
                      data={[
                        {
                          label: "Solicitação",
                          description: data.endingDetails?.collectResquestedAt,
                        },
                        {
                          label: "Data",
                          description: data.endingDetails?.collectDate,
                        },
                        {
                          label: "Motivo",
                          description: data.endingDetails?.collectReason,
                        },
                      ]}
                    />
                    <MdEdit
                      style={{
                        position: "absolute",
                        top: 31,
                        right: 30,
                        cursor: "pointer",
                      }}
                      size={20}
                      color={Colors.darkGreenAlt}
                      onClick={() =>
                        this.setState({
                          showFinishContractAlert: true,
                        })
                      }
                    />
                  </ContentContainer>
                )}
              </Box>
            </Box>

            <ContentContainer label={`Produtos (${data.products.length})`}>
              <Box
                style={{ display: "flex", flexDirection: "column", gap: 28 }}
              >
                <Table style={{ minWidth: 662 }} aria-label="simple table">
                  <TableBody>
                    {data.products.map((product, index) => {
                      return (
                        <ProductCard
                          period={data.period}
                          product={product}
                          qtd={product.qtd}
                          data={data}
                          getContract={this.getContract}
                          returnProductOption={true}
                          key={`${product.name} - ${index}`}
                        />
                      );
                    })}
                  </TableBody>
                </Table>

                {data?.productReturn?.length ? (
                  <>
                    <Box style={{ padding: "36px 0px 16px 0px" }}>
                      <Text strong color={Colors.darkGreen + "aa"}>
                        {`Produtos Devolvidos (${data.productReturn.length})`}
                      </Text>
                    </Box>
                    <VisibilityReturnProduct
                      products={data?.productReturn}
                      period={data.period}
                      getPriceByProductItem={getPriceByProductItem}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </ContentContainer>
            <Box style={{ display: "flex", gap: 24 }}>
              <ContentContainer
                label={`Faturas (${payments.length || 0})`}
                fullWidth
              >
                {payments.length > 0 ? (
                  <>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Tipo</TableCell>
                          <TableCell>Portador</TableCell>
                          <TableCell>Parcela</TableCell>
                          <TableCell align="center">Status</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {payments.reverse().map((payment) => (
                          <TableRow
                            key={uid()}
                            hover
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_VINDI_URL}admin/bills/${payment.bill_id}`
                              )
                            }
                          >
                            <TableCell>{payment?.bill_id}</TableCell>
                            <TableCell>
                              <Moment
                                format="DD/MM/YYYY"
                                date={new Date(payment?.created_at)}
                              ></Moment>
                            </TableCell>
                            <TableCell>
                              {formatter.currency(payment?.amount)}
                            </TableCell>
                            <TableCell>
                              {payment?.product?.name || "-"}
                            </TableCell>
                            <TableCell>
                              {payment?.payment_profile?.holder_name || "-"}
                            </TableCell>
                            <TableCell>{payment?.cycle || "-"}</TableCell>
                            <TableCell align="center">
                              {paymentStatus[payment.status]}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Rows
                      rowGap={10}
                      data={[
                        {
                          label: "Tipo de pagamento",
                          description: data?.payment?.payment_profile
                            ?.payment_company?.name
                            ? `Cartão de Crédito (${data.payment.payment_profile.payment_company.name})`
                            : "-",
                        },
                        {
                          label: "Cartão",
                          description: data?.payment?.payment_profile
                            ?.card_number_last_four
                            ? `**** **** **** ${data?.payment?.payment_profile?.card_number_last_four}`
                            : "-",
                        },
                      ]}
                    />
                  </>
                ) : (
                  <Box style={styles.noNotesContainer}>
                    <Text style={{ fontSize: 14, color: "#999" }}>
                      Nenhum pagamento registrado.
                    </Text>
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 10,
                  }}
                >
                  <TuimButton
                    text="Nova Fatura"
                    onClick={() =>
                      this.setState({ showAdditionalBillModal: true })
                    }
                  />
                  <TuimButton
                    text="Alterar Cartão"
                    tertiary
                    onClick={() => this.setState({ AlterCart: true })}
                  />
                </Box>
              </ContentContainer>
            </Box>
            <Box style={{ display: "flex", gap: 24 }}>
              <ContentContainer label="Dados do Usuário" fullWidth>
                <Rows
                  data={[
                    { label: "Nome", description: userInfos.name },
                    { label: "E-mail", description: userInfos.email },
                    {
                      label: "Celular",
                      description: (
                        <ExternalLink
                          data={userInfos.phone}
                          icon={<FaWhatsapp size={20} color="#688284" />}
                          action={() =>
                            window.open(
                              `http://api.whatsapp.com/send/?phone=55${userInfos.phone.replace(
                                /\D/g,
                                ""
                              )}`
                            )
                          }
                        />
                      ),
                    },
                    { label: "CPF", description: userInfos.cpf },
                    { label: "RG", description: userInfos.rg },
                    {
                      label: "Data de Nascimento",
                      description: userInfos.birthDay,
                    },
                    {
                      label: "PN",
                      description: data?.contract_info?.pn || "-",
                    },
                    { label: "Parceria", description: userInfos.partnership },
                    { label: "Ativo", description: userInfos.active },
                  ]}
                />
              </ContentContainer>

              <ContentContainer label="Observações Internas" fullWidth>
                <InternalNotes reference={{ type: "Contract", id: data._id }} />
              </ContentContainer>
            </Box>
            {data.obsAdmin && (
              <ContentContainer label="Observações">
                <Text>{data.obsAdmin}</Text>
              </ContentContainer>
            )}
          </>
        )}
        <Alert
          msg_alert={msg_alert}
          notificarionColor={notificarionColor}
          snackbar_alert={snackbar_alert}
        />
        {showCancelContractAlert && (
          <AlertDialog
            msg={`Deseja realmente cancelar este Contrato #${data.id_subscription} ? `}
            title="Cancelar Contrato"
            open={showCancelContractAlert}
            buttons={[
              {
                label: "Cancelar a operação",
                onPress: () => {
                  this.setState({
                    showCancelContractAlert: false,
                  });
                },
              },
              {
                label: "Cancela fatura e a assinatura",
                onPress: () => {
                  this.setState({
                    showCancelContractAlert: false,
                  });
                  this.handleEndContract({ type: "cancel", cancelBills: true });
                },
                color: "primary",
              },
              {
                label: "Cancelar a assinatura",
                onPress: () => {
                  this.setState({
                    showCancelContractAlert: false,
                  });
                  this.handleEndContract({ type: "cancel" });
                },
                color: "primary",
              },
            ]}
          />
        )}

        {showReactivateContractAlert && (
          <AlertDialog
            msg={`Deseja realmente reativar este Contrato #${data.id_subscription} ? `}
            title="Reativar Contrato"
            open={showReactivateContractAlert}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showReactivateContractAlert: false,
                  });
                },
              },
              {
                label: "Confirmar",
                onPress: () => {
                  this.setState({
                    showReactivateContractAlert: false,
                  });
                  this.reactivateContract();
                },
                color: "primary",
              },
            ]}
          />
        )}
        {showFinishContractAlert && (
          <EndContract
            contract={{ id: data._id, subscriptionId: data.id_subscription }}
            endingDetails={{
              collectResquestedAt: data?.endingDetails?.collectResquestedAt,
              collectDate: data?.endingDetails?.collectDate,
              collectReason: data?.endingDetails?.collectReason,
            }}
            handleClose={() =>
              this.setState({ showFinishContractAlert: false })
            }
          />
        )}

        {showModalAproveContract && (
          <AlertDialog
            msg={`Deseja realmente Aprovar o Contrato #${data.id_subscription} ? `}
            title="Aprovar Contrato"
            open={showModalAproveContract}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showModalAproveContract: false,
                  });
                },
              },
              {
                label: "Aprovar",
                onPress: () => {
                  this.setState({
                    showModalAproveContract: false,
                  });
                  this.aproveContract();
                },
                color: "primary",
              },
            ]}
          />
        )}
        {showModalSaveContractChanges && (
          <AlertDialog
            msg={`Deseja realmente salvar as mudanças feitas no contrato #${data.id_subscription} ? `}
            title="Salvar Contrato"
            open={showModalSaveContractChanges}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showModalSaveContractChanges: false,
                  });
                },
              },
              {
                label: "Salvar",
                onPress: () => {
                  this.setState({
                    showModalSaveContractChanges: false,
                  });
                  this.saveContractChanges(contractAlert, data);
                },
                color: "primary",
              },
            ]}
          />
        )}
        {showModalReproveContract && (
          <AlertDialog
            msg={`Deseja realmente reprovar o contrato #${data.id_subscription} ? `}
            title="Reprovar Contrato"
            open={showModalReproveContract}
            buttons={[
              {
                label: "Cancelar",
                onPress: () => {
                  this.setState({
                    showModalReproveContract: false,
                  });
                },
              },
              {
                label: "Reprovar",
                onPress: () => {
                  this.setState({
                    showModalReproveContract: false,
                  });
                  this.disapproveContract();
                },
                color: "primary",
              },
            ]}
          />
        )}
        {showAdditionalBillModal && (
          <AdditionalBill
            idVindi={data.id_user?.idVindi}
            contract={{ id: data._id, subscriptionId: data.id_subscription }}
            handleClose={() =>
              this.setState({ showAdditionalBillModal: false })
            }
          />
        )}
      </>
    );
  }
}

const discount = ({
  discount_price = null,
  discount_percentage = null,
  discount_code,
}) => {
  if (discount_percentage)
    return `(${Number(discount_percentage).toFixed(0)}%)`;
  if (discount_price) return `(R$ ${Number(discount_price).toFixed(0)})`;
  if (discount_code) return `(${Number(discount_code).toFixed(0)}%)`;
};

const styles = {
  noNotesContainer: {
    display: "flex",
    width: "100%",
    height: 120,
    justifyContent: "center",
    alignItems: "center",
  },
};

Contract.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contract);
