import { Box, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import Numeral from "numeral";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { TuimButton } from "../layout/Button";
import Loading from "../utils/Loading";
Numeral.locale("pt-br");

export default function LogContract({
  onCloseModal,
  modalVisible,
  id_subscription,
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getLogs = async () => {
    setLoading(true);
    const token = await localStorage.getItem("token");
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/logContract`,
        { id_subscription: id_subscription },
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then(async (response) => {
        const { data } = response;
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  useEffect(() => {
    getLogs();
  }, []);

  return (
    <Box sx={{ position: "relative", outline: "none" }}>
      <Loading loading={loading} />
      <Modal
        open={modalVisible}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClick={() => onCloseModal()}
      >
        <div
          style={{
            position: "absolute",
            maxWidth: 1087,
            backgroundColor: "#fff",
            border: "2px solid #000",
            padding: 30,
            margin: "auto",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0,
            maxHeight: 450,
            border: "none",
            overflowX: "hidden",
            overflowY: "scroll",
            borderRadius: 20,
            outline: "none",
          }}
          onClick={(event) => event.stopPropagation()}
        >
          <Typography style={{ fontSize: 18, fontWeight: 500 }}>
            Logs
          </Typography>

          <Box
            sx={{
              backgroundColor: "#e9e9e9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              position: "absolute",
              top: 25,
              right: 20,
              padding: 10,
              width: 40,
              height: 40,
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "#f1f1f1",
                cursor: "pointer",
              },
            }}
            onClick={() => onCloseModal()}
          >
            X
          </Box>
          <TableContainer style={{ display: "flex", marginTop: 20 }}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Descrição</TableCell>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell align="center">Sistema</TableCell>
                  <TableCell align="center">Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.description}
                    </TableCell>
                    <TableCell align="center">{row.type}</TableCell>
                    <TableCell align="center">{row.system}</TableCell>
                    <TableCell align="center">
                      <Moment
                        format="DD/MM/YYYY HH:mm:ss"
                        date={new Date(row.createdAt)}
                      ></Moment>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: 15,
              marginTop: 15,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TuimButton onClick={() => onCloseModal()} text="Fechar" />
          </div>
        </div>
      </Modal>
    </Box>
  );
}
