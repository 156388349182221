import {
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { formatter } from "../../functions/index";
import Loading from "../utils/Loading";

import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";

class ListCategories extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      searchTerm: "",
      loading: true,
    };

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {
    const _this = this;

    const token = await localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/v2/admin/categories`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          data,
        });

        this.setState({ loading: false });
      });
  };

  onSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  render() {
    const { classes, data, searchTerm, loading } = this.state;

    return (
      <>
        <Loading loading={loading} />
        <ContentHeader
          title="Categorias"
          buttons={[
            {
              text: "Nova",
              secondary: true,
              onClick: () => this.props.history.push(`/app/category/`),
            },
          ]}
        />

        <ContentContainer>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Digite o nome da categoria"
              onChange={this.onSearchChange}
              value={searchTerm}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
            />
          </div>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell align="center">Data de cadastro</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .filter((item) =>
                  item.title.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((row) => (
                  <TableRow
                    key={row._id}
                    hover
                    onClick={() =>
                      this.props.history.push(`/app/category/${row._id}`)
                    }
                    className={classes.row}
                  >
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="center">
                      {formatter.date(row.createdAt, "short")}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </ContentContainer>
      </>
    );
  }
}

ListCategories.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
  search: {
    position: "relative",
    borderRadius: 12,
    backgroundColor: fade("#ececec", 0.5),
    "&:hover": {
      backgroundColor: fade("#ececec", 0.8),
    },

    marginLeft: 0,
    padding: `4px`,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    marginLeft: theme.spacing.unit * 1,
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0,
    margin: "auto",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
});

export default withStyles(styles)(ListCategories);
