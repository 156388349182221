import { Box, Collapse } from "@material-ui/core";
import React, { useState } from "react";
import { Text } from "../text";
import { Colors } from "../../../../styles/Colors";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

/*

Default props:

- FlexDirection: column;
- Alignment: 'flex-start

*/

export const ContentContainer = (props) => {
  const [expand, setExpand] = useState(false);

  const {
    children,
    row = false,
    center = false,
    right = false,
    fullWidth = false,
    gap = 24,
    width = null,
    style = {},
    onClick = () => {},
    label = null,
    collapsable = false,
  } = props;

  // const expandContent = ((collapsable && expand) || (!collapsable));

  return (
    <Box
      onClick={onClick}
      style={{
        ...styles.contentContainer,
        ...(width && { width }),
        ...(fullWidth && { flex: 1 }),
        ...style,
      }}
    >
      {label && (
        <Box style={{ marginBottom: 16 }}>
          <Box style={{ display: "flex" }}>
            <Text color={Colors.darkGreenAlt} strong style={{ flex: 1 }}>
              {label}
            </Text>
            {collapsable ? (
              !expand ? (
                <KeyboardArrowDown
                  htmlColor={Colors.paleDarkerGreen}
                  onClick={() => setExpand((prev) => !prev)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <KeyboardArrowUp
                  htmlColor={Colors.paleDarkerGreen}
                  onClick={() => setExpand((prev) => !prev)}
                  style={{ cursor: "pointer" }}
                />
              )
            ) : (
              <></>
            )}
          </Box>
          <Box
            style={{
              backgroundColor: Colors.paleDarkGreen,
              width: "100%",
              height: 1,
              position: "relative",
              marginTop: 6,
            }}
          >
            <Box
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                right: 0,
                top: 0,
                backgroundImage: "linear-gradient(to left, #fff, transparent)",
              }}
            />
          </Box>
        </Box>
      )}
      {/* <Collapse in={expandContent} timeout={600} collapsedHeight={0}> */}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap,
          overflowX: "auto",
          flex: 1,
          ...(row
            ? {
                flexDirection: "row",
                ...(center && { justifyContent: "center" }),
                ...(right && { justifyContent: "flex-end" }),
              }
            : {
                ...(center && { alignItems: "center" }),
                ...(right && { alignItems: "flex-end" }),
              }),
        }}
      >
        {children}
      </Box>
      {/* </Collapse> */}
    </Box>
  );
};

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    padding: `30px`,
    overflow: "hidden",
    borderRadius: 20,
    backgroundColor: "#fff",
    boxShadow: `rgba(149, 157, 165, 0.12) 0px 4px 24px`,
    position: "relative",
  },
};
