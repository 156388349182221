import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BackspaceIcon from '@material-ui/icons/Backspace';
import Moment from 'react-moment'

export default function MediaControlCard(props) {
    const theme = useStyles();  
    return (
        <Card  style={theme.bloco}>
            <div >
                <CardContent style={theme.container}>
                {props.status == 'active'?<AssignmentTurnedInIcon style={{ color: "green", float: `right`}}/> : <BackspaceIcon style={{ color: "red", float: `right`}}/>}
                    <Typography style={theme.root} variant="subtitle1" color="textSecondary">
                       <b>Nome: {props.name}</b> 
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                       Numero: **** **** **** {props.numero}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                       Bandeira:  <img alt={props.bandeira} width='30px' src={`https://tuim.com.br/static/bandeiras/${props.bandeira}.svg`} />
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                       Data de cadastro:  <Moment format="DD/MM/YYYY HH:mm:ss" date={new Date(props.data)} />
                    </Typography>
                </CardContent>
                
               
              
            </div>
        </Card>
    );
}

const useStyles = theme => ({
   
    bloco:{
        boxShadow: "none" 

    },
    container:{       
        width: 386,
        boxShadow: "0px 1px 5px 0px rgb(0 0 0 / 20%)",
        margin: 10,
    },
    root: {
        display: "flex"
        
    },
    details: {
        display: "flex",
        flexDirection: "column"
    },
    content: {
        flex: "1 0 auto",
       
    },
    cover: {
        width: 151,
       
    },
    controls: {
        display: "flex",
        alignItems: "center",
        paddingLeft: 1,
        paddingBottom: 1,
    },
    playIcon: {
        height: 38,
        width: 38
    }
});