import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { pluralize } from "../../functions";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";

const ListTranslations = () => {
  const [data, setData] = useState([]);
  const getTranslations = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_TRANSLATION_API_URL}/translations?origin=admin`,
        {
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_TRANSLATION_TOKEN}`,
          },
        }
      )
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTranslations();
  }, []);

  return (
    <>
      <ContentHeader
        title="Traduções"
        subtitle={`${data.length} ${pluralize("registro", data.length)}`}
      />

      <ContentContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={5}
          page={0}
          backIconButtonProps={{
            "aria-label": "Página anterior",
          }}
          nextIconButtonProps={{
            "aria-label": "Próxima página",
          }}
          //   onChangePage={this.handleChangePage}
          //   onChangeRowsPerPage={this.handleChangeRowsPerPage}
        /> */}
        <Table style={{}}>
          <TableHead>
            <TableRow>
              <TableCell>Texto</TableCell>
              {/* <TableCell>Ativo</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow
                key={item._id}
                hover
                onClick={() =>
                  window.open(`/app/translations/${item._id}`, "_blank")
                }
                style={{ cursor: "pointer" }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  //   style={{
                  //     whiteSpace: "nowrap",
                  //     overflow: "hidden",
                  //     textOverflow: "ellipsis",
                  //     WebkitLineClamp: 1,
                  //     display: "-webkit-box",
                  //     WebkitBoxOrient: "vertical",
                  //   }}
                >
                  {item.text}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={5}
          page={0}
          backIconButtonProps={{
            "aria-label": "Página anterior",
          }}
          nextIconButtonProps={{
            "aria-label": "Próxima página",
          }}
          //   onChangePage={this.handleChangePage}
          //   onChangeRowsPerPage={this.handleChangeRowsPerPage}
        /> */}
      </ContentContainer>
    </>
  );
};

export default ListTranslations;
