import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";
import React from "react";
import { formatter, lightOrDark, pluralize } from "../../functions";
import { Colors } from "../../styles/Colors";
import { TuimButton } from "../layout/Button";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Text } from "../layout/components/text";
import { Discount, Selector, TextArea, TextField } from "../ui";
import { AddButton, RemoveButton } from "../ui/components/buttons";
import Dropzone from "../ui/components/upload/Dropzone";
import ImageList from "../ui/components/upload/ImageList";
import Loading from "../utils/Loading";
import TableSelectModal from "../utils/TableSelectModal";
import Confirm from "../utils/Confirm";
import { IoClose } from "react-icons/io5";
import { Modal } from "../layout/components/modal/Modal";

class EditKit extends React.Component {
  constructor(props) {
    super(props);

    const { pathname } = props.location;
    const arrayPath = pathname.split("/");
    const _id = arrayPath[arrayPath.length - 1];

    this.state = {
      loading: true,
      allVal: 0,
      discount: 0,
      allPrices: [],
      data: {
        _id,
        name: "",
        description: "",
        technicalDescription: "",
        price: 0,
        category: "CONJUNTOS",
        discounts: [],
        kitPrice: 0,
        kitDiscount: 0,
        colors: [],
        features: [],
        units: [],
        styles: [],
        images: [],
        urlImg: null,
        ambients: [],
        id_john: "",
        estoque: 0,
        products: [],
        toPartner: false,
        active: false,
        visible: true,
      },
      discounts: [],
      categories: [],
      features: [],
      ambients: [],
      units: [],
      styles: [],
      products: [],
      selectorData: [],
      list_products: [],
      isDiscountFormOpen: false,
      isSelectorOpen: false,
      saving: false,
      isConfirmOpen: false,
      openModalProducts: false,
      itemsSelected: [],
      createDiscount: "",
      productsPriceSum: 0,
      discountAmount: 0,
      kitPrice: 0,
      removeItem: null,
    };

    this.getRowsSelectedModal = this.getRowsSelectedModal.bind(this);

    this.onCheckChange = this.onCheckChange.bind(this);
    this.addAllProducts = this.addAllProducts.bind(this);
    this.getDiscount = this.getDiscount.bind(this);
  }

  arredondar = (nr) => {
    return Math.round(nr, -1).toFixed(2);
  };

  validateStock = (data) => {
    const hasStock = data.products.filter((item) => item.qtd === 0);

    if (hasStock.length > 0) {
      const itemsWithoutStock = hasStock.map((item) => item.product.name);
      const pluralSuffix = itemsWithoutStock.length > 1 ? "s" : "";
      const alertMessage = `O${pluralSuffix} item${pluralSuffix} ${itemsWithoutStock.join(
        ", "
      )} ${pluralSuffix.length > 0 ? "estão" : "está"} sem estoque.`;

      alert(alertMessage);
      return false;
    }

    return true;
  };

  onCheckChange = (name) => (event) => {
    const { data } = this.state;

    const hasStock = this.validateStock(data);

    if (!hasStock) return;

    this.setState({
      data: {
        ...data,
        [name]: event.target.checked,
      },
      success: false,
    });
  };

  componentDidMount = async () => {
    this.getKit();
    this.getCategories();
    this.getStyles();
    this.getFeatures();
    this.getUnits();
    this.getAmbients();
    this.getDiscount();
  };

  getKitPrice = () => {
    const productsPriceSum = this.state.data.products.reduce(
      (sum, current) => sum + Math.round(current.qtd * current.product.price),
      0
    );

    const discountAmount = Math.round(
      productsPriceSum * (this.state.data.kitDiscount / 100)
    );

    const kitPrice = Math.round(productsPriceSum - discountAmount);

    return { productsPriceSum, discountAmount, kitPrice };
  };

  getDiscount = async () => {
    const _this = this;
    const token = localStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_API_URL}/listDiscount`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          discounts: data,
          loading: false,
        });
      });
  };

  onSave = async () => {
    const { data } = this.state;

    const hasStock = this.validateStock(data);

    if (!hasStock) return;

    if (!data._id) {
      this.newKit();
      return;
    }

    this.setState({
      saving: true,
      loading: true,
    });

    const token = localStorage.getItem("token");
    const { kitPrice } = this.getKitPrice();

    const body = {
      ...data,
      price: kitPrice.toFixed(2),
    };

    axios
      .patch(`${process.env.REACT_APP_API_URL}/kit/${data._id}`, body, {
        headers: { Authorization: "bearer " + token },
      })
      .then(() => {
        this.setState({
          saving: false,
          success: true,
          loading: false,
        });

        alert("Kit atualizado com sucesso");

        window.location.reload();
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
          loading: false,
        });
        alert("Tivemos um problema ao atualizar o kit");
      });
  };

  onDeletePress = async () => {
    const { data } = this.state;
    if (!data._id) return;

    this.setState({ isConfirmOpen: false });
    this.setState({ loading: true });

    const token = localStorage.getItem("token");
    axios
      .delete(`${process.env.REACT_APP_API_URL}/kit/${data._id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        alert(response.data.message);
        this.setState({ success: true });
        window.location.href = "/app/kits";
      })
      .catch((error) =>
        this.setState({ success: false, error: error.response })
      )
      .finally(() => this.setState({ saving: false, loading: false }));
  };

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.round(max);
    return Math.round(Math.random() * (max - min)) + min;
  }

  newKit = async () => {
    const { data } = this.state;

    this.setState({
      saving: true,
      loading: true,
    });

    const token = localStorage.getItem("token");
    const { kitPrice } = this.getKitPrice();

    const body = {
      ...data,
      price: kitPrice.toFixed(2),
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/kit`, body, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        alert(response.data.message);
        window.location.href = `/app/kit/${response.data.data._id}`;
      })
      .catch((error) => {
        this.setState({
          saving: false,
          success: false,
          error: error.response,
          loading: false,
        });
      });
  };

  getKit = async () => {
    const { _id } = this.state.data;
    const { itemsSelected } = this.state;
    const { discounts } = this.state.data;

    if (!_id) {
      this.setState({ loading: false });
      return;
    }

    const token = localStorage.getItem("token");

    await axios
      .get(`${process.env.REACT_APP_API_URL}/kits/${_id}`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        let { data } = response;
        let discou = [];

        if (data.discounts == "") {
          discou = discounts;
        } else {
          discou = data.discounts;
        }
        data.discounts = discou;

        data.products.map((product) => {
          itemsSelected.push({ product: product.product, qtd: "" });
        });
        this.setState({
          data: { ...this.state.data, ...data },
          loading: false,
          itemsSelected,
        });

        this.addAllProducts();
      });
  };

  getCategories = async () => {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/category/`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.setState({
          categories: data.map((item) => item.title),
        });
      });
  };

  getFeatures = async () => {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/feature/`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.setState({
          features: data.map((item) => item.title),
        });
      });
  };

  getUnits = async () => {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/unit/`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.setState({
          units: data.map((item) => item.title),
        });
      });
  };

  getStyles = async () => {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/style/`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.setState({
          styles: data.map((item) => item.title),
        });
      });
  };

  getAmbients = async () => {
    const token = localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/ambient/`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.setState({
          ambients: data.map((item) => item.title),
        });
      });
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const { data } = this.state;

    this.setState({ data: { ...data, [name]: value } });
  };

  onChangeQtdProduct = async (e) => {
    const { name, value } = e.target;
    const { data } = this.state;
    let { products } = data;

    let product = data.products[name];
    product.qtd = await value;

    products[name] = await product;

    this.setState({ data: { ...data, products: products } });
    await this.addAllProducts();
  };

  deleteProductKit = async (index) => {
    const { data } = this.state;
    let { products } = data;

    delete products[index];

    let products_ = products.filter(function (el) {
      return el != null;
    });

    this.setState({
      data: { ...data, products: products_ },
      itemsSelected: products_,
    });
    await this.addAllProducts();
  };

  onDiscountAdd = (data) => {
    let { discounts } = this.state.data;
    const { percentual, parametro } = data;

    discounts.push({ percentual, parametro });

    this.setState({
      data: {
        ...this.state.data,
        discounts,
      },
      isDiscountFormOpen: false,
    });
  };

  removeDiscount = (index) => {
    let { discounts } = this.state.data;

    discounts.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        discounts,
      },
    });
  };

  onAddColor = (e) => {
    let { colors } = this.state.data;

    colors.push({ color: "#FFF" });
    this.setState({
      data: {
        ...this.state.data,
        colors,
      },
    });
  };

  onRemoveColor = (index) => {
    let { colors } = this.state.data;

    colors.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        colors,
      },
    });
  };

  onChangeColor = (e, index) => {
    let { colors } = this.state.data;

    colors[index].color = e.target.value;

    this.setState({
      data: {
        ...this.state.data,
        colors,
      },
    });
  };

  onAddFeature = (item) => {
    let { features } = this.state.data;

    features.push({ title: item.label });

    this.setState({
      data: {
        ...this.state.data,
        features,
      },
    });
  };

  removeFeature = (index) => {
    let { features } = this.state.data;

    features.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        features,
      },
    });
  };

  onAddUnit = (item) => {
    let { units } = this.state.data;

    units.push({ title: item.label });

    this.setState({
      data: {
        ...this.state.data,
        units,
      },
    });
  };

  removeUnit = (index) => {
    let { units } = this.state.data;

    units.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        units,
      },
    });
  };

  onAddAmbient = (item) => {
    let { ambients } = this.state.data;

    ambients.push({ title: item.label });

    this.setState({
      data: {
        ...this.state.data,
        ambients,
      },
    });
  };

  removeAmbient = (index) => {
    let { ambients } = this.state.data;

    ambients.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        ambients,
      },
    });
  };

  onAddStyle = (item) => {
    let { styles } = this.state.data;

    styles.push({ title: item.label });

    this.setState({
      data: {
        ...this.state.data,
        styles,
      },
    });
  };

  removeStyle = (index) => {
    let { styles } = this.state.data;

    styles.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        styles,
      },
    });
  };

  onDropFiles = (files) => {
    const uploadedFiles = files.map((file, index) => ({
      file,
      id: Math.round(Math.random() * 1e6)
        .toString()
        .padStart(6, "0"),
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(this.processUpload);
  };

  processUpload = async (uploadedFile) => {
    this.setState({
      data: {
        ...this.state.data,
        images: [...this.state.data.images, uploadedFile],
      },
    });

    const data = new FormData();

    data.append("file", uploadedFile.file, uploadedFile.name);

    const token = localStorage.getItem("token");

    axios
      .post(`${process.env.REACT_APP_API_URL}/upload`, data, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;

        this.updateFile(uploadedFile.id, data);
      })
      .catch((error) => console.log("error", error));
  };

  updateFile = (id, data) => {
    this.setState({
      data: {
        ...this.state.data,
        images: this.state.data.images.map((image) => {
          if (image.id == id) {
            return { ...image, ...data, ...{ uploaded: true } };
          }

          return image;
        }),
      },
    });
  };

  onDeleteImage = (index) => {
    let { images } = this.state.data;

    images.splice(index, 1);

    this.setState({
      data: {
        ...this.state.data,
        images,
      },
    });
  };

  getRowsSelectedModal = async (selectedProducts) => {
    this.setState({
      data: {
        ...this.state.data,
        products: selectedProducts,
      },
      openModalProducts: !this.state.openModalProducts,
    });

    await this.addAllProducts();
  };

  openModalProducts = () =>
    this.setState({ openModalProducts: !this.state.openModalProducts });

  getProdutosClickOpen = async () => {
    this.openModalProducts();
    const _this = this;
    const token = localStorage.getItem("token");
    await axios
      .get(`${process.env.REACT_APP_API_URL}/products/admin`, {
        params: {
          limit: 5000,
        },
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data } = response;
        _this.setState({
          list_products: data.data,
        });
        _this.setState({ loading: false });
      });
  };

  addAllProducts = async () => {
    let { data } = this.state;
    let allPrices = [];
    let qtd = "";
    let val = "";
    let res = 0;

    if (allPrices.length == 0) {
      return;
    }
    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    data.products.map((p) => {
      qtd = p.qtd;
      val = p.price;
      res = val * qtd;
      allPrices.push(res);
    });

    let resultado = await allPrices.reduce(reducer);
    this.setState({
      allVal: resultado,
    });
  };

  setItemsSelected = (itemsSelected) => {
    this.setState({
      data: {
        ...this.state.data,
        products: itemsSelected,
      },
    });
  };

  applyDiscountKit = () => {
    let { allVal } = this.state;
    const { kitDiscount = 0 } = this.state.data;

    let res = this.arredondar(allVal - (kitDiscount / 100) * allVal);

    this.setState({
      data: {
        ...this.state.data,
        price: res,
      },
    });
  };
  getDiscounts = (e) => {
    let discou = e.target.value;
    this.setState({
      discount: discou,
    });
  };

  changeDiscount = (e) => {
    const { discounts } = this.state;
    let index = e.target.value;
    if (index === "") {
      this.setState({
        createDiscount: "on",
        data: {
          ...this.state.data,
          discounts: [],
        },
      });
    } else {
      let descontos = discounts[index].discounts;
      let descontoNome = discounts[index].name;
      this.setState({
        createDiscount: "",
        data: {
          ...this.state.data,
          discounts: descontos,
          discountName: descontoNome,
        },
      });
    }
  };

  removeItemFromKit = async () => {
    const newProductList = this.state.data.products.filter(
      (item) => item.product._id !== this.state.removeItem.product._id
    );

    this.setState({
      data: {
        ...this.state.data,
        products: newProductList,
      },
      removeItem: null,
    });
  };

  render() {
    const {
      loading,
      data,
      isDiscountFormOpen,
      isSelectorOpen,
      features,
      units,
      ambients,
      selectorData,
      selectorCallback,
      styles,
      list_products,
      openModalProducts,
      discounts = [],
      createDiscount,
      removeItem,
    } = this.state;

    const { products } = data;
    const { productsPriceSum, discountAmount, kitPrice } = this.getKitPrice();

    const columnsModalProducts = [
      { id: "name", numeric: false, disablePadding: true, label: "Nome" },
      { id: "id_sap", numeric: false, disablePadding: false, label: "ID SAP" },
      {
        id: "estoque",
        numeric: false,
        disablePadding: false,
        label: "Estoque",
      },
    ];

    return (
      <>
        {removeItem && (
          <Modal
            actionButtonFunction={async () => await this.removeItemFromKit()}
            actionButtonText="Excluir"
            actionTuimButtonType="destructive"
            cancelButtonFunction={() => this.setState({ removeItem: null })}
            itemName={removeItem.product.name}
            message="Voce Deseja realmente excluir este item?"
          />
        )}
        <Loading loading={loading} />
        <ContentHeader
          title={data.name || "Novo Kit"}
          subtitle={`Estoque: ${data?.estoque}`}
          buttons={[
            { text: "Salvar", secondary: true, onClick: this.onSave },
            {
              text: "Excluir",
              onClick: () => this.setState({ isConfirmOpen: true }),
              destructive: true,
            },
          ]}
        />
        <Box
          style={{
            display: "flex",
            gap: 24,
          }}
        >
          <ContentContainer label="Nome e descrição" fullWidth>
            <TextField
              value={data.name}
              onChange={this.onChange}
              name="name"
              placeholder="Digite o nome do kit"
              title="Nome do Kit"
            />
            <TextArea
              value={data.description}
              name="description"
              title="Descrição"
              onChange={this.onChange}
              textAreaStyle={{ resize: "none" }}
            />
            <TextArea
              value={data.technicalDescription}
              name="technicalDescription"
              title="Descrição Técnica"
              onChange={this.onChange}
              textAreaStyle={{ resize: "none" }}
            />
          </ContentContainer>
          <ContentContainer label="Imagens">
            <Box
              style={{
                display: "flex",
                flex: 1,
                maxWidth: 300,
                maxHeight: 580,
                flexDirection: "column",
                marginRight: 8,
                overflow: "auto",
              }}
            >
              {data.urlImg && (
                <div style={{ marginBottom: 15 }}>
                  <Text detail>Principal</Text>
                  <img
                    src={data.urlImg}
                    style={{ width: "100%", borderRadius: 12 }}
                    alt="main-uploaded"
                  />
                </div>
              )}
              {data.secondaryImage && (
                <div style={{ marginBottom: 15 }}>
                  <Text detail>Secundária</Text>
                  <img
                    src={data.secondaryImage}
                    style={{ width: "100%", borderRadius: 12 }}
                    alt="secondary-uploaded"
                  />
                </div>
              )}
              <Dropzone onDrop={this.onDropFiles} />
              <ImageList
                data={data.images}
                onDeleteImage={this.onDeleteImage}
                setImageDefault={(urlImg) =>
                  this.setState({ data: { ...data, urlImg } })
                }
                setSecondaryImage={(secondaryImage) =>
                  this.setState({ data: { ...data, secondaryImage } })
                }
              />
            </Box>
          </ContentContainer>
        </Box>
        <Box
          style={{
            display: "flex",
            gap: 24,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 24,
              flex: 1,
            }}
          >
            <ContentContainer label="Preço" fullWidth>
              <Text
                style={{
                  textAlign: "center",
                  color: Colors.darkGreenAlt,
                  fontSize: 24,
                  fontWeight: "700",
                  paddingTop: "20px",
                }}
              >
                {formatter.currency(kitPrice)}
              </Text>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <DottedLine
                  leftData="Produtos"
                  rightData={formatter.currency(productsPriceSum)}
                />
                <DottedLine
                  leftData="Desconto"
                  rightData={formatter.currency(discountAmount)}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <TextField
                  value={data.kitDiscount}
                  onChange={({ target }) =>
                    this.setState({
                      data: { ...data, kitDiscount: target.value },
                    })
                  }
                  min="0"
                  max="100"
                  type="number"
                  name="price"
                  placeholder="Desconto"
                  title="Porcentagem de desconto"
                />
                <Text detail>Aplicada ao preço final do kit.</Text>
              </Box>
            </ContentContainer>
            <ContentContainer label="Catacterísticas">
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <Text strong>Cor</Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {data.colors.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 20,
                        marginBottom: 20,
                      }}
                    >
                      <input
                        type="text"
                        key={`color-${item.color}-${index}`}
                        style={{
                          height: 30,
                          width: 70,
                          fontSize: 14,
                          fontWeight: "600",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: "gray",
                          backgroundColor: item.color,
                          color:
                            lightOrDark(item.color) == "light"
                              ? "#000"
                              : "#FFF",
                        }}
                        value={item.color}
                        onChange={(e) => this.onChangeColor(e, index)}
                        name="color"
                      />
                      <RemoveButton onClick={() => this.onRemoveColor(index)} />
                    </div>
                  ))}
                  <AddButton text="+ Cor" onClick={this.onAddColor} />
                </div>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <Text strong>Características</Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: 12,
                  }}
                >
                  {data.features.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => this.removeFeature(index)} />
                    </div>
                  ))}
                  <AddButton
                    text="+ Característica"
                    onClick={() =>
                      this.setState({
                        isSelectorOpen: true,
                        selectorData: features,
                        selectorCallback: (item) => this.onAddFeature(item),
                      })
                    }
                  />
                </div>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <Text strong>Disponibilidade</Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: 12,
                  }}
                >
                  {data.units.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => this.removeUnit(index)} />
                    </div>
                  ))}
                  <AddButton
                    text="+ Filial"
                    onClick={() =>
                      this.setState({
                        isSelectorOpen: true,
                        selectorData: units,
                        selectorCallback: (item) => this.onAddUnit(item),
                      })
                    }
                  />
                </div>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <Text strong>Estilos</Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  {data.styles.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 15,
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => this.removeStyle(index)} />
                    </div>
                  ))}
                  <AddButton
                    text="+ Estilo"
                    onClick={() =>
                      this.setState({
                        isSelectorOpen: true,
                        selectorData: styles,
                        selectorCallback: (item) => this.onAddStyle(item),
                      })
                    }
                  />
                </div>
              </Box>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <Text strong>Ambientes</Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  {data.ambients.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginRight: 15,
                      }}
                    >
                      <div
                        style={{
                          borderColor: "gray",
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderRadius: 5,
                          padding: 7,
                          fontSize: 12,
                        }}
                      >
                        {item.title}
                      </div>
                      <RemoveButton onClick={() => this.removeAmbient(index)} />
                    </div>
                  ))}
                  <AddButton
                    text="+ Ambiente"
                    onClick={() =>
                      this.setState({
                        isSelectorOpen: true,
                        selectorData: ambients,
                        selectorCallback: (item) => this.onAddAmbient(item),
                      })
                    }
                  />
                </div>
              </Box>
              {isSelectorOpen && (
                <Selector
                  open={isSelectorOpen}
                  onSelect={(item) => {
                    this.setState({ isSelectorOpen: false });
                    selectorCallback(item);
                  }}
                  title="Selecione"
                  data={selectorData}
                  onClose={() => this.setState({ isSelectorOpen: false })}
                />
              )}
            </ContentContainer>
          </Box>
          <ContentContainer label="Produtos" style={{ width: 700 }}>
            {products.length > 0 ? (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 28,
                  overflow: "hidden",
                }}
              >
                <Table aria-label="simple table">
                  <TableBody>
                    {products.map((product, index) => {
                      return (
                        <TableRow key={product.product._id} sx={{ margin: 0 }}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              display: "flex",
                              flex: 1,
                              position: "relative",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                gap: 30,
                                flex: 1,
                              }}
                            >
                              <div
                                style={{
                                  backgroundImage: `url(${product.product.urlImg})`,
                                  backgroundSize: "cover",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center",
                                  width: 120,
                                  height: 80,
                                  borderRadius: 8,
                                }}
                              />
                              <button
                                style={{
                                  background: "#CB2027",
                                  position: "absolute",
                                  top: 5,
                                  left: 5,
                                  borderRadius: "50%",
                                  padding: "0 2px",
                                  border: "none",
                                  cursor: "pointer",
                                  boxShadow:
                                    "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
                                }}
                                onClick={() =>
                                  this.setState({ removeItem: product })
                                }
                              >
                                <IoClose
                                  color="#fff"
                                  size={18}
                                  style={{ marginTop: 2 }}
                                />
                              </button>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  gap: 12,
                                }}
                              >
                                <Box>
                                  <Link
                                    href={`/app/product/${product.product._id}`}
                                    target="_blank"
                                  >
                                    <Text
                                      strong
                                      style={{
                                        fontSize: 18,
                                        maxWidth: 420,
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                      color="#444"
                                    >
                                      {product.product.name}
                                    </Text>
                                  </Link>
                                  <Text detail>
                                    Estoque: {product.product.estoque}
                                  </Text>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    gap: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      backgroundColor: Colors.paleDarkGreen,
                                      padding: `2px 18px`,
                                      borderRadius: 20,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text detail strong color="#444">
                                      {formatter.currency(
                                        Math.round(
                                          product.product.price * product.qtd
                                        )
                                      )}
                                    </Text>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      backgroundColor: Colors.paleYellow,
                                      padding: `2px 16px`,
                                      borderRadius: 20,
                                      alignItems: "center",
                                      gap: 20,
                                    }}
                                  >
                                    <Box
                                      onClick={() =>
                                        product?.qtd > 1
                                          ? this.onChangeQtdProduct({
                                              target: {
                                                name: index,
                                                value: product?.qtd - 1,
                                              },
                                            })
                                          : {}
                                      }
                                      style={{
                                        display: "flex",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Text strong>{`-`}</Text>
                                    </Box>
                                    <Text strong>{product?.qtd}</Text>

                                    <Box
                                      onClick={() =>
                                        this.onChangeQtdProduct({
                                          target: {
                                            name: index,
                                            value: product?.qtd + 1,
                                          },
                                        })
                                      }
                                      style={{
                                        display: "flex",
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Text strong>{`+`}</Text>
                                    </Box>
                                  </Box>
                                  {/* <Box
                                    style={{
                                      display: "flex",
                                      backgroundColor: "#f0f0f0",
                                      padding: `2px 18px`,
                                      borderRadius: 20,
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text detail strong color="#444">
                                      {formatter.currency(
                                        Math.round(
                                          product.product.price * product.qtd
                                        )
                                      )}
                                    </Text>
                                  </Box> */}
                                </Box>
                              </div>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Text style={{ fontSize: 14, color: "#999" }}>
                  Nenhum produto selecionado.
                </Text>
              </Box>
            )}
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <TuimButton
                text={
                  products.length > 0
                    ? "Editar produtos"
                    : "Selecionar produtos"
                }
                secondary
                onClick={() => this.getProdutosClickOpen()}
              />
            </Box>

            <TableSelectModal
              itemsSelected={products}
              items={list_products}
              order="asc"
              open={openModalProducts}
              refName="name"
              orderBy="name"
              page={0}
              rowsPerPage={10}
              selected={products}
              title="Produtos"
              nameButton="Salvar e voltar"
              columns={columnsModalProducts}
              getRowsSelectedModal={this.getRowsSelectedModal.bind(this)}
              setItemSelected={this.setItemsSelected.bind(this)}
            />
          </ContentContainer>
        </Box>
        <ContentContainer
          label="Desconto por período"
          style={{ minWidth: 400 }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              flexWrap: "wrap",
              gap: 20,
            }}
          >
            {data.discounts.map((item, index) => {
              const periodPrice =
                item.parametro > 5
                  ? this.arredondar(
                      parseFloat(kitPrice) -
                        (Math.abs(item.percentual) * kitPrice) / 100
                    )
                  : this.arredondar(
                      parseFloat(kitPrice) +
                        (Math.abs(item.percentual) * kitPrice) / 100
                    );

              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 20,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    <TextField
                      key={index}
                      value={item.percentual}
                      onChange={this.onChangeDiscount}
                      name="price"
                      placeholder="%"
                      title={`${item.parametro} meses`}
                      inputStyle={{ width: 100 }}
                    />
                    <Text detail>{formatter.currency(periodPrice)}</Text>
                    <RemoveButton
                      onClick={() => this.removeDiscount(index)}
                      style={{
                        right: 0,
                        top: 0,
                        position: "absolute",
                      }}
                    />
                  </div>
                </div>
              );
            })}
            {createDiscount == "on" ? (
              <div
                onClick={() => this.setState({ isDiscountFormOpen: true })}
                style={{
                  padding: 7,
                  borderWidth: 0.5,
                  borderStyle: "dashed",
                  cursor: "pointer",
                  borderRadius: 5,
                  marginBottom: 15,
                  fontSize: 12,
                }}
              >
                + Desconto
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <FormControl
                  style={{
                    margin: 1,
                    minWidth: 120,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {typeof data.discountName == "undefined"
                      ? "Descontos"
                      : data.discountName}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.discountName}
                    onChange={this.changeDiscount}
                  >
                    <MenuItem value="">Criar um valor </MenuItem>
                    {discounts.map((d, index) => (
                      <MenuItem value={index}>{d.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {createDiscount == "on" ? (
              <div style={{ width: "100%" }}>
                <FormControl
                  style={{
                    margin: 1,
                    minWidth: 120,
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {typeof data.discountName == "undefined"
                      ? "Descontos"
                      : data.discountName}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.discountName}
                    onChange={this.changeDiscount}
                  >
                    <MenuItem value="">Criar um valor </MenuItem>
                    {discounts.map((d, index) => (
                      <MenuItem value={index}>{d.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : (
              ""
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                gap: 20,
                flexDirection: "column",
              }}
            >
              {isDiscountFormOpen && (
                <Discount
                  onClose={() => this.setState({ isDiscountFormOpen: false })}
                  onDiscountAdd={this.onDiscountAdd}
                />
              )}
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.toPartner}
                      onChange={this.onCheckChange("toPartner")}
                      value="active"
                      color="primary"
                    />
                  }
                  label="Para Parceria"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.active}
                      onChange={this.onCheckChange("active")}
                      value="active"
                      color="primary"
                    />
                  }
                  label="Ativo"
                />
              </div>
            </div>
          </div>
          <Confirm
            open={this.state.isConfirmOpen}
            title="Exclusão de Kit"
            text="Deseja realmente excluir este kit?"
            onDeletePress={this.onDeletePress}
            onClose={() => this.setState({ isConfirmOpen: false })}
          />
        </ContentContainer>
      </>
    );
  }
}

const DottedLine = ({ leftData, rightData }) => {
  if (!leftData || !rightData) return <></>;

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        gap: 4,
      }}
    >
      <Text detail>{leftData}</Text>
      <Box
        style={{
          borderBottom: `1px dotted #ccc`,
          width: "100%",
          height: "84%",
        }}
      />
      <Text detail>{rightData}</Text>
    </Box>
  );
};

const styles = (theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  wrapper: {
    marginTop: theme.spacing.unit * 3,
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  dropZone: {
    display: "flex",
    flex: 1,
    padding: 15,
    justifyContent: "center",
    alignItems: "center",
    margin: 15,
    borderRadius: 10,
    flexDirection: "column",
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: "gray",
    maxHeight: 150,
    cursor: "pointer",
  },
});

export default withStyles(styles)(EditKit);
