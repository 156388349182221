import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableHead, TableRow, Paper, Table, Typography, Grid, Toolbar, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import Moment from 'react-moment';
import Loading from '../utils/Loading';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class ListBlockedClient extends Component {

   constructor(props) {
      super(props)

      const { classes } = props;

      this.state = {
         classes,
         data: [],
         searchTerm: '',
         loading: false
      }

      this.onSearchChange = this.onSearchChange.bind(this);
   }

   componentDidMount = async () => {

      const { data } = this.state
      const _this = this

      const token = await localStorage.getItem('token');
      this.setState({ loading: true })
      axios.get(`${process.env.REACT_APP_API_URL}/blockClient`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response
            _this.setState({
               data,
               loading: false
            })
         })
         .catch(error => {
            this.setState({ loading: false })
            console.log(error)
         })
   }

   onSearchChange = (e) => {

      this.setState({
         searchTerm: e.target.value
      })
   }

   render() {

      const { classes, data, searchTerm, loading } = this.state

      return (
         <div>
            <Loading loading={loading} />
            <Grid container>
               <Grid item xs >
                  <Typography
                     component="h1"
                     variant="h6"
                     color="inherit"
                     noWrap
                     className={classes.title}

                  >{`Clientes Bloqueados (${this.state.data.length})`}
                  </Typography>
               </Grid>
            </Grid>

            <Paper className={classes.root}>
               <Toolbar className={classes.tableOptions}>
                  <div className={classes.search}>
                     <div className={classes.searchIcon}>
                        <SearchIcon />
                     </div>
                     <InputBase
                        placeholder="Digite o nome ou CPF do cliente"
                        onChange={this.onSearchChange}
                        value={searchTerm}
                        classes={{
                           root: classes.inputRoot,
                           input: classes.inputInput,
                        }}
                     />
                  </div>
                  <ReactHTMLTableToExcel
                     className={classes.btn}
                     table="blockedClients"
                     filename="ClientesBloqueados"
                     sheet="Sheet"
                     buttonText="Exportar para Excel" 
                  />
               </Toolbar>
               <Table className={classes.table} id="blockedClients">
                  <TableHead>
                     <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>CPF</TableCell>
                        <TableCell align="center">Data de Cadastro</TableCell>

                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {data.filter(item =>
                        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        item.cpf.toLowerCase().includes(searchTerm.toLowerCase())
                     ).map(row => (
                        <TableRow
                           key={row._id}
                           hover
                           onClick={() => this.props.history.push(`/app/client/${row.id_user}`)}
                           className={classes.row}
                        >
                           <TableCell component="th" scope="row">
                              {row.name}
                           </TableCell>
                           <TableCell component="th" scope="row">
                              {row.cpf}
                           </TableCell>
                           <TableCell align="center">
                              <Moment format="DD/MM/YYYY">
                                 {row.createdAt}
                              </Moment>

                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </Paper>
         </div>

      );
   }
}

ListBlockedClient.propTypes = {
   classes: PropTypes.object.isRequired,
};

const styles = theme => ({
   root: {
      width: '100%',
      marginTop: theme.spacing.unit * 2,
      overflowX: 'auto',
   },
   table: {
      minWidth: 700,
   },
   row: {
      cursor: 'pointer'
   },
   fab: {
      margin: 0,
   },
   tableOptions: {
      marginTop: 15,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
   },
   search: {
      position: 'relative',
      backgroundColor: fade('#ececec', 0.55),
      '&:hover': {
         backgroundColor: fade('#ececec', 0.80),
      },
      width: '100%',
      padding: 5,
      borderRadius: 12,
      [theme.breakpoints.up('sm')]: {
         width: 'auto',
      },
   },
   searchIcon: {
      position: 'absolute',
      margin: 'auto',
      top: 0,
      bottom: 0,
      left: 12,
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   btn: {
      cursor: "pointer",
      padding: "6px 16px",
      fontSize: "0.875rem",
      minWidth: "64px",
      boxSizing: "border-box",
      lineHeight: 1.75,
      fontWeight: 500,
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      borderRadius: "4px",
      border: "none",
      textTransform: "uppercase",
      boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
      color: "rgba(0, 0, 0, 0.87)",
      backgroundColor: "#00c830",
   },
   inputRoot: {
      color: 'inherit',
      width: 450,
   },
   inputInput: {
      paddingTop: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 5,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
         width: 450,
      },
   }
});

export default withStyles(styles)(ListBlockedClient);
