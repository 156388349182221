import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Table,
  Typography,
  Grid,
  Fab,
  Toolbar,
  InputBase,
  Button,
} from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import Loading from "../utils/Loading";
import TablePagination from "@material-ui/core/TablePagination";
import ModalNewLocation from "./ModalNewLocation";
import ModalEditLocation from "./ModalEditLocation";
import Snackbar from "@material-ui/core/Snackbar";
import { fade } from "@material-ui/core/styles/colorManipulator";

class BlackList extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      data: [],
      searchTerm: "",
      loading: true,
      filter: {},
      page: 0,
      rowsPerPage: 12,
      count_data: 0,
      openAddModal: false,
      snackbar_alert: false,
      msg_alert: "",
      location: {
        cep: "",
        address: {
          uf: "",
        },
        description: "",
        active: true,
      },
      openEditModal: false,
    };

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {
    const _this = this;

    const token = await localStorage.getItem("token");

    axios
      .get(`${process.env.REACT_APP_API_URL}/cep/blacklist`, {
        headers: { Authorization: "bearer " + token },
      })
      .then((response) => {
        const { data, count } = response.data;
        _this.setState({
          data,
          count_data: count,
          loading: false,
        });
      });
  };

  onChangeCEPMask = (e) => {
    const { filter } = this.state;
    let { name, value } = e.target;
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{5})(\d)/, "$1-$2");
    if (value.length < 10) {
      this.setState({
        filter: {
          ...filter,
          [name]: value,
        },
      });
    }
  };

  onChange = (e) => {
    const { filter } = this.state;
    let { name, value } = e.target;
    console.log("name", name, value);
    if (name == "cep") {
      value = this.cepMask(value);
    }
    this.setState({
      filter: {
        ...filter,
        [name]: value,
      },
    });
  };
  onChangeCEP = (e) => {
    const { filter } = this.state;
    let { name, value } = e.target;
    console.log("name", name, value);
    if (name == "cep") {
      value = this.cepMask(value);
    }
    this.setState({
      filter: {
        ...filter,
        [name]: value,
      },
    });
  };

  cepMask(value) {
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{5})(\d)/, "$1-$2");

    return value;
  }

  onSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  filterCEP = async () => {
    this.setState({ loading: true });
    const _this = this;
    const { filter = {}, page = 0, limit = 25 } = this.state;
    let { cep = "" } = filter;
    const token = await localStorage.getItem("token");
    cep = this.cepMask(cep);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/cep/blacklist?cep=${cep}&page=${page}&limit=${limit}`,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        const { data, count } = response.data;
        _this.setState({
          data,
          count_data: count,
          loading: false,
        });
      });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
    this.filterCEP();
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  };

  newLocationBlacklist = () => {
    console.log("CLICK openAddModal");
    this.setState({ openAddModal: true });
  };

  onRegisterSuccess = () => {
    this.filterCEP();
    this.setState({
      snackbar_alert: true,
      msg_alert: "Cadastro realizado com sucesso.",
    });
  };
  onEditSuccess = () => {
    this.filterCEP();
    this.setState({
      snackbar_alert: true,
      msg_alert: "Área de Risco atualizada com sucesso.",
      openEditModal: false,
    });
  };

  handleOpenEditModal = (id) => {
    const { data } = this.state;
    const location = data.find((item) => item._id === id);

    if (location) {
      this.setState({ openEditModal: true, location: location });
    }
  };

  render() {
    const {
      classes,
      data,
      loading,
      page,
      rowsPerPage,
      count_data,
      openAddModal,
      snackbar_alert,
      msg_alert,
      openEditModal,
      location,
      filter,
    } = this.state;
    const { cep: cepFilter = "" } = filter;
    console.log("openEditModal", openEditModal);
    return (
      <div>
        <Loading loading={loading} />

        <Grid container>
          <Grid item xs>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              Lista de regiões de risco
            </Typography>
          </Grid>
          <Grid item>
            <Fab
              color="primary"
              aria-label="Add"
              className={classes.fab}
              size="small"
              onClick={() => this.setState({ openAddModal: true })}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>

        <ModalNewLocation
          open={openAddModal}
          handleClose={() => this.setState({ openAddModal: false })}
          onSuccess={this.onRegisterSuccess}
        />
        {openEditModal && (
          <ModalEditLocation
            open={openEditModal}
            handleClose={() => this.setState({ openEditModal: false })}
            onSuccess={this.onEditSuccess}
            data={location}
          />
        )}

        <Paper className={classes.root}>
          <Toolbar>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Digite o CEP"
                onChange={this.onChangeCEPMask}
                value={cepFilter}
                name="cep"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 50 }}
              onClick={() => this.filterCEP()}
            >
              Filtrar
            </Button>
          </Toolbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count_data}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Página anterior",
            }}
            nextIconButtonProps={{
              "aria-label": "Próxima página",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>CEP</TableCell>
                <TableCell align="center">Estado</TableCell>
                <TableCell align="center">Descrição</TableCell>
                <TableCell align="center">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  hover
                  onClick={() => this.handleOpenEditModal(row._id)}
                  className={classes.row}
                >
                  <TableCell component="th" scope="row">
                    {row.cep}
                  </TableCell>
                  <TableCell align="center">{row.address.uf}</TableCell>
                  <TableCell align="center">{row.description}</TableCell>
                  <TableCell align="center">
                    {row.active ? <CheckCircle /> : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {data.length <= 0 && (
            <>
              <h1
                style={{ textAlign: "center", fontWeight: 400, fontSize: 23 }}
              >
                Nenhum registro encontrado.
              </h1>
            </>
          )}
        </Paper>

        <Snackbar
          open={snackbar_alert}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackbar_alert: false })}
        >
          <div
            style={{
              // padding: 20, textAlign: 'center', width: '100%', color: '#fff',
              fontWeight: 500,
              backgroundColor: "#4caf50",
              display: "flex",
              padding: "20px 26px",
              fontSize: "18px",
              borderRadius: 4,
              marginTop: -150,
              boxShadow:
                "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
            }}
          >
            {msg_alert}
          </div>
        </Snackbar>
      </div>
    );
  }
}

BlackList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade("#ececec", 0.35),
    "&:hover": {
      backgroundColor: fade("#ececec", 0.8),
    },
    // marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing.unit * 3,
      width: "auto",
    },
  },
  searchIcon: {
    // width: theme.spacing.unit * 1,
    marginLeft: theme.spacing.unit * 1,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
});

export default withStyles(styles)(BlackList);
