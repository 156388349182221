import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';

const Subtitle = (props) => (
    <div className={props.classes.title}>
        {props.text}
    </div>
)

const styles = theme => ({
    title: {
        fontSize: 16,
        fontWeight: '500',
        marginBottom: 10,
        marginTop: 30     
    }
});

export default (withStyles(styles)(Subtitle))