import React from "react";
import { Box, Typography as Text } from "@material-ui/core";
import { Colors } from "../../../styles/Colors";

export function ReportCardHeader({ value = 0, caption = '' }) {
   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
         <Text style={{ fontSize: 34, fontWeight: 700, color: Colors.textRegular }}>
            {value}
         </Text>
         <Text style={{ fontSize: 14, color: Colors.textRegular }}>
            {caption}
         </Text>
      </Box>
   )
}