import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {
   Paper, Typography,
   IconButton, Menu, MenuItem, TableBody, TableCell, TableHead, TableRow, Table
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import Loading from '../utils/Loading';
import Moment from 'react-moment';
import ReactMarkdown from 'react-markdown'
import Select from '@material-ui/core/Select';

const CommentRight = (props) => (


   <div style={{ backgroundColor: 'rgb(225, 255, 199)', fontSize: 14, marginLeft: 'auto', marginTop: 20, padding: 10, position: 'relative', float: 'right', marginRight: 40, maxWidth: 500, width: '100%' }}>

      <div style={{ fontSize: 12, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
         <label style={{ fontWeight: 500 }} >{props.name}</label>
         <label >{<Moment format="DD/MM/YYYY HH:mm" date={new Date(props.createdAt)} ></Moment>}</label>
      </div>

      <div style={{ marginTop: 10 }}>
         <ReactMarkdown source={`${props.text}`} />
      </div>

      <div style={{ position: 'absolute', borderColor: 'transparent transparent transparent #e1ffc7', borderWidth: '0px 0 20px 20px', top: 0, right: -10, borderStyle: 'solid' }}></div>

   </div>

)
const CommentLeft = (props) => (

   <div style={{ backgroundColor: '#fff', fontSize: 14, marginLeft: 40, marginTop: 20, padding: 10, position: 'relative', float: 'left', maxWidth: 500, width: '100%' }}>

      <div style={{ fontSize: 12, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
         <label style={{ fontWeight: 500 }} >{props.name}</label>
         <label >{<Moment format="DD/MM/YYYY HH:mm" date={new Date(props.createdAt)} ></Moment>}</label>
      </div>

      <div style={{ marginTop: 10 }}>
         <ReactMarkdown source={`${props.text}`} />
      </div>

      <div style={{ position: 'absolute', borderColor: 'transparent #fff transparent transparent', borderWidth: '0px 20px 20px 0px', top: 0, left: -10, borderStyle: 'solid' }}></div>

   </div>
)
class ViewTask extends Component {

   constructor(props) {
      super(props)

      const { classes } = props;
      const { pathname } = props.location
      const arrayPath = pathname.split('/')
      const _id = arrayPath[arrayPath.length - 1];

      this.state = {
         classes,
         data: {
            _id,
            title: '',
            description: '',
            active: false,
            email_credentials: false,
            admin: true,
            user: '',
            company: '',
            status: 'Pendente',
            type: '',
            executer: '',
            responsible: '',


         },
         saving: false,
         success: false,
         error: null,
         anchorEl: null,
         open: false,
         showAlert: false,
         showErrorNewUser: false,
         showDeleteUserAlert: false,
         loading: true,
         showAlertNewUser: false,
         companies: [],
         taskTypes: [],
         executers: [],
         users: [],
         hours: [],
         interactionVisible: false,
         userData: {
            userId: '5ebbd7d54ed3246583fb9103'
         },
         interaction: '',
         interactions: [],
         scrollBottom: false,
         started: false,
         started_hour: undefined,
         status: [],
         historic: []

      }

      this.onChange = this.onChange.bind(this);
      this.onCheckChange = this.onCheckChange.bind(this);
      this.handleClick = this.handleClick.bind(this);
      this.handleClose = this.handleClose.bind(this);
   }

   handleClick = event => {
      this.setState({ anchorEl: event.currentTarget, open: true });
   };

   handleClose = () => {
      this.setState({ anchorEl: null, open: false, showAlert: false });
   };

   startTask = async () => {
      const { userData, data } = this.state

      const token = await localStorage.getItem('token');

      let data_post = {
         responsible: userData.userId,
         task: data._id
      }
      axios.post(`${process.env.REACT_APP_API_URL}/task/start`, data_post, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            this.setState({ started: true })
            alert("Tarefa Inciada com Sucesso");
         })

   }
   stopTask = async () => {
      const { userData, data, started_hour } = this.state

      const token = await localStorage.getItem('token');
      let _this = this;

      let data_post = {
         id: started_hour
      }

      axios.post(`${process.env.REACT_APP_API_URL}/task/stop`, data_post, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response
            this.setState({ started: false })
            alert("Finalizando a hora iniciada.");
            console.log("task Hour created", response.data)
            // _this.setState({
            //     loading: false
            // })

         })

   }
   sendInteraction = async () => {

      let { userData, interaction } = this.state
      let _this = this;
      const token = await localStorage.getItem('token');

      if (interaction.length == 0) {
         alert("Favor preencher o campo Comentario.")
         return;
      }
      let data = {
         user: userData.userId,
         task: this.state.data._id,
         interaction: interaction
      }

      axios.post(`https://w3lib-manager-api.herokuapp.com/interaction`, data, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response

            _this.setState({ interaction: '', interactions: response.data })
            console.log("Interaction created", response.data)

         })

   }
   componentDidMount = async () => {
      const token = await localStorage.getItem('token');
      const _this = this

      const { _id } = this.state.data

      if (!_id) {
         this.setState({ loading: false })
         return;
      }

      await axios.get(`https://w3lib-manager-api.herokuapp.com/task/${_id}`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response
            _this.setState({
               data, loading: false
            })
         })

      axios.get(`https://w3lib-manager-api.herokuapp.com/interaction/${_id}`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response
            _this.setState({
               interactions: data
            })

         })
      axios.get(`https://w3lib-manager-api.herokuapp.com/taskHours/${_id}`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response
            _this.setState({
               hours: data
            })

         })
      axios.post(`https://w3lib-manager-api.herokuapp.com/task/inprogress/`, { id: _id }, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response
            if (data.started) {
               this.setState({ started: data.started, started_hour: data._id })
            } else {
               this.setState({ started: data.started })
            }

         })

      axios.get(`https://w3lib-manager-api.herokuapp.com/taskStatus`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response

            this.setState({ status: data })


         })
      axios.get(`https://w3lib-manager-api.herokuapp.com/taskHistory/${_id}`, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            const { data } = response

            this.setState({ historic: data })
         })
   }

   interactionChangeVisible = () => {
      this.setState({ interactionVisible: !this.state.interactionVisible })
   }

   onChange = (e) => {
      const { data } = this.state
      this.setState({
         data: {
            ...data,
            [e.target.name]: e.target.value
         },
         success: false
      })


   }
   onChangeStatus = async (e) => {
      this.setState({ loading: true })
      const token = await localStorage.getItem('token');
      const _this = this

      let { status, data } = this.state
      const { _id } = this.state.data

      if (!_id) {
         this.setState({ loading: false })
         alert('Erro ao processar está informação')
         return;
      }


      let statusUpdated = await status.filter(item => item._id == e.target.value)[0];

      if (typeof statusUpdated == 'undefined') {
         alert('Erro ao mudar o status');
         this.setState({ loading: false })
         return;
      }


      axios.post(`https://w3lib-manager-api.herokuapp.com/task/changeStatus/${_id}`, { status: e.target.value }, {
         headers: { 'Authorization': "bearer " + token }
      })
         .then(response => {
            this.setState({
               data: {
                  ...data,
                  status: statusUpdated
               },
               loading: false

            })

         })

   }

   onCheckChange = name => event => {

      const { data } = this.state

      this.setState({
         data: {
            ...data,
            [name]: event.target.checked
         },
         success: false
      });
   };


   scrollableInteraction = () => {
      var objDiv = document.getElementById("comments").scrollIntoView(false);
      this.setState({ scrollBottom: true })
   }
   render() {

      const { classes, saving, hours, started, status, started_hour, scrollBottom, userData, success, interaction, interactions, loading, anchorEl, open, interactionVisible, historic } = this.state
      const { data } = this.state

      const buttonClassname = classNames({
         [classes.buttonSuccess]: success,
      });

      if (interactions.length > 0 && scrollBottom == false) {
         this.scrollableInteraction();
      }

      return (

         <Paper className={classes.paper}>
            <Loading loading={loading} />

            <Typography component="h1" variant="h5">
               {data.title}

               {
                  userData._id == data.user._id || userData.type == 'Administrador' ?
                     <>
                        <IconButton
                           aria-label="More"
                           aria-owns={true}
                           aria-haspopup="true"
                           onClick={this.handleClick}
                        >
                           <MoreVertIcon />
                        </IconButton>

                        <Menu
                           id="long-menu"
                           anchorEl={anchorEl}
                           open={open}
                           onClose={this.handleClose}
                           PaperProps={{
                              style: {
                                 maxHeight: 200,
                                 width: 200,
                              },
                           }}
                        >

                           <MenuItem key={1} onClick={this.onDeleteUserPress}>
                              Excluir Tarefa
                           </MenuItem>
                           <MenuItem key={1} onClick={() => this.props.history.push(`/app/task/${data._id}`)}>
                              Editar Tarefa
                           </MenuItem>
                        </Menu>

                     </>
                     : ''}

               <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }} >
                  <p className={classes.titleDesc}>Detalhes</p>
                  <p className={classes.itemDesc}><b className={classes.itemDescStrong}>Tarefa: </b>{data.title}</p>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' }}>

                     <p className={classes.itemDesc}><b className={classes.itemDescStrong}>Solicitante: </b>{data.user.name}</p>
                     <p className={classes.itemDesc}><b className={classes.itemDescStrong}>Executor: </b>{data.executer.name}</p>
                     <p className={classes.itemDesc}><b className={classes.itemDescStrong}>Empresa: </b>{data.company.name}</p>
                     <p className={classes.itemDesc} style={{ backgroundColor: data.status.color, color: '#fff', borderRadius: 5, padding: 5 }}><b className={classes.itemDescStrong}>Status: </b>{data.status.status}</p>
                     <p className={classes.itemDesc}><b className={classes.itemDescStrong}>Criado Em: </b>{<Moment format="DD/MM/YYYY HH:mm" date={new Date(data.createdAt)} ></Moment>}</p>
                     <p className={classes.itemDesc}><b className={classes.itemDescStrong}>Prazo: </b>{<Moment format="DD/MM/YYYY" date={new Date(data.deadline)} ></Moment>}</p>
                  </div>
                  <p className={classes.itemDesc}><b className={classes.itemDescStrong}>Descrição da Tarefa: </b><br />
                     <ReactMarkdown source={`${data.description}`} />
                  </p>

                  <div style={{ marginTop: 20, display: 'flex', flexDirection: 'column' }}>
                     <label style={{ fontSize: 14, fontWeight: 600 }}>Status da Tarefa</label>
                     <Select onChange={this.onChangeStatus} name="status" id="status" value={data.status} style={{ maxWidth: 200 }} >

                        {
                           status.map((item, index) => {
                              return (
                                 <MenuItem value={item._id}>{item.status}</MenuItem>

                              )
                           })
                        }
                     </Select>
                  </div>

                  <div>
                     <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <p style={{ cursor: 'pointer' }}>Comentarios</p>


                     </div>


                     <div className={classes.interactionsDiv} style={{ display: 'flex', flexDirection: 'column' }} >
                        <div className={classes.interactionsDiv} style={{ height: 250, overflowY: interactions.length > 0 ? 'auto' : 'auto', flexDirection: 'column', scrollBehavior: 'smooth', width: '100%', display: 'flex' }} id="comments">
                           {interactions.map((item, intex) => {
                              return (
                                 userData.userId == item.user._id ?
                                    <CommentRight name={item.user.name} createdAt={item.createdAt} text={item.interaction} />
                                    :
                                    <CommentLeft name={item.user.name} createdAt={item.createdAt} text={item.interaction} />
                              )
                           })}
                        </div>
                        <div style={{ marginTop: 20, width: '100%', textAlign: "right", padding: 10 }}>
                           <textarea onChange={(e) => this.setState({ interaction: e.target.value })} rows="5" style={{ width: '100%', borderRadius: 5, padding: 10 }} value={interaction}></textarea>
                           <button onClick={() => this.sendInteraction()} style={{ borderRadius: 5, backgroundColor: '#1565c0', color: '#fff', padding: '5px 10px', marginRight: 10, marginBottom: 20, marginTop: 10 }}>
                              Enviar
                           </button>

                        </div>
                     </div>
                  </div>

                  <Table className={classes.table}>
                     <TableHead>
                        <TableRow>
                           <TableCell>Inicializado</TableCell>
                           <TableCell align="center">Finalizado</TableCell>
                           <TableCell align="center">Minutos</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {hours.map(row => (
                           <TableRow
                              key={row._id}
                              className={classes.row}

                           >
                              <TableCell component="th" scope="row">
                                 {
                                    <Moment format="DD/MM/YYYY HH:mm" date={new Date(row.createdAt)} />
                                 }

                              </TableCell>
                              <TableCell align="center">{
                                 <Moment format="DD/MM/YYYY HH:mm" date={new Date(row.finishedAt)} />
                              }</TableCell>
                              <TableCell align="center">{typeof row.minutes != 'undefined' ? row.minutes : ''}</TableCell>

                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>

               </div>

            </Typography>


         </Paper >
      )
   }
}

const styles = theme => ({
   main: {
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
         width: 400,
         marginLeft: 'auto',
         marginRight: 'auto',
      },
   },
   paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
   },
   avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing.unit,
   },
   button: {
      marginLeft: theme.spacing.unit
   },
   leftIcon: {
      marginRight: theme.spacing.unit,
   },
   success: {
      backgroundColor: green[600],
   },
   buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
   },
   wrapper: {
      marginTop: theme.spacing.unit * 3,
      position: 'relative',
   },
   buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
         backgroundColor: green[700],
      },
   },
   titleName: {
      flex: 1
   },
   titleBar: {
      flexDirection: 'row'
   }, titleDesc: {
      fontSize: 14, textTransform: 'uppercase', fontWeight: 700
   },
   itemDesc: {
      fontSize: 14, fontWeight: 400
   },
   itemDescStrong: {
      fontSize: 14, fontWeight: 500
   },
   buttonCustomStart: {
      fontSize: 14, textAlign: 'center', borderRadius: 10, padding: '5px 10px', backgroundColor: green[500], maxWidth: 50, cursor: 'pointer', color: '#fff',
      '&:hover': {
         backgroundColor: green[700],
      },
   },
   buttonCustomFinish: {
      fontSize: 14, textAlign: 'center', borderRadius: 10, padding: '5px 10px', backgroundColor: blue[800], maxWidth: 50, cursor: 'pointer', color: '#fff',
      '&:hover': {
         backgroundColor: blue[900],
      },
   },
   interactionsDiv: {
      fontSize: 14, backgroundColor: '#e8eeff', borderRadius: 10
   }
});

const mapStateToProps = state => ({
   userData: state.userReducer.userData.data
})

export default connect(
   mapStateToProps,
   null
)(withStyles(styles)(ViewTask))
