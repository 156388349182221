import { Box, Typography as Text } from "@material-ui/core";
import React from "react";
import { Colors } from "../../../styles/Colors";

export function ReportContainerTab({ tabs = [], selectedTab = 'root', onClick = () => { } }) {
   return (
      <Box style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
         {tabs.map((tab, index) =>
            <Box
               key={`${tab}_${index}`}
               onClick={() => onClick(tab)}
               sx={{
                  ...styles.tab,
                  ...(selectedTab === tab && { backgroundColor: Colors.lightGreen, color: '#fff', fontWeight: 500 }),
                  "&:hover": {
                     backgroundColor: selectedTab === tab ? Colors.lightGreen : Colors.paleDarkGreen
                  }
               }}>
               <Text style={{ fontSize: 14, fontWeight: 'inherit' }}>{tab}</Text>
            </Box>

         )}
      </Box>
   )
}

const styles = {
   tab: {
      padding: `6px 14px`,
      borderRadius: 6,
      cursor: 'pointer'
   }
}