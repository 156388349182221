import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const ComboBox = (props) => {
  const [open, setOpen] = React.useState(false);
  const [clickedOption, setClickedOption] = React.useState(false);

  const handleBlur = (e) => {
    const input = e.target.value;
    if (
      !clickedOption &&
      props.categories &&
      !props.categories.includes(input)
    ) {
      alert(
        "O Tipo de Móvel selecionada não existe. Por favor, selecione um Tipo de Móvel existente."
      );
      e.target.value = "";
      props.onChange({ target: { name: e.target.name, value: "" } });
    }
    setTimeout(() => {
      setOpen(false);
      setClickedOption(false);
    }, 200);
  };

  const handleOptionMouseDown = (e) => {
    setClickedOption(true);
  };

  const handleOptionClick = (e) => {
    e.target.name = props.name;
    props.onChange(e);
  };

  return (
    <div
      style={{ position: "relative", display: "inline-block", ...props.style }}
    >
      <div className={props.classes.label}>{props.title}</div>
      <input
        type="text"
        className={props.classes.field}
        value={props.value}
        // onChange={(e) => props.onChange && props.onChange(e)}
        name={props.name}
        placeholder={props.placeholder}
        onFocus={() => setOpen(true)}
        onBlur={handleBlur}
      />
      <div
        className={open ? props.classes.openDropdown : props.classes.dropdown}
      >
        {props.categories
          .filter(
            (el) => el.toLowerCase().indexOf(props.value.toLowerCase()) !== -1
          )
          .slice(0, 5)
          .map((item, index) => (
            <option
              onMouseDown={handleOptionMouseDown}
              onClick={handleOptionClick}
              className={props.classes.item}
              value={item}
              key={index}
            >
              {item}
            </option>
          ))}
      </div>
    </div>
  );
};

const styles = (theme) => ({
  field: {
    fontSize: 14,
    padding: 10,
    borderWidth: 0,
    backgroundColor: "#f3f3f3",
    width: "100%",
  },
  label: {
    fontSize: 12,
    fontWeight: "500",
    marginBottom: 5,
  },
  item: {
    borderBottomStyle: "solid",
    borderBottomWidth: 0.5,
    borderColor: "#b5b5b5",
    padding: 5,
    fontSize: 12,
  },
  dropdown: {
    display: "none",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "#FFF",
    minWidth: 300,
    cursor: "pointer",
  },
  openDropdown: {
    display: "block",
    position: "absolute",
    zIndex: 1,
    backgroundColor: "#FFF",
    minWidth: 300,
    cursor: "pointer",
  },
});

export default withStyles(styles)(ComboBox);
