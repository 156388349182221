import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

class Forbidden extends Component {
   constructor(props) {
      super(props)

      const { classes } = props;

      this.state = {
         classes,
         data: [],
         loading: true
      }
   }

   render() {
      return (
         <div>
            <div align="center">
               <h1 style={{ textAlign: 'center' }}>403</h1>
               <br />
               <br />
               <br />
               <h2 style={{ textAlign: 'center' }}>Forbidden</h2>
            </div>
         </div>
      );
   }
}

const styles = theme => ({
   fab: {
      margin: 0,
   }
});

export default withStyles(styles)(Forbidden);
