import axios from "axios";
import { Colors } from "../../../../../styles/Colors";

export async function loadReport(props) {

  const groupByOptions = {
    'month': 'monthYear',
    'partnership': 'partnership.type',
    'partner': 'partnership.partner',
    'status': 'status',
  }

  const { startDate, endDate, group = 'month', subGroup = 'status', status = null, includesAccumulator = false, baseDate = "createdAt" } = props;

  try {
    const token = localStorage.getItem('token');
    const url = `${process.env.REACT_APP_API_URL}/v2/report/contracts?from=${startDate}&to=${endDate}&group=${groupByOptions[group]}&subGroup=${groupByOptions[subGroup]}${status ? `&status=${status}` : ''}&includesAccumulator=${includesAccumulator}&baseDate=${baseDate}`
    const response = await axios.get(url, {
      headers: { 'Authorization': "bearer " + token }
    });

    if (response.status === 200) {
      return response.data
    }
    if (response.status === 204) {
      alert('Sem registros\n\nNão encontramos nenhum contrato para o período selecionado.')
      return null
    }
  } catch (error) {
    console.log(error)
    alert('Tivemos um problema\n\nDesculpe. Não foi possível carregar os dados.')
    return null
  }
  finally {
  }
}

const statuses = {
  declined: {
    status: "declined",
    display: 'Reprovados',
    colors: {
      background: Colors.paleRed + '88',
      border: Colors.paleDarkRed + '88',
    }
  },
  pending: {
    status: "pending",
    display: 'Pendentes',
    colors: {
      background: Colors.paleYellow + '88',
      border: Colors.paleDarkYellow + '88',
    }
  },
  active: {
    status: "active",
    display: 'Ativos',
    colors: {
      background: Colors.paleDarkGreen + '88',
      border: Colors.paleDarkerGreen + '88'
    }
  },
  finished: {
    status: "finished",
    display: 'Finalizados',
    colors: {
      background: '#f8f8f8',
      border: '#eee'
    }
  }
};

export const monthlyStatusOrder = [
  statuses.declined,
  statuses.pending,
  statuses.active,
  statuses.finished
];

export const statusOrderByCategory = [statuses.declined, statuses.pending];
export const statusOrderByHistory = [statuses.active, statuses.finished];