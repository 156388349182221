import React from "react";
import { Snackbar } from "@material-ui/core";

export const Alert = ({ snackbar_alert, notificarionColor, msg_alert }) => {
  return (
    <Snackbar open={snackbar_alert} autoHideDuration={6000}>
      <div
        style={{
          textAlign: "center",
          width: "100%",
          color: "#fff",
          fontWeight: 500,
          backgroundColor: notificarionColor,
          display: "flex",
          padding: "20px 26px",
          fontSize: "18px",
          borderRadius: 4,
          marginTop: -150,
          boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
        }}
      >
        {msg_alert}
      </div>
    </Snackbar>
  );
};
