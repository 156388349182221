import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import axios from "axios";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Text } from "../layout/components/text";
import Loading from "../utils/Loading";

const checkStockAlert = async () => {
  const token = localStorage.getItem("token");
  return await axios.get(`${process.env.REACT_APP_API_URL}/v2/stock-alert`, {
    headers: { Authorization: "bearer " + token },
  });
};

export const StockListAlert = () => {
  const [products, setProducts] = useState([]);
  const [kits, setKits] = useState([]);
  const [loadingContent, setLoadingContent] = useState(false);

  const loadProducts = async () => {
    setLoadingContent(true);
    await checkStockAlert().then(({ data = [] }) => {
      setProducts(data.stockAlert);
      setKits(data.kits);
      setLoadingContent(false);
    });
  };

  useEffect(() => {
    loadProducts();
  }, []);

  return (
    <>
      <Loading loading={loadingContent} />
      <ContentHeader title="Estoque" />

      <List product={products} rowAction={window.open} />
      <Kits kits={kits} rowAction={window.open} />
    </>
  );
};

const listColumns = [
  { label: "Produto", field: "name" },
  { label: "Alerta", field: "stockAlert" },
  { label: "Estoque", field: "stockSp" },
];
const listColumnsKit = [
  { label: "Kit", field: "image" },
  { label: "", field: "name" },
  { label: "Produtos sem estoque", field: "name" },
  { label: "Para parceiro", field: "isPartner" },
];

const List = ({ product = [], rowAction }) => {
  return (
    <ContentContainer label={`Alertas (${product.length})`}>
      <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              {listColumns.map(({ label, field }) => (
                <TableCell key={label} style={{ color: "#444" }}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {product?.map((item) => {
              return (
                <TableRow
                  key={item._id}
                  hover
                  style={{ position: "relative", cursor: "pointer" }}
                  onClick={() => {
                    rowAction(`/app/product/${item._id}`);
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  <TableCell
                    align="left"
                    style={{
                      whiteSpace: "nowrap",
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ height: "auto", width: "7rem", borderRadius: 4 }}
                      src={item.urlImg}
                      alt="product"
                    />
                    <Text
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        fontWeight: 500,
                      }}
                    >
                      {item.name}
                    </Text>
                  </TableCell>
                  <TableCell align="left">
                    <Text>{item.stockAlert}</Text>
                  </TableCell>
                  <TableCell align="left">
                    <Text style={{ color: "#d84d38", fontWeight: 500 }}>
                      {item.stockSp}
                    </Text>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </ContentContainer>
  );
};

const Kits = ({ kits = [], rowAction }) => {
  return (
    <ContentContainer label={`Kits com produtos sem estoque (${kits.length})`}>
      <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              {listColumnsKit.map(({ label, field }) => (
                <TableCell key={label} style={{ color: "#444" }}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {kits?.map((kit) => {
              const prods = kit?.products;

              return (
                <TableRow
                  key={kit.name}
                  hover
                  style={{ position: "relative", cursor: "pointer" }}
                  onClick={() => {
                    rowAction(`/app/kit/${kit._id}`);
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  <TableCell style={{ width: "8rem" }}>
                    {kit.urlImg && (
                      <img
                        style={{
                          height: "auto",
                          width: "7rem",
                          borderRadius: 4,
                        }}
                        src={kit.urlImg}
                        alt="product"
                      />
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Text
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        fontWeight: 500,
                      }}
                    >
                      {kit.name}
                    </Text>
                  </TableCell>
                  <TableCell align="left">
                    {prods.map((KitProduct) => {
                      return (
                        <li key={KitProduct._id} style={{ margin: 10 }}>
                          {KitProduct.name}
                        </li>
                      );
                    })}
                  </TableCell>
                  <TableCell align="left">
                    <Text>{kit.toPartner ? "Sim" : "Não"}</Text>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </ContentContainer>
  );
};
